import React from 'react'

import { isPrimitive } from 'utility-types'

import { Select } from 'src/components/base'
import { Flex } from 'src/components/layouts'
import { useUser } from 'src/features/User'
import theme from 'src/styles/theme.module.scss'

import { BtnLogout } from '../BtnLogout'
import { UserRoleIcon } from '../UserRoleIcon'

export function AuthMenu(props: { onLogout(): void }) {
  const { onLogout } = props
  const user = useUser()
  return (
    <Select
      value="user"
      options={[
        {
          label: (
            <Flex align="center" gap={8}>
              <UserRoleIcon
                size={20}
                role={user.role}
                color={theme.th_clr_primary}
              />
              {user.username}
            </Flex>
          ),
          value: 'user',
        },
        {
          label: (
            <BtnLogout
              onClick={onLogout}
              style={{ width: '100%', justifyContent: 'flex-start' }}
            />
          ),
          value: 'logout',
        },
      ]}
      isSearchable={false}
      hideSelectedOptions
      styles={{
        container: base => ({
          ...base,
          minWidth: 130,
        }),
        option: base => ({
          ...base,
          cursor: 'pointer',
          backgroundColor: 'transparent',

          /* Remove padding from option element,
           * so that items with own click handler take all the space
           * and always catch click on them */
          padding: 0,

          '> :only-child': {
            padding: '8px 12px',
          },
        }),
      }}
      // Make sure option always has a child element, so customized padding styles are applied
      formatOptionLabel={({ label }) =>
        isPrimitive(label) ? <div>{label}</div> : label
      }
    />
  )
}
