import { Grid } from 'src/components'

import { IProductTier } from '../../types'

import { ProductTier } from './ProductTier'

export function ProductTiersList(props: { items: IProductTier[] }) {
  const { items } = props
  return (
    <Grid columns={3} autoColumns="max-content">
      {items.map((x, i) => (
        <ProductTier key={i} tier={x} />
      ))}
    </Grid>
  )
}
