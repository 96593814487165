import { push } from 'connected-react-router'
import { toast } from 'react-toastify'

import { routes, useDispatch } from 'src/apps/cabinet'
import { Button, Loader } from 'src/components'

import { BiddingRTQApi } from '../../api'

interface IBtnBuyNowProps {
  price: number
  tier_id: string
  product_id: string
}

export function BtnBuyNow(props: IBtnBuyNowProps) {
  const { price, product_id, tier_id } = props

  const [onClick, { isLoading }] = useBuyProductHandler({
    product_id,
    tier_id,
  })

  return (
    <Button variant="primary" size="sm" onClick={onClick} disabled={isLoading}>
      {isLoading && <Loader size="1em" />}
      Buy now for ${price}
    </Button>
  )
}

function useBuyProductHandler(
  params: Pick<IBtnBuyNowProps, 'product_id' | 'tier_id'>
) {
  const { product_id, tier_id } = params
  const dispatch = useDispatch()

  const [buy, state] = BiddingRTQApi.endpoints.buy.useMutation()

  return [
    async () => {
      try {
        const request = buy({ product: product_id, tier: tier_id })
        const { agreement_id } = await request.unwrap()
        dispatch(push(`${routes.avatar.agreements}#${agreement_id}`))
      } catch (e) {
        toast.error((e as Error).message, {
          autoClose: false,
          toastId: 'buy-now',
        })
      }
    },

    state,
  ] as const
}
