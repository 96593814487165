import { AiOutlineQuestionCircle } from 'react-icons/ai'

import { Badge, Grid } from 'src/components'
import { AbstractChainId } from 'src/types'
import { getChainName } from 'src/utils'

import { IWCPeerMeta } from '../../../../WalletConnect'
import { PeerIcon, PeerLink } from '../PeerLink'

import styles from './PeerInfo.module.scss'

export interface IPeerInfoProps {
  peer: IWCPeerMeta
  chainId: AbstractChainId | undefined
}

export function PeerInfo(props: IPeerInfoProps) {
  const { peer, chainId } = props

  return (
    <Grid>
      <Grid columns="max-content auto" align="center" className={styles.box}>
        <Choose>
          <When condition={peer.icons.length > 0}>
            <PeerIcon
              src={peer.icons[0]}
              alt={peer.name}
              appName={peer.name}
              className={styles.app_icon}
            />
          </When>

          <Otherwise>
            <AiOutlineQuestionCircle className={styles.app_icon} />
          </Otherwise>
        </Choose>

        <div>
          <PeerLink
            to={peer.url}
            className={styles.peer_name}
            appName={peer.name}
          />
          <div className={styles.peer_description}>{peer.description}</div>
        </div>
      </Grid>

      <Grid justify="center" gap="0.5em">
        You will be connected to following chain:
        <Badge border>
          <b>{getChainName(chainId as NonNullable<typeof chainId>)}</b>
        </Badge>
      </Grid>
    </Grid>
  )
}
