// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EthereumTransactionView_stats__1My9J {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  grid-row-gap: 0.5rem;\n  row-gap: 0.5rem;\n  grid-column-gap: 0.5rem;\n  column-gap: 0.5rem;\n  font-size: 0.875rem;\n  align-items: center;\n}\n\n.EthereumTransactionView_stat_name__3gJON {\n  color: var(--th-clr-gray-600);\n}\n\n.EthereumTransactionView_stat_value__rlfye {\n  color: var(--th-clr-gray-700);\n  margin-left: auto;\n  height: 32px;\n  display: grid;\n  align-items: center;\n}", "",{"version":3,"sources":["webpack://src/apps/cabinet/features/Wallet/pages/WalletDetails/WalletConnect/CallRequest/EthereumTransactionView.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,qCAAA;EACA,oBAAA;EAAA,eAAA;EACA,uBAAA;EAAA,kBAAA;EACA,mBAAA;EACA,mBAAA;AACF;;AAEA;EACE,6BAAA;AACF;;AAEA;EACE,6BAAA;EACA,iBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;AACF","sourcesContent":[".stats {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  row-gap: 0.5rem;\n  column-gap: 0.5rem;\n  font-size: 0.875rem;\n  align-items: center;\n}\n\n.stat_name {\n  color: var(--th-clr-gray-600);\n}\n\n.stat_value {\n  color: var(--th-clr-gray-700);\n  margin-left: auto;\n  height: 32px;\n  display: grid;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stats": "EthereumTransactionView_stats__1My9J",
	"stat_name": "EthereumTransactionView_stat_name__3gJON",
	"stat_value": "EthereumTransactionView_stat_value__rlfye"
};
export default ___CSS_LOADER_EXPORT___;
