import { ReactElement, ReactNode, useMemo, useRef } from 'react'

import {
  Button,
  ErrorMessage,
  IButtonProps,
  RTQSuspender,
  useModal,
} from 'src/components/base'
import { useAsyncEffect, useOnMount } from 'src/hooks'

export interface IPDFPreviewProps extends IStyled {
  src: string | File
  label?: ReactNode
  btnVariant?: IButtonProps['variant']
  modalTitle?: ReactNode
}

export function PDFPreview(props: IPDFPreviewProps) {
  const {
    src,
    label = 'Open PDF',
    btnVariant,
    modalTitle,
    className,
    style,
  } = props

  const { fileURL, isRemoteFile } = useResolveURL(src)

  const $preview = (
    <embed
      src={fileURL}
      width="600"
      height="800"
      style={{ maxHeight: '100%' }}
    />
  )

  const modal = useModal(
    { modalBodyStyle: { padding: 0 }, title: modalTitle },
    /* prevent scrollbars in modal body */
    <div style={{ height: '100%', overflow: 'hidden' }}>
      <Choose>
        <When condition={isRemoteFile}>
          <URLPreloader src={src as string}>{$preview}</URLPreloader>
        </When>

        <Otherwise>{$preview}</Otherwise>
      </Choose>
    </div>
  )

  return (
    <Button
      onClick={modal.open}
      variant={btnVariant}
      className={className}
      style={style}
    >
      {label}
      {modal.$element}
    </Button>
  )
}

function useResolveURL(src: string | File) {
  const refURL = useRef<string>()

  const cleanupMaybe = () => {
    if (refURL.current !== undefined) {
      URL.revokeObjectURL(refURL.current)
    }
  }

  const isRemoteFile = typeof src === 'string'

  const fileURL = useMemo(() => {
    if (isRemoteFile) {
      return src
    }
    cleanupMaybe()
    refURL.current = URL.createObjectURL(src)
    return refURL.current
  }, [src, isRemoteFile])

  useOnMount(() => cleanupMaybe)

  return { fileURL, isRemoteFile }
}

/**
 * @see https://dcspoc.atlassian.net/browse/PLT-676?focusedCommentId=10842
 */
function URLPreloader(props: { src: string; children: ReactElement }) {
  const { src, children } = props
  const { value: isSuccess, status } = useAsyncEffect(
    () => fetch(src, { method: 'HEAD' }).then(r => r.ok),
    [src]
  )
  const isLoading = status === 'loading'

  return (
    <RTQSuspender
      state={{
        isSuccess: isSuccess ?? false,
        isError: !isSuccess,
        isLoading,
        isFetching: isLoading,
        error: 'File is not found',
      }}
      renderError={e => (
        <div style={{ padding: 12 }}>
          <ErrorMessage block>{e}</ErrorMessage>
        </div>
      )}
    >
      {() => children}
    </RTQSuspender>
  )
}
