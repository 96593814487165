import { parseWalletConnectUri } from '@walletconnect/utils'
import { uniqueId } from 'lodash'

import { extendLogger } from 'src/log'

import { WCLogger } from './Logger'
import { IConnectorOptions, IWCFacade, WCClientUpdateListener } from './types'
// import { WCFacade_V1 } from './v1/Facade'
import { WCFacade_V2 } from './v2/Facade'

/**
 * This class provides a unified interface for WC@1 and WC@2 clients,
 * trying to seamlessly switching them under the hood depending on provided connection URI.
 */
export class WCManager implements IWCFacade {
  private readonly _v1: IWCFacade
  private readonly _v2: IWCFacade
  private readonly _dummy: IWCFacade

  private _uri: string | null = null
  private _version: number | null = null

  private _onUpdateListeners: WCClientUpdateListener[] = []

  readonly id = uniqueId('Manager_')
  protected logger = extendLogger(this.id, WCLogger.log)

  static async init(opts: IConnectorOptions) {
    return new this({
      uri: opts.uri,
      // Probably should remove this part completely,
      // since WC@1 is officially deprecated now, and it's default bridge server doesn't work at all.
      // client_v1: new WCFacade_V1(opts),
      client_v1: DUMMY_FACADE,
      client_v2: await WCFacade_V2.init(opts),
    })
  }

  protected constructor(opts: {
    uri: IConnectorOptions['uri']
    client_v1: IWCFacade
    client_v2: IWCFacade
  }) {
    this._dummy = DUMMY_FACADE
    this._v1 = opts.client_v1
    this._v2 = opts.client_v2

    this.setupUpdateListeners()

    const { uri } = opts
    if (uri !== undefined) {
      /* returned promise is ignored
       * not sure whether it can be a source of issues
       * so far works fine */
      this.setURI(uri)
    } else {
      this._version = this.resolveInitialVersion()
    }

    this.logger.log('New WC Manager created', this)
  }

  protected get client(): IWCFacade {
    switch (this._version) {
      case 1:
        return this._v1
      case 2:
        return this._v2
      default:
        return this._dummy
    }
  }

  async setURI(uri: string) {
    if (uri === this._uri) return
    try {
      const prevVersion = this._version
      this._version = parseWalletConnectUri(uri).version
      this._uri = uri
      this.logger.log('Setting new URI:\n', uri)
      await this.client.setURI(uri)
      if (prevVersion !== this._version) {
        this.logger.log(
          'Protocol version changed: %s -> %s\nSwitching active client',
          prevVersion,
          this._version
        )
        this.emitUpdate()
      }
    } catch (e) {
      console.error('[WalletConnect] Failed to update URI', e)
    }
  }

  private resolveInitialVersion(): typeof this._version {
    if (this._v1.connected && !this._v2.connected) {
      return 1
    } else if (this._v2.connected && !this._v1.connected) {
      return 2
    } else {
      /*
       * If somehow both versions are connected at the same time
       * (which should not be allowed by our UI btw),
       * it is an ambiguous situation, and there can be only an opinionated decision here.
       * I think we should require to explicitly initiate a new session in this case,
       * rather then trying to "restore" a god knows what.
       */
      return null
    }
  }

  get connected() {
    return this.client.connected
  }

  get account() {
    return this.client.account
  }

  get chainId() {
    return this.client.chainId
  }

  get peerMeta() {
    return this.client.peerMeta
  }

  get state() {
    return this.client.state
  }

  approveSession(...args: Parameters<IWCFacade['approveSession']>) {
    return this.client.approveSession(...args)
  }

  rejectSession(...args: Parameters<IWCFacade['rejectSession']>) {
    return this.client.rejectSession(...args)
  }

  updateSession(...args: Parameters<IWCFacade['updateSession']>) {
    return this.client.updateSession(...args)
  }

  disconnect(...args: Parameters<IWCFacade['disconnect']>) {
    return this.client.disconnect(...args)
  }

  async awaitSessionRequest(
    ...args: Parameters<IWCFacade['awaitSessionRequest']>
  ) {
    const [uri] = args
    if (uri !== undefined) {
      await this.setURI(uri)
    }
    return this.client.awaitSessionRequest(...args)
  }

  approveRequest(...args: Parameters<IWCFacade['approveRequest']>) {
    return this.client.approveRequest(...args)
  }

  rejectRequest(...args: Parameters<IWCFacade['rejectRequest']>) {
    return this.client.rejectRequest(...args)
  }

  onUpdate(...args: Parameters<IWCFacade['onUpdate']>) {
    const [cb] = args
    this._onUpdateListeners.push(cb)
  }

  teardown(...args: Parameters<IWCFacade['teardown']>) {
    this._v1.teardown(...args)
    this._v2.teardown(...args)
    this._dummy.teardown(...args)
    this._onUpdateListeners = []
  }

  protected setupUpdateListeners() {
    const sub = (client: IWCFacade) => {
      client.onUpdate(() => {
        if (this.client === client) {
          this.emitUpdate()
        }
      })
    }
    sub(this._v1)
    sub(this._v2)
    sub(this._dummy)
  }

  protected emitUpdate() {
    this._onUpdateListeners.forEach(fn => fn())
  }
}

// ---

const DUMMY_FACADE: IWCFacade = {
  get connected() {
    return false
  },
  get account() {
    return undefined
  },
  get chainId() {
    return undefined
  },
  get state() {
    return {
      assetSymbol: null,
      callRequest: null,
      sessionRequest: null,
    }
  },
  get peerMeta() {
    return undefined
  },
  teardown() {},
  async approveSession() {},
  async rejectSession() {},
  async updateSession() {},
  async disconnect() {},
  async approveRequest() {},
  async rejectRequest() {},
  onUpdate() {},
  async setURI() {},
  awaitSessionRequest() {
    return Promise.reject(new Error('Dummy facade cannot establish session'))
  },
}
