import { matchPath } from 'react-router-dom'

import { Location } from 'history'

import { NavItem, Navigation } from 'src/apps/cabinet/components'
import { routes as allRoutes } from 'src/apps/cabinet/routes'

const routes = allRoutes.advisor

const matches = (location: Location, params: Parameters<typeof matchPath>[1]) =>
  matchPath(location.pathname, params) !== null

export function AdvisorNavigation() {
  return (
    <Navigation>
      <NavItem
        link={routes.dashboard}
        isActive={(match, location) =>
          matches(location, { exact: true, path: routes.dashboard }) ||
          matches(location, routes.avatar_details)
        }
      >
        Contacts
      </NavItem>

      <NavItem
        link={routes.templates}
        isActive={(match, location) =>
          matches(location, routes.templates) ||
          matches(location, routes.agreement_form)
        }
      >
        Agreement Templates
      </NavItem>
    </Navigation>
  )
}
