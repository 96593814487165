// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_user_photo__38qT5 {\n  flex-shrink: 0;\n  display: inline-block;\n  border-radius: 12px;\n  border: 1px solid var(--th-border-color);\n  overflow: hidden;\n  width: var(--size, 64px);\n  height: var(--size, 64px);\n  aspect-ratio: 1;\n}\n.styles_user_photo__38qT5 img {\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n}", "",{"version":3,"sources":["webpack://src/components/domain/UserPhoto/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,qBAAA;EAEA,mBAAA;EACA,wCAAA;EACA,gBAAA;EAGA,wBAAA;EACA,yBAAA;EACA,eAAA;AADF;AAGE;EACE,WAAA;EACA,YAAA;EACA,iBAAA;AADJ","sourcesContent":[".user_photo {\n  flex-shrink: 0;\n  display: inline-block;\n\n  border-radius: 12px;\n  border: 1px solid var(--th-border-color);\n  overflow: hidden;\n\n  @value default-size: 64px;\n  width: var(--size, #{default-size});\n  height: var(--size, #{default-size});\n  aspect-ratio: 1;\n\n  img {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"default-size": "64px",
	"user_photo": "styles_user_photo__38qT5"
};
export default ___CSS_LOADER_EXPORT___;
