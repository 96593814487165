import { Badge, IBadgeProps } from 'src/components/base'
import { AgreementType } from 'src/types'

import { AgreementTypeIcon } from '../../AgreementIcon'
import { IBaseAgreementBadgeProps } from '../types'

export interface IAgreementTypeBadgeProps extends IBaseAgreementBadgeProps {
  children: AgreementType
}

export function AgreementTypeBadge(props: IAgreementTypeBadgeProps) {
  const { children: type, iconSize = 20, showTitle, ...rest } = props
  return (
    <Badge {...rest} variant={getBadgeVariant(type)}>
      <AgreementTypeIcon type={type} size={iconSize} showTitle={showTitle} />
    </Badge>
  )
}

function getBadgeVariant(type: AgreementType): IBadgeProps['variant'] {
  switch (type) {
    case AgreementType.DfnsAcquisition:
    case AgreementType.SafeheronAcquisition:
      return 'success'
    case AgreementType.NodeAcquisition:
      return 'info'
    default:
      return undefined
  }
}
