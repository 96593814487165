import { useState } from 'react'
import { useWatch } from 'react-hook-form'

import { routes } from 'src/apps/cabinet/routes'
import { Button, Fieldset, Form, FormContext, Grid, Link } from 'src/components'
import { IAgreement } from 'src/types'

import { BiddingRTQApi } from '../../../api'
import { ILotState, IProductTier } from '../../../types'

import { BidOutcome } from './BidOutcome'
import { FieldStake } from './FieldStake'
import { LotTimer } from './LotTimer'
import { isParticipatingAuction } from './lib'
import { IBiddingFormFields } from './types'

import styles from './BiddingForm.module.scss'

interface IBiddingFormProps {
  tier: IProductTier
  lot: ILotState
  agreementId: IAgreement['id'] | undefined
  onCancel(): void
}

export function BiddingForm(props: IBiddingFormProps) {
  const { tier, lot, agreementId, onCancel } = props

  const FORM_ID = `bid-form-${lot.id}`

  const [isFinished, setIsFinished] = useState(false)
  const [makeBid] = BiddingRTQApi.endpoints.makeBid.useMutation()

  const currentBid = lot.price
  const minBid = currentBid + 1

  return (
    <Form<IBiddingFormFields>
      id={FORM_ID}
      showDefaultControls={false}
      defaultValues={{ price: minBid }}
      onSubmit={async ({ price }) =>
        await makeBid({
          price,
          tier: tier.id,
          product: tier.product_id,
          lot: lot.id,
        }).unwrap()
      }
      onReset={onCancel}
    >
      <Grid>
        <Grid columns="1fr 180px" className={styles.bid_state_display}>
          <div>Current bid: ${currentBid}</div>

          <LotTimer
            className={styles.timer}
            lot={lot}
            onExpired={() => {
              setIsFinished(true)
            }}
          />

          <BidOutcome
            isFinished={isFinished}
            isWinning={lot.winning_bid}
            className={styles.outcome}
          />
        </Grid>

        <Fieldset disabled={isFinished}>
          <FieldStake
            min={minBid}
            isWinning={lot.winning_bid}
            currentBid={currentBid}
          />
        </Fieldset>

        <Grid columns autoColumns="1fr">
          <Button variant="secondary" type="reset" size="md">
            Cancel
          </Button>

          <FormContext>
            {form => (
              <Choose>
                <When
                  condition={
                    isFinished &&
                    isParticipatingAuction(form.state) &&
                    lot.winning_bid
                  }
                >
                  <Link
                    to={`${routes.avatar.agreements}#${agreementId}`}
                    size="md"
                    variant="btn-primary"
                  >
                    Sign contract
                  </Link>
                </When>

                <Otherwise>
                  <BtnConfirmBid
                    currentBid={currentBid}
                    disabled={isFinished || lot.winning_bid}
                    formId={FORM_ID}
                  />
                </Otherwise>
              </Choose>
            )}
          </FormContext>
        </Grid>
      </Grid>
    </Form>
  )
}

function BtnConfirmBid(props: {
  currentBid: number
  formId: string
  disabled?: boolean
}) {
  const { disabled = false, currentBid, formId } = props
  const yourBid = useWatch<IBiddingFormFields>({ name: 'price' })
  return (
    <Button
      type="submit"
      form={formId}
      variant="primary"
      size="md"
      disabled={disabled || yourBid <= currentBid}
      className={styles.btn_action}
    >
      Confirm bid ${yourBid}
    </Button>
  )
}
