import { Page } from 'src/components'

import { WalletProvider } from '../../../types'

import { WalletConfirmCard } from './components/WalletConfirmCard'
import { PROVIDERS_DATA } from './const'

import styles from './styles.module.scss'

interface IConfirmStepProps {
  name: string
  provider: WalletProvider
  onSubmit(): void
}

export const ConfirmStep = (props: IConfirmStepProps) => {
  const { name, provider, onSubmit } = props

  return (
    <Page
      title="Confirm your wallet"
      description={
        <div className={styles.description}>
          Take a moment to review the wallet details. Note that a short waiting
          period is required before your wallet is fully installed.
        </div>
      }
      alignHeader="center"
      alignContent="center"
    >
      <WalletConfirmCard
        walletName={name}
        providerName={PROVIDERS_DATA[provider].title}
        description={PROVIDERS_DATA[provider].description}
        logo={PROVIDERS_DATA[provider].logo}
        bg={PROVIDERS_DATA[provider].bg}
        features={PROVIDERS_DATA[provider].features}
        onSubmit={onSubmit}
      />
    </Page>
  )
}
