import { push } from 'connected-react-router'
import { useCallback } from 'react'
import { generatePath } from 'react-router-dom'

import { routes, useDispatch } from 'src/apps/cabinet'

import { AdvisorApi } from '../../../api'
import { IAgreementPayload, IInvestorAvatar } from '../../../types'

export function useOnSubmit(avatars: IInvestorAvatar[]) {
  const [submit] = AdvisorApi.endpoints.createAgreement.useMutation()
  const dispatch = useDispatch()

  return useCallback(
    async (data: IAgreementPayload) => {
      const nameValue = data.avatar
      const avatar = avatars.find(x => x.name === nameValue)
      const isNew = avatar === undefined

      await submit({ ...data, avatar: nameValue }).unwrap()

      const redirectLink = isNew
        ? routes.advisor.dashboard
        : generatePath(routes.advisor.avatar_details, {
            id: avatar.id,
          })

      dispatch(push(redirectLink))
    },
    [avatars, dispatch, submit]
  )
}
