import { BlockchainAsset, DateTime } from 'src/types'

import { BlockchainAddress, IWalletDescriptor, WalletID } from './base'

export enum TransactionVerifyStatus {
  None = 'NONE',
  Warn = 'WARN',
  Block = 'BLOCK',
}

export interface ITransactionPayload {
  from: WalletID
  to: BlockchainAddress
  value: string
  asset: BlockchainAsset
}

export interface ITransactionAPIParams
  extends ITransactionPayload,
    Pick<IWalletDescriptor, 'provider'> {}

export interface ITransactionVerifyResponse {
  action: TransactionVerifyStatus
  warnings: ITransactionVerifyWarningResponse[]
}

export interface ITransactionVerifyWarningResponse {
  kind: string
  message: string
  severity: TxnWarningSeverity
}

export enum TxnWarningSeverity {
  Warning = 'WARNING',
  Critical = 'CRITICAL',
}

export interface ITransactionHistoryEntry {
  type: 'sent' | 'received'
  hash: string
  from: BlockchainAddress
  to: BlockchainAddress
  date: DateTime
  value: string
  value_usd: string
  gas: string // in WEI
}

export interface ITransactionFormFields
  extends Omit<ITransactionPayload, 'from'> {}
