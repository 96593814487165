import { generatePath } from 'react-router-dom'

import { routes } from 'src/apps/cabinet/routes'
import { Icon, Link, Tooltip } from 'src/components'

import { WalletProviderBadge, WalletStatusBadge } from '../../../components'
import { isWalletReady } from '../../../lib'
import { IWalletListItem } from '../../../types'

import { WalletAssets } from './WalletAssets'
import { WalletBalance } from './WalletBalance'

import styles from './Wallet.module.scss'

export function Wallet(props: { wallet: IWalletListItem }) {
  const { wallet } = props

  return (
    <div className={styles.root} id={wallet.address}>
      <div className={styles.main}>
        <div className={styles.header}>
          <Tooltip
            overlay={wallet.wallet_name}
            autoWrap={false}
            placement="right"
            destroyTooltipOnHide
          >
            <div className={styles.title}>{wallet.wallet_name}</div>
          </Tooltip>

          <div className={styles.menu}>
            <WalletStatusBadge status={wallet.status} />
          </div>
        </div>

        <If condition={isWalletReady(wallet)}>
          <div className={styles.balance}>
            <WalletBalance wallet={wallet} />
          </div>

          <div className={styles.assets}>
            <div className={styles.assets_title}>Assets</div>
            <WalletAssets wallet={wallet} />
          </div>
        </If>
      </div>

      <div className={styles.footer}>
        <div className={styles.provider}>
          <WalletProviderBadge type={wallet.type} />
        </div>

        {/* No point in going to details page until wallet is ready, as there is nothing to show there */}
        <If condition={isWalletReady(wallet)}>
          <Link
            to={generatePath(routes.avatar.wallet.wallet_details, {
              id: wallet.address,
            })}
            variant="btn-secondary"
          >
            View Wallet <Icon type="chevron-right" size="1em" />
          </Link>
        </If>
      </div>
    </div>
  )
}
