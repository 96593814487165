import { ReactNode } from 'react'
import { LinkProps } from 'react-router-dom'

import { ButtonVariant } from '../Button'

export const BTN_CLASS_PREFIX = 'btn-'

type LinkButtonVariant = Values<{
  [K in ButtonVariant]: `${typeof BTN_CLASS_PREFIX}${K}`
}>

export type LinkVariant =
  | 'primary'
  | 'secondary'
  | 'none' /* unstyled variant */
  | LinkButtonVariant

export type LinkSize = 'sm' | 'md' | 'lg' | 'xl'

export interface ILinkProps extends Omit<LinkProps, 'component'> {
  variant?: LinkVariant
  size?: LinkSize
  startIcon?: ReactNode
  endIcon?: ReactNode
  disabled?: boolean
  accent?: boolean
  external?: boolean
}
