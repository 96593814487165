import Messages from './messages'
import { DefaultValidationMessage, ErrorType } from './types'

function isMessageMissing(type: ErrorType, rule: unknown) {
  switch (type) {
    case 'required':
      return typeof rule === 'boolean'

    case 'min':
    case 'max':
    case 'minLength':
    case 'maxLength':
      return typeof rule === 'number'

    case 'pattern':
      return rule instanceof RegExp

    case 'validate':
      // validation func by definition returns an error message
      return false

    default:
      return false
  }
}

export function getDefaultMsg(
  type: ErrorType,
  rule: unknown,
  value: unknown
): string | undefined {
  if (isMessageMissing(type, rule)) {
    const defaultMsg = Messages[type] as DefaultValidationMessage
    return typeof defaultMsg === 'function'
      ? defaultMsg(rule, value)
      : defaultMsg
  }
  return undefined
}

type Validator<T> = (x: T) => string | undefined

type ValidatorBuilderArgs<T> = [
  predicate: (x: T) => boolean,
  msg: string | ((x: T) => string)
]

function validatorFromConfig<T>(
  ...args: ValidatorBuilderArgs<T>
): Validator<T> {
  const [predicate, msg] = args
  return x => {
    const isValid = predicate(x)
    if (isValid) {
      return undefined
    }
    return typeof msg === 'string' ? msg : msg(x)
  }
}

export function makeValidator<T>(...args: ValidatorBuilderArgs<T>): Validator<T>
export function makeValidator<T>(
  configs: Array<ValidatorBuilderArgs<T>>
): Validator<T>

export function makeValidator<T>(...args: unknown[]): Validator<T> {
  let configs: Array<ValidatorBuilderArgs<T>>
  if (Array.isArray(args[0])) {
    configs = args[0]
  } else {
    configs = [[args[0], args[1]] as ValidatorBuilderArgs<T>]
  }

  const fns = configs.map(x => validatorFromConfig(...x))
  return x => {
    for (const fn of fns) {
      const result = fn(x)
      if (result !== undefined) {
        return result
      }
    }
    return undefined
  }
}
