import clsx from 'clsx'

import { WalletProvider } from '../../types'

import { ReactComponent as LogoDFNS } from './dfns.svg'
import imgLogoSafeheron from './safeheron.png'
import { ReactComponent as LogoSafeheronCompact } from './safeheron_compact.svg'

import styles from './styles.module.scss'

export function WalletProviderBadge(props: {
  type: WalletProvider
  compact?: boolean
}) {
  const { type, compact = false } = props

  if (compact) {
    switch (type) {
      case 'safeheron':
        return <LogoSafeheronCompact className={styles.compact} />
      case 'dfns':
        return (
          <div className={clsx(styles.compact, styles.dfns)}>
            <LogoDFNS />
          </div>
        )
    }
  } else {
    switch (type) {
      case 'safeheron':
        return <img src={imgLogoSafeheron} alt={type} className={styles.full} />
      case 'dfns':
        return <LogoDFNS className={styles.full} />
    }
  }
}
