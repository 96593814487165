import { useRef, useState } from 'react'

export * from '@react-hook/async'

/**
 * Use along with `useAsync` hook:
 * `const [state, trigger] = useAsyncUnwrap(useAsync(myFn))`
 * Here, `trigger` fn will return non-void promise,
 * which will resolve to a state value, and reject in case of crash
 * (while still updating corresponding fields in `state`).
 * Similar to what `.unwrap()` in RTQ does, but without that intermediate call.
 */
export function useAsyncUnwrap<
  S extends { value: unknown; error: unknown },
  F extends Func<Promise<void>>
>([state, trigger]: readonly [S, F]) {
  const refTrigger = useRef(trigger)
  refTrigger.current = trigger

  const refState = useRef(state)
  refState.current = state

  const [cb] = useState(() => {
    return (...args: Parameters<F>) => {
      const promise = refTrigger.current(...args)
      const promiseWithValue = promise.then(() => {
        const { value, error } = refState.current
        return error === undefined ? value : Promise.reject(error)
      })
      return promiseWithValue as Promise<Exclude<S['value'], undefined>>
    }
  })

  return [state, cb] as const
}
