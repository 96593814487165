import { AgreementStatus, AgreementType, UserRole } from '../types'

export * from './misc'
export * from './refs'
export * from './location'
export * from './format'
export * from './react'
export * from './redux'
export * from './storage'
export * from './blockchain'

export function getAgreementTypeLabel(x: AgreementType) {
  switch (x) {
    case AgreementType.NodeAcquisition:
      return 'Node Acquisition'
    case AgreementType.DfnsAcquisition:
      return 'DFNS Acquisition'
    case AgreementType.SafeheronAcquisition:
      return 'Safeheron Acquisition'
    case AgreementType.ManagedPortfolio:
      return 'Managed Portfolio'
  }
}

export function getAgreementStatusLabel(x: AgreementStatus) {
  switch (x) {
    case AgreementStatus.Proposed:
      return 'Proposed'
    case AgreementStatus.Verified:
      return 'Verified'
    case AgreementStatus.Signed:
      return 'Signed'
    case AgreementStatus.Notarised:
      return 'Notarised'
    case AgreementStatus.Declined:
      return 'Declined'
  }
}

export function getUserRoleLabel(x: UserRole) {
  switch (x) {
    case UserRole.Investor:
      return 'Investor'
    case UserRole.Avatar:
      return 'Avatar'
    case UserRole.Advisor:
      return 'Network Service Provider'
    case UserRole.Operator:
      return 'Network Operator'
    default:
      return 'unknown role'
  }
}
