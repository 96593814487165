import { LocationDescriptorObject } from 'history'
import qs from 'qs'

import { AgreementType } from 'src/types'

import { routes } from '../../routes'

import { IAgreementFormQueryParams, IInvestorAvatar } from './types'

export function buildAgreementFormLink(
  opts: {
    type?: AgreementType
    user?: IInvestorAvatar['id']
  } = {}
): LocationDescriptorObject {
  const { type, user } = opts
  const params: IAgreementFormQueryParams = {
    type,
    user: user?.toString(),
  }
  return {
    pathname: routes.advisor.agreement_form,
    search: qs.stringify(params),
  }
}
