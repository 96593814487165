import { resolveCSSSize } from 'src/utils'

import { IBoxSize, MaybeBoxSize } from './types'

import styles from './Box.module.scss'

export function isBoxSize(x: unknown): x is IBoxSize {
  switch (x) {
    case 'xs':
    case 'sm':
    case 'md':
    case 'lg':
    case 'xl':
    case '2xl':
      return true
    default:
      return false
  }
}

export function resolveBoxCSSSize(x: MaybeBoxSize | undefined) {
  // return isBoxSize(x) ? 'var(--box-size)' : resolveCSSSize(x)
  return isBoxSize(x) ? undefined : resolveCSSSize(x)
}

export function resolveBoxSizeClassName(
  x: MaybeBoxSize | undefined
): string | undefined {
  if (!isBoxSize(x)) return undefined
  switch (x) {
    case 'xs':
      return styles.size_xs
    case 'sm':
      return styles.size_sm
    case 'md':
      return styles.size_md
    case 'lg':
      return styles.size_lg
    case 'xl':
      return styles.size_xl
    case '2xl':
      return styles.size_2xl
  }
}
