import { Route, Switch } from 'react-router-dom'

import { routes } from 'src/apps/cabinet'

import { AdvisorLayout } from './components'
import { AgreementFormPage } from './pages/AgreementForm'
import { AgreementTemplatesPage } from './pages/AgreementTemplates'
import { AvatarDetailsPage } from './pages/AvatarDetails'
import { ContactsPage } from './pages/ContactsPage'
import { InboxPage } from './pages/InboxPage'

export function AdvisorRouter() {
  return (
    <AdvisorLayout>
      <Switch>
        <Route path={routes.advisor.dashboard} component={ContactsPage} exact />
        <Route path={routes.advisor.inbox} component={InboxPage} exact />
        <Route
          path={routes.advisor.templates}
          component={AgreementTemplatesPage}
          exact
        />
        <Route
          path={routes.advisor.avatar_details}
          component={AvatarDetailsPage}
          exact
        />
        <Route
          path={routes.advisor.agreement_form}
          component={AgreementFormPage}
          exact
        />
        <Route
          path={routes.advisor.agreement_details}
          render={() => 'TODO: agreement details'}
          exact
        />
      </Switch>
    </AdvisorLayout>
  )
}
