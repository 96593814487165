import { AiOutlineQuestionCircle } from 'react-icons/ai'

import {
  Card,
  FallbackImg,
  Grid,
  Link,
  List,
  Page,
  RTQSuspender,
  Suspendable,
} from 'src/components'

import { WalletRTQApi } from '../../api'
import { IDefiProduct } from '../../types'

export function ProductsListPage() {
  const state = WalletRTQApi.endpoints.getProducts.useQuery()
  return (
    <Page title="DeFi products">
      <RTQSuspender state={state}>
        {products => (
          <Suspendable>
            <Card style={{ width: 500 }}>
              <List data={products} renderItemContent={renderListItem} />
            </Card>
          </Suspendable>
        )}
      </RTQSuspender>
    </Page>
  )
}

function renderListItem(product: IDefiProduct) {
  const { name, link, description, icon } = product
  const IMG_STYLE = { width: '2rem', height: '2rem', borderRadius: '50%' }
  return (
    <Grid columns="max-content 1fr">
      <FallbackImg
        src={icon}
        alt={name}
        style={IMG_STYLE}
        fallback={<AiOutlineQuestionCircle style={IMG_STYLE} />}
      />
      <div>
        <Link external to={link} variant="secondary">
          {name}
        </Link>
        <div>{description}</div>
      </div>
    </Grid>
  )
}
