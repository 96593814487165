import { flatten, sortBy } from 'lodash'
import { v4 as uuid } from 'uuid'

import { rtqPromisifyBaseQuery, rtqResolveQueryFn } from 'src/api'
import { IUser } from 'src/apps/cabinet/features/User/types'
import { AgreementType } from 'src/types'
import { toFormData } from 'src/utils'

import {
  ICreateWalletParams,
  ICreateWalletPayload,
  ISignWalletAgreementPayload,
  IWallet,
  IWalletListItem,
  IWalletListItemResponse,
  WalletID,
  WalletProvider,
  WalletStatus,
} from '../../types'

import Base, { tags } from './base'

export default Base.injectEndpoints({
  endpoints: builder => ({
    getWallet: builder.query<IWallet, WalletID>({
      query: uuid => `wallet/${uuid}`,
      providesTags: result => tags.Wallet.providesOne(result?.address),
    }),

    createWallet: builder.mutation<void, ICreateWalletParams>({
      query: data => {
        /* Generating wallet id on FE is intentional.
         * For something something "requests caching" / "single source of truth" / ... reasons. */
        const address = uuid()

        const payload: ICreateWalletPayload = {
          wallet_name: data.name,
          type: data.provider,
          address,
          request_id: address,
        }

        return {
          url: 'wallet',
          method: 'POST',
          data: payload,
        }
      },
      invalidatesTags: () => [tags.Wallet.list()],
    }),

    renameWallet: builder.mutation<void, { wallet: WalletID; name: string }>({
      query: ({ wallet, name }) => {
        return {
          url: 'wallet',
          method: 'PATCH',
          data: {
            request_id: wallet,
            wallet_name: name,
          },
        }
      },
      invalidatesTags: (result, err, { wallet }) => [tags.Wallet(wallet)],
    }),

    getWalletsList: builder.query<
      IWalletListItem[],
      { balance?: boolean } | void
    >({
      queryFn: async (
        { balance: showBalance = false } = {},
        rtq,
        opts,
        baseQuery
      ) => {
        const byProvider = async (
          provider: WalletProvider
        ): Promise<IWalletListItem[]> => {
          const req = rtqPromisifyBaseQuery<IWalletListItemResponse[]>(
            baseQuery({
              url: `wallets/${provider}`,
              params: {
                balances: showBalance,
              },
            })
          )
          const res = await req
          return res.map(({ request_id, ...rest }) => ({
            ...rest,
            type: provider,
            address: request_id,
          }))
        }

        return rtqResolveQueryFn(() => {
          const wallets = Promise.all([
            byProvider('dfns'),
            byProvider('safeheron'),
          ])
          return wallets
            .then(flatten)
            .then(xs => sortBy(xs, getWalletSortingWeight).reverse())
        })
      },

      providesTags: result =>
        tags.Wallet.providesList(result?.map(x => x.address)),
    }),

    // TODO:
    //  implement this API on server?
    //  currently files are static in FE folder
    getWalletAgreement: builder.query<string, WalletProvider>({
      queryFn: provider => {
        function getLink() {
          switch (provider) {
            case 'dfns':
              return '/Wallet-Agreement-DFNS.html'
            case 'safeheron':
              return '/Wallet-Agreement-Safeheron.html'
          }
        }

        return rtqResolveQueryFn(() => fetch(getLink()).then(res => res.text()))
      },
    }),

    signWalletAgreement: builder.mutation<
      void,
      { avatarId: IUser['id']; provider: WalletProvider }
    >({
      query: ({ avatarId, provider }) => {
        const payload: ISignWalletAgreementPayload = {
          avatar: avatarId.toString(),
          agreementType: getAgreementTypeByProvider(provider),
        }
        return {
          url: '/avatar/agreements/auto-sign',
          method: 'post',
          data: toFormData(payload),
        }
      },
    }),
  }),
})

/** @link https://dcspoc.atlassian.net/browse/PLT-559  */
function getWalletSortingWeight(wallet: IWalletListItem) {
  return `${getWalletStatusWeight(wallet.status)}_${wallet.created_at}`
}

function getWalletStatusWeight(status: WalletStatus) {
  switch (status) {
    case WalletStatus.Provisioned:
      return 3
    case WalletStatus.In_progress:
      return 2
    case WalletStatus.Pending:
      return 1
    case WalletStatus.Failed:
      return 0
    default:
      return -1
  }
}

function getAgreementTypeByProvider(provider: WalletProvider): AgreementType {
  switch (provider) {
    case 'dfns':
      return AgreementType.DfnsAcquisition
    case 'safeheron':
      return AgreementType.SafeheronAcquisition
    default:
      throw new Error(`Unknown provider: ${provider}`)
  }
}
