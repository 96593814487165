import { TAG_LIST, createTag } from './base'
import { providesTagMaybe, providesTagsList } from './providers'
import { T_ID, TagType, TypedTagFunc } from './types'

export const rtqTag = <T extends TagType, ID extends T_ID>(type: T, id: ID) =>
  createTag(type, id)

rtqTag.type = <T extends TagType>(type: T): TypedTagFunc<T> => {
  const fn = <ID extends T_ID>(id: ID) => rtqTag<T, ID>(type, id)
  fn.toString = () => type
  fn.list = () => rtqTag(type, TAG_LIST)
  fn.providesOne = providesTagMaybe.type(type)
  fn.providesList = providesTagsList.type(type)
  return fn
}

rtqTag.list = <T extends TagType>(type: T) => rtqTag(type, TAG_LIST)
