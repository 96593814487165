import {
  Action,
  ThunkAction,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import {
  TypedUseSelectorHook,
  useDispatch as useDispatchBase,
  useSelector as useSelectorBase,
} from 'react-redux'

import { rtqApi } from 'src/api'
import { HistoryReferrerSlice } from 'src/features/HistoryReferrer'

import { AdvisorApi, AdvisorSlice } from './features/Advisor'
import { AvatarRTQApi, AvatarSlice } from './features/Avatar'
import { BiddingRTQApi } from './features/Bidding'
import { UserSlice, selectUser } from './features/User'
import { WalletRTQApi } from './features/Wallet'
import { history } from './routes'

// ---
// "register" all your RTQ apis in this list
const RTQ_APIS = [rtqApi, AdvisorApi, AvatarRTQApi, WalletRTQApi, BiddingRTQApi]

// ---

const RTQ_REDUCERS = RTQ_APIS.reduce(
  (m, x) => ({ ...m, [x.reducerPath]: x.reducer }),
  {} as Record<typeof RTQ_APIS[0]['reducerPath'], typeof RTQ_APIS[0]['reducer']>
)

export const rootReducer = combineReducers({
  ...RTQ_REDUCERS,

  router: connectRouter(history),
  referrer: HistoryReferrerSlice.reducer,
  user: UserSlice.reducer,

  avatar: AvatarSlice.reducer,
  advisor: AdvisorSlice.reducer,
})

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaults =>
    getDefaults().concat(
      routerMiddleware(history),
      ...RTQ_APIS.map(x => x.middleware)
    ),
})

setupListeners(store.dispatch)

// ---

export type AppDispatch = typeof store.dispatch
export type AppState = ReturnType<typeof rootReducer>
export type AppSelector<T = unknown> = (state: AppState) => T
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>

export const useSelector: TypedUseSelectorHook<AppState> = useSelectorBase
export const useDispatch: () => AppDispatch = useDispatchBase
export const useUser = () => useSelector(selectUser)
