import { ReactNode } from 'react'

import { Icon } from '../Icon'

import styles from './ListPlaceholder.module.scss'

export function ListPlaceholder(props: { text?: ReactNode; desc?: ReactNode }) {
  const { text = 'No entries found', desc } = props
  return (
    <div className={styles.root}>
      <Icon type="empty-list" className={styles.img} />
      <div>
        <div className={styles.title}>{text}</div>
        <If condition={desc !== undefined}>
          <div className={styles.desc}>{desc}</div>
        </If>
      </div>
    </div>
  )
}
