import { ReactNode } from 'react'
import { ErrorBoundary } from 'react-app-error-boundary'

import { PageSettings } from 'src/components'

import { routes } from '../../routes'

import { Header, IHeaderProps } from './Header'

import styles from './Layout.module.scss'

export interface ICabinetLayoutProps
  extends Pick<IHeaderProps, 'hrefHome' | 'hrefInbox'> {
  children: ReactNode
  nav?: ReactNode
}

export function CabinetLayout(props: ICabinetLayoutProps) {
  const { children, nav, hrefHome = routes.home, hrefInbox } = props
  return (
    <div className={styles.root}>
      <Header hrefHome={hrefHome} hrefInbox={hrefInbox}>
        {nav}
      </Header>

      <main className={styles.main}>
        <PageSettings stickyHeader>
          <ErrorBoundary>{children}</ErrorBoundary>
        </PageSettings>
      </main>
    </div>
  )
}
