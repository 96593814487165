// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BiddingForm_btn_stake_inc__3J1Rj {\n  --size: 44px;\n  width: var(--size);\n  height: var(--size);\n  padding: 12px;\n  font-size: 1.5rem;\n  font-weight: normal;\n}\n\n.BiddingForm_bid_state_display__1Esjc {\n  padding-block: 20px;\n  border: 1px solid var(--th-border-color);\n  border-left: none;\n  border-right: none;\n  font-size: 0.875rem;\n  font-weight: 400;\n  color: var(--th-clr-txt-hint);\n}\n\n.BiddingForm_outcome__WBYn2 {\n  grid-column: 2/2;\n  justify-self: flex-end;\n}\n\n.BiddingForm_timer__3NCzW {\n  width: 140px;\n  justify-self: flex-end;\n  text-align: end;\n}", "",{"version":3,"sources":["webpack://src/apps/cabinet/features/Bidding/pages/Bidding/BiddingForm/BiddingForm.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,kBAAA;EACA,mBAAA;EAEA,aAAA;EACA,iBAAA;EACA,mBAAA;AAAF;;AAGA;EACE,mBAAA;EAEA,wCAAA;EACA,iBAAA;EACA,kBAAA;EAEA,mBAAA;EACA,gBAAA;EACA,6BAAA;AAFF;;AAKA;EACE,gBAAA;EACA,sBAAA;AAFF;;AAKA;EACE,YAAA;EACA,sBAAA;EACA,eAAA;AAFF","sourcesContent":[".btn_stake_inc {\n  --size: 44px;\n  width: var(--size);\n  height: var(--size);\n\n  padding: 12px;\n  font-size: 1.5rem;\n  font-weight: normal;\n}\n\n.bid_state_display {\n  padding-block: 20px;\n\n  border: 1px solid var(--th-border-color);\n  border-left: none;\n  border-right: none;\n\n  font-size: 0.875rem;\n  font-weight: 400;\n  color: var(--th-clr-txt-hint);\n}\n\n.outcome {\n  grid-column: 2/2;\n  justify-self: flex-end;\n}\n\n.timer {\n  width: 140px;\n  justify-self: flex-end;\n  text-align: end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn_stake_inc": "BiddingForm_btn_stake_inc__3J1Rj",
	"bid_state_display": "BiddingForm_bid_state_display__1Esjc",
	"outcome": "BiddingForm_outcome__WBYn2",
	"timer": "BiddingForm_timer__3NCzW"
};
export default ___CSS_LOADER_EXPORT___;
