import { AiOutlineQuestionCircle } from 'react-icons/ai'

import clsx from 'clsx'

import { FallbackImg, Icon } from 'src/components'
import { BlockchainAsset } from 'src/types'
import { getAssetIconURI, mergeStyles } from 'src/utils'

import styles from './CryptoLogo.module.scss'

export function CryptoLogo(
  props: { asset: BlockchainAsset; size?: string | number } & IStyled
) {
  const { asset, className, size, style } = props
  return (
    <FallbackImg
      src={getAssetIconURI(asset)}
      alt={asset}
      fallback={
        <Icon
          type={AiOutlineQuestionCircle}
          className={clsx(styles.logo, className)}
        />
      }
      className={clsx(styles.logo, className)}
      style={mergeStyles(style, { '--size': size })}
    />
  )
}
