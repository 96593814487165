// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Header_root__3roM- {\n  display: grid;\n  grid-gap: 1.5rem;\n  gap: 1.5rem;\n  grid-template-columns: auto 1fr auto;\n  height: 60px;\n  border-bottom: 1px solid var(--th-border-color);\n  padding-inline: 1.5rem;\n  align-items: center;\n}\n\n.Header_content__3iFGH {\n  /* none */\n}\n\n.Header_logo__37C0j {\n  color: var(--th-clr-txt-primary);\n  --logo-clr-icon: var(--th-clr-primary);\n  height: 44px;\n}\n\n.Header_user__1HaNW {\n  display: flex;\n  grid-gap: 16px;\n  gap: 16px;\n  margin-left: auto;\n}\n.Header_user__1HaNW:empty {\n  display: none;\n}", "",{"version":3,"sources":["webpack://src/apps/cabinet/components/Layout/Header.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gBAAA;EAAA,WAAA;EACA,oCAAA;EAEA,YAAA;EACA,+CAAA;EACA,sBAAA;EACA,mBAAA;AAAF;;AAGA;EACE,SAAA;AAAF;;AAGA;EACE,gCAAA;EACA,sCAAA;EAEA,YAAA;AADF;;AAIA;EACE,aAAA;EACA,cAAA;EAAA,SAAA;EACA,iBAAA;AADF;AAGE;EACE,aAAA;AADJ","sourcesContent":[".root {\n  display: grid;\n  gap: 1.5rem;\n  grid-template-columns: auto 1fr auto;\n\n  height: 60px;\n  border-bottom: 1px solid var(--th-border-color);\n  padding-inline: 1.5rem;\n  align-items: center;\n}\n\n.content {\n  /* none */\n}\n\n.logo {\n  color: var(--th-clr-txt-primary);\n  --logo-clr-icon: var(--th-clr-primary);\n\n  height: 44px;\n}\n\n.user {\n  display: flex;\n  gap: 16px;\n  margin-left: auto;\n\n  &:empty {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Header_root__3roM-",
	"content": "Header_content__3iFGH",
	"logo": "Header_logo__37C0j",
	"user": "Header_user__1HaNW"
};
export default ___CSS_LOADER_EXPORT___;
