import { sortBy } from 'lodash'

import { ICountry, INotification } from 'src/types'

import { rtqApi as baseApi } from '../baseAPI'
import { rtqTags } from '../utils'

import COUNTRIES from './countries.json'

const tags = rtqTags(['Notification'])

export const rtqApi = baseApi
  .enhanceEndpoints({
    addTagTypes: tags.toArray(),
  })
  .injectEndpoints({
    endpoints: builder => ({
      getNotifications: builder.query<
        INotification[],
        void | { date?: string; isRead?: boolean }
      >({
        query: params => ({
          url: 'notifications',
          params,
        }),
        transformResponse: (xs: INotification[]) =>
          sortBy(xs, x => x.createdAt).reverse(),
        providesTags: tags.Notification.providesList,
      }),

      readNotifications: builder.mutation<void, INotification['id'][]>({
        query: ids => ({
          url: 'notifications',
          method: 'POST',
          data: ids,
        }),

        /**
         * Do optimistic update instead of cache invalidation, because we don't want to re-fetch the entire list whenever something is marked read.
         * @see https://redux-toolkit.js.org/rtk-query/usage/manual-cache-updates#optimistic-updates
         */
        async onQueryStarted(ids, { dispatch, queryFulfilled }) {
          const patch = dispatch(
            rtqApi.util.updateQueryData(
              'getNotifications',
              undefined,
              draft => {
                draft
                  .filter(x => ids.includes(x.id))
                  .forEach(x => {
                    x.isRead = true
                  })
              }
            )
          )
          try {
            await queryFulfilled
          } catch {
            patch.undo()
          }
        },
      }),

      getCountries: builder.query<ICountry[], void>({
        // TODO: should be added on backend in future versions; remove json file then
        queryFn: () => ({
          data: COUNTRIES,
        }),
      }),
    }),
  })
