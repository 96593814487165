import { ReactElement, ReactNode } from 'react'
import { ErrorBoundary } from 'react-app-error-boundary'

import {
  Badge,
  BlockchainAddress as BlockchainAddressView,
  Button,
  Grid,
  Icon,
  Tooltip,
} from 'src/components'
import { AbstractChainId, BlockchainAddress, BlockchainAsset } from 'src/types'
import { getChainName } from 'src/utils'

import { IWCFacade } from '../../../../WalletConnect'
import { CryptoLogo } from '../../../../components'
import { CallRequest } from '../CallRequest'
import { Layout } from '../Layout'
import { PeerIcon, PeerLink } from '../PeerLink'

import styles from './Connected.module.scss'

export function WCConnected(props: { wc: IWCFacade }) {
  const { wc } = props

  return (
    <WalletLayout wc={wc}>
      <ErrorBoundary>
        <CallRequest
          wc={wc}
          title={
            <WalletLayout wc={wc} className={styles.wallet_layout_compact} />
          }
        />
      </ErrorBoundary>

      <Button
        accent
        className={styles.btn_disconnect}
        onClick={() => wc.disconnect()}
      >
        Disconnect
      </Button>
    </WalletLayout>
  )
}

function WalletLayout(props: {
  wc: IWCFacade
  className?: string
  children?: ReactNode
}) {
  const { wc, children, className } = props
  const peer = wc.peerMeta as NonNullable<typeof wc.peerMeta>

  return (
    <Layout
      className={className}
      header={<Icon type="walletconnect" className={styles.wc_icon_header} />}
      title={
        <AppInfo
          appIcon={peer.icons[0]}
          appUrl={peer.url}
          appName={peer.name}
        />
      }
      desc={
        <ConnectionInfo
          chainId={wc.chainId}
          asset={wc.state.assetSymbol}
          address={wc.account}
        />
      }
    >
      {children}
    </Layout>
  )
}

function AppInfo(props: { appIcon: string; appUrl: string; appName: string }) {
  const { appIcon, appUrl, appName } = props
  return (
    <div className={styles.app_info}>
      <PeerLink
        to={appUrl}
        className={styles.app_link}
        appName={appName}
        appIcon={
          <PeerIcon
            src={appIcon}
            alt={appName}
            appName={appName}
            className={styles.app_icon}
          />
        }
      />
    </div>
  )
}

function ConnectionInfo(props: {
  chainId: AbstractChainId | undefined
  asset: BlockchainAsset | null
  address: BlockchainAddress | undefined
}) {
  const { asset, chainId, address } = props

  const hasAsset = asset !== null
  const hasAddress = address !== undefined

  const $asset = hasAsset && (
    <Badge>
      <CryptoLogo asset={asset} className={styles.icon_asset} />
      {asset}
    </Badge>
  )

  const $address = hasAddress && (
    <BlockchainAddressView copy style={{ fontSize: '1rem' }}>
      {address}
    </BlockchainAddressView>
  )

  return (
    <Grid columns justify="center" align="center">
      {chainId !== undefined && (
        <Badge icon className={styles.chain_name}>
          {getChainName(chainId)}
        </Badge>
      )}

      <If condition={hasAsset}>
        <Choose>
          <When condition={hasAddress}>
            <Tooltip
              overlay={$address}
              placement="right"
              overlayInnerStyle={{ padding: 2 }}
            >
              {$asset as ReactElement}
            </Tooltip>
          </When>

          <Otherwise>{$asset}</Otherwise>
        </Choose>
      </If>
    </Grid>
  )
}
