// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FieldStake_root__2ToQN.FieldStake_winning__2VVW- input:invalid {\n  --input-clr-error: var(--th-clr-success);\n}", "",{"version":3,"sources":["webpack://src/apps/cabinet/features/Bidding/pages/Bidding/BiddingForm/FieldStake.module.scss"],"names":[],"mappings":"AAEI;EACE,wCAAA;AADN","sourcesContent":[".root {\n  &.winning {\n    input:invalid {\n      --input-clr-error: var(--th-clr-success);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "FieldStake_root__2ToQN",
	"winning": "FieldStake_winning__2VVW-"
};
export default ___CSS_LOADER_EXPORT___;
