import { Card, Link, Modal } from 'src/components'

import { AgreementTypesList } from '../../../components'
import { buildAgreementFormLink } from '../../../lib'
import { IInvestorAvatar } from '../../../types'

export function AgreementTypeSelector(props: { user?: IInvestorAvatar['id'] }) {
  const { user } = props
  return (
    <Modal isOpen defaultRequired style={{ content: { width: 500 } }}>
      <Card title="Choose agreement type">
        <AgreementTypesList
          renderItem={(type, props) => (
            <Link
              {...props}
              variant="none"
              to={buildAgreementFormLink({ type, user })}
              replace
            />
          )}
        />
      </Card>
    </Modal>
  )
}
