// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CustomScroll_container__34DXP {\n  /* none */\n}\n\n.CustomScroll_view__36Ou3 {\n  /* Fix an artifacts of scrollbar width measurement.\n   * Without it, negative margin, provided by lib, eats 1px of scrollable content,\n   * producing visual bugs like missing right border on elements. */\n  padding-right: 1px;\n}", "",{"version":3,"sources":["webpack://src/components/base/CustomScroll/CustomScroll.module.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;AACF;;AAEA;EACE;;mEAAA;EAGA,kBAAA;AACF","sourcesContent":[".container {\n  /* none */\n}\n\n.view {\n  /* Fix an artifacts of scrollbar width measurement.\n   * Without it, negative margin, provided by lib, eats 1px of scrollable content,\n   * producing visual bugs like missing right border on elements. */\n  padding-right: 1px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CustomScroll_container__34DXP",
	"view": "CustomScroll_view__36Ou3"
};
export default ___CSS_LOADER_EXPORT___;
