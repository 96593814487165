import { createSlice } from '@reduxjs/toolkit'

import { IAvatarState } from './types'

const initialState: IAvatarState = {
  loading: false,
  error: undefined,
}

export const AvatarSlice = createSlice({
  name: 'avatar',
  initialState,

  reducers: {},
})
