import React from 'react'
import { Redirect } from 'react-router-dom'

import { routes, useUser } from 'src/apps/cabinet'
import { UserRole } from 'src/features/User'

export const Home: React.FC = () => {
  const user = useUser()

  return (
    <Choose>
      <When condition={user.role === UserRole.Advisor}>
        <Redirect to={routes.advisor.dashboard} />
      </When>

      <When condition={user.role === UserRole.Avatar}>
        <Redirect to={routes.avatar.dashboard} />
      </When>

      {/* TODO: design some welcome/goodbye page for user without role? */}
    </Choose>
  )
}
