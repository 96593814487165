// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CryptoLogo_logo__M1o7C {\n  --size: 2em;\n  width: var(--size);\n  height: var(--size);\n  border-radius: 50%;\n  aspect-ratio: 1;\n}", "",{"version":3,"sources":["webpack://src/apps/cabinet/features/Wallet/components/CryptoLogo/CryptoLogo.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,kBAAA;EACA,mBAAA;EACA,kBAAA;EAEA,eAAA;AAAF","sourcesContent":[".logo {\n  --size: 2em;\n  width: var(--size);\n  height: var(--size);\n  border-radius: 50%;\n  // jut in case, try to maintain shape if only one dimension is overridden somehow\n  aspect-ratio: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "CryptoLogo_logo__M1o7C"
};
export default ___CSS_LOADER_EXPORT___;
