import { ReactNode } from 'react'
import { AiOutlineQuestionCircle } from 'react-icons/ai'

import {
  CustomScroll,
  FallbackImg,
  ITooltipProps,
  Icon,
  Link,
  RTQSuspender,
  Tooltip,
} from 'src/components'

import { WalletRTQApi } from '../../../../api'

import styles from './ProductsList.module.scss'

export function ProductsList() {
  const state = WalletRTQApi.endpoints.getProducts.useQuery()
  return (
    <RTQSuspender state={state}>
      {products => (
        <CustomScroll autoHeightMax={300}>
          <ul className={styles.list}>
            {products.map(x => (
              <li key={x.ID} className={styles.item}>
                <FallbackImg
                  src={x.icon}
                  alt={x.name}
                  className={styles.product_icon}
                  fallback={
                    <AiOutlineQuestionCircle className={styles.product_icon} />
                  }
                />
                <div>
                  <Link
                    external
                    to={x.link}
                    className={styles.product_name}
                    variant="secondary"
                  >
                    {x.name}
                  </Link>
                  {x.description}
                </div>
              </li>
            ))}
          </ul>
        </CustomScroll>
      )}
    </RTQSuspender>
  )
}

export function ProductListMenu(
  props: Pick<ITooltipProps, 'placement'> & { label?: ReactNode }
) {
  const { label = 'Recommended DApps', ...rest } = props
  return (
    <Tooltip
      {...rest}
      autoWrap={false}
      destroyTooltipOnHide
      overlay={<ProductsList />}
      overlayClassName={styles.tooltip_content}
    >
      <span className={styles.tooltip_anchor}>
        <Icon type="info" /> {label}
      </span>
    </Tooltip>
  )
}
