import { sortBy } from 'lodash'

import { Grid, RTQSuspender } from 'src/components'
import theme from 'src/styles/theme.module.scss'

import { BiddingRTQApi } from '../../api'
import { IProductTier } from '../../types'

import { BiddingForm } from './BiddingForm'

export function TierBidding(props: { tier: IProductTier; onCancel(): void }) {
  const { tier, onCancel } = props

  const auctionState = BiddingRTQApi.endpoints.createBidConnection.useQuery(
    {
      product: tier.product_id,
      tier: tier.id,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  )

  return (
    <Grid>
      <b>Make a bid</b>
      <div style={{ color: theme.th_clr_txt_hint, fontSize: '0.875rem' }}>
        The bidding process allows the market to facilitate price discovery.
      </div>

      <RTQSuspender
        state={auctionState}
        /* Make sure new data is fetched first, to initiate timer properly. Don't use data from cache. */
        remountOnUpdate
      >
        {state =>
          sortBy(state.lots_state, x => x.id).map(lot => (
            <BiddingForm
              key={lot.id}
              tier={tier}
              lot={lot}
              agreementId={state.agreementId}
              onCancel={onCancel}
            />
          ))
        }
      </RTQSuspender>
    </Grid>
  )
}
