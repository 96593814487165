import { useMemo } from 'react'
import { Column } from 'react-table'

import { useImmer } from 'use-immer'

import { Grid, RTQSuspender, Suspendable, Table } from 'src/components'
import { BlockchainAsset } from 'src/types'
import { getAssetFullName } from 'src/utils'

import { WalletRTQApi } from '../../../../api'
import { CryptoLogo } from '../../../../components'
import { IWalletAsset, IWalletDescriptor } from '../../../../types'

import { AssetControls } from './AssetControls'
import { AvailableAssetsContext, SuspendedAssetsContext } from './Contexts'

import styles from './AssetsList.module.scss'

export function AssetsListContainer(props: IWalletDescriptor) {
  const { provider, wallet } = props

  const state = WalletRTQApi.endpoints.getWalletAssets.useQuery({
    provider,
    wallet,
  })

  const { isAssetSuspended, setAssetSuspended } = useSuspendedAssets()

  const columns = useColumns()
  return (
    <RTQSuspender state={state}>
      {data => (
        <AvailableAssetsContext.Provider value={data.assets}>
          <SuspendedAssetsContext.Provider value={setAssetSuspended}>
            <Suspendable>
              <Table
                data={data.assets}
                columns={columns}
                getRowProps={({ row }) => ({
                  className: Suspendable.resolveSuspendedClassName(
                    isAssetSuspended(row.original.asset_symbol)
                  ),
                })}
                // Assume wallet can't have so many assets that is really requires pagination
                showPagination={false}
                manualPagination
              />
            </Suspendable>
          </SuspendedAssetsContext.Provider>
        </AvailableAssetsContext.Provider>
      )}
    </RTQSuspender>
  )
}

function useColumns() {
  return useMemo(
    (): Column<IWalletAsset>[] => [
      {
        id: 'name',
        accessor: 'asset_symbol',
        Header: 'Asset',
        Cell: ({ value }) => <CellAssetName asset={value} />,
      },
      {
        accessor: 'balance',
        Header: 'Balance',
        Cell: ({ row, value }) => (
          <div className={styles.cell_balance}>
            <div className={styles.cell_balance_crypto}>
              {value} {row.original.asset_symbol}
            </div>
            <div className={styles.cell_balance_usd}>
              ~${parseFloat(row.original.balance_usd).toFixed(2)}
            </div>
          </div>
        ),
      },
      {
        id: 'controls',
        accessor: 'asset_symbol',
        Cell: ({ value }) => <AssetControls asset={value} />,
        tx: {
          style: {
            width: 130,
          },
        },
      },
    ],
    []
  )
}

function useSuspendedAssets() {
  const [xs, update] = useImmer(() => new Set<BlockchainAsset>())
  return {
    isAssetSuspended: (asset: BlockchainAsset) => xs.has(asset),
    setAssetSuspended: (asset: BlockchainAsset, suspended: boolean) =>
      update(xs => {
        suspended ? xs.add(asset) : xs.delete(asset)
      }),
  }
}

function CellAssetName(props: { asset: BlockchainAsset }) {
  const { asset } = props
  return (
    <Grid columns align="center" autoColumns="max-content">
      <CryptoLogo asset={asset} />
      <div>
        <div className={styles.cell_txt_primary}>{getAssetFullName(asset)}</div>
        <div className={styles.cell_txt_secondary}>{asset}</div>
      </div>
    </Grid>
  )
}
