// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_root__19G6W {\n  white-space: nowrap;\n}", "",{"version":3,"sources":["webpack://src/components/base/formatters/DateTime/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF","sourcesContent":[".root {\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "styles_root__19G6W"
};
export default ___CSS_LOADER_EXPORT___;
