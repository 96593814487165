import { cloneElement, isValidElement, memo } from 'react'
import { FieldValues, useFormContext } from 'react-hook-form'

import { isPrimitive } from 'utility-types'

import {
  Button,
  ErrorMessage,
  IButtonProps,
  IFormContentProps,
  IFormControlsProps,
  Loader,
} from 'src/components/base'
import { Grid } from 'src/components/layouts'

import { BaseForm, FormSubmitError } from './BaseForm'
import { FormButtonProps, IFormProps } from './types'

import styles from './Form.module.scss'

export default function Form<TFieldValues extends FieldValues = FieldValues>(
  props: IFormProps<TFieldValues>
) {
  const {
    reset,
    error,
    btnReset,
    btnSubmit,
    buttonsLayout,
    buttonsAlign,
    allowSubmit,
    onlySubmitChanges,
    showDefaultControls,
    renderSubmitting,
    children,
    ...rest
  } = props

  return (
    <BaseForm {...rest} reset={typeof reset === 'boolean' ? undefined : reset}>
      <FormContent
        isResettable={reset !== undefined}
        {...{
          error,
          btnReset,
          btnSubmit,
          buttonsLayout,
          buttonsAlign,
          allowSubmit,
          onlySubmitChanges,
          showDefaultControls,
          renderSubmitting,
        }}
      >
        {children}
      </FormContent>
    </BaseForm>
  )
}

function FormContent(props: IFormContentProps) {
  const {
    error,
    btnReset,
    btnSubmit,
    buttonsLayout,
    buttonsAlign,
    isResettable,
    allowSubmit,
    onlySubmitChanges,
    showDefaultControls = true,
    renderSubmitting,
    children,
  } = props

  const form = useFormContext()

  const { isSubmitting } = form.formState

  const $controls = showDefaultControls ? (
    <FormControls
      {...{
        isSubmitting,
        isResettable,
        allowSubmit,
        onlySubmitChanges,
        btnReset,
        btnSubmit,
        buttonsAlign,
        buttonsLayout,
        renderSubmitting,
      }}
    />
  ) : undefined

  // ---

  const $submitError = <FormSubmitError />

  const $externalError = (
    <If condition={error !== undefined && error !== null}>
      <ErrorMessage block>{error}</ErrorMessage>
    </If>
  )

  // ---

  if (typeof children === 'function') {
    return children(form, {
      controls: $controls,
      error: $submitError,
      externalError: $externalError,
    })
  }

  // ---

  return (
    <>
      {children}

      <div className={styles.footer}>
        {$controls}
        {cloneElement($submitError, { bordered: true })}
        {$externalError}
      </div>
    </>
  )
}

const renderSubmittingDefault = () => (
  /* size approximately matches default button height */
  <Loader style={{ justifySelf: 'center', gridColumn: '1/3' }} size={36.5} />
)

const FormControls = memo(function FormControls(props: IFormControlsProps) {
  const {
    btnReset = 'Reset',
    btnSubmit = 'Submit',
    buttonsLayout = '1fr',
    buttonsAlign = 'center',
    isResettable = false,
    isSubmitting = false,
    allowSubmit = true,
    onlySubmitChanges = false,
    renderSubmitting = renderSubmittingDefault,
  } = props

  const form = useFormContext()

  return (
    <Grid
      className={styles.controls}
      columns={isResettable ? 2 : 1}
      autoColumns={buttonsLayout}
      style={{ justifyContent: buttonsAlign }}
    >
      <Choose>
        <When condition={isSubmitting}>
          {renderSubmitting(renderSubmittingDefault())}
        </When>

        <Otherwise>
          <If condition={isResettable}>
            <Button
              variant="secondary"
              {...resolveFormButtonProps(btnReset)}
              type="reset"
              disabled={isSubmitting}
            />
          </If>

          <Button
            variant="primary"
            {...resolveFormButtonProps(btnSubmit)}
            type="submit"
            disabled={
              isSubmitting ||
              !allowSubmit ||
              (onlySubmitChanges && !form.formState.isDirty)
            }
          />
        </Otherwise>
      </Choose>
    </Grid>
  )
})

function resolveFormButtonProps(props: FormButtonProps): IButtonProps {
  if (isValidElement(props) || isPrimitive(props)) {
    return { children: props }
  }
  return props
}
