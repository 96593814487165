// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./img/bg-cells.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@charset \"UTF-8\";\n.styles_description__ORe7X {\n  max-width: 600px;\n  text-align: center;\n  font-size: 18px;\n}\n\n.styles_form__2mz4o {\n  width: 100%;\n  max-width: 432px;\n}\n\n.styles_finish_step_page__3lqgC {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center 0;\n  /* Stretch to show as much of background image as possible: */\n  width: 100%;\n  /* Responsive padding, based on static design.\n   In design, padding is 160px – for screen of 1024px height.\n   So for smaller screens keep this proportion, for larger ones – use static value. */\n  padding-top: min(15.625vh, 160px);\n  /* negate top padding provided by Layout */\n  margin-top: calc(var(--layout-pad-block) * -1);\n}", "",{"version":3,"sources":["webpack://src/apps/cabinet/features/Wallet/pages/InstallWallet/steps/styles.module.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACE,gBAAA;EACA,kBAAA;EACA,eAAA;AAEF;;AACA;EACE,WAAA;EACA,gBAAA;AAEF;;AACA;EACE,sEAAA;EACA,6DAAA;EACA,WAAA;EAEA;;qFAAA;EAGA,iCAAA;EAEA,0CAAA;EACA,8CAAA;AAAF","sourcesContent":[".description {\n  max-width: 600px;\n  text-align: center;\n  font-size: 18px;\n}\n\n.form {\n  width: 100%;\n  max-width: 432px;\n}\n\n.finish_step_page {\n  background: url(\"./img/bg-cells.png\") no-repeat center 0;\n  /* Stretch to show as much of background image as possible: */\n  width: 100%;\n\n  /* Responsive padding, based on static design.\n   In design, padding is 160px – for screen of 1024px height.\n   So for smaller screens keep this proportion, for larger ones – use static value. */\n  padding-top: min(calc(160 / 1024 * 100vh), 160px);\n\n  /* negate top padding provided by Layout */\n  margin-top: calc(var(--layout-pad-block) * -1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"description": "styles_description__ORe7X",
	"form": "styles_form__2mz4o",
	"finish_step_page": "styles_finish_step_page__3lqgC"
};
export default ___CSS_LOADER_EXPORT___;
