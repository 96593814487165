import { useDispatch, useSelector } from 'src/apps/cabinet'
import { BtnLogout, Select } from 'src/components'
import { isGuestUser } from 'src/features/User'
import theme from 'src/styles/theme.module.scss'
import { getUserRoleLabel } from 'src/utils'

import { logout, selectUser } from '../../features/User'

import { UserInfo } from './UserInfo'

import styles from './UserMenu.module.scss'

function UserMenuContainer() {
  const { user, isGuest } = useSelector(state => {
    const user = selectUser(state)
    return { user, isGuest: isGuestUser(user) }
  })

  if (isGuest) {
    return null
  }

  return (
    <UserMenu
      img={user.photo ?? undefined}
      username={user.username}
      desc={getUserRoleLabel(user.role)}
    />
  )
}

export { UserMenuContainer as UserMenu }

enum MenuOptions {
  userInfo,
  logout,
}

function UserMenu(props: { img?: string; username: string; desc: string }) {
  return (
    <Select
      className={styles.root}
      options={[
        {
          label: <UserInfo {...props} />,
          value: MenuOptions.userInfo,
        },
        {
          label: <Logout />,
          value: MenuOptions.logout,
        },
      ]}
      value={MenuOptions.userInfo}
      hideSelectedOptions
      isSearchable={false}
      styles={{
        option: base => ({
          ...base,
          cursor: 'pointer',
          backgroundColor: 'transparent',
          padding: 0,

          '> :only-child': {
            padding: '14px 16px',
          },

          '&:hover': {
            backgroundColor: theme.th_clr_gray_100,
          },
        }),
      }}
    />
  )
}

function Logout() {
  const dispatch = useDispatch()
  return (
    <BtnLogout
      onClick={() => dispatch(logout())}
      text="Log out"
      className={styles.btn_logout}
    />
  )
}
