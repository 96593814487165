import { goBack } from 'connected-react-router'
import { ReactNode } from 'react'
import Modal from 'react-modal'

import { CabinetLayout } from '../../../../components'
import { useDispatch } from '../../../../store'

/**
 * This flow is put into modal, to render a different layout
 * – one with the main navigation replaced by wizard progressbar.
 *
 * Alternatively it could be some portals-based system, allowing to put arbitrary content in the header slot.
 * Which would be much more cumbersome to implement.
 */
export function InstallWalletModal(props: {
  children: ReactNode
  progressbar: ReactNode
}) {
  const { children, progressbar } = props
  const dispatch = useDispatch()

  return (
    <Modal
      isOpen
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      // TODO:
      //  ideally it should only ask for confirmation if user has changed something:
      //  i.e. entered a wallet name, or signed an agreement.
      //  But this feature is out of current scope.
      //  Currently it basically just protects from an accidental press of "esc" btn.
      onRequestClose={() => {
        if (
          window.confirm('Your progress will be lost. Do you want to proceed?')
        ) {
          dispatch(goBack())
        }
      }}
      style={{
        content: {
          width: '100vw',
          height: '100vh',
        },
      }}
    >
      <CabinetLayout nav={progressbar}>{children}</CabinetLayout>
    </Modal>
  )
}
