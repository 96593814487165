import { FunctionComponent, HTMLAttributes } from 'react'
import { ScrollbarProps, Scrollbars } from 'react-custom-scrollbars-2'

import clsx from 'clsx'
import { Overwrite } from 'utility-types'

import styles from './CustomScroll.module.scss'

export interface ICustomScrollProps
  extends Overwrite<
    ScrollbarProps,
    {
      /* Remove strange presence of `ComponentType` in default typings.
       * View is anyway rendered as a function:
       * @see https://github.com/RobPethick/react-custom-scrollbars-2/blob/a5838518889667dfc755d4d4d5b2e99018416c5c/src/Scrollbars/index.js#L575C21-L575C21
       * so must by a mistake in types. */
      renderView?: FunctionComponent
    }
  > {}

export function CustomScroll(props: ICustomScrollProps) {
  const {
    autoHeight = true,
    /* This basically means "no scrollbar unless height is explicitly specified" */
    autoHeightMax = '100%',
    hideTracksWhenNotNeeded = true,
    renderView = renderViewDefault,
    className,
    ...rest
  } = props
  return (
    <Scrollbars
      {...rest}
      {...{ autoHeight, autoHeightMax, hideTracksWhenNotNeeded }}
      className={clsx(className, styles.container)}
      renderView={({ className, ...props }) =>
        renderView({ ...props, className: clsx(className, styles.view) })
      }
    />
  )
}

function renderViewDefault(props: HTMLAttributes<unknown>) {
  return <div {...props} />
}
