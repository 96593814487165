import React, { HTMLAttributes } from 'react'

import clsx from 'clsx'

import { Grid, IGridProps } from 'src/components/layouts'

import styles from './Fieldset.module.scss'

export interface IFieldsetProps extends HTMLAttributes<HTMLFieldSetElement> {
  disabled?: boolean
  grid?: Omit<IGridProps, 'children'>
}

const DEFAULT_GAP = '0.5rem'

const Fieldset: React.FC<IFieldsetProps> = props => {
  const { children, className, grid, ...rest } = props
  return (
    <fieldset {...rest} className={clsx(styles.fieldset, className)}>
      <Grid gap={DEFAULT_GAP} {...grid}>
        {children}
      </Grid>
    </fieldset>
  )
}

export default Fieldset
