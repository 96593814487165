import clsx from 'clsx'

import { Badge } from 'src/components'

import { ProductTierLevel } from './types'

import styles from './ProductTierBadge.module.scss'

export function ProductTierBadge(props: {
  children: string
  className?: string
}) {
  const { children, className } = props
  const tier = children.toLowerCase()
  return (
    <Badge
      className={clsx(styles.root, className, {
        [styles.bronze]: tier === ProductTierLevel.Bronze,
        [styles.silver]: tier === ProductTierLevel.Silver,
        [styles.gold]: tier === ProductTierLevel.Gold,
      })}
    >
      <span className={styles.text}>{children}</span>
    </Badge>
  )
}
