import clsx from 'clsx'

import { mergeStyles } from 'src/utils'

import { resolveBoxCSSSize, resolveBoxSizeClassName } from './lib'
import { IBoxParams, IBoxProps, MaybeBoxSize } from './types'

import styles from './Box.module.scss'

export function Box(props: IBoxProps) {
  const { children, ...params } = props
  const styles = useBoxStyles(params)
  return <div {...styles}>{children}</div>
}

export function useBoxStyles(props: IBoxParams): IStyled {
  const { size, inline, bordered, ratio } = props
  const { sizeClassName, size: cssSize } = useBoxSize(size)
  return {
    className: clsx(styles.box, sizeClassName, {
      [styles.inline]: inline,
      [styles.bordered]: bordered,
    }),
    style: mergeStyles(undefined, {
      height: cssSize,
      aspectRatio: ratio,
    }),
  }
}

export function useBoxSize(size: MaybeBoxSize | undefined) {
  return {
    size: resolveBoxCSSSize(size),
    sizeClassName: resolveBoxSizeClassName(size),
  }
}
