export function normalizeUrl(url: string): string {
  const withProtocol = /^https?:\/\//.test(url) ? url : `http://${url.trim()}`
  return normalizeSlashes(withProtocol)
}

export function normalizeSlashes(x: string): string {
  // 15.03.22: Lookbehind still not supported in safari: .replace(/(?<!:)\/+/g, '/')
  return x.replace(/(:\/)?\/+/g, '$1/')
}

export function formatBool(x: boolean): string {
  return x ? 'Yes' : 'No'
}

export function maybeStrToNumber(
  x: string | number | undefined
): number | undefined {
  if (x === undefined) {
    return undefined
  }
  return +x
}

// TODO:
//  For stage 1, backend doesn't provide any currency info.
//  Thus, simply use EUR, for demo purposes only.
//  If ("when"?) we'll have different currencies, there should be some factory,
//  providing memoized formatter instance for each currency.
//  > formatMoney(value: number, currency?: string): string
//  @see https://www.npmjs.com/package/intl-format-cache
const DefaultMoneyFormatter = new Intl.NumberFormat('en', {
  style: 'currency',
  currency: 'EUR',
})

export const formatMoney = DefaultMoneyFormatter.format.bind(
  DefaultMoneyFormatter
)
