import React, { useMemo, useRef } from 'react'
import { AiOutlineMail } from 'react-icons/ai'
import { Column } from 'react-table'

import clsx from 'clsx'

import { rtqApi } from 'src/api'
import { DateTime, Flex, Grid, Table } from 'src/components'
import theme from 'src/styles/theme.module.scss'
import { INotification } from 'src/types'

import { CellDetails } from './CellDetails'
import { CellOverview } from './CellOverview'
import { useMarkReadHandlers } from './lib'
import { INotificationsListProps } from './types'

import styles from './NotificationsList.module.scss'

export function NotificationsList(props: INotificationsListProps) {
  const { data, renderActions } = props

  /* TODO:
      Fetch items right here? Anyway they're all fetched from same endpoint with same params
      And fetch only unread ones somehow? */
  const [markRead] = rtqApi.endpoints.readNotifications.useMutation()

  return (
    <Table
      className={styles.root}
      data={data}
      columns={useColumns(renderActions)}
      showPagination={false}
      manualPagination
      useRowProps={function useRowProps({ row }) {
        const item = row.original
        const handlers = useMarkReadHandlers(item, id => markRead([id]))
        return {
          className: clsx({
            [styles.row_unread]: !item.isRead,
          }),
          onMouseEnter: handlers.onStartTimer,
          onMouseLeave: handlers.onResetTimer,
        }
      }}
    />
  )
}

function useColumns(renderActions: INotificationsListProps['renderActions']) {
  const refRenderActions = useRef(renderActions)
  refRenderActions.current = renderActions

  return useMemo(
    (): Column<INotification>[] => [
      {
        accessor: 'createdAt',
        Header: 'Date',
        Cell: ({ value, row: { original: item } }) => (
          <Grid columns align="center" gap="0.5em">
            <AiOutlineMail
              size={20}
              style={{
                color: item.isRead ? theme.th_clr_primary : theme.th_clr_error,
              }}
            />
            <div className={styles.cell_datetime}>
              <DateTime value={value} format={DateTime.F_DATE} />
              <br />
              <DateTime value={value} format={DateTime.F_TIME} />
            </div>
          </Grid>
        ),
      },
      {
        id: 'overview',
        accessor: 'id',
        Header: 'Overview',
        Cell: ({ row }) => (
          <Flex align="center" gap="0.5em">
            <CellOverview item={row.original} />
          </Flex>
        ),
        accent: true,
        tx: {
          style: {
            minWidth: 220,
          },
        },
      },
      {
        id: 'details',
        accessor: 'id',
        Header: 'Details',
        Cell: ({ row }) => <CellDetails item={row.original} />,
        tx: {
          style: {
            minWidth: 240,
          },
        },
      },

      // pre-release 26.07.23: disabled by results of UI review; not used for now
      /*{
        id: 'actions',
        accessor: 'id',
        Cell: ({ row }) => {
          const render = refRenderActions.current
          return render?.(row.original) ?? null
        },
      },*/
    ],
    []
  )
}
