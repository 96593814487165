import { Badge, Flex, Grid, Page, RTQSuspender } from 'src/components'

import { BiddingRTQApi } from '../../api'

import { ProductsList } from './ProductsList'

import styles from './Page.module.scss'

export function BiddingPage() {
  const state = BiddingRTQApi.endpoints.getProducts.useQuery()

  return (
    <Page
      title="Marketplace"
      description="DeWealth apps, dApps and extensions."
    >
      {/* TODO: use tabs when we'll have some content for other ones */}
      <RTQSuspender state={state}>
        {products => (
          <Grid gap="2rem">
            <Flex className={styles.tab_panel_header}>
              Wallets
              <Badge variant="success" border size="sm">
                {products.length} total
              </Badge>
            </Flex>
            <ProductsList items={products} />
          </Grid>
        )}
      </RTQSuspender>
    </Page>
  )
}
