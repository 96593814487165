// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PeerLink_fake_icon__2eey3 svg {\n  width: 100%;\n  height: 100%;\n}", "",{"version":3,"sources":["webpack://src/apps/cabinet/features/Wallet/pages/WalletDetails/WalletConnect/PeerLink.module.scss"],"names":[],"mappings":"AACE;EACE,WAAA;EACA,YAAA;AAAJ","sourcesContent":[".fake_icon {\n  svg {\n    width: 100%;\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fake_icon": "PeerLink_fake_icon__2eey3"
};
export default ___CSS_LOADER_EXPORT___;
