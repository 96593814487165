import React, { useMemo } from 'react'
import { Column } from 'react-table'

import {
  AgreementStatusBadge,
  AgreementTypeBadge,
  DateTime,
  Grid,
  Icon,
  PDFPreview,
  Page,
  RTQSuspender,
  Table,
} from 'src/components'
import { useOnMount } from 'src/hooks'
import { AgreementStatus, IAgreement, UserRole } from 'src/types'
import { getAgreementTypeLabel, getUserRoleLabel } from 'src/utils'

import { AvatarRTQApi } from '../../api'

import { PropositionControls } from './PropositionControls'

import styles from './AgreementsPage.module.scss'

export function AgreementsPage() {
  const state = AvatarRTQApi.endpoints.getAgreements.useQuery()

  const { data: agreements = [] } = state
  return (
    <Page
      title="Agreements"
      description={agreements.length > 0 ? `${agreements.length} total` : null}
    >
      <RTQSuspender state={state}>
        {agreements => <AgreementsList items={agreements} />}
      </RTQSuspender>
    </Page>
  )
}

function AgreementsList(props: { items: IAgreement[] }) {
  const { items } = props

  /* Targeting async element with hash won't work,
   * target is resolved synchronously on navigation end.
   * So trigger navigation again after data is loaded.
   * DO NOT do this if there is no hash – it will loop infinitely. */
  useOnMount(() => {
    const { location } = window
    if (location.hash !== '') {
      location.assign(location.href)
    }
  })

  return (
    <Table
      data={items}
      columns={useColumns()}
      getRowProps={({ row }) => ({
        id: row.original.id,
        className: styles.row,
      })}
      showPagination={false}
      manualPagination
    />
  )
}

function useColumns() {
  return useMemo(
    (): Column<IAgreement>[] => [
      {
        accessor: 'type',
        Header: 'Contract',
        Cell: ({ value }) => (
          <AgreementTypeBadge showTitle border>
            {value}
          </AgreementTypeBadge>
        ),
        accent: true,
      },
      {
        accessor: 'sender',
        Header: getUserRoleLabel(UserRole.Advisor),
        // TODO: user's avatar?
        tx: {
          style: {
            minWidth: 210,
          },
        },
      },
      {
        accessor: 'date',
        Header: 'Date signed',
        Cell: ({ value }) => <DateTime value={value} format="MMM d, yyyy" />,
      },
      {
        accessor: 'status',
        Header: 'State',
        Cell: ({ value }) => (
          <AgreementStatusBadge showTitle border>
            {value}
          </AgreementStatusBadge>
        ),
      },
      {
        accessor: 'attachment',
        Cell: ({ value, row: { original: item } }) => (
          <Grid columns align="center" gap="0.5em">
            <PDFPreview
              modalTitle={<>{getAgreementTypeLabel(item.type)} Contract</>}
              src={value}
              btnVariant="tertiary"
              style={{
                whiteSpace: 'nowrap',
              }}
              label={
                <>
                  <Icon type="eye" size="1.25em" /> View contract
                </>
              }
            />
            <If condition={item.status === AgreementStatus.Proposed}>
              <PropositionControls
                contractId={item.id}
                contractType={item.type}
              />
            </If>
          </Grid>
        ),
        tx: {
          style: {
            minWidth: 280,
          },
        },
      },
    ],
    []
  )
}
