import { ReactNode, createContext, createElement, useMemo } from 'react'

import { IWallet, WalletID, WalletProvider } from '../../types'

interface IWalletContext {
  provider: WalletProvider
  address: WalletID
  name: string
}

export const WalletContext = createContext<IWalletContext>({
  provider: 'dfns',
  address: '',
  name: '',
})

export function WalletContextProvider(props: {
  wallet: IWallet
  children: ReactNode
}) {
  const { wallet, children } = props
  return createElement(
    WalletContext.Provider,
    {
      value: useMemo(
        () => ({
          provider: wallet.type,
          address: wallet.address,
          name: wallet.wallet_name,
        }),
        [wallet]
      ),
    },
    children
  )
}
