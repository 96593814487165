import { sortBy } from 'lodash'

import { rtqTags } from 'src/api'

import {
  ICreateFavoriteAssetPayload,
  IFavoriteAssetsResponse,
  IFavoriteWalletAsset,
  IFavoriteWalletAssetRaw,
  IRemoveFavoriteAssetPayload,
} from '../../types'

import Base from './asset'

const tags = rtqTags(['FavoriteAsset'])

const BASE_URL = 'favourites/address'

export default Base.enhanceEndpoints({
  addTagTypes: tags.toArray(),
}).injectEndpoints({
  endpoints: builder => ({
    getFavoriteAssets: builder.query<IFavoriteWalletAsset[], void>({
      query: () => BASE_URL,

      transformResponse(res: IFavoriteAssetsResponse): IFavoriteWalletAsset[] {
        const parse = (
          is_static: boolean,
          xs: IFavoriteWalletAssetRaw[] | null
        ): IFavoriteWalletAsset[] =>
          xs === null ? [] : xs.map(x => ({ ...x, is_static }))

        const result = [
          ...parse(true, res.static_addresses),
          ...parse(false, res.dynamic_addresses),
        ]
        return sortBy(result, x => x.is_static + x.name + x.asset_symbol)
      },

      providesTags: result =>
        tags.FavoriteAsset.providesList(result?.map(x => x.address)),
    }),

    addFavoriteAsset: builder.mutation<void, ICreateFavoriteAssetPayload>({
      query: payload => ({
        url: BASE_URL,
        method: 'POST',
        data: payload,
      }),
      invalidatesTags: [tags.FavoriteAsset.list()],
    }),

    removeFavoriteAsset: builder.mutation<void, IRemoveFavoriteAssetPayload>({
      query: payload => ({
        url: BASE_URL,
        method: 'DELETE',
        data: payload,
      }),
      invalidatesTags: (result, error, payload) => [
        tags.FavoriteAsset(payload.address),
      ],
    }),
  }),
})
