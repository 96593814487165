import { difference } from 'lodash'

import { Button, RTQSuspender, Tooltip } from 'src/components'
import { BlockchainAsset } from 'src/types'

import { WalletRTQApi } from '../../../../api'
import { IWallet, IWalletAssetsListResponse } from '../../../../types'

import { useNewAssetModal } from './AssetForm'

export function BtnAddAsset(props: { wallet: IWallet }) {
  const { wallet } = props
  const state = WalletRTQApi.endpoints.getWalletAssets.useQuery(
    {
      provider: wallet.type,
      wallet: wallet.address,
    },
    {
      selectFromResult: state => ({
        ...state,
        data: resolveAvailableAssets(state.data),
      }),
    }
  )

  return (
    <RTQSuspender state={state}>
      {availableAssets => <Content assets={availableAssets} />}
    </RTQSuspender>
  )
}

function Content(props: { assets: BlockchainAsset[] }) {
  const { assets } = props
  const modal = useNewAssetModal(assets)
  const disabled = assets.length === 0
  return (
    <>
      {modal.$element}

      <Tooltip
        autoWrap={false}
        overlay={
          disabled
            ? 'This wallet already has all supported assets'
            : 'Add asset to wallet'
        }
      >
        <Button
          variant="primary"
          disabled={disabled}
          pseudoDisabled
          onClick={modal.open}
        >
          + Add asset
        </Button>
      </Tooltip>
    </>
  )
}

function resolveAvailableAssets(
  data?: IWalletAssetsListResponse
): BlockchainAsset[] {
  if (data === undefined) return []

  const { assets, supported_assets } = data
  return difference(
    supported_assets,
    assets.map(x => x.asset_symbol)
  )
}
