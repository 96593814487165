import { ReactNode } from 'react'

import clsx from 'clsx'

import { ErrorMessage, Icon, Tooltip } from 'src/components'

import styles from './Balance.module.scss'

// ---

export function formatWalletBalance(x: string | number): string {
  return (+x).toFixed(2)
}

// ---

export interface IBalanceProps extends IStyled {
  children?: ReactNode
  label?: ReactNode
  value: string | number
  error?: unknown
  currency?: string
}

export function Balance(props: IBalanceProps) {
  const {
    children,
    value,
    label = 'Balance',
    currency,
    error,
    className,
    style,
  } = props

  const formatted = formatWalletBalance(value)
  const [integer, decimal] = formatted.split('.')

  const $extras = (
    <>
      {renderError(error)}
      {children}
    </>
  )

  return (
    <div
      className={clsx(styles.root, className, {
        [styles.error]: hasError(error),
      })}
      style={style}
    >
      <div className={styles.title}>{label}</div>
      <div className={styles.value}>
        <span className={styles.integer}>
          ~{currency}
          {integer}
        </span>
        <span className={styles.decimal}>
          .{decimal} {$extras}
        </span>
      </div>
    </div>
  )
}

// ---

function hasError(error: unknown) {
  return error !== undefined && error !== null
}

function renderError(error: unknown) {
  if (!hasError(error)) return null

  const hasErrorText = typeof error !== 'boolean'

  return (
    <Tooltip
      overlay={<ErrorMessage>{error}</ErrorMessage>}
      visible={hasErrorText ? undefined : false}
      autoWrap={false}
    >
      <Icon type="error" color />
    </Tooltip>
  )
}
