import { FieldInput, Fieldset, Flex, Form, IFormProps } from 'src/components'

import { ProductListMenu } from './ProductsList'

interface IWalletConnectFormFields {
  uri: string
}

export function WalletConnectForm(
  props: Pick<IFormProps<IWalletConnectFormFields>, 'onSubmit'>
) {
  return (
    <Form<IWalletConnectFormFields>
      btnSubmit="Connect"
      renderSubmitting={loader => (
        <Flex align="center">Waiting for connection... {loader}</Flex>
      )}
      {...props}
    >
      <Fieldset>
        <FieldInput<IWalletConnectFormFields, 'uri'>
          name="uri"
          placeholder="wc:..."
          label={
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>WalletConnect URI</div>
              <ProductListMenu placement="bottom" label="Recommended DApps" />
            </div>
          }
          rules={{ required: true }}
          autoComplete="off"
          autoFocus
        />
      </Fieldset>
    </Form>
  )
}
