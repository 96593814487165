import { useWatch } from 'react-hook-form'

import clsx from 'clsx'

import { Button, FieldInput, Flex, useFormContext } from 'src/components'

import { IBiddingFormFields } from './types'

import stylesForm from './BiddingForm.module.scss'
import stylesField from './FieldStake.module.scss'

export function FieldStake(props: {
  min: number
  isWinning: boolean
  currentBid: number
}) {
  const { min, isWinning, currentBid } = props

  const form = useFormContext()
  const yourBid = useWatch<IBiddingFormFields>({
    name: 'price',
  })

  return (
    <Flex justify="center">
      <Button
        variant="secondary"
        className={stylesForm.btn_stake_inc}
        disabled={yourBid <= min}
        onClick={() => {
          form.setValue('price', yourBid - 1)
        }}
      >
        –
      </Button>

      <FieldInput<IBiddingFormFields, 'price', 'number'>
        // TODO: update this field value whenever `lot.price` changes?
        name="price"
        type="number"
        placeholder=""
        rules={{ required: true, min }}
        style={{ width: 88 }}
        control={{
          showError: false,
          collapseEmptyFooter: true,
          className: clsx(stylesField.root, {
            [stylesField.winning]: isWinning,
          }),
        }}
      />

      <Button
        variant="secondary"
        className={stylesForm.btn_stake_inc}
        onClick={() => {
          form.setValue(
            'price',
            /* save user a couple of clicks, immediately set new bid higher than current */
            yourBid < currentBid ? currentBid + 1 : yourBid + 1
          )
        }}
      >
        +
      </Button>
    </Flex>
  )
}
