import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { AuthRoute, UserRole } from 'src/features/User'

import { AdvisorRouter } from './features/Advisor'
import { AvatarRouter } from './features/Avatar'
import { Home } from './pages/Home'
import { routes } from './routes'

export default function AppRouter() {
  return (
    <Switch>
      <Route path={routes.home} component={Home} exact />

      <AuthRoute roles={[UserRole.Avatar]} path={routes.avatar.root}>
        <AvatarRouter />
      </AuthRoute>

      <AuthRoute roles={[UserRole.Advisor]} path={routes.advisor.root}>
        <AdvisorRouter />
      </AuthRoute>
    </Switch>
  )
}
