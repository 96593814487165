import React from 'react'
import { DropzoneOptions, useDropzone } from 'react-dropzone'
import { MdVerticalAlignBottom } from 'react-icons/md'

import clsx from 'clsx'

import { IFileInputFilesRendererProps, IFileInputProps } from './types'

import styles from './styles.module.scss'

export type { IFileInputProps }

export const FileInputDefaultPlaceholder = (
  <>
    <MdVerticalAlignBottom size={20} style={{ verticalAlign: 'middle' }} />
    &nbsp;Upload image
  </>
)

export function FileInput(props: IFileInputProps) {
  const {
    onChange,
    onError,
    name,
    accept = 'image/*',
    maxSize = 1024 * 1024,
    minSize,
    placeholder = FileInputDefaultPlaceholder,
    renderFiles = renderFileInputDefaultFilesList,
    className,
    style,
    unstyled,
  } = props

  const { getRootProps, getInputProps, acceptedFiles, open } = useDropzone({
    accept: resolveAcceptProp(accept),
    maxSize,
    minSize,
    multiple: false,

    // Drop this because of unstable cross-browser support
    // @see https://github.com/react-dropzone/react-dropzone/tree/master#file-dialog-cancel-callback
    useFsAccessApi: false,

    // When this is enabled, click on root element will open dialog. Which is not always desired.
    // Instead, wrap our input into <label> – which is one to be styled inside container
    noClick: true,

    onDropAccepted([file]) {
      onChange?.(file)
    },
    onDropRejected([rejection]) {
      onError?.(rejection)
    },
  })

  return (
    <div
      {...getRootProps()}
      className={clsx(styles.root, className)}
      style={style}
    >
      <label
        className={clsx(styles.placeholder, {
          [styles.default_placeholder]: !unstyled,
        })}
      >
        {placeholder}
        <input name={name} {...getInputProps()} />
      </label>

      {renderFiles({ open, files: acceptedFiles })}
    </div>
  )
}

export function renderFileInputDefaultFilesList(
  props: IFileInputFilesRendererProps
) {
  const { files } = props
  return (
    <>
      {files.map(file => (
        <div
          key={`${file.name}_${file.type}_${file.size}`}
          className={styles.filename}
        >
          {file.name}
        </div>
      ))}
    </>
  )
}

function resolveAcceptProp(
  prop: IFileInputProps['accept']
): DropzoneOptions['accept'] {
  if (prop === undefined) return undefined

  // assume it's just MIME type, as normal file input accepts
  if (typeof prop === 'string') {
    return {
      [prop]: [],
    }
  }

  // Extended object notation, appropriate for FilePicker dialog
  // @see https://developer.mozilla.org/en-US/docs/Web/API/window/showOpenFilePicker#parameters
  return prop
}
