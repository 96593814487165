// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WalletsList_list__ABLxi {\n  display: grid;\n  grid-gap: 1rem;\n  gap: 1rem;\n  --wallet-width: 476px;\n  grid-template-columns: repeat(auto-fit, var(--wallet-width));\n}", "",{"version":3,"sources":["webpack://src/apps/cabinet/features/Wallet/pages/Wallets/WalletsList/WalletsList.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,cAAA;EAAA,SAAA;EACA,qBAAA;EACA,4DAAA;AACF","sourcesContent":[".list {\n  display: grid;\n  gap: 1rem;\n  --wallet-width: 476px;\n  grid-template-columns: repeat(auto-fit, var(--wallet-width));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "WalletsList_list__ABLxi"
};
export default ___CSS_LOADER_EXPORT___;
