import { ReactElement } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { routes } from 'src/apps/cabinet'
import { RTQSuspender } from 'src/components'

import { BiddingPage, StoreProductPage } from '../Bidding/pages'
import {
  InstallWalletPage,
  WalletRTQApi,
  WalletsPage,
  renderWalletRoutes,
} from '../Wallet'

import { AvatarLayout } from './components'
import { AgreementsPage } from './pages/Agreements'
import { NotificationsCenterPage } from './pages/NotificationsCenter'

export function AvatarRouter() {
  return (
    <AvatarLayout>
      <WalletsProvider>
        {({ hasWallets }) => (
          <Switch>
            <Route
              path={routes.avatar.wallet.wallet_install}
              /* Intro screen "Let's set up a wallet" should only appear as part of onboarding flow,
               when user creates his very first wallet. */
              render={() => <InstallWalletPage skipIntro={hasWallets} />}
              exact
            />

            {/* Until user has a wallet, he isn't going anywhere else */}
            <If condition={!hasWallets}>
              <Redirect to={routes.avatar.wallet.wallet_install} />
            </If>

            <Route
              path={routes.avatar.dashboard}
              component={WalletsPage}
              exact
            />

            <Route
              path={routes.avatar.agreements}
              component={AgreementsPage}
              exact
            />

            <Route
              path={routes.avatar.inbox}
              component={NotificationsCenterPage}
              exact
            />

            <Route path={routes.avatar.store} component={BiddingPage} exact />
            <Route
              path={routes.avatar.store_product}
              render={({ match }) => (
                <StoreProductPage productId={match.params.id} />
              )}
              exact
            />

            {renderWalletRoutes({ base: routes.avatar.root })}

            <Redirect to={routes.avatar.dashboard} />
          </Switch>
        )}
      </WalletsProvider>
    </AvatarLayout>
  )
}

function WalletsProvider(props: {
  children: (data: { hasWallets: boolean }) => ReactElement
}) {
  const { children } = props

  const state = WalletRTQApi.endpoints.getWalletsList.useQuery(
    /* We don't need wallet balance for this specific task – only wallets count.
     * But since then user is going to be redirected to wallets dashboard anyway –
     * it makes sense to load balances immediately, to don't re-fetch wallets in dashboard. */
    { balance: true },
    {
      selectFromResult: state => ({
        ...state,
        data: state.data?.length ?? 0,
      }),
    }
  )

  return (
    <RTQSuspender state={state}>
      {walletsCount => children({ hasWallets: walletsCount > 0 })}
    </RTQSuspender>
  )
}
