import { ReactElement, ReactNode, isValidElement } from 'react'

import { sortBy, zipObject } from 'lodash'
import { isPrimitive } from 'utility-types'

import { DateTime } from 'src/types'

export { serialize as toFormData } from 'object-to-formdata'

export { shallowEqual } from 'react-redux'

export function assert<T>(val: T): asserts val is NonNullable<T> {
  if (val === undefined || val === null) {
    throw new Error(`Expected 'val' to be defined, but received ${val}`)
  }
}

export function zipObjectFromKeys<K extends string | number | symbol, V>(
  keys: K[],
  getValue: (key: K) => V
): Record<K, V> {
  return zipObject(keys, keys.map(getValue)) as Record<K, V>
}

export function resolveErrorMessage(e: unknown): ReactNode {
  if (e === null || e === undefined) {
    return null
  }
  if (isValidElement(e as object)) {
    return e as ReactElement
  }
  if (isPrimitive(e)) {
    return e
  }
  if (e instanceof Error || 'message' in (e as Dict)) {
    return (e as Error).message
  }
  return JSON.stringify(e, null, 4)
}

export function sortNewest<T extends { date: DateTime }>(xs: T[]): T[] {
  return sortBy(xs, x => x.date).reverse()
}
