import React, { ReactNode } from 'react'

export function PrivacyPolicyLink(props: IStyled & { children?: ReactNode }) {
  const { children = 'Privacy Policy', className, style } = props
  return (
    <a
      className={className}
      href="/Privacy_Policy.pdf"
      target="_blank"
      style={{ textDecoration: 'underline', ...style }}
    >
      {children}
    </a>
  )
}
