import { IconButton } from 'src/components'
import { useOnMount } from 'src/hooks'

import { WalletRTQApi } from '../../api'
import { Balance } from '../../components'
import { IWallet } from '../../types'

export function WalletBalance(props: { wallet: IWallet }) {
  const { wallet } = props

  const [fetchBalance, state] =
    WalletRTQApi.endpoints.getWalletAssets.useLazyQuery({
      selectFromResult: state => {
        const { data } = state
        return { ...state, data: data?.balance_usd ?? 0 }
      },
    })

  const load = (preferCache?: boolean) =>
    fetchBalance({ provider: wallet.type, wallet: wallet.address }, preferCache)

  useOnMount(() => {
    load(true)
  })

  const { data: balance, error, isFetching } = state

  return (
    <Balance label="Balance" value={balance} error={error} currency="$">
      <IconButton
        icon="refresh"
        variant="secondary"
        style={{ fontSize: 'inherit' }}
        pending={isFetching}
        onClick={() => load(false)}
      />
    </Balance>
  )
}
