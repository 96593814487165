// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Grid_grid__zaHhd {\n  display: grid;\n}\n.Grid_grid__zaHhd.Grid_inline__3JQwl {\n  display: inline-grid;\n}", "",{"version":3,"sources":["webpack://src/components/layouts/Grid/Grid.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AACE;EACE,oBAAA;AACJ","sourcesContent":[".grid {\n  display: grid;\n\n  &.inline {\n    display: inline-grid;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": "Grid_grid__zaHhd",
	"inline": "Grid_inline__3JQwl"
};
export default ___CSS_LOADER_EXPORT___;
