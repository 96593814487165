import { ReactElement, useContext } from 'react'

import { ErrorMessage, Flex, Loader, RTQSuspender } from 'src/components'
import {
  AbstractChainId,
  BlockchainAddress,
  BlockchainAsset,
  BlockchainId,
} from 'src/types'
import {
  DEFAULT_WALLET_ASSET,
  DEFAULT_WALLET_CHAIN,
  isChainSupportsAsset,
} from 'src/utils'

import { WalletRTQApi } from '../../../../api'
import { IWalletAsset, IWalletDescriptor } from '../../../../types'
import { WalletContext } from '../../WalletContext'

interface IResolvedAssets {
  supportedAssets: BlockchainAsset[]
  fallbackAsset: BlockchainAsset | undefined
  fallbackChain: BlockchainId
}

export interface IAssetsProviderResponse extends IResolvedAssets {
  getAssetAddress: (asset: BlockchainAsset) => Promise<BlockchainAddress>
}

export function AssetsProvider(props: {
  chainId: AbstractChainId
  children: (data: IAssetsProviderResponse) => ReactElement
}) {
  const { chainId, children } = props

  const [assetsState, getAssetAddress] = useWalletAssets(chainId)

  return (
    <RTQSuspender
      state={assetsState}
      loader={
        <Flex align="center">
          <Loader size="1.3rem" /> Checking your wallet assets...
        </Flex>
      }
      renderError={e => (
        <ErrorMessage>
          Failed to load wallet assets to connect:
          <br />
          <i>{e}</i>
        </ErrorMessage>
      )}
    >
      {({ supportedAssets, fallbackAsset, fallbackChain }) =>
        children({
          supportedAssets,
          fallbackAsset,
          fallbackChain,
          getAssetAddress,
        })
      }
    </RTQSuspender>
  )
}

function useWalletAssets(chainId: AbstractChainId) {
  const wallet = useContext(WalletContext)
  const walletDescriptor: IWalletDescriptor = {
    provider: wallet.provider,
    wallet: wallet.address,
  }
  const assetsListState = WalletRTQApi.endpoints.getWalletAssets.useQuery(
    walletDescriptor,
    {
      selectFromResult: state => ({
        ...state,
        data: resolveAssetsList(chainId, state.data?.assets),
      }),
    }
  )

  const [getAssetAddress] =
    WalletRTQApi.endpoints.getAssetAddress.useLazyQuery()

  return [
    assetsListState,

    (asset: BlockchainAsset) =>
      getAssetAddress({ ...walletDescriptor, asset }).unwrap(),
  ] as const
}

function resolveAssetsList(
  chainId: AbstractChainId,
  walletAssets: IWalletAsset[] | undefined = []
): IResolvedAssets {
  const symbols = walletAssets.map(x => x.asset_symbol)
  const supported = symbols.filter(x => isChainSupportsAsset(chainId, x))
  const fallback = DEFAULT_WALLET_ASSET
  return {
    supportedAssets: supported,
    fallbackChain: DEFAULT_WALLET_CHAIN,
    /* Logically, fallback should always be available.
     * Technically, anything can happen on BE side – including wallet with no supported assets whatsoever.
     * Such extreme case also should be handled by FE. */
    fallbackAsset: symbols.includes(fallback) ? fallback : undefined,
  }
}
