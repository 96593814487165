import React from 'react'
import { toast } from 'react-toastify'

import {
  FieldControl,
  Form,
  IconButton,
  IconSettings,
  Loader,
  useModalConfirm,
} from 'src/components'
import { AgreementType, IAgreement } from 'src/types'
import { getAgreementTypeLabel } from 'src/utils'

import { AvatarRTQApi } from '../../api'

export function PropositionControls(props: {
  contractId: IAgreement['id']
  contractType: AgreementType
}) {
  const { contractId, contractType } = props
  const [accept, { isLoading: isAccepting }] =
    AvatarRTQApi.endpoints.acceptAgreement.useMutation()

  const [decline, { isLoading: isDeclining }] =
    AvatarRTQApi.endpoints.declineAgreement.useMutation()

  const isLoading = isAccepting || isDeclining

  const modalAccept = useAcceptModal({
    isLoading: isAccepting,
    contractType,
    onSubmit: () =>
      accept({ agreementId: contractId })
        .unwrap()
        .catch(e => toast.error(e.message)),
  })

  const modalDecline = useDeclineModal({
    isLoading: isDeclining,
    onSubmit: ({ reason }) =>
      decline({ agreementId: contractId, declineReason: reason }).unwrap(),
  })

  return (
    <fieldset
      disabled={isLoading}
      style={{ display: 'flex', gap: '0.5rem', justifyContent: 'center' }}
    >
      <Choose>
        <When condition={isLoading}>
          <Loader />
        </When>

        <Otherwise>
          <IconSettings size="2em">
            <IconButton
              icon="success"
              title={{
                overlay: 'Accept contract',
                destroyTooltipOnHide: true,
              }}
              onClick={modalAccept.open}
            />

            <IconButton
              icon="cancel"
              accent
              title={{
                overlay: 'Decline contract',
                destroyTooltipOnHide: true,
              }}
              onClick={modalDecline.open}
            />
          </IconSettings>
        </Otherwise>
      </Choose>

      {modalAccept.$element}
      {modalDecline.$element}
    </fieldset>
  )
}

function useDeclineModal(params: {
  isLoading: boolean
  onSubmit: (data: { reason: string }) => void
}) {
  const { isLoading, onSubmit } = params

  const FORM_ID = 'form-decline'
  return useModalConfirm(
    {
      title: 'Decline contract',
      form: FORM_ID,
      btnConfirm: { children: 'Decline', accent: true },
      isLoading,
    },
    ({ close }) => (
      <Form<{ reason: string }>
        id={FORM_ID}
        onSubmit={async ({ reason }) => {
          await onSubmit({ reason })
          close()
        }}
        showDefaultControls={false}
      >
        <FieldControl name="reason" rules={{ required: true }}>
          <textarea
            placeholder="Decline reason:"
            style={{
              resize: 'none',
              width: 360,
              height: 160,
              borderRadius: 12,
              fontSize: '1rem',
            }}
          />
        </FieldControl>
      </Form>
    )
  )
}

function useAcceptModal(params: {
  isLoading: boolean
  contractType: AgreementType
  onSubmit(): void
}) {
  const { contractType, isLoading, onSubmit } = params

  return useModalConfirm(
    {
      title: 'Accept contract',
      isLoading,
      btnConfirm: { children: 'Accept' },
      onConfirm: onSubmit,
    },
    <>
      Accept this{' '}
      <b style={{ color: 'var(--th-clr-primary)' }}>
        {getAgreementTypeLabel(contractType)}
      </b>{' '}
      contract?
    </>
  )
}
