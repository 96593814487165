import { createContext } from 'react'

import { BlockchainAsset } from 'src/types'

import { IWalletAsset } from '../../../../types'

export const AvailableAssetsContext = createContext<IWalletAsset[]>([])
export const SuspendedAssetsContext = createContext<
  (asset: BlockchainAsset, suspended: boolean) => void
>(() => {})
