// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../img/bg-hero-img.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CreateWalletLoader_wrapper__3FkyA {\n  width: 128px;\n  height: 128px;\n  margin-bottom: 96px;\n  border-radius: 50%;\n  box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.03), 0 12px 16px -4px rgba(16, 24, 40, 0.08);\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center center no-repeat;\n}\n\n.CreateWalletLoader_success_icon__3r4Yz {\n  width: 48px;\n}", "",{"version":3,"sources":["webpack://src/apps/cabinet/features/Wallet/pages/InstallWallet/steps/components/CreateWalletLoader.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,0FAAA;EACA,2EAAA;AACF;;AAEA;EACE,WAAA;AACF","sourcesContent":[".wrapper {\n  width: 128px;\n  height: 128px;\n  margin-bottom: 96px;\n  border-radius: 50%;\n  box-shadow: 0 4px 6px -2px rgb(16 24 40 / 3%), 0 12px 16px -4px rgb(16 24 40 / 8%);\n  background: url(\"../img/bg-hero-img.png\") center center no-repeat;\n}\n\n.success_icon {\n  width: 48px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "CreateWalletLoader_wrapper__3FkyA",
	"success_icon": "CreateWalletLoader_success_icon__3r4Yz"
};
export default ___CSS_LOADER_EXPORT___;
