import { useMemo } from 'react'
import { Column } from 'react-table'

import clsx from 'clsx'

import {
  BlockchainAddress,
  Flex,
  Icon,
  IconButton,
  IconSettings,
  Suspendable,
  Table,
  Tooltip,
  useModalConfirm,
} from 'src/components'
import { UserRole } from 'src/types'
import { getAssetFullName, getUserRoleLabel } from 'src/utils'

import { WalletRTQApi } from '../../../api'
import { CryptoLogo } from '../../../components'
import { IFavoriteWalletAsset } from '../../../types'

import styles from './AssetsList.module.scss'

export function AssetsList(props: { items: IFavoriteWalletAsset[] }) {
  const { items } = props
  const columns = useColumns()
  return (
    <Suspendable>
      <Table
        className={styles.table}
        data={items}
        columns={columns}
        showPagination={false}
        manualPagination
        getRowProps={({ row }) => ({
          className: clsx({
            [styles.static_row]: row.original.is_static,
          }),
        })}
      />
    </Suspendable>
  )
}

function useColumns() {
  return useMemo(
    (): Column<IFavoriteWalletAsset>[] => [
      {
        accessor: 'name',
        Header: 'Name',
        Cell: ({ value }) => (
          <span className={styles.cell_txt_accent}>{value}</span>
        ),
        tx: {
          className: styles.cell_name,
        },
      },

      {
        accessor: 'asset_symbol',
        Header: 'Network',
        Cell: ({ value }) => (
          <Flex align="center">
            <CryptoLogo asset={value} />
            <span className={styles.cell_txt_accent}>
              {getAssetFullName(value) ?? value}
            </span>
          </Flex>
        ),
      },
      {
        accessor: 'address',
        Header: 'Address',
        Cell: ({ value }) => (
          <BlockchainAddress copy trunc>
            {value}
          </BlockchainAddress>
        ),
      },
      {
        id: 'controls',
        accessor: 'name',
        Cell: ({ row: { original: item } }) => (
          <IconSettings size={24}>
            <Choose>
              <When condition={!item.is_static}>
                <BtnRemoveAsset asset={item} />
              </When>

              <Otherwise>
                <Tooltip
                  overlay={
                    <>
                      This is your {getUserRoleLabel(UserRole.Advisor)}'s wallet
                    </>
                  }
                  placement="left"
                >
                  <Icon type="info" color />
                </Tooltip>
              </Otherwise>
            </Choose>
          </IconSettings>
        ),
      },
    ],
    []
  )
}

function BtnRemoveAsset(props: { asset: IFavoriteWalletAsset }) {
  const { asset } = props

  const [deleteAsset, { isLoading }] =
    WalletRTQApi.endpoints.removeFavoriteAsset.useMutation()

  const dialog = useModalConfirm(
    {
      onConfirm: () => deleteAsset(asset),
      btnConfirm: { accent: true, children: 'Delete' },
      title: 'Delete contact',
      icon: <Icon type="delete" box size="md" />,
    },
    <>
      Delete <b>{asset.name}</b> from your address book?
    </>
  )

  return (
    <>
      {dialog.$element}
      <IconButton
        icon="delete"
        accent
        onClick={dialog.open}
        pending={isLoading}
        title={{
          overlay: 'Delete contact',
          placement: 'left',
        }}
      />
    </>
  )
}
