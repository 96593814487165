import React, { ReactNode, useRef } from 'react'

import theme from 'src/styles/theme.module.scss'

import { INotification } from '../../../types'

const MARK_READ_DELAY = 500

export function useMarkReadHandlers(
  item: INotification,
  onRead: (id: INotification['id']) => void
) {
  const refHoverTimer = useRef<NodeJS.Timeout>()

  if (item.isRead) {
    return {
      onStartTimer: undefined,
      onResetTimer: undefined,
    }
  }

  return {
    onStartTimer: () => {
      if (refHoverTimer.current !== undefined) return
      refHoverTimer.current = setTimeout(onRead, MARK_READ_DELAY, item.id)
    },
    onResetTimer: () => {
      if (refHoverTimer.current) {
        clearTimeout(refHoverTimer.current)
        refHoverTimer.current = undefined
      }
    },
  }
}

/* TODO: 21.07.23: move this stuff to styles after approving design and release */
export function Typography(props: {
  children: ReactNode
  color?: 'primary' | 'secondary'
  bold?: boolean
  inline?: boolean
}) {
  const { children, color = 'primary', bold, inline = true } = props
  const Tag = inline ? 'span' : 'div'
  return (
    <Tag
      style={{
        lineHeight: 1.5,
        fontWeight: bold ? 500 : undefined,
        fontSize: '0.875rem',
        color:
          color === 'primary'
            ? theme.th_clr_txt_primary
            : theme.th_clr_txt_secondary,
      }}
    >
      {children}
    </Tag>
  )
}

export function KeyValue(props: {
  left: ReactNode
  right: ReactNode
  inline?: boolean
}) {
  const { left, right, inline } = props
  const Tag = inline ? 'span' : 'div'
  return (
    <Tag>
      <Typography bold color="secondary">
        {left}
      </Typography>{' '}
      <Typography bold color="primary">
        {right}
      </Typography>
    </Tag>
  )
}
