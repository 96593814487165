import { CSSProperties, ReactChild } from 'react'
import {
  Row,
  RowPropGetter,
  TableOptions,
  TableRowProps,
  TableState,
} from 'react-table'

import { ICustomPaginationProps } from './pagination'
import { PatchPropGetter } from './util'

import './plugins-builtin'
import './plugins-custom'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ITableProps<D extends object = any>
  extends Omit<TableOptions<D>, 'getRowProps'>,
    ICustomPaginationProps {
  className?: string
  style?: CSSProperties
  classNameInner?: string
  styleInner?: CSSProperties

  columnsTotalWidth?: CSSProperties['width']
  scrollableHeight?: number | string
  placeholder?: ReactChild

  getRowProps?: PatchPropGetter<RowPropGetter<D>>
  /* In this handler you can use hooks for a particular row
   * Usage: `useRowProps={function useRowProps({ row }) { ... }` – to beat eslint rules-of-hooks */
  useRowProps?: (params: { row: Row<D> }) => Omit<TableRowProps, 'key'>

  onChangeState?(state: TableState<D>): void
  emitStateInitialized?: boolean
}
