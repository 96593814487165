import clsx from 'clsx'

import { ICardProps } from './types'

import styles from './Card.module.scss'

export function Card(props: ICardProps) {
  const {
    tag: Tag = 'div',
    children,
    title,
    subtitle,
    titleTag: TitleTag = 'div',
    subtitleTag: SubtitleTag = 'div',
    footer,
    aside,
    className,
    style,
    spacing,
    elevate = false,
  } = props

  const hasAside = aside !== undefined

  const $main = (
    <>
      <If condition={title !== undefined || subtitle !== undefined}>
        <div className={styles.header}>
          <TitleTag className={styles.title}>{title}</TitleTag>
          <SubtitleTag className={styles.subtitle}>{subtitle}</SubtitleTag>
        </div>
      </If>

      {children}

      <If condition={footer !== undefined}>
        <div className={styles.footer}>{footer}</div>
      </If>
    </>
  )

  return (
    <Tag
      className={clsx(styles.card, className, {
        [styles.elevated]: elevate,
        [styles.spacing_sm]: spacing === 'sm',
        [styles.spacing_xs]: spacing === 'xs',
        [styles.card_aside]: hasAside,
      })}
      style={style}
    >
      <Choose>
        <When condition={hasAside}>
          <div className={styles.aside}>{aside}</div>
          <div className={styles.main}>{$main}</div>
        </When>

        <Otherwise>{$main}</Otherwise>
      </Choose>
    </Tag>
  )
}
