import { ReactNode } from 'react'

import { CabinetLayout } from '../../../components'
import { routes } from '../../../routes'

import { AdvisorNavigation } from './Navigation'

export function AdvisorLayout(props: { children: ReactNode }) {
  const { children } = props
  return (
    <CabinetLayout
      hrefHome={routes.advisor.dashboard}
      hrefInbox={routes.advisor.inbox}
      nav={<AdvisorNavigation />}
    >
      {children}
    </CabinetLayout>
  )
}
