import { useContext } from 'react'

import { Fieldset, Form, Icon, useModal } from 'src/components'
import { BlockchainAsset } from 'src/types'

import { WalletRTQApi } from '../../../../api'
import { FieldSelectAsset } from '../../../../components'
import { WalletContext } from '../../WalletContext'

export function useNewAssetModal(availableAssets: BlockchainAsset[]) {
  const wallet = useContext(WalletContext)
  const [createAsset] = WalletRTQApi.endpoints.createWalletAsset.useMutation()

  const modal = useModal(
    {
      title: 'Add a new asset to wallet',
      icon: <Icon type="wallet" box size="md" />,
      style: { content: { width: 400 } },
    },
    ({ close }) => (
      <NewAssetForm
        availableAssets={availableAssets}
        onSubmit={async form => {
          await createAsset({
            provider: wallet.provider,
            request_uuid: wallet.address,
            asset_symbol: form.asset,
          }).unwrap()
          close()
        }}
      />
    )
  )
  return modal
}

interface INewAssetFormFields {
  asset: BlockchainAsset
}

function NewAssetForm(props: {
  availableAssets?: BlockchainAsset[]
  onSubmit(data: INewAssetFormFields): void
}) {
  const { onSubmit, availableAssets = Object.values(BlockchainAsset) } = props

  return (
    <Form<INewAssetFormFields>
      onSubmit={onSubmit}
      allowSubmit={availableAssets.length > 0}
      btnSubmit="Add asset"
    >
      <Fieldset>
        <FieldSelectAsset
          name="asset"
          options={availableAssets}
          rules={{ required: true }}
          label="Select an asset to add"
          control={{
            layout: 'col',
          }}
        />
      </Fieldset>
    </Form>
  )
}
