import { useMemo } from 'react'

import { WalletRTQApi } from 'src/apps/cabinet/features/Wallet/api'

export function useSupportedAssets() {
  const { getWalletSupportedAssets } = WalletRTQApi.endpoints

  const stateDFNS = getWalletSupportedAssets.useQuery('dfns')
  const stateSafeheron = getWalletSupportedAssets.useQuery('safeheron')

  const supportedAssets = useMemo(
    () => [
      ...new Set([...(stateDFNS.data ?? []), ...(stateSafeheron.data ?? [])]),
    ],
    [stateDFNS.data, stateSafeheron.data]
  )

  return {
    supportedAssets,
    isFetching: stateDFNS.isLoading || stateSafeheron.isLoading,
  }
}
