import { memo, useContext, useState } from 'react'

import { IconButton } from 'src/components'
import { BlockchainAsset } from 'src/types'

import { WalletRTQApi } from '../../../../api'
import { WalletContext } from '../../WalletContext'

export const ControlAssetAddress = memo(function ControlAssetAddress(props: {
  asset: BlockchainAsset
}) {
  const { asset } = props

  const [onGetAddr, { address, isFetching, isShowAddrCopiedMsg }] =
    useOnGetAddressHandler(asset)

  return (
    <IconButton
      onClick={onGetAddr}
      title={{
        overlay: renderAddressTooltip({
          address,
          isCopied: isShowAddrCopiedMsg,
        }),
        destroyTooltipOnHide: true,
      }}
      pending={isFetching}
      icon="location"
    />
  )
})

function renderAddressTooltip(props: { address?: string; isCopied: boolean }) {
  const { address, isCopied } = props
  return (
    <Choose>
      <When condition={address === undefined}>Get asset address</When>

      <Otherwise>
        <div style={{ color: 'var(--th-clr-primary)' }}>{address}</div>
        <div style={{ textAlign: 'center' }}>
          <Choose>
            <When condition={isCopied}>Copied to clipboard!</When>

            <Otherwise>(click to copy)</Otherwise>
          </Choose>
        </div>
      </Otherwise>
    </Choose>
  )
}

// ---

function useOnGetAddressHandler(asset: BlockchainAsset) {
  const [getAddress, { address, isFetching }] = useEnsureAddressHandler(asset)

  const [isShowAddrCopiedMsg, setIsShowAddrCopiedMsg] = useState(false)

  const fetchOrCopyAddr = async () => {
    /* It could be simply `clipboard.writeText(await getAddress())` –
     * but Safari doesn't want you to have easy solutions.
     * In Safari clipboard access MUST be synchronous only. */
    await navigator.clipboard.write([
      new ClipboardItem({
        'text/plain': getAddress().then(
          txt => new Blob([txt], { type: 'text/plain' })
        ),
      }),
    ])
    await timeout(2000, setIsShowAddrCopiedMsg)
  }

  return [
    fetchOrCopyAddr,
    { address, isFetching, isShowAddrCopiedMsg },
  ] as const
}

function useEnsureAddressHandler(asset: BlockchainAsset) {
  const wallet = useContext(WalletContext)

  const [fetchAddress, { data: address, isFetching }] =
    WalletRTQApi.endpoints.getAssetAddress.useLazyQuery()

  return [
    async () => {
      if (address !== undefined) {
        return address
      }

      const request = fetchAddress({
        provider: wallet.provider,
        wallet: wallet.address,
        asset,
      })
      return await request.unwrap()
    },
    { address, isFetching },
  ] as const
}

async function timeout(delay: number, onLoading: (x: boolean) => void) {
  onLoading(true)
  await new Promise(res => setTimeout(res, delay))
  onLoading(false)
}
