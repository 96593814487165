import { ReactNode, useLayoutEffect, useReducer } from 'react'
import { createPortal } from 'react-dom'

export function Portal(props: {
  node: string | (() => Element | null)
  children: ReactNode
}) {
  useForceRender()
  const { node, children } = props
  // TODO: cache this?
  // Assume that by default for portals we use ids anyway. Anything more sophisticated would require a selector function.
  const el = typeof node === 'string' ? document.getElementById(node) : node()
  return el === null ? null : createPortal(children, el)
}

function useForceRender() {
  const [, forceRender] = useReducer(x => x + 1, 0)

  useLayoutEffect(() => {
    forceRender()
  }, [])
}
