import { createContext } from 'react'

import { IStoreProduct } from '../../types'

export const ProductContext = createContext<IStoreProduct>({
  id: '',
  merchant: 'dfns',
  name: '',
  description: '',
})
