import { createBrowserHistory } from 'history'

export const routes = {
  home: '/',

  avatar: {
    root: '/avatar',
    get dashboard() {
      return this.root
    },

    get agreements() {
      return `${this.root}/agreements` as const
    },

    get inbox() {
      return `${this.root}/inbox` as const
    },

    get wallet() {
      return wallet_module(this.root)
    },

    get store() {
      return `${this.root}/store` as const
    },

    get store_product() {
      return `${this.store}/product/:id` as const
    },
  },

  advisor: {
    root: '/advisor',
    get dashboard() {
      return this.root
    },
    get templates() {
      return `${this.root}/agreement-templates` as const
    },
    get avatar_details() {
      return `${this.root}/avatar/:id` as const
    },
    get agreement_form() {
      return `${this.root}/agreements/new` as const
    },
    get agreement_details() {
      return `${this.root}/agreements/:id` as const
    },
    get contacts() {
      return `${this.root}/contacts` as const
    },
    get inbox() {
      return `${this.root}/inbox` as const
    },
  },

  wallet_module,
} as const

function wallet_module(prefix: string) {
  const path = 'wallets'
  const root = prefix === '' ? path : `${prefix}/${path}`
  return {
    products: `${root}/products`,
    contacts: `${root}/address-book`,
    wallet_install: `${root}/install`,
    wallet_details: `${root}/wallet/:id`,
  } as const
}

// ---

const HISTORY_BASENAME = '/'

export const history = createBrowserHistory({
  basename: HISTORY_BASENAME,
})
