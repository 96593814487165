import { useContext } from 'react'

import { Card, ErrorMessage, Form, Grid, useFormContext } from 'src/components'
import { useOnChange } from 'src/hooks'
import { BlockchainAsset, BlockchainMethod } from 'src/types'
import { isSupportedBlockchainMethod } from 'src/utils'

import { IWCCallRequest } from '../../../../WalletConnect'
import { usePaymentTransactionFlow } from '../../../../lib'
import { WalletContext } from '../../WalletContext'

import { EthereumTransactionView } from './EthereumTransactionView'
import { PersonalSignView } from './PersonalSignView'
import { SwitchChainView } from './SwitchChainView'

import styles from './CallRequest.module.scss'

export function CallRequest(props: {
  req: IWCCallRequest
  asset: BlockchainAsset
  onApprove(): void
  onReject(): void
}) {
  const { req, asset, onApprove, onReject } = props
  const { method } = req

  const { provider } = useContext(WalletContext)
  const paymentFlow = usePaymentTransactionFlow({
    txnSource: 'wc',
    provider,
    sendTxn: onApprove,
  })

  return (
    <Grid gap="1rem">
      <div className={styles.header}>Approve this transaction?</div>
      <Card spacing="xs" className={styles.card}>
        {renderRequestView(req)}
      </Card>

      <Choose>
        <When condition={paymentFlow.isUnsafeTxn}>
          {paymentFlow.renderUnsafeTxnConfirm({ onReject })}
        </When>

        <Otherwise>
          <Form
            reset
            btnReset={{ children: 'Reject', accent: true }}
            btnSubmit={{ children: 'Approve' }}
            onReset={onReject}
            onSubmit={
              req.method === BlockchainMethod.eth_sendTransaction
                ? () => paymentFlow.run({ ...req.params, asset })
                : onApprove
            }
            allowSubmit={isSupportedBlockchainMethod(method)}
          >
            <AutoSubmitEffect method={method} />
          </Form>
        </Otherwise>
      </Choose>
    </Grid>
  )
}

function renderRequestView(request: IWCCallRequest) {
  const { method, params } = request
  switch (method) {
    case BlockchainMethod.eth_sendTransaction:
      return <EthereumTransactionView {...params} />

    case BlockchainMethod.eth_switchChain:
      return <SwitchChainView {...params} />

    case BlockchainMethod.personalSign:
      return <PersonalSignView params={params} />

    default:
      return (
        <ErrorMessage style={{ fontSize: '1rem' }}>
          Currently we don't support this kind of transactions
        </ErrorMessage>
      )
  }
}

function AutoSubmitEffect(props: { method: BlockchainMethod }) {
  const form = useFormContext()

  const { method } = props
  useOnChange(
    method,
    method => {
      /**
       * From what we know so far, if dApp requests a switchChain method, it expects it to "just work".
       * Not like "user should confirm in his wallet that he's agreed with chain switching".
       * Thus, auto-confirm these requests.
       */
      if (method === BlockchainMethod.eth_switchChain) {
        form.onSubmit()
      }
    },
    {
      onMount: true,
      // In case submit is instant, prevent UI blinking
      layout: true,
    }
  )
  return null
}
