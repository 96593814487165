import React, { CSSProperties, useMemo } from 'react'

import clsx from 'clsx'

import { IGridProps } from './types'

import styles from './Grid.module.scss'

export function Grid(props: IGridProps) {
  const {
    children,
    tag: Tag = 'div',
    gap = '1rem',
    columns,
    autoColumns,
    autoRows,
    justify,
    align,
    style,
    className,
    inline,
    ...rest
  } = props

  const inlineStyles = useMemo((): CSSProperties => {
    const [justifyContent, justifyItems] = resolveAlignmentShorthand(justify)
    const [alignContent, alignItems] = resolveAlignmentShorthand(align)

    return {
      gap,
      gridAutoFlow: isColumnLayout(columns) ? 'column' : undefined, // `row` is browser's default
      gridAutoRows: autoRows,
      gridAutoColumns: autoColumns,
      gridTemplateColumns: createTemplateColumns({ columns, autoColumns }),
      justifyContent,
      justifyItems,
      alignContent,
      alignItems,
      /**
       * Explicit style overrides everything.
       * Which creates an ambiguity in props, for sure.
       * But it's left intentionally, as "last resort" –
       * in case you got some lost with all those "handy shorthands".
       */
      ...style,
    }
  }, [columns, gap, style, autoColumns, autoRows, justify, align])

  return (
    <Tag
      {...rest}
      className={clsx(styles.grid, className, {
        [styles.inline]: inline,
      })}
      style={inlineStyles}
    >
      {children}
    </Tag>
  )
}

// ---

const DEFAULT_AUTO_COLUMN_WIDTH = 'auto'

function isColumnLayout(x: IGridProps['columns']) {
  return x === true || x === 1
}

function createTemplateColumns(
  params: Pick<IGridProps, 'columns' | 'autoColumns'>
): string | undefined {
  const { columns, autoColumns } = params
  if (isColumnLayout(columns)) {
    return undefined
  }

  if (typeof columns === 'string') {
    return columns
  }

  if (typeof columns === 'number') {
    return `repeat(${columns}, ${autoColumns ?? DEFAULT_AUTO_COLUMN_WIDTH})`
  }

  return undefined
}

function resolveAlignmentShorthand(
  str: IGridProps['justify'] | IGridProps['align']
): [string | undefined, string | undefined] {
  /**
   * `justify="start"` = `justify-content: start; justify-items: start`
   * `justify="start end"` = `justify-content: start; justify-items: end`
   *
   * It is assumed that by default you don't need that fine-tuning with different settings for content and items.
   * Like, if you want `center` – you want everything to be centered.
   */
  const [content, items = content] = str?.split(' ') ?? ([] as typeof str[])
  return [content, items]
}
