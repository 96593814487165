import { BsGraphUpArrow } from 'react-icons/bs'
import { FaFileContract, FaNetworkWired } from 'react-icons/fa'

import { AgreementType } from 'src/types'
import { getAgreementTypeLabel } from 'src/utils'

import { Flex } from '../../layouts'

export interface IAgreementTypeIconProps {
  type: AgreementType
  showTitle?: boolean
  size?: number | string
  color?: string
}

export function AgreementTypeIcon(props: IAgreementTypeIconProps) {
  const { type, size = '1.25em', color, showTitle } = props
  const Component = getIconComponent(type)
  return (
    <Flex align="center" inline gap="0.5em">
      <Component size={size} color={color} style={{ flexShrink: 0 }} />
      {showTitle && getAgreementTypeLabel(type)}
    </Flex>
  )
}

function getIconComponent(type: AgreementType) {
  switch (type) {
    case AgreementType.SafeheronAcquisition:
    case AgreementType.DfnsAcquisition:
      return FaFileContract
    case AgreementType.NodeAcquisition:
      return FaNetworkWired
    case AgreementType.ManagedPortfolio:
      return BsGraphUpArrow
    default:
      return () => null
  }
}
