import { RouterRootState } from 'connected-react-router'

export * from './base'
export * from './blockchain'
export * from './Agreement'
export * from './Notification'

// @see auth-gateway/src/main/kotlin/com/dcs/authgateway/enum/Role.kt
export enum UserRole {
  Test = 'Test', // mock, client-side only
  /* TODO: rename this entry to "NetworkServiceProvider" (on BE too) */
  Advisor = 'ADVISER',
  Investor = 'INVESTOR',
  Operator = 'NETWORK_OPERATOR',
  Avatar = 'AVATAR',
}

export interface ICountry {
  name: string
  code: string
  flag: string
}

// This is required to make `useSelector` hook work with utility selectors from `connected-react-router`
declare module 'react-redux' {
  interface DefaultRootState extends RouterRootState {}
}
