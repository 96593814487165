// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SignAgreementStep_root__3rAxq {\n  --agreement-border: 1px solid var(--th-clr-gray-200);\n  width: 800px;\n  border-radius: 16px;\n  border: var(--agreement-border);\n}\n\n.SignAgreementStep_doc__hQ8Gw {\n  padding-inline: 1.5rem;\n  padding-top: 1rem;\n  height: 600px;\n  overflow-y: auto;\n}\n\n.SignAgreementStep_footer__uYW2A {\n  display: grid;\n  padding-inline: 1.5rem;\n  padding-block: 1rem 1.5rem;\n  border-top: var(--agreement-border);\n}", "",{"version":3,"sources":["webpack://src/apps/cabinet/features/Wallet/pages/InstallWallet/steps/SignAgreementStep.module.scss"],"names":[],"mappings":"AAAA;EACE,oDAAA;EAEA,YAAA;EACA,mBAAA;EACA,+BAAA;AAAF;;AAGA;EACE,sBAAA;EACA,iBAAA;EACA,aAAA;EACA,gBAAA;AAAF;;AAGA;EACE,aAAA;EACA,sBAAA;EACA,0BAAA;EACA,mCAAA;AAAF","sourcesContent":[".root {\n  --agreement-border: 1px solid var(--th-clr-gray-200);\n\n  width: 800px;\n  border-radius: 16px;\n  border: var(--agreement-border);\n}\n\n.doc {\n  padding-inline: 1.5rem;\n  padding-top: 1rem;\n  height: 600px;\n  overflow-y: auto;\n}\n\n.footer {\n  display: grid; // stretch content to full width\n  padding-inline: 1.5rem;\n  padding-block: 1rem 1.5rem;\n  border-top: var(--agreement-border);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "SignAgreementStep_root__3rAxq",
	"doc": "SignAgreementStep_doc__hQ8Gw",
	"footer": "SignAgreementStep_footer__uYW2A"
};
export default ___CSS_LOADER_EXPORT___;
