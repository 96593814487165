// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PeerInfo_box__2SsYQ {\n  padding: 16px;\n  border-radius: 12px;\n  border: 1px solid var(--th-border-color);\n}\n\n.PeerInfo_app_icon__ifV3h {\n  --size: 3em;\n  width: var(--size);\n  height: var(--size);\n}\n\n.PeerInfo_peer_name__3t61v {\n  font-size: 1.125rem;\n  font-weight: bold;\n  grid-gap: 0.25em;\n  gap: 0.25em;\n  line-height: 1.5;\n}\n\n.PeerInfo_peer_description__1IJRT {\n  color: var(--th-clr-txt-secondary);\n}\n\n.PeerInfo_chain_name__3qgqF {\n  color: var(--th-clr-primary);\n  font-weight: bold;\n}", "",{"version":3,"sources":["webpack://src/apps/cabinet/features/Wallet/pages/WalletDetails/WalletConnect/SessionRequest/PeerInfo.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,wCAAA;AACF;;AAEA;EACE,WAAA;EACA,kBAAA;EACA,mBAAA;AACF;;AAEA;EACE,mBAAA;EACA,iBAAA;EACA,gBAAA;EAAA,WAAA;EACA,gBAAA;AACF;;AAEA;EACE,kCAAA;AACF;;AAEA;EACE,4BAAA;EACA,iBAAA;AACF","sourcesContent":[".box {\n  padding: 16px;\n  border-radius: 12px;\n  border: 1px solid var(--th-border-color);\n}\n\n.app_icon {\n  --size: 3em;\n  width: var(--size);\n  height: var(--size);\n}\n\n.peer_name {\n  font-size: 1.125rem;\n  font-weight: bold;\n  gap: 0.25em;\n  line-height: 1.5;\n}\n\n.peer_description {\n  color: var(--th-clr-txt-secondary);\n}\n\n.chain_name {\n  color: var(--th-clr-primary);\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "PeerInfo_box__2SsYQ",
	"app_icon": "PeerInfo_app_icon__ifV3h",
	"peer_name": "PeerInfo_peer_name__3t61v",
	"peer_description": "PeerInfo_peer_description__1IJRT",
	"chain_name": "PeerInfo_chain_name__3qgqF"
};
export default ___CSS_LOADER_EXPORT___;
