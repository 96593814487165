import { UUID } from 'src/types'

export type WalletID = UUID
export type BlockchainAddress = string // real "physical" address of BC wallet

export enum WalletStatus {
  Pending = 'pending',
  In_progress = 'in_progress',
  Provisioned = 'provisioned',
  Failed = 'failed',
}

export type WalletProvider = 'safeheron' | 'dfns'

export interface IWalletDescriptor {
  provider: WalletProvider
  wallet: WalletID
}

export interface IDefiProduct {
  ID: number
  name: string
  link: string
  description: string
  icon: string
}
