import { useWatch } from 'react-hook-form'
import { FiEye } from 'react-icons/fi'

import { PickByValue } from 'utility-types'

import { FieldFileInput, Flex, PDFPreview, Tooltip } from 'src/components'

import { INodeRentAgreementPayload } from '../../../types'

export function FieldUploadContract<
  Name extends keyof PickByValue<INodeRentAgreementPayload, string | File>
>(props: { name: Name }) {
  const { name } = props
  const value = useWatch<INodeRentAgreementPayload, Name>({ name })
  return (
    <Flex>
      <FieldFileInput<INodeRentAgreementPayload, Name>
        name={name}
        rules={{
          required: true,
        }}
        accept="application/pdf"
        placeholder="Upload contract PDF"
        control={{ style: { width: '100%' } }}
      />

      <If condition={value !== undefined}>
        <PDFPreview
          src={value as NonNullable<typeof value>}
          label={
            <Tooltip overlay="Preview file" autoWrap={false}>
              <FiEye size={24} />
            </Tooltip>
          }
          btnVariant="tertiary"
          style={{
            alignSelf: 'flex-start',
            padding: 8,
          }}
        />
      </If>
    </Flex>
  )
}
