import React from 'react'
import { FieldValues, FormProvider } from 'react-hook-form'

import {
  ErrorMessage as BaseFieldError,
  Props as FieldErrorProps,
} from '@hookform/error-message'

import {
  ErrorMessage,
  IErrorMessageProps,
} from 'src/components/base/ErrorMessage'

import FormDebug from './FormDebug'
import { IBaseFormProps } from './types'
import { FORM_ERROR_KEY_NAME, useForm } from './useForm'

export function BaseForm<TFieldValues extends FieldValues = FieldValues>(
  props: IBaseFormProps<TFieldValues>
) {
  const {
    debug = process.env.REACT_APP_DEBUG_FORMS === 'true',
    id,
    style,
    className,
    children,
    mode = 'onChange',
    ...config
  } = props

  const form = useForm({ mode, ...config })

  return (
    <FormProvider {...form}>
      <form
        id={id}
        className={className}
        style={style}
        onSubmit={form.onSubmit}
        // Although it's not recommended to invoke `reset` in `onReset` callback,
        // don't see any issues with this so far.
        // @see https://react-hook-form.com/api/useform/reset
        onReset={form.onReset}
      >
        <If
          condition={debug === true && process.env.NODE_ENV === 'development'}
        >
          <FormDebug />
        </If>

        {typeof children === 'function' ? children(form) : children}
      </form>
    </FormProvider>
  )
}

export const FieldError: typeof BaseFieldError = React.memo(function FieldError(
  props
) {
  /**
   * @see https://www.npmjs.com/package/@hookform/error-message
   * `as` prop takes precedence over 'render', when specified explicitly.
   * Respectively, if user passed a `render` here, don't specify default `as`, so it won't override thr `render`.
   */
  const defaultView = 'render' in props ? undefined : ErrorMessage
  const { as = defaultView, ...rest } = props
  return (
    <BaseFieldError
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      {...(rest as FieldErrorProps<any, any>)}
      as={as}
    />
  )
})

export const FormSubmitError = React.memo(function FormSubmitError(
  props: Omit<IErrorMessageProps, 'children'>
) {
  return (
    <FieldError
      name={FORM_ERROR_KEY_NAME}
      render={msg => <ErrorMessage {...props}>{msg}</ErrorMessage>}
    />
  )
})
