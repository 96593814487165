import { GoStopwatch } from 'react-icons/go'

import { Badge } from 'src/components'
import theme from 'src/styles/theme.module.scss'

export function WalletInstallTimeBadge(props: { time: string }) {
  const { time } = props
  return (
    <Badge
      size="sm"
      border
      style={{
        fontWeight: 500,
        ['--badge-clr-border' as string]: theme.th_clr_gray_200,
      }}
    >
      ~{time} <GoStopwatch />
    </Badge>
  )
}
