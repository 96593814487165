import { Grid, IconSettings } from 'src/components'
import { BlockchainAsset } from 'src/types'
import { DEFAULT_WALLET_ASSET } from 'src/utils'

import { ControlAssetAddress } from './ControlAssetAddress'
import { ControlCreateTransaction } from './ControlCreateTransaction'
import { ControlDeleteAsset } from './ControlDeleteAsset'

export function AssetControls(props: { asset: BlockchainAsset }) {
  const { asset } = props
  return (
    <Grid columns autoColumns="max-content" inline>
      <IconSettings size={28}>
        <ControlCreateTransaction asset={asset} />
        <ControlAssetAddress asset={asset} />
        <If condition={asset !== DEFAULT_WALLET_ASSET}>
          <ControlDeleteAsset asset={asset} />
        </If>
      </IconSettings>
    </Grid>
  )
}
