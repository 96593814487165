import { forwardRef } from 'react'
import { LinkProps, Link as ReactLink } from 'react-router-dom'

import clsx from 'clsx'

import { ButtonVariant, resolveButtonClasses } from '../Button'
import stylesButton from '../Button/Button.module.scss'
import { Icon } from '../Icon'

import { BTN_CLASS_PREFIX, ILinkProps } from './Link.types'

import styles from './Link.module.scss'

export const Link = forwardRef<HTMLAnchorElement, ILinkProps>(function Link(
  props,
  ref
) {
  const {
    children,
    className,
    size = 'lg',
    variant = 'secondary',
    external = false,
    target = external ? '_blank' : undefined,
    startIcon,
    endIcon = external ? <Icon type="external" size="1em" /> : undefined,
    disabled,
    accent,
    to,
    ...rest
  } = props

  return (
    <ReactLink
      {...rest}
      to={to}
      target={target}
      ref={ref}
      className={clsx(
        className,
        styles.link,
        resolveLinkClasses({ variant, size, disabled, accent })
      )}
      component={external ? ExternalLinkComponent : undefined}
    >
      {startIcon && <span className={styles.icon_wrapper}>{startIcon}</span>}
      {children}
      {endIcon && <span className={styles.icon_wrapper}>{endIcon}</span>}
    </ReactLink>
  )
})

const ExternalLinkComponent: LinkProps['component'] = ({
  navigate, // don't pass this to DOM element
  href,
  ...rest
}) => {
  /* ReactRouter treats all passed links as relative to history root, and always prepends root path.
   * Regardless of whether they are external.
   * So have to manually strip off anything that could appear before the protocol part. */
  const hrefExternal = href.replace(/^.*?\/(http)/, '$1')
  return (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a {...rest} href={hrefExternal} />
  )
}

export function resolveLinkClasses(
  params: Pick<ILinkProps, 'variant' | 'size' | 'disabled' | 'accent'>
) {
  const { variant, size, disabled, accent } = params
  if (variant?.startsWith(BTN_CLASS_PREFIX)) {
    const btnVariant = variant.slice(BTN_CLASS_PREFIX.length) as ButtonVariant
    return clsx(resolveButtonClasses({ variant: btnVariant, size }), {
      [stylesButton.disabled]: disabled,
      [stylesButton.accent]: accent,
    })
  }

  return clsx({
    [styles.link_variant]: variant !== 'none',
    [styles.disabled]: disabled,
    [styles.accent]: accent,

    [styles.primary]: variant === 'primary',
    [styles.secondary]: variant === 'secondary',

    [styles.size_sm]: size === 'sm',
    [styles.size_md]: size === 'md',
    [styles.size_lg]: size === 'lg',
    [styles.size_xl]: size === 'xl',
  })
}
