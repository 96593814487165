import {
  AgreementTypeIcon,
  IListProps,
  InDev,
  List,
  renderDefaultListItem,
} from 'src/components'
import { AgreementType } from 'src/types'

export function AgreementTypesList(props: IListProps<AgreementType>) {
  const { renderItem = renderDefaultListItem, ...rest } = props
  return (
    <List
      tag="div"
      data={[
        AgreementType.NodeAcquisition,
        AgreementType.SafeheronAcquisition,
        AgreementType.ManagedPortfolio,
      ]}
      renderItemContent={type => (
        <AgreementTypeIcon type={type} size={24} showTitle />
      )}
      // TODO: remove this intermediate method when ManagedPortfolio agreement will be implemented
      renderItem={(...args) => {
        const $content = renderItem(...args)
        const [type] = args
        return (
          <Choose>
            <When condition={type === AgreementType.ManagedPortfolio}>
              <InDev>{$content}</InDev>
            </When>

            <Otherwise>{$content}</Otherwise>
          </Choose>
        )
      }}
      defaultItemClickable
      getItemProps={() => ({ style: { display: 'flex' } })}
      {...rest}
    />
  )
}
