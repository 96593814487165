import {
  TypedUseSelectorHook,
  useSelector as useSelectorBase,
} from 'react-redux'

import type { AppState as AppStateAuth } from 'src/apps/auth'
import type { AppState as AppStateCabinet } from 'src/apps/cabinet'
import type { AppState as AppStateOperator } from 'src/apps/operator'

import { getUserHomePage } from '../lib'
import { selectUser } from '../selectors'

// This assumes every app has `user` slice in it's store. It's developer responsibility.
export const useUserSelector: TypedUseSelectorHook<
  AppStateCabinet | AppStateAuth | AppStateOperator
> = useSelectorBase

export const useUser = () => useUserSelector(selectUser)

export const useUserHomepage = () => getUserHomePage(useUser())
