import { rtqApi } from 'src/api'
import { Flex, Loader, Page } from 'src/components'
import { DEFAULT_POLLING_INTERVAL } from 'src/const'

import { NotificationsList } from './NotificationsList'

export function NotificationsCenterPage() {
  const { data, $status } = useNotifications()

  return (
    <Page title={<Flex align="center">Notifications {$status}</Flex>}>
      <NotificationsList items={data} />
    </Page>
  )
}

function useNotifications() {
  const {
    data = [],
    isLoading,
    isFetching,
  } = rtqApi.endpoints.getNotifications.useQuery(undefined, {
    pollingInterval: DEFAULT_POLLING_INTERVAL,
    refetchOnReconnect: true,
    refetchOnFocus: true,
  })

  // const unreadCount = data.filter(x => !x.isRead).length
  const $status = (
    <>
      {/* pre-release 26.07.23: hidden by results of UI review; design is to be developed */}
      {/*<If condition={unreadCount > 0}>
        <span style={{ color: 'var(--th-clr-error)' }}>({unreadCount})</span>
      </If>*/}

      <If condition={isFetching || isLoading}>
        <Loader size="1em" />
      </If>
    </>
  )

  return {
    data,
    $status,
  }
}
