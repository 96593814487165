import { generatePath } from 'react-router-dom'

import { routes } from 'src/apps/cabinet'
import { Flex, Grid, Icon, Link } from 'src/components'

import { WalletProvider } from '../../../Wallet'
import {
  WalletInstallTimeBadge,
  WalletProviderBadge,
} from '../../../Wallet/components'
import { IStoreProduct } from '../../types'

import styles from './ProductsList.module.scss'

export function ProductsList(props: { items: IStoreProduct[] }) {
  const { items } = props
  return (
    <Flex gap="1rem">
      {items.map(product => (
        <Product product={product} key={product.id} />
      ))}
    </Flex>
  )
}

function Product(props: { product: IStoreProduct }) {
  const { product } = props
  return (
    <Grid gap="1rem" className={styles.product}>
      <Grid gap="1rem" className={styles.product_main}>
        <div className={styles.product_header}>
          <WalletProviderBadge type={product.merchant} compact />
        </div>

        <div>
          <div className={styles.product_title}>
            {renderProductName(product.name)}
          </div>
          <div className={styles.product_desc}>{product.description}</div>
        </div>

        {/* TODO: show minimal price badge?
                  DB structure doesn't allow it for now */}

        <div className={styles.product_details}>
          <div className={styles.what_you_get}>What you'll get</div>
          <ProductFeatures items={getProductFeatures(product.merchant)} />
        </div>

        {/* TODO: link "Learn more about {MerchantName}"; pointing to where? */}
      </Grid>

      <Flex
        className={styles.product_footer}
        justify="space-between"
        align="center"
      >
        <WalletInstallTimeBadge
          time={getProductInstallTime(product.merchant)}
        />

        {/* TODO: in case we have products in "Coming soon" state, hide this button */}
        <Link
          to={generatePath(routes.avatar.store_product, { id: product.id })}
          variant="btn-secondary"
          size="sm"
        >
          View products
        </Link>
      </Flex>
    </Grid>
  )
}

function ProductFeatures(props: { items: string[] }) {
  const { items } = props
  return (
    <Grid tag="ul" gap="0.75rem">
      {items.map((x, i) => (
        <Flex
          key={i}
          tag="li"
          gap="0.5rem"
          align="center"
          className={styles.product_feature}
        >
          <Icon
            type="check"
            size={20}
            className={styles.product_feature_check}
          />
          {x}
        </Flex>
      ))}
    </Grid>
  )
}

function renderProductName(x: string | WalletProvider) {
  switch (x) {
    case 'dfns':
      return 'DFNS'
    case 'safeheron':
      return 'Safeheron'
    default:
      return x
  }
}

/* TODO: this probably should be in DB. Currently there is no DB fields for this info. */
function getProductFeatures(provider: WalletProvider) {
  switch (provider) {
    case 'dfns':
      return [
        'Keyless wallet infrastructure.',
        'Decentralized custody network with a zero-trust approach.',
      ]
    case 'safeheron':
      return [
        'Open-source digital asset self-custody service.',
        'Multi-party computation (MPC) for private keys.',
      ]
  }
}

/* TODO: This probably also should be in DB */
function getProductInstallTime(provider: WalletProvider) {
  switch (provider) {
    case 'dfns':
      return '5min'
    case 'safeheron':
      return '20min'
    default:
      return ''
  }
}
