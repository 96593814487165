import { memo, useContext } from 'react'
import { toast } from 'react-toastify'

import { IconButton, useModal } from 'src/components'
import { BlockchainAsset } from 'src/types'

import { WalletRTQApi } from '../../../../api'
import { usePaymentTransactionFlow } from '../../../../lib'
import { TransactionForm } from '../../TransactionForm'
import { WalletContext } from '../../WalletContext'

import { AvailableAssetsContext } from './Contexts'

export const ControlCreateTransaction = memo(
  function ControlCreateTransaction(props: { asset: BlockchainAsset }) {
    const { asset } = props

    const transactionDialog = useTransactionDialog(asset)

    return (
      <>
        <IconButton
          onClick={transactionDialog.open}
          title={{
            overlay: 'Create transaction',
            destroyTooltipOnHide: true,
          }}
          icon="coin"
        />
        {transactionDialog.$element}
      </>
    )
  }
)

// ---

function useTransactionDialog(asset: BlockchainAsset) {
  const wallet = useContext(WalletContext)
  const availableAssets = useContext(AvailableAssetsContext)

  const [sendTransaction] = WalletRTQApi.endpoints.sendTransaction.useMutation()

  const paymentFlow = usePaymentTransactionFlow({
    txnSource: 'polity',
    provider: wallet.provider,
    sendTxn: txn =>
      sendTransaction({ ...txn, provider: wallet.provider }).unwrap(),

    onTxnSubmitted(response) {
      toast.success(
        <>
          <div>Transaction {response.transaction_id} has been sent!</div>
          <div>Try refreshing assets list shortly</div>
        </>
      )
    },
  })

  const txnDialog = useModal(
    {
      style: { content: { width: 500 } },
      title: `Create transaction in wallet '${wallet.name}'`,
      /* Make sure user wouldn't close modal by accident
       * when smth dangerous happens with his money */
      defaultRequired: paymentFlow.isUnsafeTxn,
      showCloseBtn: true,
      onRequestClose: paymentFlow.reset,
    },
    ({ close }) => {
      if (paymentFlow.isUnsafeTxn) {
        return paymentFlow.renderUnsafeTxnConfirm({ onDone: close }) ?? <div />
      }

      return (
        <TransactionForm
          availableAssets={availableAssets}
          defaultValues={{ asset }}
          onSubmit={fields =>
            paymentFlow.run({ ...fields, from: wallet.address }, close)
          }
        />
      )
    }
  )

  return txnDialog
}
