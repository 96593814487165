import { ReactNode } from 'react'

import { WizardProgressbar } from 'src/components/domain'
import { zipObjectFromKeys } from 'src/utils'

import { WizardStep } from './lib'

const PROGRESSBAR_STEPS = [
  WizardStep.SelectProvider,
  WizardStep.WalletName,
  WizardStep.Overview,
  WizardStep.SignAgreement,
]

export function InstallWalletProgressbar(props: { step: WizardStep }) {
  const { step } = props
  return (
    <WizardProgressbar
      step={resolveActiveStep(step)}
      steps={zipObjectFromKeys(PROGRESSBAR_STEPS, getStepLabel)}
    />
  )
}

/* Wallet installation flow technically has more steps than by design is shown in progressbar.
 * Exceeding steps should be somehow mapped to included ones.
 * See design: https://www.figma.com/file/xkqLbLYkXoYZlwH5U4BT28/User-Path-1%3A-Client-Access-Management-(Copy)?type=design&node-id=340%3A22178&mode=design&t=HCCyhwWtRbbFXi3H-1 */
function resolveActiveStep(step: WizardStep): WizardStep {
  switch (step) {
    case WizardStep.Intro:
      return step
    case WizardStep.CreateWallet:
      return WizardStep.SignAgreement
    default:
      return step
  }
}

function getStepLabel(step: WizardStep): ReactNode {
  switch (step) {
    case WizardStep.SelectProvider:
      return 'Select your Provider'
    case WizardStep.WalletName:
      return 'Name your wallet'
    case WizardStep.Overview:
      return 'Confirm your wallet'
    case WizardStep.SignAgreement:
      return 'Sign Agreement'
    default:
      return null
  }
}
