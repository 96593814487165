import { forwardRef } from 'react'

import clsx from 'clsx'

import { IButtonProps } from './Button.types'

import styles from './Button.module.scss'

export const Button = forwardRef<HTMLButtonElement, IButtonProps>(
  function Button(props, ref) {
    const {
      children,
      type = 'button',
      className,
      size = 'lg',
      variant = 'primary',
      accent = false,
      startIcon,
      endIcon,
      pseudoDisabled,
      disabled,
      onClick,
      ...rest
    } = props

    return (
      <button
        {...rest}
        ref={ref}
        type={type}
        className={clsx(className, resolveButtonClasses({ variant, size }), {
          [styles.accent]: accent,
          [styles.disabled]: pseudoDisabled && disabled,
        })}
        disabled={pseudoDisabled ? false : disabled}
        onClick={disabled && pseudoDisabled ? undefined : onClick}
      >
        {startIcon && <span className={styles.icon_wrapper}>{startIcon}</span>}
        {children}
        {endIcon && <span className={styles.icon_wrapper}>{endIcon}</span>}
      </button>
    )
  }
)

export function resolveButtonClasses(
  params: Pick<IButtonProps, 'variant' | 'size'>
) {
  const { variant, size } = params
  return clsx(styles.btn, {
    [styles.primary]: variant === 'primary',
    [styles.secondary]: variant === 'secondary',
    [styles.tertiary]: variant === 'tertiary',

    [styles.size_sm]: size === 'sm',
    [styles.size_md]: size === 'md',
    [styles.size_lg]: size === 'lg',
    [styles.size_xl]: size === 'xl',
  })
}
