import { Grid } from '../../layouts'
import { Button } from '../Button'
import { Loader } from '../Loader'

import { Modal } from './Modal'
import { IModalConfirmProps } from './types'

import styles from './ModalConfirm.module.scss'

export function ModalConfirm(props: IModalConfirmProps) {
  const {
    children,
    form,
    btnCancel = {},
    btnConfirm = {},
    onCancel,
    onConfirm,
    allowConfirm = true,
    isLoading = false,
    /* TODO: somehow sync loader size with button height? */
    loader = <Loader size={40.5} />,
    ...rest
  } = props

  const {
    children: txtConfirm = 'Ok',
    variant: btnConfirmVariant = 'primary',
  } = btnConfirm
  const {
    children: txtCancel = 'Cancel',
    variant: btnCancelVariant = 'secondary',
  } = btnCancel

  return (
    <Modal
      {...rest}
      onRequestClose={onCancel}
      footer={
        <Choose>
          <When condition={isLoading}>
            <Grid align="center" justify="center">
              {typeof loader === 'function' ? loader() : loader}
            </Grid>
          </When>

          <Otherwise>
            <Grid className={styles.footer} columns autoColumns="1fr">
              <Button
                {...btnCancel}
                onClick={onCancel}
                variant={btnCancelVariant}
                type={form ? 'reset' : 'button'}
                form={form}
              >
                {txtCancel}
              </Button>

              <Button
                {...btnConfirm}
                onClick={onConfirm}
                variant={btnConfirmVariant}
                type={form ? 'submit' : 'button'}
                form={form}
                disabled={!allowConfirm}
              >
                {txtConfirm}
              </Button>
            </Grid>
          </Otherwise>
        </Choose>
      }
    >
      {children}
    </Modal>
  )
}
