import { ReactNode } from 'react'
import { ErrorBoundary } from 'react-app-error-boundary'

import { Button, ErrorMessage, Loader } from 'src/components'

import { IWCFacade, useWalletConnect } from '../../../WalletConnect'
import { WalletID } from '../../../types'

import { WCConnected } from './Connected'
import { WCConnector } from './Connector'
import { Layout } from './Layout'

export function WCContainer(props: { wallet: WalletID }) {
  const { wallet } = props
  return (
    <WCErrorHandler>
      <ConnectionManager wallet={wallet} />
    </WCErrorHandler>
  )
}

function ConnectionManager(props: { wallet: WalletID }) {
  const { wallet } = props
  const [{ status, value: wc, error }] = useWalletConnect({
    storageId: `plt:wc-session:${wallet}`,
  })

  // TODO: make RTQSuspender compatible with useAsync?
  const loading = status === 'loading'
  const hasData = wc !== undefined

  return hasData ? renderClient(wc) : renderMeta(loading, error)
}

function renderClient(wc: IWCFacade) {
  return wc.connected ? <WCConnected wc={wc} /> : <WCConnector wc={wc} />
}

function WCErrorHandler(props: { children: ReactNode }) {
  const { children } = props
  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <Layout
          header={null}
          title={
            <ErrorMessage>
              Unexpected error happened in WalletConnect
            </ErrorMessage>
          }
          desc={<ErrorMessage>{error}</ErrorMessage>}
        >
          <Button variant="secondary" onClick={resetErrorBoundary}>
            Retry
          </Button>
        </Layout>
      )}
    >
      {children}
    </ErrorBoundary>
  )
}

function renderMeta(loading: boolean, error: unknown) {
  if (loading) {
    return <Loader size={28} />
  }

  /* Throw error to be caught by ErrorBoundary up in tree */
  if (error !== undefined) {
    throw error
  }

  return null
}
