import React, { ReactNode } from 'react'
import { generatePath } from 'react-router-dom'

import { routes } from 'src/apps/cabinet'
import {
  NotificationsList as BaseNotificationsList,
  Icon,
  Link,
  Tooltip,
} from 'src/components'
import { INotification, NotificationType } from 'src/types'

export function NotificationsList(props: { items: INotification[] }) {
  const { items } = props
  return (
    <BaseNotificationsList
      data={items}
      renderActions={item => {
        switch (item.notificationType) {
          case NotificationType.Wallet:
            return renderLink({
              to: generatePath(routes.avatar.wallet.wallet_details, {
                id: item.payload.request_uuid,
              }),
              tooltip: 'Go to wallet details',
            })

          case NotificationType.NodeAcquisition:
          case NotificationType.SafeheronAcquisition:
          case NotificationType.DfnsAcquisition:
            return renderLink({
              to: `${routes.avatar.agreements}#${item.payload.agreementId}`,
              tooltip: 'Go to agreement',
            })

          default:
            return null
        }
      }}
    />
  )
}

function renderLink(props: { to: string; tooltip: ReactNode }) {
  const { to, tooltip } = props
  return (
    <Link to={to} variant="btn-tertiary">
      <Tooltip overlay={tooltip}>
        <Icon box size="sm" type="chevron-right" />
      </Tooltip>
    </Link>
  )
}
