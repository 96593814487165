import { Grid } from 'src/components'
import { BlockchainAsset } from 'src/types'
import { getChainSupportedAssets } from 'src/utils'

import { IWCFacade, IWCSessionRequest } from '../../../../WalletConnect'

import { AssetsProvider, IAssetsProviderResponse } from './AssetsProvider'
import { PeerInfo } from './PeerInfo'
import { SessionRequestForm } from './SessionRequestForm'

interface ISessionRequestProps {
  wc: IWCFacade
  request: IWCSessionRequest
  onDone?(): void
}

export function SessionRequest(props: ISessionRequestProps) {
  const { request } = props

  return (
    <Grid>
      <div>DApp asks your permission to establish connection</div>
      <PeerInfo chainId={request.chainId} peer={request.peerMeta} />
      <hr />
      <FormContainer {...props} />
    </Grid>
  )
}

function FormContainer(props: ISessionRequestProps) {
  const { wc, request, onDone } = props

  const handleSubmit = useSubmitHandler(props)
  return (
    <AssetsProvider chainId={request.chainId}>
      {({ supportedAssets, fallbackAsset, fallbackChain, getAssetAddress }) => (
        <SessionRequestForm
          assets={supportedAssets}
          fallbackAsset={fallbackAsset}
          recommendedAssets={getChainSupportedAssets(request.chainId)}
          onReset={async () => {
            await wc.rejectSession({ id: request.id })
            onDone?.()
          }}
          onSubmit={async ({ asset }) =>
            handleSubmit({
              selectedAsset: asset,
              fallbackAsset,
              fallbackChain,
              getAssetAddress,
            })
          }
        />
      )}
    </AssetsProvider>
  )
}

function useSubmitHandler(params: ISessionRequestProps) {
  const { wc, request, onDone } = params

  return async (
    params: Pick<
      IAssetsProviderResponse,
      'getAssetAddress' | 'fallbackAsset' | 'fallbackChain'
    > & {
      selectedAsset: BlockchainAsset | undefined
    }
  ) => {
    const { selectedAsset, fallbackAsset, getAssetAddress, fallbackChain } =
      params

    const shouldTrySwitchChain = selectedAsset === undefined
    const asset = selectedAsset ?? fallbackAsset

    if (asset === undefined) {
      console.error('Neither selected nor fallback asset selected')
      return
    }

    const address = await getAssetAddress(asset)

    await wc.approveSession({
      id: request.id,
      chains: request.chains,
      methods: request.methods,
      events: request.events,
      accounts: [address],
      asset,
    })

    /**
     * Logic of this behavior see here:
     * @link https://dcspoc.atlassian.net/browse/PLT-403?focusedCommentId=10560
     */
    if (shouldTrySwitchChain) {
      await wc.updateSession({
        requestId: request.id,
        chainId: fallbackChain,
      })
    }

    onDone?.()
  }
}
