// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProviderCard_card__1zgOL {\n  display: flex;\n  flex-direction: column;\n  width: 412px;\n  border: 1px solid var(--th-clr-gray-200);\n  border-radius: 16px;\n  font-size: 14px;\n}\n\n.ProviderCard_card_header__2U3t5 {\n  display: flex;\n  align-items: center;\n  grid-gap: 10px;\n  gap: 10px;\n  margin-bottom: 24px;\n  font-weight: 500;\n  font-size: 24px;\n}\n\n.ProviderCard_card_content__350yV {\n  flex-grow: 1;\n  padding: 0 20px;\n}\n\n.ProviderCard_card_footer__3JaM2 {\n  padding: 16px 20px;\n  border-top: 1px solid var(--th-clr-gray-200);\n}\n.ProviderCard_card_footer__3JaM2 button {\n  width: 100%;\n}\n\n.ProviderCard_card_img__1z7Xc {\n  display: block;\n  margin: 16px auto;\n}", "",{"version":3,"sources":["webpack://src/apps/cabinet/features/Wallet/pages/InstallWallet/steps/components/ProviderCard.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,wCAAA;EACA,mBAAA;EACA,eAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,cAAA;EAAA,SAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;AACF;;AAEA;EACE,YAAA;EACA,eAAA;AACF;;AAEA;EACE,kBAAA;EACA,4CAAA;AACF;AACE;EACE,WAAA;AACJ;;AAGA;EACE,cAAA;EACA,iBAAA;AAAF","sourcesContent":[".card {\n  display: flex;\n  flex-direction: column;\n  width: 412px;\n  border: 1px solid var(--th-clr-gray-200);\n  border-radius: 16px;\n  font-size: 14px;\n}\n\n.card_header {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  margin-bottom: 24px;\n  font-weight: 500;\n  font-size: 24px;\n}\n\n.card_content {\n  flex-grow: 1;\n  padding: 0 20px;\n}\n\n.card_footer {\n  padding: 16px 20px;\n  border-top: 1px solid var(--th-clr-gray-200);\n\n  button {\n    width: 100%;\n  }\n}\n\n.card_img {\n  display: block;\n  margin: 16px auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "ProviderCard_card__1zgOL",
	"card_header": "ProviderCard_card_header__2U3t5",
	"card_content": "ProviderCard_card_content__350yV",
	"card_footer": "ProviderCard_card_footer__3JaM2",
	"card_img": "ProviderCard_card_img__1z7Xc"
};
export default ___CSS_LOADER_EXPORT___;
