import {
  Row,
  TableCellProps,
  TableRowProps,
  useColumnOrder,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'

import clsx from 'clsx'
import { isEqual, merge } from 'lodash'

import { useOnChange } from 'src/hooks'

import Pagination from './Pagination'
import Placeholder from './Placeholder'
import { ITableProps } from './types'

import styles from './table.module.scss'

export default function Table<Row extends object>(props: ITableProps<Row>) {
  const {
    getRowProps,
    useRowProps,
    className,
    style,
    classNameInner,
    styleInner,
    placeholder,
    scrollableHeight,
    columnsTotalWidth,
    // pagination
    pageSizesList,
    renderPageCounter,
    showPagination = true,
    showPageSizeSelector = false,
    // other customs
    onChangeState,
    emitStateInitialized,
    // useTable hook config
    ...config
  } = props

  const table = useTable(
    config,
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    useColumnOrder
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page: rows,
    state,
    pageCount,
  } = table

  useOnChange(
    state,
    state => {
      onChangeState?.(state)
    },
    {
      // Use deep equality, because table state is a complex nested structure
      eq: isEqual,
      onMount: emitStateInitialized,
    }
  )

  /* keys are provided internally by `getXXXProps` methods */
  /* eslint-disable react/jsx-key */
  const $table = (
    <table
      {...getTableProps({
        className: clsx(styles.table, classNameInner),
        style: { width: columnsTotalWidth, ...styleInner },
      })}
    >
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => {
              const { tx, th } = column
              return (
                <th
                  {...mergeCellProps(
                    column.getHeaderProps(tx),
                    column.getHeaderProps(
                      typeof th === 'function' ? patchPropGetter(th) : th
                    )
                  )}
                >
                  {column.render('Header')}
                </th>
              )
            })}
          </tr>
        ))}
      </thead>

      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row)
          const rowProps = row.getRowProps(patchPropGetter(getRowProps))
          return (
            <DataRow
              key={rowProps.key}
              row={row}
              extraProps={rowProps}
              useRowProps={useRowProps}
            />
          )
        })}
      </tbody>
    </table>
  )

  // ---

  const isEmpty = pageCount === 0
  const shouldShowPagination = showPagination && !isEmpty

  const $pagination = shouldShowPagination && (
    <Pagination
      {...table}
      pageIndex={state.pageIndex}
      pageSize={state.pageSize}
      pageSizesList={pageSizesList}
      showPageSizeSelector={showPageSizeSelector}
      renderPageCounter={renderPageCounter}
    />
  )

  return (
    <div className={clsx(styles.container, className)} style={style}>
      <div
        className={styles.placeholder_container}
        style={{ maxHeight: scrollableHeight }}
      >
        <Choose>
          <When condition={isEmpty}>
            <Placeholder>{placeholder}</Placeholder>
          </When>

          <Otherwise>{$table}</Otherwise>
        </Choose>
      </div>
      {$pagination}
    </div>
  )
}

function DataRow<T extends object>(props: {
  row: Row<T>
  extraProps?: TableRowProps
  useRowProps?: ITableProps<T>['useRowProps']
}) {
  const { row, useRowProps, extraProps } = props

  return (
    <tr
      {...mergeClassNames(
        { className: styles.data_row },
        extraProps,
        useRowProps?.({ row })
      )}
    >
      {row.cells.map(cell => {
        const { tx, td, cell_content, accent } = cell.column
        const cellProps = mergeCellProps(
          cell.getCellProps(tx),
          cell.getCellProps(typeof td === 'function' ? patchPropGetter(td) : td)
        )
        return (
          <td
            {...cellProps}
            className={clsx(cellProps.className, {
              [styles.cell_txt_accent]: accent,
            })}
          >
            <div
              {...mergeClassNames(
                { className: styles.cell_content },
                cell.getCellProps(
                  typeof cell_content === 'function'
                    ? patchPropGetter(cell_content)
                    : cell_content
                )
              )}
            >
              {cell.render('Cell')}
            </div>
          </td>
        )
      })}
    </tr>
  )
}

// ---

function patchPropGetter<Meta, Props, R>(fn?: (meta: Meta, props: Props) => R) {
  return (props: Props, meta: Meta) =>
    !fn ? props : merge({}, props, fn(meta, props))
}

function mergeCellProps(common: TableCellProps, extra: TableCellProps) {
  return merge({}, common, extra, {
    className: clsx(styles.cell, common.className, extra.className),
  })
}

function mergeClassNames(...xs: Array<{ className?: string } | undefined>) {
  return merge({}, ...xs, {
    className: clsx(...xs.map(x => x?.className)),
  })
}
