import { FaArrowRight } from 'react-icons/fa'

import { Badge, IBadgeProps } from 'src/components/base'
import { AgreementStatus } from 'src/types'

import { AgreementStatusIcon } from '../../AgreementIcon'
import { IBaseAgreementBadgeProps } from '../types'

export interface IAgreementStatusBadgeProps extends IBaseAgreementBadgeProps {
  children: AgreementStatus
  prev?: AgreementStatus
}

export function AgreementStatusBadge(props: IAgreementStatusBadgeProps) {
  const { children: status, iconSize = 20, showTitle, prev, ...rest } = props
  return (
    <Badge {...rest} variant={getBadgeVariant(status)}>
      <If condition={prev !== undefined}>
        <AgreementStatusIcon
          status={prev as NonNullable<typeof prev>}
          size={iconSize}
          showTitle={showTitle}
        />
        <FaArrowRight />
      </If>
      <AgreementStatusIcon
        status={status}
        size={iconSize}
        showTitle={showTitle}
      />
    </Badge>
  )
}

function getBadgeVariant(status: AgreementStatus): IBadgeProps['variant'] {
  switch (status) {
    case AgreementStatus.Verified:
    case AgreementStatus.Notarised:
      return 'success'
    case AgreementStatus.Signed:
      return 'info'
    case AgreementStatus.Declined:
      return 'error'
    default:
      return undefined
  }
}
