// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Balance_root__2fIma.Balance_error__dC017 .Balance_integer__2JggS,\n.Balance_root__2fIma.Balance_error__dC017 .Balance_decimal__1fT8D {\n  color: var(--th-clr-error);\n}\n\n.Balance_value__2DqT1 {\n  /* none */\n}\n\n.Balance_title__2wk21 {\n  color: var(--th-clr-gray-700);\n  font-size: 1rem;\n  line-height: 1.5;\n}\n\n.Balance_integer__2JggS {\n  font-size: 3rem;\n  font-weight: 600;\n  line-height: 1.25;\n  color: var(--th-clr-txt-primary);\n}\n\n.Balance_decimal__1fT8D {\n  font-size: 1.875rem;\n  font-weight: 500;\n  line-height: 1.25;\n  color: var(--th-clr-txt-secondary);\n  display: inline-flex;\n  align-items: center;\n  grid-gap: 0.5rem;\n  gap: 0.5rem;\n}", "",{"version":3,"sources":["webpack://src/apps/cabinet/features/Wallet/components/Balance/Balance.module.scss"],"names":[],"mappings":"AAEI;;EAEE,0BAAA;AADN;;AAMA;EAAS,SAAA;AAFT;;AAIA;EACE,6BAAA;EACA,eAAA;EACA,gBAAA;AADF;;AAIA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gCAAA;AADF;;AAIA;EACE,mBAAA;EACA,gBAAA;EACA,iBAAA;EACA,kCAAA;EAEA,oBAAA;EACA,mBAAA;EACA,gBAAA;EAAA,WAAA;AAFF","sourcesContent":[".root {\n  &.error {\n    .integer,\n    .decimal {\n      color: var(--th-clr-error);\n    }\n  }\n}\n\n.value { /* none */ }\n\n.title {\n  color: var(--th-clr-gray-700);\n  font-size: 1rem;\n  line-height: 1.5;\n}\n\n.integer {\n  font-size: 3rem;\n  font-weight: 600;\n  line-height: 1.25;\n  color: var(--th-clr-txt-primary);\n}\n\n.decimal {\n  font-size: 1.875rem;\n  font-weight: 500;\n  line-height: 1.25;\n  color: var(--th-clr-txt-secondary);\n\n  display: inline-flex;\n  align-items: center;\n  gap: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Balance_root__2fIma",
	"error": "Balance_error__dC017",
	"integer": "Balance_integer__2JggS",
	"decimal": "Balance_decimal__1fT8D",
	"value": "Balance_value__2DqT1",
	"title": "Balance_title__2wk21"
};
export default ___CSS_LOADER_EXPORT___;
