import { forwardRef, isValidElement } from 'react'

import clsx from 'clsx'
import { isPrimitive } from 'utility-types'

import { mergeStyles } from 'src/utils'

import { useBoxStyles } from '../../layouts/Box'
import { Icon, useIconSettingsContext } from '../Icon'
import { Loader } from '../Loader'
import { Tooltip } from '../Tooltip'

import { Button } from './Button'
import { IIconButtonProps, IIconTooltipProps } from './IconButton.types'

import styles from './IconButton.module.scss'

export const IconButton = forwardRef<HTMLButtonElement, IIconButtonProps>(
  function IconButton(props, ref) {
    const { size: defaultSize } = useIconSettingsContext()
    const {
      size = defaultSize,
      title,
      disabled,
      className,
      style,
      pending,
      icon,
      color,
      ...rest
    } = props

    const boxStyles = useBoxStyles({
      size,
      inline: true,
      bordered: false,
    })

    const $button = (
      <Button
        {...rest}
        size="unset"
        ref={ref}
        disabled={disabled}
        className={clsx(className, styles.btn_icon, boxStyles.className)}
        style={mergeStyles(boxStyles.style, style)}
      >
        {pending ? (
          <Loader className={styles.spinner} />
        ) : (
          <Icon
            color={color}
            type={icon}
            disabled={disabled}
            /* let Icon grow as much as button allows */
            size="auto"
          />
        )}
      </Button>
    )

    if (title === undefined) {
      return $button
    }

    // ---

    const tooltipProps: IIconTooltipProps =
      isPrimitive(title) || isValidElement(title) ? { overlay: title } : title

    return (
      <Tooltip {...tooltipProps} autoWrap={false}>
        {$button}
      </Tooltip>
    )
  }
)
