import { useContext } from 'react'

import { Button, Flex, useModal } from 'src/components'

import { WalletProviderBadge } from '../../../Wallet/components'
import { IProductTier } from '../../types'

import { BtnBuyNow } from './BtnBuyNow'
import { ProductTierBadge } from './ProductTierBadge'
import { ProductContext } from './ProuctContext'
import { TierBidding } from './TierBidding'
import { ProductTierLevel } from './types'

import styles from './ProductTier.module.scss'

export function ProductTier(props: { tier: IProductTier }) {
  const { tier } = props
  const product = useContext(ProductContext)
  const modal = useBiddingModal({ tier })

  return (
    <div className={styles.root}>
      <Flex justify="space-between" align="center">
        <ProductTierBadge>{tier.tier_name}</ProductTierBadge>
        <WalletProviderBadge type={product.merchant} compact />
      </Flex>

      <div className={styles.desc}>{getTierDescription(tier.tier_name)}</div>

      {/* TODO: display highest bid in live, and timer? */}

      <Flex gap="0.5rem" justify="flex-end">
        <Button onClick={modal.open} variant="secondary" size="sm">
          Make bid
        </Button>

        <BtnBuyNow
          price={tier.buy_now_price}
          tier_id={tier.id}
          product_id={tier.product_id}
        />
      </Flex>

      {modal.$element}
    </div>
  )
}

/* TODO: this should be a field in DB */
function getTierDescription(tierName: string) {
  switch (tierName.toLowerCase()) {
    case ProductTierLevel.Bronze:
      return 'One Wallet'
    case ProductTierLevel.Silver:
      return 'Two Wallets'
    case ProductTierLevel.Gold:
      return 'Three Wallets'
    default:
      return null
  }
}

function useBiddingModal(props: { tier: IProductTier }) {
  const { tier } = props
  const product = useContext(ProductContext)
  return useModal(
    {
      title: (
        <Flex align="center">
          <WalletProviderBadge type={product.merchant} compact />
          <ProductTierBadge>{tier.tier_name}</ProductTierBadge>
        </Flex>
      ),
      style: { content: { width: 400 } },
    },
    ({ close }) => <TierBidding tier={tier} onCancel={close} />
  )
}
