import React from 'react'

import { rtqApi } from 'src/api'
import { NotificationsList, Page } from 'src/components'
import { DEFAULT_POLLING_INTERVAL } from 'src/const'

export function InboxPage() {
  const { data = [] } = rtqApi.endpoints.getNotifications.useQuery(undefined, {
    pollingInterval: DEFAULT_POLLING_INTERVAL,
    refetchOnReconnect: true,
    refetchOnFocus: true,
  })

  return (
    <Page title="Notifications">
      <NotificationsList data={data} />
    </Page>
  )
}
