import { ReactElement, useLayoutEffect } from 'react'

import stylesTheme from 'src/styles/theme.module.scss'

export function ThemeProvider(props: {
  children: ReactElement
  theme?: 'polity'
}) {
  const { children, theme = 'polity' } = props
  useBodyClass(theme === 'polity' ? stylesTheme.theme_polity : '')
  return children
}

function useBodyClass(cls: string) {
  useLayoutEffect(() => {
    const toggle = (state: boolean) =>
      document.body.classList.toggle(cls, state)
    toggle(true)
    return () => {
      toggle(false)
    }
  }, [cls])
}
