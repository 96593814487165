import { ReactNode } from 'react'
import { FieldPath, FieldValues } from 'react-hook-form'

import {
  FieldSelect,
  Flex,
  IDefaultSelectOption,
  IFieldSelectProps,
} from 'src/components'
import { BlockchainAsset } from 'src/types'

import { CryptoLogo } from './CryptoLogo'

interface IFieldSelectAssetProps<
  Fields extends FieldValues = FieldValues,
  Name extends FieldPath<Fields> = FieldPath<Fields>
> extends Omit<IFieldSelectProps<Fields, Name>, 'formatOptionLabel'> {
  getAssetName?: (option: IDefaultSelectOption) => BlockchainAsset
}

export function FieldSelectAsset<
  Fields extends FieldValues = FieldValues,
  Name extends FieldPath<Fields> = FieldPath<Fields>
  /* rest of FieldSelect generics omitted, so far there is no need in them */
>(props: IFieldSelectAssetProps<Fields, Name>) {
  const {
    getAssetName = defaultGetAssetName,
    placeholder = 'Select asset...',
    ...rest
  } = props
  return (
    <FieldSelect
      {...rest}
      placeholder={placeholder}
      formatOptionLabel={option =>
        renderOption(getAssetName(option), option.label)
      }
    />
  )
}

function defaultGetAssetName(option: IDefaultSelectOption): BlockchainAsset {
  return option.label as BlockchainAsset
}

function renderOption(asset: BlockchainAsset, label: ReactNode) {
  return (
    <Flex align="center" gap="0.5em">
      <CryptoLogo asset={asset} style={{ width: '1.5em' }} />
      {label}
    </Flex>
  )
}
