import { ReactNode } from 'react'

import clsx from 'clsx'

import styles from './WizardProgressbar.module.scss'

export interface IWizardProgressbarProps<Steps extends string> {
  step: Steps
  steps: Partial<Record<Steps, ReactNode>>
}

export function WizardProgressbar<Steps extends string>(
  props: IWizardProgressbarProps<Steps>
) {
  const { step, steps } = props

  const items = Object.entries<ReactNode>(steps)
  const stepIndex = items.findIndex(([x]) => x === step)

  return (
    <div
      className={styles.root}
      style={{
        ['--wizard-step-index' as string]: stepIndex,
        ['--wizard-total-steps' as string]: items.length,
      }}
    >
      <div className={styles.bar}></div>
      <div className={styles.steps}>
        {items.map(([step, label], i) => (
          <div key={step} className={getStepClassName(i, stepIndex)}>
            {label}
          </div>
        ))}
      </div>
    </div>
  )
}

const getStepClassName = (stepIndex: number, activeIndex: number): string =>
  clsx(styles.step, {
    [styles.step_active]: activeIndex === stepIndex,
    [styles.step_passed]: activeIndex > stepIndex,
  })
