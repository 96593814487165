import { createApi as createRTQApi } from '@reduxjs/toolkit/query/react'

import { axiosBaseQuery } from './axiosBaseQuery'

/**
 * @see https://redux-toolkit.js.org/rtk-query/usage/code-splitting
 */
export const rtqApi = createRTQApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery(),

  endpoints: () => ({}),
})
