import { BlockchainAddress as BlockchainAddressView } from 'src/components'
import { BlockchainAddress } from 'src/types'

import styles from './EthereumTransactionView.module.scss'

const ADDR_TRUNC_LEN = 4

export function EthereumTransactionView(props: {
  from: BlockchainAddress
  to: BlockchainAddress
  value: string
  gas?: string
}) {
  const { from, to, value, gas } = props
  return (
    <div className={styles.stats}>
      <div className={styles.stat_name}>From</div>
      <BlockchainAddressView
        className={styles.stat_value}
        trunc={ADDR_TRUNC_LEN}
      >
        {from}
      </BlockchainAddressView>

      <div className={styles.stat_name}>To</div>
      <BlockchainAddressView
        className={styles.stat_value}
        trunc={ADDR_TRUNC_LEN}
      >
        {to}
      </BlockchainAddressView>

      <div className={styles.stat_name}>Amount</div>
      <div className={styles.stat_value}>{hexToNonExpETH(value)}</div>

      {/* Not sure whether we should to display optional fields like "maxFeePerGas"
       * Does it even mean anything to user? */}
      {gas !== undefined && (
        <>
          <div className={styles.stat_name}>Gas</div>
          <div className={styles.stat_value}>{hexToNonExpETH(gas)}</div>
        </>
      )}
    </div>
  )
}

// TODO: get this from some SDK for standards?
function weiToETH(x: number): number {
  return x / 1e18
}

function hexToNonExpETH(x: string) {
  const num = parseInt(x, 16)
  const eth = weiToETH(num)
  const ethStr = eth.toString(10)
  const exp = /^(?:\d+\.)?\d+e-(\d+)$/.exec(ethStr)?.[1]
  return exp === undefined ? ethStr : eth.toFixed(parseInt(exp))
}
