import SignClient from '@walletconnect/sign-client'
import {
  SessionTypes,
  SignClientTypes,
  /* Probably this is a wrong way to import things. But I don't give a shit.
   * Try yourself to install WC@1 and WC@2 along with all their deps in one project
   * and make them properly resolve all imports. Good damn luck. */
} from '@walletconnect/sign-client/node_modules/@walletconnect/types'
import { getClientMeta, parseWalletConnectUri } from '@walletconnect/utils'
import { flatMap, groupBy, mapValues, uniqueId } from 'lodash'

import { extendLogger } from 'src/log'
import { isSupportedBlockchainMethod } from 'src/utils'

import { WCLogger } from '../Logger'
import {
  IConnectorOptions,
  IWCFacade,
  IWCFacadeState,
  IWCSessionRequest,
  WCClientUpdateListener,
} from '../types'

const TRACKED_EVENTS: Readonly<SignClientTypes.Event[]> = [
  'session_proposal',
  'session_ping',
  'session_event',
  'session_request',
  'session_update',
  'session_expire',
  'session_delete',
] as const

const SESSION_FIELD_LOCAL_STATE = '@@/PolityState'

export class WCFacade_V2 implements IWCFacade {
  readonly id = uniqueId('wc@2_') // just for debugging
  protected readonly logger = extendLogger(this.id, WCLogger.log)

  protected readonly wc: SignClient
  protected _uri: string | null = null
  protected readonly _storageId: string

  protected _onUpdateListeners: WCClientUpdateListener[] = []

  static async init(opts: IConnectorOptions) {
    const { uri, storageId } = opts

    const client = await SignClient.init({
      projectId: process.env.REACT_APP_WALLET_CONNECT2_PROJECT_ID,

      metadata: {
        ...(getClientMeta() as NonNullable<ReturnType<typeof getClientMeta>>),
        /*  This is a lifehack to associate a session with a specific Polity wallet.
         *  The simplest way found so far to do this.
         *  Maybe there are some options to work with WC Storage, to do this properly.*/
        name: storageId,
      },
    })

    const instance = new this({ ...opts, client })
    if (uri) {
      await instance.setupPairing(uri)
    }
    return instance
  }

  protected constructor(opts: IConnectorOptions & { client: SignClient }) {
    const { storageId } = opts
    this.wc = opts.client
    this.setupListeners(this.wc)
    this._storageId = storageId

    this.logger.log('Instance V2 created', this)
  }

  async setURI(uri: string) {
    if (uri === this._uri) return
    this._uri = uri
    await this.setupPairing(uri)
  }

  get session() {
    const { wc, _storageId } = this
    return wc.session.getAll().find(x => x.self.metadata.name === _storageId)
  }

  protected get activeNamespace() {
    const { session } = this
    if (!session) return undefined
    return Object.values(session.namespaces)[0]
  }

  get connected() {
    const { session } = this
    /**
     * There is `acknowledged` prop on session, which is probably
     * intended to indicate a real connection state (try to find a word in docs about what it is).
     * BUT initially, right after approval, it's false:
     * https://github.com/WalletConnect/walletconnect-monorepo/blob/418f61fc19e2e5d85688e5a0ce84bec46ae06434/packages/sign-client/src/controllers/engine.ts#L225
     * It's updated later in `session_approve` event:
     * https://github.com/WalletConnect/walletconnect-monorepo/blob/418f61fc19e2e5d85688e5a0ce84bec46ae06434/packages/sign-client/src/controllers/engine.ts#L658-L664
     * BUT this event is kinda "internal", or hell knows what it is (pay attention to `engineEvent` wrapper),
     * and you can't subscribe to it.
     * No idea what authors meant with that, so just use the fact that a session object has been created.
     */
    return session !== undefined
    // return session?.acknowledged ?? false
  }

  get account() {
    const { activeNamespace: ns } = this
    return ns?.accounts.map(x => parseAccount(x).address)[0]
  }

  get chainId() {
    const { activeNamespace: ns } = this
    if (!ns) return undefined

    // assume only one connected account at a time
    // currently (Private MVP stage, 09.05.23) it is what our UI requires
    const acc = ns.accounts[0]

    const { chainId } = parseAccount(acc)
    return chainId
  }

  get state(): IWCFacadeState {
    const { session } = this
    if (session === undefined)
      return {
        assetSymbol: null,
        callRequest: null,
        sessionRequest: null,
      }

    // see logic description in `writeState` method
    const dict = session as unknown as Dict
    const state = (dict[SESSION_FIELD_LOCAL_STATE] ?? {}) as IWCFacadeState

    return {
      assetSymbol: state.assetSymbol ?? null,
      sessionRequest: state.sessionRequest ?? null,
      callRequest: state.callRequest ?? null,
    }
  }

  get peerMeta() {
    return this.session?.peer.metadata
  }

  async approveSession(...args: Parameters<IWCFacade['approveSession']>) {
    const [{ id, accounts, asset, chains, methods, events }] = args
    const { wc } = this

    const namespaces: SessionTypes.Namespaces = mapValues(
      groupBy(
        chains
          .map(chain => [chain, chain.split(':')[0]])
          .map(([chain, ns]) => ({
            ns,
            accounts: accounts.map(addr => `${chain}:${addr}`),
          })),

        x => x.ns
      ),

      (group): SessionTypes.Namespace => ({
        accounts: flatMap(group, x => x.accounts),
        methods,
        events,
      })
    )

    const { acknowledged } = await wc.approve({ id, namespaces })
    const session = await acknowledged()

    await this.writeState({
      assetSymbol: asset,
      sessionRequest: undefined,
    })

    this.logger.log('Session acknowledged', session)
  }

  async rejectSession(...args: Parameters<IWCFacade['rejectSession']>) {
    const [{ id, reason = 'Rejected by user' }] = args
    const { wc } = this
    this.logger.log('rejectSession', id)
    try {
      await wc.reject({ id, reason: { code: 1, message: reason } })
    } catch (e) {
      const msg = (e as Error).message
      const isProposalExpired = msg.includes("proposal id doesn't exist")
      if (isProposalExpired) {
        /* If proposal expired by the time you decided to reject it,
         * obviously consider it rejected successfully. */
        return
      } else {
        throw e
      }
    } finally {
      this.emitUpdate()
    }
  }

  async updateSession(...args: Parameters<IWCFacade['updateSession']>) {
    this.logger.log('updateSession', ...args)

    const [{ chainId, requestId }] = args
    const { wc, session } = this

    if (session === undefined) return

    /* I have no idea at all whether this is THE way to do this.
     * Maybe there is some "correct" way and dome specially intended methods for that
     * – not like WC has documentaion about that.
     * It works, at least */
    const [[, ns]] = Object.entries(session.namespaces)
    const acc_params = parseAccount(ns.accounts[0])
    const new_chain = `${acc_params.namespace}:${chainId}`
    const new_acc = `${new_chain}:${acc_params.address}`
    ns.accounts = [new_acc]
    // Must be updated too, otherwise it throws "namespaces don't conform to requiredNamespaces"
    session.requiredNamespaces[acc_params.namespace].chains = [new_chain]

    // This just writes session to localstorage, it does not send response to dApp
    await wc.update({
      topic: session.topic,
      namespaces: session.namespaces,
    })

    // Now this notifies dApp that we're good
    // @see https://eips.ethereum.org/EIPS/eip-3326, the "Returns" section
    await wc.respond({
      topic: session.topic,
      response: {
        id: requestId,
        jsonrpc: '2.0',
        result: null,
      },
    })

    // This is indirect relation
    // Assumed that we update session only in response on `switchChain` call request
    // For now (private MVP stage, 17.07.23) there are no other scenarios.
    await this.writeState({
      callRequest: undefined,
    })
  }

  async disconnect(...args: Parameters<IWCFacade['disconnect']>) {
    const [reason = 'Disconnected by user'] = args
    const { wc, session } = this
    if (session !== undefined) {
      this.logger.log('killSession')
      await wc.disconnect({
        topic: session.topic,
        reason: { code: 1, message: reason },
      })
      this.emitUpdate()
    }
  }

  async approveRequest(...args: Parameters<IWCFacade['approveRequest']>) {
    const { wc } = this
    const [{ id, topic, result }] = args
    this.logger.log('Approve request', id)
    await wc.respond({
      topic,
      response: {
        id,
        jsonrpc: '2.0',
        result,
      },
    })
    await this.writeState({ callRequest: undefined })
  }

  async rejectRequest(...args: Parameters<IWCFacade['rejectRequest']>) {
    const { wc } = this
    const [{ id, topic, msg }] = args
    this.logger.log('Reject request', id)
    try {
      await wc.respond({
        topic,
        response: {
          id,
          jsonrpc: '2.0',
          error: { code: 1, message: msg },
        },
      })
    } catch (e) {
      const msg = (e as Error).message
      const isRequestExpired = msg.includes('expirer: ')
      if (isRequestExpired) {
        /* If request expired by the time you decided to reject it,
         * obviously consider it rejected successfully. */
        return
      } else {
        throw e
      }
    } finally {
      await this.writeState({ callRequest: undefined })
    }
  }

  onUpdate(cb: WCClientUpdateListener) {
    this._onUpdateListeners.push(cb)
  }

  teardown() {
    const { wc } = this
    TRACKED_EVENTS.forEach(name => {
      wc.removeAllListeners(name)
    })
  }

  async awaitSessionRequest(
    ...args: Parameters<IWCFacade['awaitSessionRequest']>
  ) {
    const [uri] = args
    const { wc } = this

    if (uri !== undefined) {
      await this.setURI(uri)
    }

    return new Promise<IWCSessionRequest>(resolve => {
      wc.once('session_proposal', async event => {
        this.logger.log('EVENT:session_request', event)

        const { requiredNamespaces, optionalNamespaces, proposer } =
          event.params

        const ns =
          Object.values(requiredNamespaces)[0] ??
          /* Some DApps (namely Zapper) do not provide `requiredNamespaces` field.
           * If you ask "how is that, this field is obligatory in WC protocol? o_0" – I have the same question. */
          Object.values(optionalNamespaces)[0]

        // is it really possible that `chains` are not defined, or it's a bug in typings?
        const chains = ns.chains ?? []

        const [, chainId = ''] = (chains[0] ?? '').split(':')

        const sessionRequest: IWCSessionRequest = {
          id: event.id,
          chainId,
          peerMeta: proposer.metadata,
          chains,
          methods: ns.methods,
          events: ns.events,
        }

        await this.writeState({ sessionRequest })
        resolve(sessionRequest)
      })
    })
  }

  // ---

  protected async setupPairing(uri: string) {
    const { wc } = this

    const { handshakeTopic: topic } = parseWalletConnectUri(uri)
    const controller = wc.core.pairing
    const pairings = controller.getPairings()
    const matching = pairings.find(x => x.topic === topic)

    /* Pairings on their own mean nothing.
     * They don't emit any events, don't affect any kind of "connected" state, anything.
     * They are just something internal required to start creating sessions.
     * Activating a pairing doesn't change any state visible to user. */
    if (matching) {
      await controller.activate({ topic })
      this.logger.log('Activated saved pairing', matching)
    } else {
      const pairing = await controller.pair({ uri })
      this.logger.log('New pairing created', pairing)
    }
  }

  protected setupListeners(wc: SignClient) {
    TRACKED_EVENTS.forEach(name => {
      wc.on(name, e => {
        this.logger.log(`EVENT:${name}`, e)
        this.emitUpdate()
      })
    })

    wc.on('session_request', async event => {
      const {
        id,
        topic,
        params: {
          request: { method, params },
        },
      } = event

      this.logger.log('EVENT:session_request', event)

      if (!isSupportedBlockchainMethod(method)) {
        this.logger.log(`Request method is not supported: ${method}`)
        return
      }

      this.writeState({
        callRequest: {
          id,
          topic,
          method,
          params:
            Array.isArray(params) && params.length === 1 ? params[0] : params,
        },
      })
    })
  }

  protected emitUpdate() {
    this._onUpdateListeners.forEach(fn => fn())
  }

  protected async writeState(patch: Partial<IWCFacadeState>) {
    const { wc, session } = this
    if (!session) return
    const key = session.topic

    const payload = {
      [SESSION_FIELD_LOCAL_STATE]: { ...this.state, ...patch },
    }
    this.logger.log('Update local state', payload)

    // Put our extra state fields in session data, so it's stored/cleared automatically for us.
    // It's a handy approach saving us lots of effort of organising some redux state
    // (which also had to be persisted then somehow)
    // and syncing it with session data.
    // Just make sure it doesn't override any of standard session fields
    const fakeSessionFields = payload as unknown as Parameters<
      typeof wc.session.update
    >[1]

    wc.session.update(key, fakeSessionFields)
    this.emitUpdate()
  }
}

// ---

// any standard helper for this?
function parseAccount(x: string) {
  const parts = x.split(':')
  if (parts.length !== 3) {
    throw new Error(`Malformed account string: ${x}`)
  }
  const [namespace, chainId, address] = parts
  return {
    namespace,
    chainId,
    address,
    chain: `${namespace}:${chainId}`,
  }
}
