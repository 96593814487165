/**
 * @see https://www.npmjs.com/package/debug
 */
import debug from 'debug'

const Base = debug('bastion')
export const Log = extendLogger('')

export function extendLogger(name: string, base: typeof Base = Base) {
  const log = base.extend(name)
  const error = log.extend('error')
  error.log = console.error.bind(console)
  return {
    log,
    error,
  }
}

if (process.env.NODE_ENV === 'development') {
  Object.assign(window, { node_debug: debug })

  // No idea why this is not in lib
  Object.assign(debug, {
    toggle(name: string, state?: boolean) {
      const isEnabled = debug.enabled(name)
      const shouldEnable = state ?? !isEnabled

      let namespaces = (
        debug as typeof debug & { namespaces: string }
      ).namespaces.split(/[\s,]+/)

      if (shouldEnable && !isEnabled) {
        namespaces.push(name)
      } else if (!shouldEnable && isEnabled) {
        namespaces = namespaces.filter(x => {
          if (!name.includes('*')) {
            return x !== name
          }
          const re = new RegExp(`^${name.replace(/\*/g, '.*?')}$`)
          return !re.test(x)
        })
      }
      debug.enable(namespaces.join(','))
    },
  })
}
