// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_root__22lJD {\n  color: var(--th-clr-error);\n  white-space: pre-line;\n  max-width: 100%;\n  overflow: hidden;\n}\n\n.styles_block__1KTuB {\n  background-color: var(--th-clr-bg-error);\n  padding: 10px 16px;\n  position: relative;\n  border-radius: var(--th-border-radius);\n  display: flex;\n  align-items: center;\n  grid-gap: 0.5rem;\n  gap: 0.5rem;\n}\n.styles_block__1KTuB .styles_icon__3nRMg {\n  flex-shrink: 0;\n}", "",{"version":3,"sources":["webpack://src/components/base/ErrorMessage/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,qBAAA;EACA,eAAA;EACA,gBAAA;AACF;;AAEA;EACE,wCAAA;EAEA,kBAAA;EACA,kBAAA;EACA,sCAAA;EAEA,aAAA;EACA,mBAAA;EACA,gBAAA;EAAA,WAAA;AADF;AAGE;EACE,cAAA;AADJ","sourcesContent":[".root {\n  color: var(--th-clr-error);\n  white-space: pre-line;\n  max-width: 100%;\n  overflow: hidden;\n}\n\n.block {\n  background-color: var(--th-clr-bg-error);\n\n  padding: 10px 16px;\n  position: relative;\n  border-radius: var(--th-border-radius);\n\n  display: flex;\n  align-items: center;\n  gap: 0.5rem;\n\n  .icon {\n    flex-shrink: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "styles_root__22lJD",
	"block": "styles_block__1KTuB",
	"icon": "styles_icon__3nRMg"
};
export default ___CSS_LOADER_EXPORT___;
