import { ErrorMessage, Grid, Suspendable } from 'src/components'
import { useOnChange } from 'src/hooks'

import { WalletRTQApi } from '../../../../api'
import { IWallet } from '../../../../types'
import { useWalletAssetsApp } from '../reducer'

import { Filters, IFiltersState } from './Filters'
import { TransactionsList } from './TransactionsList'

export function TransactionsListContainer(props: { wallet: IWallet }) {
  const { wallet } = props

  const [{ data: response, error, isFetching }, { setFilters, setPagination }] =
    useTxns(wallet)

  const app = useWalletAssetsApp()

  return (
    <Grid>
      <Filters wallet={wallet} onChange={setFilters} />

      <Choose>
        <When condition={error !== undefined}>
          <ErrorMessage>{error}</ErrorMessage>
        </When>

        <Otherwise>
          <Suspendable suspended={isFetching}>
            <div>
              <TransactionsList
                asset={app.state.txns.asset}
                items={response?.data ?? []}
                pageCount={response?.pagination.pageCount ?? 0}
                onChangeState={state => {
                  const { pageIndex, pageSize } = state
                  setPagination({ pageIndex, pageSize })
                }}
              />
            </div>
          </Suspendable>
        </Otherwise>
      </Choose>
    </Grid>
  )
}

function useTxns(wallet: IWallet) {
  const app = useWalletAssetsApp()

  const [fetchTxns, txnsState] =
    WalletRTQApi.endpoints.getTransactionsHistory.useLazyQuery()

  useOnChange(
    app.state.txns,
    query => {
      const { asset, ...rest } = query
      if (asset !== undefined) {
        fetchTxns({ wallet: wallet.address, asset, ...rest })
      }
    },
    {
      onMount: true,
    }
  )

  return [
    txnsState,
    {
      setFilters: (filters: IFiltersState) => app.setTxnsAsset(filters.asset),
      setPagination: app.setTxnsPagination,
    },
  ] as const
}
