import { FieldInput, FieldSelect, Fieldset } from 'src/components'
import { AgreementType } from 'src/types'

import {
  IInvestorAvatar,
  ISafeheronAcquisitionAgreementPayload as IPayload,
} from '../../../types'

import { BaseAgreementForm } from './BaseAgreementForm'
import { useOnSubmit } from './lib'

export function AgreementFormSafeheronAcquisition(props: {
  defaultAvatarId?: IInvestorAvatar['id']
  avatars: IInvestorAvatar[]
}) {
  const { avatars, defaultAvatarId } = props
  const onSubmit = useOnSubmit(avatars)

  return (
    <BaseAgreementForm
      onSubmit={onSubmit}
      defaultValues={{
        agreementType: AgreementType.SafeheronAcquisition,
        avatar: resolveDefaultAvatar(avatars, defaultAvatarId),
      }}
    >
      <Fieldset>
        <FieldSelect<IPayload, 'avatar', IInvestorAvatar>
          label="Avatar"
          control={{
            layout: 'col',
          }}
          rules={{
            required: true,
          }}
          options={avatars}
          name="avatar"
          getOptionValue={x => x.name}
          getOptionLabel={x => x.name}
        />

        <FieldInput<IPayload, 'investment', 'number'>
          type="number"
          name="investment"
          rules={{
            required: true,
          }}
        />

        <FieldInput<IPayload, 'fee', 'number'>
          type="number"
          name="fee"
          placeholder="Fee, %"
          rules={{
            required: true,
            min: 0,
            max: 99,
          }}
        />
      </Fieldset>
    </BaseAgreementForm>
  )
}

function resolveDefaultAvatar(
  xs: IInvestorAvatar[],
  value?: IInvestorAvatar['id']
) {
  const existing = xs.find(x => x.id === value) ?? xs[0]
  return existing?.name
}
