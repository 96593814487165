import { useMemo } from 'react'

import clsx from 'clsx'
import { format } from 'date-fns'

import { IDateTimeFormatProps } from './types'

import styles from './styles.module.scss'

const DAY_FORMAT = 'EEE'
export const DEFAULT_TIME_FORMAT = 'HH:mm:ss'
export const DEFAULT_DATE_FORMAT = 'd MMM yyyy'
export const DEFAULT_DATETIME_FORMAT = `${DAY_FORMAT}, ${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`

export function DateTime(props: IDateTimeFormatProps) {
  const {
    value,
    format: formatStr = DEFAULT_DATE_FORMAT,
    relative,
    className,
    style,
  } = props
  const date = useMemo(() => new Date(value), [value])

  // For relative date, no memoization – re-render each time to properly display time diff changes.
  const diff = relative ? getDiff(date) : null

  const formatted = useMemo(
    () => diff ?? format(date, formatStr),
    [date, formatStr, diff]
  )

  return (
    <span className={clsx(styles.root, className)} style={style}>
      {formatted}
    </span>
  )
}

DateTime.F_DATE_DAY = `${DAY_FORMAT}, ${DEFAULT_DATE_FORMAT}`
DateTime.F_DATE = DEFAULT_DATE_FORMAT
DateTime.F_TIME = DEFAULT_TIME_FORMAT
DateTime.F_DATETIME = DEFAULT_DATETIME_FORMAT

const toSec = (x: number) => x / 1000
const toMin = (x: number) => toSec(x) / 60
const toHours = (x: number) => toMin(x) / 60
const toDays = (x: number) => toHours(x) / 24

function getDiff(date: Date) {
  const now = Date.now()
  const diff = now - date.valueOf()
  if (diff < 0) {
    return null
  }

  if (diff < 1000) {
    return 'a few moments ago'
  }

  const resolved = resolveDiff(diff)
  if (resolved === null) {
    return null
  }

  const [value, units] = resolved
  return `${Math.ceil(value)} ${units} ago`
}

function resolveDiff(diff: number): null | [number, string] {
  const SEC = 1000
  const MINUTE = 60 * SEC
  const HOUR = 60 * MINUTE
  const DAY = 24 * HOUR
  const WEEK = 7 * DAY

  if (diff < MINUTE) {
    return [toSec(diff), 'seconds']
  }

  if (diff < HOUR) {
    return [toMin(diff), 'minutes']
  }

  if (diff < DAY) {
    return [toHours(diff), 'hours']
  }

  if (diff < WEEK) {
    return [toDays(diff), 'days']
  }

  return null
}
