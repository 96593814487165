import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  ReactNode,
  createContext,
  createElement,
  useContext,
  useReducer,
} from 'react'

import { BlockchainAsset, IPaginationQuery } from 'src/types'

interface IAssetsState {
  txns: IPaginationQuery & { asset?: BlockchainAsset }
}

const slice = createSlice({
  name: 'wallet-assets',
  initialState: (): IAssetsState => ({ txns: {} }),
  reducers: {
    setTxnsPagination(state, action: PayloadAction<IPaginationQuery>) {
      Object.assign(state.txns, action.payload)
    },
    setTxnsAsset(state, action: PayloadAction<BlockchainAsset>) {
      state.txns.asset = action.payload
    },
  },
})

function useAssetsReducer() {
  const [state, dispatch] = useReducer(slice.reducer, slice.getInitialState())

  type Actions = typeof slice.actions
  const dispatcher =
    <Key extends keyof Actions>(name: Key) =>
    (payload: Parameters<Actions[Key]>[0]) => {
      const creator = slice.actions[name] as Func
      const action = creator(payload)
      return dispatch(action)
    }

  return {
    state,
    setTxnsPagination: dispatcher('setTxnsPagination'),
    setTxnsAsset: dispatcher('setTxnsAsset'),
  }
}

const AssetsContext = createContext<ReturnType<typeof useAssetsReducer>>({
  state: slice.getInitialState(),
  setTxnsPagination: () => {},
  setTxnsAsset: () => {},
})

export function AssetContextProvider(props: { children: ReactNode }) {
  const { children } = props
  return createElement(
    AssetsContext.Provider,
    {
      value: useAssetsReducer(),
    },
    children
  )
}

export function useWalletAssetsApp() {
  return useContext(AssetsContext)
}
