import { useMemo } from 'react'
import { Column } from 'react-table'

import {
  Badge,
  BlockchainAddress,
  DateTime,
  Grid,
  ITableProps,
  Icon,
  Table,
} from 'src/components'
import theme from 'src/styles/theme.module.scss'
import { BlockchainAsset } from 'src/types'

import { ITransactionHistoryEntry } from '../../../../types'

interface ITransactionsListProps {
  items: ITransactionHistoryEntry[]
  pageCount: number
  onChangeState?: ITableProps['onChangeState']
  asset: BlockchainAsset | undefined
}

export function TransactionsList(props: ITransactionsListProps) {
  const { asset, items, pageCount, onChangeState } = props
  return (
    <Table
      columns={useColumns({ asset })}
      data={items}
      onChangeState={onChangeState}
      emitStateInitialized
      // Pagination is server-side
      manualPagination
      // Needed when using manualPagination
      pageCount={pageCount}
      // Has to be disabled despite of what is said in docs.
      // Resetting effect is always triggered because of misuse of `useEffect` hook
      // @see https://github.com/TanStack/table/blob/06703a56890122cedf1b2fa4b82982999537774e/src/plugin-hooks/usePagination.js#L115-L126
      // Deps `filters` and `sortBy` are arrays, thus always considered changed, regardless of their content.
      autoResetPage={false}
    />
  )
}

function useColumns(params: Pick<ITransactionsListProps, 'asset'>) {
  return useMemo(
    (): Column<ITransactionHistoryEntry>[] => [
      {
        Header: 'Date',
        accessor: 'date',
        Cell: ({ cell }) => (
          <div style={{ fontSize: '0.875rem' }}>
            <DateTime
              value={cell.value}
              format={DateTime.F_DATE}
              style={{ color: theme.th_clr_txt_primary }}
            />
            <br />
            <DateTime
              value={cell.value}
              format={DateTime.F_TIME}
              style={{ color: theme.th_clr_txt_secondary }}
            />
          </div>
        ),
      },
      {
        id: 'txn-type',
        accessor: 'type',
        Header: 'Type',
        Cell: ({ value: type }) => {
          switch (type) {
            case 'sent':
              return (
                <Badge variant="warn">
                  <Icon type="arrow-narrow-up" />
                  Sent
                </Badge>
              )
            case 'received':
              return (
                <Badge variant="success">
                  <Icon type="arrow-narrow-down" />
                  Received
                </Badge>
              )
            default:
              return null
          }
        },
      },
      {
        Header: 'Amount',
        accessor: 'value',
        Cell: ({ value, row: { original: item } }) => (
          <Grid gap="0.25rem" style={{ whiteSpace: 'nowrap' }}>
            <div>
              {value} {params.asset}
            </div>
            <If condition={item.value_usd !== ''}>
              <div
                style={{
                  color: theme.th_clr_txt_secondary,
                  fontSize: '0.875em',
                }}
              >
                ~${parseFloat(item.value_usd).toFixed(2)}
              </div>
            </If>
            <div
              style={{ color: theme.th_clr_txt_secondary, fontSize: '0.875em' }}
            >
              Gas: {item.gas} WEI
            </div>
          </Grid>
        ),
      },
      /* TODO:
          ideally, if sender/receiver address belongs ot user's address book,
          show that contact name instead of just address */
      {
        Header: 'Issuer',
        accessor: 'from',
        Cell: ({ value }) => (
          <BlockchainAddress copy>{value}</BlockchainAddress>
        ),
      },
      {
        Header: 'Recipient',
        accessor: 'to',
        Cell: ({ value }) => (
          <BlockchainAddress copy>{value}</BlockchainAddress>
        ),
      },
    ],
    [params.asset]
  )
}
