import { FieldPath, FieldValues } from 'react-hook-form'

import { combineRefs } from 'src/utils'

import { Select } from '../../controls'
import FieldControl from '../FieldControl'

import { DefaultFieldSelectOption, IFieldSelectProps } from './types'

export function FieldSelect<
  Fields extends FieldValues = FieldValues,
  Name extends FieldPath<Fields> = FieldPath<Fields>,
  Option = DefaultFieldSelectOption,
  Async extends boolean = false,
  Creatable extends boolean = false
>(props: IFieldSelectProps<Fields, Name, Option, Async, Creatable>) {
  const {
    control,
    name,
    rules,
    label,
    hint,
    defaultValue,
    optionAsValue,
    ...rest
  } = props

  return (
    <FieldControl<Fields, Name>
      {...control}
      {...{ name, rules, label, hint, defaultValue }}
    >
      {controller => {
        const {
          field: { ref, ...field },
          fieldState: { invalid },
        } = controller
        const { refInput } = rest
        return (
          <Select<Option, Async, Creatable>
            {...rest}
            {...field}
            invalid={invalid}
            refInput={combineRefs(ref, refInput)}
            onChange={
              optionAsValue
                ? (value, option) => field.onChange(option)
                : field.onChange
            }
          />
        )
      }}
    </FieldControl>
  )
}
