import { ReactElement, ReactNode } from 'react'

import { resolveErrorMessage } from '../../utils'

import { ErrorMessage } from './ErrorMessage'
import { Loader } from './Loader'
import { SuspendableProvider } from './Suspendable'

export interface RTQState<T> {
  data?: T
  isSuccess: boolean
  isLoading: boolean
  isError: boolean
  isFetching: boolean
  error?: unknown
}

interface IRTQSuspenderProps<S extends RTQState<unknown>> {
  state: S
  children(data: NonNullable<S['data']>): ReactElement | ReactElement[]
  /* Specify error message text. No markup here. */
  error?: ReactNode | ((e: unknown) => ReactNode)
  /* _Render_ error message with markup */
  renderError?(errorElement: ReactNode, error: unknown): ReactNode
  loader?: ReactElement
  remountOnUpdate?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function RTQSuspender<S extends RTQState<any>>(
  props: IRTQSuspenderProps<S>
) {
  const {
    state,
    children,
    loader = <Loader />,
    renderError = e => <ErrorMessage>{e}</ErrorMessage>,
    error: msg = x => x,
    remountOnUpdate = false,
  } = props
  const { data, isSuccess, error, isError, isLoading, isFetching } = state

  const $el = (
    <Choose>
      <When condition={isError}>
        {renderError(
          normalizeErrorText(typeof msg === 'function' ? msg(error) : msg),
          error
        )}
      </When>

      <When condition={isLoading || (remountOnUpdate && isFetching)}>
        {loader}
      </When>

      <When condition={isSuccess}>{children(data)}</When>
    </Choose>
  )

  // When `isLoading=true` it's an initial load when nothing is available at all.
  // Nothing to suspend yet.
  const isSuspended = isFetching && !isLoading

  return (
    <SuspendableProvider suspended={isSuspended}>{$el}</SuspendableProvider>
  )
}

function normalizeErrorText(e: unknown): ReactNode {
  const msg = resolveErrorMessage(e)
  if (
    typeof msg === 'string' &&
    msg.includes('Error occurred while trying to proxy')
  ) {
    console.error(msg)
    /* Don't show tech details in UI. Only user-friendly messages. */
    return 'Unexpected network error. Please try again later.'
  }

  return msg
}
