import { Children, ReactNode, isValidElement, useEffect } from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'

import { IconSettings } from 'src/components'

import styles from './Navigation.module.scss'

export function Navigation(props: { children: ReactNode }) {
  const { children } = props
  const validChildren = useChildrenOfType(NavItem, children)
  return (
    <nav className={styles.root}>
      <IconSettings size={24}>
        <ul className={styles.items}>{validChildren}</ul>
      </IconSettings>
    </nav>
  )
}

function useChildrenOfType(type: Func, children: ReactNode) {
  const allChildren = Children.toArray(children)
  const validChildren = allChildren
    .filter(isValidElement)
    .filter(x => x.type === type)

  useEffect(() => {
    if (allChildren.length > validChildren.length) {
      console.warn(
        `<Navigation> only accepts children of <${type.name}> type. Other items will be ignored.`
      )
    }
  }, [allChildren.length, validChildren.length, type.name])

  return validChildren
}

interface INavItemProps extends Pick<NavLinkProps, 'isActive' | 'exact'> {
  children: ReactNode
  link: string
  icon?: ReactNode
}

export function NavItem(props: INavItemProps) {
  const { children, link, icon, exact = true, isActive } = props
  return (
    <li className={styles.item}>
      <NavLink
        to={link}
        activeClassName={styles.item_active}
        exact={exact}
        isActive={isActive}
      >
        <span className={styles.item_icon}>{icon}</span>
        {children}
      </NavLink>
    </li>
  )
}
