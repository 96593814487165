// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Page_tab_panel_header__1zVTg {\n  font-weight: 500;\n  font-size: 1.875rem;\n  line-height: 1.27;\n}", "",{"version":3,"sources":["webpack://src/apps/cabinet/features/Bidding/pages/Bidding/Page.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,mBAAA;EACA,iBAAA;AACF","sourcesContent":[".tab_panel_header {\n  font-weight: 500;\n  font-size: 1.875rem;\n  line-height: 1.27;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tab_panel_header": "Page_tab_panel_header__1zVTg"
};
export default ___CSS_LOADER_EXPORT___;
