import { ActionCreator, AnyAction, AsyncThunk } from '@reduxjs/toolkit'
import React from 'react'
import { useDispatch } from 'react-redux'

import { useOnChange } from '../../../hooks'
import { useUserSelector } from '../hooks'
import { ERROR_NO_CREDENTIALS_FOUND } from '../lib'
import { selectIsAuthorized, selectUserState } from '../selectors'

export type AppLoginHandler =
  | ActionCreator<AnyAction>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | AsyncThunk<any, void, any>

function useLoadUser(login: AppLoginHandler, allowGuest = false): boolean {
  const dispatch = useDispatch()
  const isAuthorized = useUserSelector(selectIsAuthorized)
  const { error } = useUserSelector(selectUserState)

  /* Using `throw dispatch(login())` appears to be a bad approach,
   * because it hurts nested components subscribed to user's state.
   * (i.e. "can't update component while rendering AuthWatcher").
   * So use good old useEffect for this. */
  useOnChange(
    isAuthorized,
    isAuthorized => {
      if (!isAuthorized) {
        dispatch(login())
      }
    },
    {
      layout: true,
      onMount: true,
    }
  )

  if (error) {
    if (error === ERROR_NO_CREDENTIALS_FOUND) {
      if (allowGuest) return true

      // This component is intended to use inside user-infrastructure apps,
      // where user must not get at all unless he's authorized.
      // So, if no auth data is found, we don't render an open home page or like that.
      // We redirect user out of here, back to auth-portal server.
      const redirect = process.env.REACT_APP_AUTH_PORTAL_URL
      if (redirect) {
        // use location.assign, because it's a redirect to another domain, out of current app
        window.location.assign(redirect)
        return false
      } else {
        throw new Error(
          "Can't perform redirect: auth portal address is not set"
        )
      }
    }

    throw new Error(error)
  }

  return isAuthorized
}

export const AuthWatcher: React.FC<{
  login: AppLoginHandler
  allowGuest?: boolean
}> = props => {
  const { children, login, allowGuest } = props
  const isAuthorized = useLoadUser(login, allowGuest)
  return isAuthorized ? <>{children}</> : null
}
