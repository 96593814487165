import { ReactNode } from 'react'

import clsx from 'clsx'

import styles from './Layout.module.scss'

export interface IWCCardProps {
  header: ReactNode
  children: ReactNode
  title: ReactNode
  desc: ReactNode
  className?: string
}

export function Layout(props: IWCCardProps) {
  const { header, children, title, desc, className } = props
  return (
    <div className={clsx(styles.root, className)}>
      <div className={styles.header}>{header}</div>

      <div className={styles.info}>
        <div className={styles.title}>{title}</div>
        <div className={styles.desc}>{desc}</div>
      </div>

      {children}
    </div>
  )
}
