import { IconButton } from 'src/components'

import { InstallWalletModal } from './Modal'
import { InstallWalletProgressbar } from './Progressbar'
import { WizardStep, useWalletFlow } from './lib'
import {
  ConfirmStep,
  CreateWalletStep,
  ProvidersStep,
  SignAgreementStep,
  StartStep,
  WalletNameStep,
} from './steps'

import styles from './InstallWalletPage.module.scss'

export function InstallWalletPage(props: { skipIntro?: boolean }) {
  const { skipIntro } = props

  const [state, actions] = useWalletFlow({ skipIntro })
  const { step, name, provider, isSigningAgreement, isCreatingWallet } = state
  const {
    goNext,
    goBack,
    setName,
    setProvider,
    confirmOverview,
    signAgreement,
    createWallet,
  } = actions

  return (
    <InstallWalletModal progressbar={<InstallWalletProgressbar step={step} />}>
      <div className={styles.root}>
        <div className={styles.header}>
          <If
            condition={
              step !== WizardStep.Intro && step !== WizardStep.CreateWallet
            }
          >
            <IconButton
              variant="tertiary"
              size="lg"
              icon="chevron-left"
              color="var(--th-clr-gray-500)"
              onClick={goBack}
            />
          </If>
        </div>

        <Choose>
          <When condition={step === WizardStep.Intro}>
            <StartStep onSubmit={goNext} />
          </When>

          <When condition={step === WizardStep.SelectProvider}>
            <ProvidersStep onSubmit={setProvider} />
          </When>

          <When condition={step === WizardStep.WalletName}>
            <WalletNameStep
              onSubmit={({ name }) => setName(name)}
              defaultName={name}
            />
          </When>

          <When condition={step === WizardStep.Overview}>
            {provider !== undefined && (
              <ConfirmStep
                name={name}
                onSubmit={confirmOverview}
                provider={provider}
              />
            )}
          </When>

          <When condition={step === WizardStep.SignAgreement}>
            {provider !== undefined && (
              <SignAgreementStep
                provider={provider}
                loading={isSigningAgreement}
                onSubmit={signAgreement}
              />
            )}
          </When>

          <When condition={step === WizardStep.CreateWallet}>
            <CreateWalletStep
              loading={isCreatingWallet}
              onSubmit={createWallet}
            />
          </When>
        </Choose>
      </div>
    </InstallWalletModal>
  )
}
