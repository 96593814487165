import { createAsyncThunk } from '@reduxjs/toolkit'

import {
  IAuthTokens,
  getAccessToken,
  isLoggedIn as hasJWTToken,
  setAuthTokens,
} from 'axios-jwt'

import * as API from './api'
import { ERROR_NO_CREDENTIALS_FOUND } from './lib'
import { selectUserState } from './selectors'
import { UserAppState } from './types'

// TODO:
//  This is quick hack to make sure user can log out while having tokens in query params (see `checkAuthState`).
//  Any better solutions? Maybe on load redirect user to location without query params?
let staleAccessToken: string | undefined = undefined

export const logout = createAsyncThunk('user/logout', async () => {
  staleAccessToken = getAccessToken()
  await API.logout()
  // no return value
})

export const loadUser = createAsyncThunk('user/load', API.getUser)

/**
 * Intended to use in infrastructure apps –
 * where users gets by redirect from auth portal, with JWT tokens provided in GET params.
 */
export const loginToInfrastructure = createAsyncThunk(
  'user/login',
  async (_, { dispatch }) => {
    if (!checkAuthState()) {
      throw new Error(ERROR_NO_CREDENTIALS_FOUND)
    }

    // ---

    const user = await dispatch(loadUser()).unwrap()
    return user
  },
  {
    condition(_, { getState }) {
      const { loading } = selectUserState(getState() as UserAppState)
      return !loading
    },
  }
)

function checkAuthState() {
  const params = new URLSearchParams(window.location.search)
  const tokens = {
    accessToken: params.get('accessToken'),
    refreshToken: params.get('refreshToken'),
  }

  // First always check query tokens, in case you got just authorized and refreshed auth data
  const hasQueryTokens =
    tokens.accessToken !== null && tokens.refreshToken !== null

  if (hasQueryTokens) {
    const isLoggedOut = tokens.accessToken === staleAccessToken
    if (!isLoggedOut) {
      setAuthTokens(tokens as IAuthTokens)
      return true
    }
  }

  // otherwise check already existing tokens
  return hasJWTToken()
}
