import { List } from 'src/components'

import { IInvestorAvatar } from '../../types'

import { AvatarsListItem } from './Item'

export function AvatarsList(props: { items: IInvestorAvatar[] }) {
  const { items } = props
  return (
    <List
      tag="div"
      data={items}
      renderItem={item => <AvatarsListItem data={item} />}
    />
  )
}
