import { IValidationRules } from './types'

export const rulesRequiredString: IValidationRules = {
  required: true,
  maxLength: 255,
  setValueAs: (x: string) => x.trimStart(),
}

export const rulesPositiveNumberString: IValidationRules = {
  pattern: {
    value: /^[0-9]+(\.[0-9]+)?$/,
    message: 'Value is not a number',
  },
}
