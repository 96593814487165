import { Flex, Page } from 'src/components'

import { WalletProvider } from '../../../types'

import { ProviderCard } from './components'
import { PROVIDERS_DATA } from './const'

import styles from './styles.module.scss'

interface IProvidersStepProps {
  onSubmit(provider: WalletProvider): void
}

export const ProvidersStep = ({ onSubmit }: IProvidersStepProps) => (
  <Page
    title="Select your Provider"
    description={
      <div className={styles.description}>
        Our wallet partners provide enterprise-grade MPC and security, so you've
        got all you need to explore the DeFi world with confidence.
      </div>
    }
    alignHeader="center"
    alignContent="center"
  >
    <Flex gap={20} wrap justify="center">
      <BoundProviderCard provider="dfns" onSubmit={onSubmit} />
      <BoundProviderCard provider="safeheron" onSubmit={onSubmit} />
    </Flex>
  </Page>
)

function BoundProviderCard(
  props: Pick<IProvidersStepProps, 'onSubmit'> & {
    provider: WalletProvider
  }
) {
  const { onSubmit, provider } = props
  const data = PROVIDERS_DATA[provider]
  return (
    <ProviderCard
      onSelect={() => onSubmit(provider)}
      title={data.title}
      logo={data.logoBg}
      logoCompact={data.logoCompact}
      detailsLink={data.link}
      description={data.description}
      features={data.features}
    />
  )
}
