import { sortBy } from 'lodash'

import { Button, Grid, Page, RTQSuspender } from 'src/components'

import { WalletRTQApi } from '../../api'

import { AssetsList, useNewAssetFormModal } from './AssetsList'

export function AddressBookPage() {
  const state = WalletRTQApi.endpoints.getFavoriteAssets.useQuery(undefined, {
    selectFromResult: state => ({
      ...state,
      data: sortBy(state.data ?? [], x => x.is_static).reverse(),
    }),
  })

  const newItemModal = useNewAssetFormModal()

  return (
    <Page
      title={
        <Grid columns="1fr auto" align="center">
          <div>Address book</div>
          <Button onClick={newItemModal.open}>+ Add contact</Button>
        </Grid>
      }
    >
      {newItemModal.$element}
      <RTQSuspender state={state}>
        {assets => <AssetsList items={assets} />}
      </RTQSuspender>
    </Page>
  )
}
