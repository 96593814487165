import { AuthRoute } from 'src/features/User'

import { routes } from '../../routes'

import { AddressBookPage, ProductsListPage, WalletDetailsPage } from './pages'

/* Not a component, because it is to be rendered inside <Switch>.
 * TODO: until migrating to react@18/react-router@6,
 *  consider using react-router-v5-compat. With it's <Routes> + <Outlet> there will be no need in tricks like this */
export function renderWalletRoutes(props: { base: string }) {
  const { base } = props
  const wallet_routes = routes.wallet_module(base)

  return (
    /* <If> internally transpiles into array of elements.
     * Thus, it can be a handy syntax sugar for rendering arrays (no key prop, no commas).
     * (and returning an _array_ rather than a _new react element_ – which is crucial for react-router@5)
     * Just need an always-true condition to render it. */
    <If condition={props !== null}>
      <AuthRoute path={wallet_routes.products} component={ProductsListPage} />
      <AuthRoute path={wallet_routes.contacts} component={AddressBookPage} />
      <AuthRoute
        path={wallet_routes.wallet_details}
        render={({
          match: {
            params: { id },
          },
        }) => <WalletDetailsPage id={id} />}
      />

      {/* InstallWallet page is handled in special way in AvatarRouter */}
    </If>
  )
}
