import { Grid, Page as PageLayout, RTQSuspender } from 'src/components'

import { WalletRTQApi } from '../../api'
import { WalletProviderBadge, WalletStatusBadge } from '../../components'
import { isWalletReady } from '../../lib'
import { IWallet } from '../../types'

import { WalletAssets } from './WalletAssets'
import { WalletBalance } from './WalletBalance'
import { WCContainer } from './WalletConnect'
import { WalletContextProvider } from './WalletContext'
import { WalletName } from './WalletName'

export function WalletDetailsPage(props: { id: string }) {
  const { id } = props
  const state = WalletRTQApi.endpoints.getWallet.useQuery(id)
  return (
    <RTQSuspender state={state}>
      {wallet => (
        <WalletContextProvider wallet={wallet}>
          <PageContent wallet={wallet} />
        </WalletContextProvider>
      )}
    </RTQSuspender>
  )
}

function PageContent(props: { wallet: IWallet }) {
  const { wallet } = props

  return (
    <PageLayout title={<PageHeader wallet={wallet} />}>
      <Choose>
        <When condition={!isWalletReady(wallet)}>
          Operations unavailable until wallet is provisioned
        </When>

        <Otherwise>
          <Grid gap="2rem">
            <WalletBalance wallet={wallet} />

            <WCContainer wallet={wallet.address} />

            <WalletAssets wallet={wallet} />
          </Grid>
        </Otherwise>
      </Choose>
    </PageLayout>
  )
}

function PageHeader(props: { wallet: IWallet }) {
  const { wallet } = props
  return (
    <Grid columns="1fr auto auto">
      <WalletName name={wallet.wallet_name} />
      <WalletStatusBadge status={wallet.status} />
      <WalletProviderBadge type={wallet.type} />
    </Grid>
  )
}
