import { useCallback, useRef, useState } from 'react'

import clsx from 'clsx'

import { IconButton } from 'src/components/base'

import styles from './styles.module.scss'

export interface IBlockchainAddressProps extends IStyled {
  children: string
  copy?: boolean
  trunc?: boolean | number
  invert?: boolean
}

const DEFAULT_TRUNC_LENGTH = 5

export function BlockchainAddress(props: IBlockchainAddressProps) {
  const {
    children: address,
    copy: allowCopy = false,
    trunc = true,
    invert = false,
    style,
    className,
  } = props

  const [onCopy, isCopied] = useCopyHandler(address)

  if (address === '') {
    return null
  }

  return (
    <div
      className={clsx(styles.address_container, className, {
        [styles.copy]: allowCopy,
        [styles.trunc]: trunc,
        [styles.copied]: isCopied,
        [styles.invert]: invert,
      })}
      style={style}
      onClick={allowCopy ? onCopy : undefined}
    >
      <span className={styles.address_text}>
        {renderAddress(address, trunc)}
      </span>

      <If condition={allowCopy}>
        <span className={styles.copied_msg}>Copied to clipboard!</span>
        <IconButton
          title="Click to copy"
          icon="copy"
          variant="primary"
          className={styles.btn_copy}
          size="1.5em"
        />
      </If>
    </div>
  )
}

const monofont = (str: string) => (
  <span className={styles.chars_mono}>{str}</span>
)

function renderAddress(value: string, trunc: boolean | number) {
  if (trunc === false) return monofont(value)

  const truncLen = trunc === true ? DEFAULT_TRUNC_LENGTH : trunc
  if (value.length <= truncLen * 2) return monofont(value)

  return (
    <>
      {monofont(value.slice(0, truncLen))}
      ...{/* dots shouldn't be of monospace font, they take too much space */}
      {monofont(value.slice(-truncLen))}
    </>
  )
}

function useCopyHandler(address: string) {
  const [isCopied, toggleIsCopied] = useTimeoutFlag(2000)
  return [
    async () => {
      if (isCopied) return
      await navigator.clipboard.writeText(address)
      await toggleIsCopied(true)
    },
    isCopied,
  ] as const
}

function useTimeoutFlag(delay: number, defaultValue = false) {
  const [state, setState] = useState(defaultValue)
  const refTimer = useRef<NodeJS.Timeout>()
  return [
    state,
    useCallback(
      async (state: typeof defaultValue | void) => {
        setState(state ?? (x => !x))
        await new Promise(res => {
          if (refTimer.current) {
            clearTimeout(refTimer.current)
          }
          refTimer.current = setTimeout(res, delay)
        })
        setState(x => !x)
      },
      [delay]
    ),
  ] as const
}
