import clsx from 'clsx'

import { mergeStyles, resolveCSSSize } from 'src/utils'

import styles from './styles.module.scss'

export interface ILoaderProps extends IStyled {
  size?: number | string
  color?: string
  width?: number
}

export function Loader(props: ILoaderProps) {
  const { className, style, size, color, width = 7 } = props

  return (
    <div
      className={clsx(className, styles.spinner)}
      style={mergeStyles(style, {
        '--spinner-size': resolveCSSSize(size),
        '--spinner-color': color,
      })}
    >
      <svg className={styles.icon} viewBox="0 0 50 50">
        <circle
          className={styles.circle}
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth={width}
        />
      </svg>
    </div>
  )
}
