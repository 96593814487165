import { ReactNode, cloneElement } from 'react'
import { HiOutlineClipboard } from 'react-icons/hi'

import clsx from 'clsx'

import { Flex, resolveFlexDirectionStyle } from '../../layouts/Flex'
import { Loader } from '../Loader'

import { IDefaultItemProps, IListProps } from './types'

import styles from './styles.module.scss'

// TODO: probably remove this component, it's overcomplicated
export function List<T>(props: IListProps<T>) {
  const {
    tag: Tag = 'ul',
    data = [],
    className,
    style,
    renderItem = defaultRenderItem,
    renderItemContent = defaultRenderContent,
    getItemProps,
    empty = 'No data available',
    emptyIcon = <HiOutlineClipboard size={24} />,
    loading = false,

    gap = 20,
    direction = 'col',
    reverse = false,
    elevate = false,
    wrap = false,

    defaultItemClickable = false,
  } = props

  return (
    <Tag
      style={{
        ...style,
        gap,
        flexWrap: wrap ? 'wrap' : undefined,
        flexDirection: resolveFlexDirectionStyle({
          column: direction === 'col',
          reverse,
        }),
      }}
      className={clsx(className, styles.list, {
        [styles.list_elevated]: elevate,
      })}
    >
      <Choose>
        <When condition={loading}>
          <Loader className={styles.loader} />
        </When>

        <When condition={data.length > 0}>
          {data.map((x, i) => {
            const { noDefaultStyles, ...extraProps } =
              getItemProps?.(x, i) ?? {}

            const defaultClassName = noDefaultStyles
              ? undefined
              : clsx(styles.item, {
                  [styles.item_clickable]: defaultItemClickable,
                })

            const props = {
              ...extraProps,
              children: renderItemContent(x, i),
              className: defaultClassName,
            }
            const $item = renderItem(x, props, i)
            return cloneElement($item, {
              key: resolveKey(x) ?? i,
              className: clsx(defaultClassName, $item.props.className),
            })
          })}
        </When>

        <Otherwise>
          <Flex
            className={styles.empty}
            justify="center"
            align="center"
            gap={8}
          >
            {emptyIcon}
            {empty}
          </Flex>
        </Otherwise>
      </Choose>
    </Tag>
  )
}

export function defaultRenderItem(x: unknown, props: IDefaultItemProps) {
  return <li {...props} />
}

export function defaultRenderContent(x: unknown): ReactNode {
  if (x === undefined) {
    return null
  }
  if (typeof x === 'object') {
    return <pre>{JSON.stringify(x, null, 2)}</pre>
  }
  return x as ReactNode
}

function resolveKey(x: unknown) {
  if (x !== null && x !== undefined && typeof x === 'object') {
    if ('id' in x) {
      return (x as Dict).id as string
    }
    // some API are a bit weird
    if ('ID' in x) {
      return (x as Dict).ID as string
    }
  }
  return null
}
