import { ReactElement, cloneElement } from 'react'
import { AiOutlineClockCircle as IconPending } from 'react-icons/ai'
import { GoGear as IconProgress } from 'react-icons/go'

import { Badge, BadgeVariant, Icon } from 'src/components'

import { WalletStatus } from '../../types'

export function WalletStatusBadge(props: { status: WalletStatus }) {
  const { status } = props

  /* This is kinda "default" status which user sees most of time.
   * So it makes no sense to see it. */
  if (status === WalletStatus.Provisioned) return null

  const variant = resolveVariant(status)
  return (
    <Badge variant={variant} style={{ textTransform: 'capitalize' }}>
      {cloneElement(resolveIcon(status), {
        size: '1em',
        style: { flexShrink: 0 },
      })}
      {status.replaceAll('_', ' ')}
    </Badge>
  )
}

function resolveVariant(status: WalletStatus): BadgeVariant | undefined {
  switch (status) {
    case WalletStatus.Pending:
      return undefined
    case WalletStatus.In_progress:
      return 'info'
    case WalletStatus.Failed:
      return 'error'
    case WalletStatus.Provisioned:
      return 'success'
  }
}

function resolveIcon(status: WalletStatus): ReactElement {
  switch (status) {
    case WalletStatus.Pending:
      return <Icon type={IconPending} />
    case WalletStatus.In_progress:
      return <Icon type={IconProgress} />
    case WalletStatus.Provisioned:
      return <Icon type="success" />
    case WalletStatus.Failed:
      return <Icon type="error" />
  }
}
