import { Alert, Form, IAlertProps } from 'src/components'

import { ITransactionVerifyResponse, TransactionVerifyStatus } from '../types'

interface ITransactionVerifyProps {
  verificationResult: ITransactionVerifyResponse
  onReject(): void
  onApprove(): void
}

export const TransactionVerifyForm = (props: ITransactionVerifyProps) => {
  const {
    verificationResult: { action, warnings },
    onApprove,
    onReject,
  } = props

  return (
    <Form
      reset
      btnReset={{ variant: 'primary', accent: true, children: 'Abort' }}
      btnSubmit={{ children: 'I understand risks' }}
      onSubmit={onApprove}
      onReset={onReject}
    >
      <Alert variant={getAlertVariant(action)} style={{ marginBottom: '1rem' }}>
        {warnings[0].message}
      </Alert>
    </Form>
  )
}

function getAlertVariant(
  status: TransactionVerifyStatus
): IAlertProps['variant'] {
  switch (status) {
    case TransactionVerifyStatus.Warn:
      return 'warning'
    case TransactionVerifyStatus.Block:
      return 'error'
    default:
      return undefined
  }
}
