import { createApi as createRTQApi } from '@reduxjs/toolkit/query/react'

import { sortBy } from 'lodash'

import { API_BASE_URL, axiosBaseQuery } from 'src/api'

import {
  IAuctionWinnerResponse,
  ILotState,
  IProductTier,
  IStoreProduct,
  ITierDescriptor,
  IWSMsg,
  IWSState,
  ProductTierLevels,
} from '../types'

export const BiddingRTQApi = createRTQApi({
  reducerPath: 'bidding_api',

  baseQuery: axiosBaseQuery({
    baseURL: `/bidding/${API_BASE_URL}`,
  }),

  endpoints: builder => ({
    getProducts: builder.query<IStoreProduct[], void>({
      query: () => 'products',
      transformResponse: (resp: { polity_products: IStoreProduct[] }) =>
        resp.polity_products,
    }),

    getProductTiers: builder.query<
      IProductTier[],
      { product: IStoreProduct['id'] }
    >({
      query: ({ product }) => `products/${product}/tiers`,
      transformResponse: (xs: IProductTier[]) =>
        sortBy(xs, getProductTierWeight),
    }),

    makeBid: builder.mutation<
      void,
      ITierDescriptor & { lot: ILotState['id']; price: number }
    >({
      query: ({ product, tier, lot, price }) => ({
        url: `products/${product}/tiers/${tier}/lots/${lot}`,
        method: 'PUT',
        data: { price },
      }),
    }),

    buy: builder.mutation<IAuctionWinnerResponse, ITierDescriptor>({
      query: ({ product, tier }) => ({
        url: `products/${product}/tiers/${tier}/buy`,
        method: 'POST',
      }),
    }),

    createBidConnection: builder.query<IWSState, ITierDescriptor>({
      query: ({ product, tier }) => `products/${product}/tiers/${tier}/connect`,
      // @see https://redux-toolkit.js.org/rtk-query/usage/streaming-updates#overview
      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
      ) {
        try {
          const {
            data: { websocket_address },
          } = await cacheDataLoaded

          const ws = new WebSocket(websocket_address)

          const listener = async (event: MessageEvent) => {
            try {
              // data in socket is binary, for some reason
              const msg = (await new Response(event.data).json()) as IWSMsg

              switch (msg.type) {
                case 'lots_state': {
                  updateCachedData(draft => {
                    draft.lots_state = msg.data
                  })
                  break
                }
                case 'won_lot': {
                  updateCachedData(draft => {
                    draft.agreementId = msg.data.agreement_id
                  })
                  break
                }
                default:
                /* none */
              }
            } catch {
              /* none */
              console.error('[Bidding] Invalid WS message format', event.data)
            }
          }

          ws.addEventListener('message', listener)

          await cacheEntryRemoved
          ws.close()
        } catch (e) {
          console.error('[Bidding] Error on establishing WS connection', e)
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }
      },
    }),
  }),
})

function getProductTierWeight(tier: IProductTier) {
  switch (tier.tier_name.toLowerCase()) {
    case ProductTierLevels.Bronze:
      return 0
    case ProductTierLevels.Silver:
      return 1
    case ProductTierLevels.Gold:
      return 2
    default:
      return 4
  }
}
