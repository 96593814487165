import { Page, RTQSuspender } from 'src/components'
import { DEFAULT_POLLING_INTERVAL } from 'src/const'

import { AdvisorApi } from '../api'
import { AvatarsList } from '../components'
import { BadgeTotal } from '../components/BadgeTotal'

export function ContactsPage() {
  /* List should update as soon as new avatar accepts acquisition contract
   * So use polling to get that update */
  const state = AdvisorApi.endpoints.getAvatars.useQuery(undefined, {
    pollingInterval: DEFAULT_POLLING_INTERVAL,
    refetchOnReconnect: true,
    refetchOnFocus: true,
  })

  return (
    <Page
      title={
        <>
          Contacts <BadgeTotal value={state.data?.length} />
        </>
      }
    >
      <RTQSuspender state={state}>
        {items => <AvatarsList items={items} />}
      </RTQSuspender>
    </Page>
  )
}
