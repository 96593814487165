import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers'

export * from './RTQTagUtils'
export type { QueryReturnValue }

/**
 * baseQuery fn must _return_ value: either `{ data }` or `{ error }` object.
 * It _does not throw_ in case of error.
 * Thus, when you have a `queryFn` in your endpoint, which requires some extra logic of processing received value,
 * it comes to annoying if-else boilerplate for handling errors.
 * It's also quite counter-intuitive when you do try-catch in async func, and never get to catch block in case of error.
 *
 * This little helper wraps a base query call, making it to behave as normal promise.
 *
 * @see https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#basequery-function-return-value
 */
export async function rtqPromisifyBaseQuery<T>(
  req: MaybePromise<QueryReturnValue>
): Promise<T> {
  const { data, error } = await req
  if (error !== undefined) {
    throw error
  } else {
    return data as T
  }
}

/**
 * Helper which takes care of maintaining expected return format of `queryFn`:
 * either `{ data }` or `{ error }` object
 *
 * @see https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#implementing-a-queryfn
 */
export async function rtqResolveQueryFn<T>(fn: () => MaybePromise<T>): Promise<
  QueryReturnValue<
    T,
    // these two params must be like this
    // for compatibility with internal RTQ types:
    unknown,
    object | undefined
  >
> {
  try {
    const data = await fn()
    return { data }
  } catch (e) {
    return {
      error: (e as Error).message ?? e,
    }
  }
}
