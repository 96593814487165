// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AgreementsPage_row__YZ7O4:target {\n  background-color: var(--th-clr-warn-50);\n  --table-row-border-color: var(--th-clr-warn-500);\n}", "",{"version":3,"sources":["webpack://src/apps/cabinet/features/Avatar/pages/Agreements/AgreementsPage.module.scss"],"names":[],"mappings":"AACE;EACE,uCAAA;EACA,gDAAA;AAAJ","sourcesContent":[".row {\n  &:target {\n    background-color: var(--th-clr-warn-50);\n    --table-row-border-color: var(--th-clr-warn-500);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": "AgreementsPage_row__YZ7O4"
};
export default ___CSS_LOADER_EXPORT___;
