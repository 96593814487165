import { ReactNode, forwardRef } from 'react'

import clsx from 'clsx'

import styles from './styles.module.scss'

export type BadgeVariant = 'success' | 'warn' | 'error' | 'info'

export interface IBadgeProps extends IStyled {
  children: ReactNode
  size?: 'sm' | 'md' | 'lg'
  variant?: BadgeVariant
  border?: boolean
  icon?: boolean
}

export const Badge = forwardRef<HTMLDivElement, IBadgeProps>((props, ref) => {
  const {
    children,
    className,
    style,
    size = 'md',
    variant,
    border,
    icon,
  } = props
  return (
    <div
      ref={ref}
      className={clsx(styles.badge, className, {
        [styles.icon]: icon,
        [styles.border]: border,

        [styles.size_sm]: size === 'sm',
        [styles.size_md]: size === 'md',
        [styles.size_lg]: size === 'lg',

        [styles.variant_error]: variant === 'error',
        [styles.variant_warn]: variant === 'warn',
        [styles.variant_success]: variant === 'success',
        [styles.variant_info]: variant === 'info',
      })}
      style={style}
    >
      {children}
    </div>
  )
})
