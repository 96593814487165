// @see wallet-infrastructure-service/internal/service/wallets/assets.go:3
export enum BlockchainAsset {
  Bitcoin = 'BTC',
  BitcoinCash = 'BCH',
  Ethereum = 'ETH',
  EthereumClassic = 'ETC',
  Polygon = 'MATIC',
  USDC = 'USDC',
  USDC_ETH = 'USDC.ETH', // What even is this?
  USDC_ERC20 = 'USDC_ERC20', // What even is this?
  Heco = 'HT',
  Tron = 'TRX',
  Dash = 'DASH',
  Avalanche = 'AVAX',
}

export enum BlockchainId {
  Ethereum = '1',
  Polygon = '137',
  Optimism = '10',
  Avocado = '634',
  // TODO:
  //  rename this if there are multiple Solana networks exist (I have no idea)
  Solana = '4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ',
}

// Not sure "engine" is a correct word for this, but you get the point
export enum BlockchainEngineId {
  Ethereum = 'eip155',
  Solana = 'solana',
}

export type AbstractChainId = string
export type BlockchainAddress = string

export enum BlockchainMethod {
  eth_sendTransaction = 'eth_sendTransaction',

  // @see https://eips.ethereum.org/EIPS/eip-3326
  // @see https://dcspoc.atlassian.net/browse/PLT-403
  eth_switchChain = 'wallet_switchEthereumChain',

  // @see https://docs.walletconnect.com/advanced/rpc-reference/ethereum-rpc#personal_sign
  personalSign = 'personal_sign',
}
