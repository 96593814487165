import React from 'react'
import { setDefaultErrorBoundaryFallback } from 'react-app-error-boundary'

import AppBase, { DefaultErrorBoundaryFallback } from 'src/AppBase'
import { AppSettings } from 'src/AppSettingsContext'

import { ThemeProvider } from '../../ThemeProvider'

import Router from './Router'
import { login } from './features/User'
import { history, routes } from './routes'
import { store } from './store'

setDefaultErrorBoundaryFallback(DefaultErrorBoundaryFallback)

const App: React.FC = () => {
  return (
    <AppSettings routes={routes}>
      <ThemeProvider theme="polity">
        <AppBase history={history} store={store} login={login}>
          <Router />
        </AppBase>
      </ThemeProvider>
    </AppSettings>
  )
}

export default App
