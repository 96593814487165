// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ListPlaceholder_root__B13wA {\n  display: grid;\n  grid-gap: 1rem;\n  gap: 1rem;\n  justify-items: center;\n  text-align: center;\n  padding-block: 16px;\n}\n\n.ListPlaceholder_title__3jfQ8 {\n  color: var(--th-clr-txt-primary);\n  font-size: 1rem;\n  font-weight: 600;\n  line-height: 1.5;\n}\n\n.ListPlaceholder_desc__1rMap {\n  color: var(--th-clr-txt-secondary);\n  font-size: 0.875rem;\n  line-height: 1.43;\n}\n\n.ListPlaceholder_img__fq2jS {\n  width: 160px;\n  height: 120px;\n}", "",{"version":3,"sources":["webpack://src/components/base/ListPlaceholder/ListPlaceholder.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,cAAA;EAAA,SAAA;EACA,qBAAA;EACA,kBAAA;EACA,mBAAA;AACF;;AAEA;EACE,gCAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AACF;;AAEA;EACE,kCAAA;EACA,mBAAA;EACA,iBAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;AACF","sourcesContent":[".root {\n  display: grid;\n  gap: 1rem;\n  justify-items: center;\n  text-align: center;\n  padding-block: 16px;\n}\n\n.title {\n  color: var(--th-clr-txt-primary);\n  font-size: 1rem;\n  font-weight: 600;\n  line-height: 1.5;\n}\n\n.desc {\n  color: var(--th-clr-txt-secondary);\n  font-size: 0.875rem;\n  line-height: 1.43;\n}\n\n.img {\n  width: 160px;\n  height: 120px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "ListPlaceholder_root__B13wA",
	"title": "ListPlaceholder_title__3jfQ8",
	"desc": "ListPlaceholder_desc__1rMap",
	"img": "ListPlaceholder_img__fq2jS"
};
export default ___CSS_LOADER_EXPORT___;
