import { ErrorBoundary } from 'react-app-error-boundary'

import {
  Grid,
  Icon,
  IconButton,
  Link,
  Page,
  RTQSuspender,
} from 'src/components'
import { useOnMount } from 'src/hooks'

import { routes } from '../../../../routes'
import { WalletRTQApi } from '../../api'
import { Balance } from '../../components'
import { isWalletReady } from '../../lib'

import { WalletsList } from './WalletsList'

export function WalletsPage() {
  const [fetchWallets, walletsState] =
    WalletRTQApi.endpoints.getWalletsList.useLazyQuery()

  const fetchWalletsWithBalance = (preferCache = false) =>
    fetchWallets({ balance: true }, preferCache)

  useOnMount(() => {
    fetchWalletsWithBalance(true)
  })

  return (
    <Page
      title={
        <Grid columns="auto max-content max-content" align="center">
          <div>Wealth</div>

          <IconButton
            icon="refresh"
            size="md"
            onClick={() => fetchWalletsWithBalance(false)}
            variant="secondary"
            title={{
              placement: 'bottom',
              overlay: 'Reload wallets',
            }}
          />

          <Link to={routes.avatar.wallet.wallet_install} variant="btn-primary">
            <Icon type="download" size={20} />
            Install Wallet
          </Link>
        </Grid>
      }
    >
      <Grid gap="2rem">
        <RTQSuspender state={walletsState}>
          {wallets => (
            <>
              <Balance
                label="Total Balance"
                value={wallets
                  .filter(isWalletReady)
                  .map(x => +(x.balance_usd ?? '0'))
                  .reduce((m, x) => m + x, 0)}
                currency="$"
              />

              <ErrorBoundary>
                <WalletsList items={wallets} />
              </ErrorBoundary>
            </>
          )}
        </RTQSuspender>
      </Grid>
    </Page>
  )
}
