import React, { useMemo } from 'react'
import { useParams } from 'react-router'
import { Column } from 'react-table'

import {
  AgreementStatusBadge,
  AgreementTypeBadge,
  Card,
  DateTime,
  Flex,
  Icon,
  Link,
  Loader,
  PDFPreview,
  Page,
  RTQSuspender,
  Table,
  UserPhoto,
} from 'src/components'
import { IAgreement, UserRole } from 'src/types'
import { getAgreementTypeLabel, getUserRoleLabel } from 'src/utils'

import { AdvisorApi } from '../../api'
import { BadgeTotal } from '../../components/BadgeTotal'
import { buildAgreementFormLink } from '../../lib'
import { IInvestorAvatar } from '../../types'

import styles from './styles.module.scss'

export function AvatarDetailsPage() {
  const { id } = useParams<{ id: string }>()

  const { data: avatar } = AdvisorApi.endpoints.getAvatars.useQuery(undefined, {
    selectFromResult: ({ data }) => ({
      data: data?.find(x => x.id === id),
    }),
  })
  const state = AdvisorApi.endpoints.getAvatarAgreements.useQuery(id)
  const { data: agreements = [] } = state

  return (
    <Page
      title={
        <Flex justify="space-between">
          <Title avatar={avatar} />
          <Link
            variant="btn-primary"
            to={buildAgreementFormLink({ user: avatar?.id })}
            className={styles.btn_new_agreement}
          >
            Create agreement
          </Link>
        </Flex>
      }
      description="Avatar details"
    >
      <Card
        title={
          <>
            Agreements <BadgeTotal value={agreements.length} />
          </>
        }
      >
        <RTQSuspender state={state}>
          {agreements => <AgreementsList items={agreements} />}
        </RTQSuspender>
      </Card>
    </Page>
  )
}

function AgreementsList(props: { items: IAgreement[] }) {
  const { items } = props

  return (
    <Table
      data={items}
      columns={useColumns()}
      getRowProps={({ row }) => ({
        id: row.original.id,
        className: styles.row,
      })}
      showPagination={false}
      manualPagination
    />
  )
}

function useColumns() {
  return useMemo(
    (): Column<IAgreement>[] => [
      {
        accessor: 'type',
        Header: 'Contract',
        Cell: ({ value }) => (
          <AgreementTypeBadge showTitle border>
            {value}
          </AgreementTypeBadge>
        ),
        accent: true,
      },
      {
        accessor: 'sender',
        Header: getUserRoleLabel(UserRole.Advisor),
        // TODO: user's avatar?
        tx: {
          style: {
            minWidth: 210,
          },
        },
      },
      {
        accessor: 'date',
        Header: 'Date signed',
        Cell: ({ value }) => <DateTime value={value} format="MMM d, yyyy" />,
      },
      {
        accessor: 'status',
        Header: 'State',
        Cell: ({ value }) => (
          <AgreementStatusBadge showTitle border>
            {value}
          </AgreementStatusBadge>
        ),
      },
      {
        accessor: 'attachment',
        Cell: ({ value, row: { original: item } }) => (
          <PDFPreview
            modalTitle={<>{getAgreementTypeLabel(item.type)} Contract</>}
            src={value}
            btnVariant="tertiary"
            style={{
              whiteSpace: 'nowrap',
            }}
            label={
              <>
                <Icon type="eye" size="1.25em" /> View contract
              </>
            }
          />
        ),
        tx: {
          style: {
            minWidth: 280,
          },
        },
      },
    ],
    []
  )
}

function Title(props: { avatar?: IInvestorAvatar }) {
  const { avatar } = props

  if (!avatar) {
    return <Loader />
  }

  return (
    <Flex align="center">
      <UserPhoto src={avatar.photo} alt={avatar.name} size={40} />
      {avatar.name}
    </Flex>
  )
}
