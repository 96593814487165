import { CSSProperties } from 'react'

export function resolveCSSSize(x: string | number | undefined) {
  if (x === undefined) return undefined
  return typeof x === 'number' ? `${x}px` : x
}

type CustomPropValue = string | number
type CSSPropOrCustom = keyof CSSProperties | `--${string}`
type CSSPropertiesWithCustoms = {
  [K in CSSPropOrCustom]?: K extends keyof CSSProperties
    ? CSSProperties[K]
    : CustomPropValue
}

export function mergeStyles(
  base: CSSProperties | CSSPropertiesWithCustoms | undefined,
  patch: CSSProperties | CSSPropertiesWithCustoms | undefined
): typeof base {
  if (base === undefined && patch === undefined) return undefined
  if (patch === undefined) return base

  const filtered = {} as Dict
  for (const [k, v] of Object.entries(patch)) {
    if (v !== undefined) {
      filtered[k] = v
    }
  }
  const hasPatch = Object.keys(filtered).length > 0

  if (base === undefined) {
    return hasPatch ? filtered : undefined
  } else {
    return hasPatch ? { ...base, ...filtered } : base
  }
}
