import { getSearch, push, replace } from 'connected-react-router'
import { useCallback, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { createDraft } from 'immer'

import { parseQueryString, updateLocationQuery } from 'src/utils'

interface IQueryParamsOptions<T extends object> extends INavigationOptions {
  parse?: Partial<{
    // TODO: raw value may be an array too
    [K in keyof T]: (raw: string | undefined) => T[K]
  }>
}

export function useQueryParams<T extends object>(
  options: IQueryParamsOptions<T> = {}
): Partial<T> {
  const search = useSelector(getSearch)
  const { parse } = options

  const refParsers = useRef(parse)
  refParsers.current = parse

  return useMemo(() => {
    const raw = parseQueryString(search)

    const parsers = refParsers.current
    const parsed: Dict = {}
    for (const [k, v] of Object.entries(raw)) {
      const parser = parsers?.[k as keyof T]
      parsed[k] = parser === undefined ? v : parser(v as string)
    }
    return parsed as Partial<T>
  }, [search])
}

// ---

interface INavigationOptions {
  replace?: boolean
}

export function useSyncQueryParams<T extends object>(
  opts: INavigationOptions & IQueryParamsOptions<T> = {}
): [Partial<T>, (data: Partial<T>, opts?: INavigationOptions) => void] {
  const { parse, replace: defaultShouldReplace = false } = opts
  type Part = Partial<T>

  const params = useQueryParams<Part>({ parse })
  const dispatch = useDispatch()
  const loc = useLocation()

  const update = useCallback(
    (data: Part, opts: INavigationOptions = {}) => {
      const shouldReplace = opts?.replace ?? defaultShouldReplace
      const func = shouldReplace ? replace : push
      dispatch(func(updateLocationQuery(loc, data)))
    },
    [defaultShouldReplace, dispatch, loc]
  )

  return [params, update]
}

// ---

export function useDraft<T extends object>(data: T): T {
  return useMemo(() => createDraft(data), [data]) as T
}

/**
 * Resulting function remains the same no matter what,
 * but can be called with arbitrary params from closure.
 *
 * It's an optimization helper.
 * Should be used for callbacks which depend on some values in context,
 * whose changes themselves should not cause children elements update.
 */
export function useClosureCallback<F extends Func>(fn: F) {
  const ref = useRef(fn)
  ref.current = fn
  return useCallback(
    (...args: Parameters<F>): ReturnType<F> => ref.current(...args),
    []
  )
}
