// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/react-scripts/node_modules/css-loader/dist/cjs.js??ref--5-oneOf-7-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../node_modules/resolve-url-loader/index.js??ref--5-oneOf-7-3!../../../../node_modules/react-scripts/node_modules/sass-loader/dist/cjs.js??ref--5-oneOf-7-4!../../layouts/Box/Box.module.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Icon_icon__2zR3u {\n  display: inline-flex;\n  --box-size-def: var(--th-icon-size);\n  --icon-size: var(--box-size);\n  height: var(--icon-size);\n  width: var(--icon-size);\n}\n.Icon_icon__2zR3u > svg {\n  width: 100%;\n  height: 100%;\n}\n\n.Icon_box__1nFOO {\n  --box-pad-def: 2px;\n  padding: var(--box-pad);\n  border-radius: min(25%, var(--th-border-radius));\n}", "",{"version":3,"sources":["webpack://src/components/base/Icon/Icon.module.scss"],"names":[],"mappings":"AAEA;EAGE,oBAAA;EAEA,mCAAA;EACA,4BAAA;EACA,wBAAA;EACA,uBAAA;AAFF;AAIE;EACE,WAAA;EACA,YAAA;AAFJ;;AAMA;EAEE,kBAAA;EACA,uBAAA;EACA,gDAAA;AAHF","sourcesContent":["@value box_module: \"../../layouts/Box/Box.module.scss\";\n\n.icon {\n  composes: box_params from box_module;\n\n  display: inline-flex;\n\n  --box-size-def: var(--th-icon-size);\n  --icon-size: var(--box-size);\n  height: var(--icon-size);\n  width: var(--icon-size); // aspect-ratio doesn't work on SVG elements\n\n  > svg {\n    width: 100%;\n    height: 100%;\n  }\n}\n\n.box {\n  composes: bordered from box_module;\n  --box-pad-def: 2px;\n  padding: var(--box-pad);\n  border-radius: min(25%, var(--th-border-radius));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box_module": "\"../../layouts/Box/Box.module.scss\"",
	"icon": "Icon_icon__2zR3u " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["box_params"] + "",
	"box": "Icon_box__1nFOO " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["bordered"] + ""
};
export default ___CSS_LOADER_EXPORT___;
