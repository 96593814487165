import { Button, Icon, Tooltip, useModal } from 'src/components'
import { useAsync, useAsyncUnwrap, useIsMountedRef } from 'src/hooks'

import { IWCFacade, IWCSessionRequest } from '../../../../WalletConnect'
import { Layout } from '../Layout'
import { SessionRequest } from '../SessionRequest'

import { WalletConnectForm } from './ConnectionForm'

import styles from './Connector.module.scss'

export function WCConnector(props: { wc: IWCFacade }) {
  const { wc } = props
  const modal = useConnectionForm(wc)

  return (
    <Layout
      title="Use WalletConnect"
      desc="Connect your wallet to a dApp via WalletConnect."
      header={
        <>
          <Icon type="walletconnect" className={styles.wc_icon_header} />
          <Tooltip
            overlay={
              <div className={styles.wc_help_text}>
                WalletConnect is an open-source protocol that allows your wallet
                to connect and interact with DApps and other wallets. By
                scanning a QR code or clicking a deep link, WalletConnect
                establishes an encrypted connection between your wallet and the
                DApp.
              </div>
            }
            destroyTooltipOnHide
            autoWrap={false}
          >
            <div className={styles.icon_help_container}>
              <Icon type="info" size="1rem" color />
            </div>
          </Tooltip>
        </>
      }
    >
      <Button variant="secondary" onClick={modal.open}>
        <Icon type="walletconnect" className={styles.wc_icon_btn} />
        Connect Wallet
      </Button>

      {modal.$element}
    </Layout>
  )
}

// ---

function useConnectionForm(wc: IWCFacade) {
  const refMounted = useIsMountedRef()

  const [{ status, value: request, cancel }, handleConnectionRequest] =
    useAsyncUnwrap(
      // ^ unwrap async, so that handler can throw and be caught by form's `onSubmit` handler,
      // automatically rendering that error in form
      useAsync(useConnectionRequestHandler(wc))
    )

  const modal = useModal(
    {
      title: 'Wallet Connect',
      subtitle: 'Set up connection to DApp',
      icon: <Icon type="walletconnect" className={styles.wc_icon_modal} />,
      style: { content: { width: 440 } },
      // Reset async state on close, so we start with a form again next time
      onAfterClose: cancel,
    },
    ({ close }) => (
      <Choose>
        <When condition={status === 'success'}>
          <SessionRequest
            wc={wc}
            request={request as NonNullable<typeof request>}
            /**
             * Child component calls this cb _after_ establishing a WC session.
             * Which updates "connected" state of WalletConnect instance.
             * Which re-renders a parent Container component.
             * Which causes unmount of this current component completely.
             * Which results in attempt to update state on unmounted component.
             * Either patch it like this, or review the dataflow logic (good luck in that).
             */
            onDone={() => (refMounted.current ? close() : undefined)}
          />
        </When>

        <Otherwise>
          <WalletConnectForm
            onSubmit={({ uri }, event, form) =>
              handleConnectionRequest(uri).catch(
                wcUriErrorHandler(message => {
                  form.setError('uri', { message })
                })
              )
            }
          />
        </Otherwise>
      </Choose>
    )
  )

  return modal
}

// ---

const SESSION_REQUEST_TIMEOUT = 1000 * 5
const SESSION_REQUEST_TIMEOUT_ERROR =
  "Didn't receive any session request.\nMaybe your link is invalid or expired."

function useConnectionRequestHandler(wc: IWCFacade) {
  return (uri: string) =>
    new Promise<IWCSessionRequest>((resolve, reject) => {
      const timerSessionWaitExpired = setTimeout(
        reject,
        SESSION_REQUEST_TIMEOUT,
        SESSION_REQUEST_TIMEOUT_ERROR
      )

      wc.awaitSessionRequest(uri)
        .then(request => {
          clearTimeout(timerSessionWaitExpired)
          resolve(request)
        })
        .catch(reject)
    })
}

function wcUriErrorHandler(fn: (e: string) => void) {
  return (e: unknown) => {
    if (!(e instanceof Error)) throw e

    if (e.message.match(/URI format/i)) {
      fn(e.message)
      return
    }

    if (e.message.match(/dummy/i)) {
      fn('Invalid or unsupported URI')
      return
    }

    throw e
  }
}
