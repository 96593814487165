// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_suspendable__X5ccb {\n  position: relative;\n}\n.styles_suspendable__X5ccb::after {\n  content: \"\";\n  position: absolute;\n  inset: 0;\n  display: none;\n}\n.styles_suspendable__X5ccb.styles_suspended__BEA7Y::after {\n  display: block;\n  -webkit-backdrop-filter: blur(2px);\n          backdrop-filter: blur(2px);\n}", "",{"version":3,"sources":["webpack://src/components/base/Suspendable/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AACE;EACE,WAAA;EACA,kBAAA;EACA,QAAA;EACA,aAAA;AACJ;AAGI;EACE,cAAA;EACA,kCAAA;UAAA,0BAAA;AADN","sourcesContent":[".suspendable {\n  position: relative;\n\n  &::after {\n    content: \"\";\n    position: absolute;\n    inset: 0;\n    display: none;\n  }\n\n  &.suspended {\n    &::after {\n      display: block;\n      backdrop-filter: blur(2px);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"suspendable": "styles_suspendable__X5ccb",
	"suspended": "styles_suspended__BEA7Y"
};
export default ___CSS_LOADER_EXPORT___;
