import { IconButton } from 'src/components'

import { WalletRTQApi } from '../../../api'
import { IWallet } from '../../../types'

import type { ContentType } from './WalletAssets'
import { useWalletAssetsApp } from './reducer'

export function BtnRefreshAssets(props: {
  wallet: Pick<IWallet, 'type' | 'address'>
  contentType: ContentType
}) {
  const app = useWalletAssetsApp()
  const { wallet, contentType } = props

  const [fetchBasicAssets] =
    WalletRTQApi.endpoints.getWalletAssets.useLazyQuery()

  const [fetchDefiAssets] =
    WalletRTQApi.endpoints.getWalletDefiAssets.useLazyQuery()

  const [fetchTxns] =
    WalletRTQApi.endpoints.getTransactionsHistory.useLazyQuery()

  const onClick = () => {
    switch (contentType) {
      case 'assets':
        return fetchBasicAssets({
          wallet: wallet.address,
          provider: wallet.type,
        })

      case 'defi':
        return fetchDefiAssets({ wallet: wallet.address })

      case 'txns': {
        const { asset, ...pagination } = app.state.txns
        if (asset !== undefined) {
          return fetchTxns({ wallet: wallet.address, ...pagination, asset })
        }
        break
      }

      default:
        return
    }
  }

  return (
    <IconButton
      icon="refresh"
      variant="secondary"
      size="md"
      onClick={onClick}
    />
  )
}
