// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/cjs.js??ref--5-oneOf-7-1!../../../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../../../node_modules/resolve-url-loader/index.js??ref--5-oneOf-7-3!../../../../../../../node_modules/react-scripts/node_modules/sass-loader/dist/cjs.js??ref--5-oneOf-7-4!../../../../../../components/layouts/Page/Page.module.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InstallWalletPage_root__iulzj {\n  /* Fully stretch to parent block size (main Layout component). */\n  height: 100%;\n  display: grid;\n  grid-template-rows: auto 1fr;\n}\n.InstallWalletPage_root__iulzj > :where(." + ___CSS_LOADER_ICSS_IMPORT_0___.locals["page"] + ") {\n  max-width: 860px;\n  margin-inline: auto;\n}\n\n.InstallWalletPage_header__1TP_3:not(:empty) {\n  padding-block: 8px;\n}", "",{"version":3,"sources":["webpack://src/apps/cabinet/features/Wallet/pages/InstallWallet/InstallWalletPage.module.scss"],"names":[],"mappings":"AAEA;EACE,gEAAA;EACA,YAAA;EACA,aAAA;EACA,4BAAA;AAAF;AAEE;EACE,gBAAA;EACA,mBAAA;AAAJ;;AAIA;EACE,kBAAA;AADF","sourcesContent":["@value page from \"src/components/layouts/Page/Page.module.scss\";\n\n.root {\n  /* Fully stretch to parent block size (main Layout component). */\n  height: 100%;\n  display: grid;\n  grid-template-rows: auto 1fr;\n\n  > :where(.page) {\n    max-width: 860px;\n    margin-inline: auto;\n  }\n}\n\n.header:not(:empty) {\n  padding-block: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["page"] + "",
	"root": "InstallWalletPage_root__iulzj",
	"header": "InstallWalletPage_header__1TP_3"
};
export default ___CSS_LOADER_EXPORT___;
