import { ConnectedRouter } from 'connected-react-router'
import React, { useEffect } from 'react'
import { ErrorBoundary } from 'react-app-error-boundary'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { Store } from 'redux'

import { History } from 'history'

import { ErrorMessage, Loader, Suspense } from 'src/components'
import { ReferrerTracker } from 'src/features/HistoryReferrer'
import { AppLoginHandler, AuthWatcher } from 'src/features/User'

const App: React.FC<{
  history: History
  store: Store
  login?: AppLoginHandler
  allowGuest?: boolean
}> = props => {
  const { children, history, store, login, allowGuest } = props
  return (
    <HelmetProvider>
      <Provider store={store}>
        <Guard>
          <ConnectedRouter history={history}>
            <ReferrerTracker />

            <Choose>
              <When condition={login === undefined}>{children}</When>
              <Otherwise>
                <AuthWatcher
                  login={login as NonNullable<typeof login>}
                  allowGuest={allowGuest}
                >
                  {children}
                </AuthWatcher>
              </Otherwise>
            </Choose>
          </ConnectedRouter>

          <ToastContainer
            draggable={false}
            theme="dark"
            newestOnTop
            bodyStyle={{
              wordBreak: 'break-word',
            }}
          />
        </Guard>
      </Provider>
    </HelmetProvider>
  )
}

const Guard: React.FC = props => {
  const { children } = props
  return (
    /* for errors at the very top, display error-overlay as normal */
    <ErrorBoundary allowDevErrorOverlay>
      <Suspense fallback={<Loader size="10%" />}>{children}</Suspense>
    </ErrorBoundary>
  )
}

export default App

/**
 * By discussion (12.06.24): never show unrecoverable errors as-is in interface.
 * Log details to console, and show only abstract "user-friendly" text in UI.
 */
export function DefaultErrorBoundaryFallback({ error }: { error: Error }) {
  const msg = 'Something went wrong. Please, contact technical support.'

  useEffect(() => {
    /* Add error text to log to easier match it with the error on screen */
    console.error(msg, '\n', error)
  }, [error])

  return <ErrorMessage>{msg}</ErrorMessage>
}
