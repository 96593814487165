import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { BaseQueryFn } from '@reduxjs/toolkit/query/react'

import { AxiosError, AxiosRequestConfig } from 'axios'

import { Log } from 'src/log'

import axiosApi, { API_BASE_URL, resolveResponseErrorMessage } from '../api'

type AxiosQueryConfig = RequiredProps<AxiosRequestConfig, 'url'>

function isConfig(x: unknown): x is AxiosQueryConfig {
  return typeof x === 'object' && x !== null
}

type AxiosConfigFactory = (queryApi: BaseQueryApi) => AxiosQueryConfig | string
type BaseUrlFactory = (queryApi: BaseQueryApi) => string

interface IBaseQueryOptions {
  baseURL: string | BaseUrlFactory
  headers?: AxiosQueryConfig['headers']
}

const DEFAULT_BASE_QUERY_OPTIONS: IBaseQueryOptions = {
  baseURL: API_BASE_URL,
}

/**
 * @see https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#axios-basequery
 */
export const axiosBaseQuery =
  ({
    baseURL: baseURLOrFunc,
    headers: baseHeaders,
  }: IBaseQueryOptions = DEFAULT_BASE_QUERY_OPTIONS): BaseQueryFn<
    string | AxiosQueryConfig | AxiosConfigFactory
  > =>
  async (arg, queryApi) => {
    // resolve config
    let config: AxiosQueryConfig

    if (typeof arg === 'function') {
      // eslint-disable-next-line no-param-reassign
      arg = arg(queryApi)
    }

    if (isConfig(arg)) {
      config = arg
    } else {
      config = { url: arg, method: 'get' }
    }

    // ---
    // resolve base url

    const baseURL =
      typeof baseURLOrFunc === 'function'
        ? baseURLOrFunc(queryApi)
        : baseURLOrFunc

    // ---
    // run query

    try {
      const { data, headers } = await axiosApi({
        baseURL: config.url.startsWith('/') ? undefined : baseURL,
        ...config,
        headers: { ...baseHeaders, ...config.headers },
      })
      return { data, meta: { headers } }
    } catch (axiosError) {
      const { response, message } = axiosError as AxiosError
      let result
      if (response !== undefined) {
        const { status, data, headers } = response
        result = {
          error: {
            status,
            data,
            // add message to emulate basic Error instance
            message: resolveResponseErrorMessage(data) ?? message,
          },
          meta: { headers },
        }
      } else {
        result = {
          error: {
            status: undefined,
            data: message,
            message,
          },
        }
      }
      Log.error('[RTQ]', result)
      return result
    }
  }
