import { createElement, useState } from 'react'

import { WalletRTQApi } from '../api'
import { TransactionVerifyForm } from '../components'
import {
  ITransactionPayload,
  ITransactionVerifyResponse,
  TransactionVerifyStatus,
  WalletProvider,
} from '../types'

interface IUnsafeTransactionData {
  txn: ITransactionPayload
  verification: ITransactionVerifyResponse
}

export interface IPaymentTransactionFlowParams<T> {
  provider: WalletProvider
  txnSource: 'polity' | 'wc'
  sendTxn(payload: ITransactionPayload): T
  onTxnSubmitted?(response: Awaited<T>): void
}

export function usePaymentTransactionFlow<T>(
  params: IPaymentTransactionFlowParams<T>
) {
  const { provider, txnSource, onTxnSubmitted, sendTxn } = params

  const [verifyTransaction] =
    WalletRTQApi.endpoints.verifyTransaction.useMutation()

  const [unsafeTxnData, setUnsafeTxnData] =
    useState<IUnsafeTransactionData | null>(null)

  const isUnsafeTxn = unsafeTxnData !== null

  const submitVerifiedTxn = async (payload: ITransactionPayload) => {
    const response = await sendTxn(payload)
    onTxnSubmitted?.(response)
  }

  return {
    isUnsafeTxn,

    reset() {
      setUnsafeTxnData(null)
    },

    async run(payload: ITransactionPayload, onSuccess?: () => void) {
      const request = verifyTransaction({
        ...payload,
        provider,
        source: txnSource,
      })
      const verification = await request.unwrap()
      if (verification.action === TransactionVerifyStatus.None) {
        await submitVerifiedTxn(payload)
        onSuccess?.()
      } else {
        setUnsafeTxnData({ txn: payload, verification })
      }
    },

    renderUnsafeTxnConfirm(
      opts: {
        onConfirm?: () => void
        onReject?: () => void
        onDone?: () => void
      } = {}
    ) {
      if (!isUnsafeTxn) return null

      const { onDone, onConfirm, onReject } = opts
      return createElement(TransactionVerifyForm, {
        verificationResult: unsafeTxnData.verification,
        async onReject() {
          await onReject?.()
          await onDone?.()
        },
        async onApprove() {
          await submitVerifiedTxn(unsafeTxnData.txn)
          await onConfirm?.()
          await onDone?.()
        },
      })
    },
  }
}
