import { TAG_LIST, createTag } from './base'
import {
  ProvidedID,
  ProvidedTagResult,
  RTQTag,
  T_ID,
  TagType,
  TagTypeOrFn,
  TypedSingleTagProvider,
  TypedTagListProvider,
} from './types'

const resolveId = (x: NonNullable<ProvidedID>): T_ID =>
  typeof x === 'object' ? x.id : x

/**
 * Logically, it has to return a tag or undefined. But for convenience purposes it returns an array.
 * You can't put undefined/null into `providesTags` list –
 * so to don't write these boilerplate ternaries every time in every endpoint, do it here.
 */
export function providesTagMaybe<T extends TagType, ID extends ProvidedID>(
  type: TagTypeOrFn<T>,
  id: ID
): ProvidedTagResult<T, ID> {
  const result =
    id === undefined
      ? []
      : [
          typeof type === 'function'
            ? type(resolveId(id))
            : createTag(type, resolveId(id)),
        ]
  return result as ProvidedTagResult<T, ID>
}

providesTagMaybe.type =
  <T extends TagType>(type: TagTypeOrFn<T>): TypedSingleTagProvider<T> =>
  id =>
    providesTagMaybe(type, id)

// ---

/**
 * @see https://redux-toolkit.js.org/rtk-query/usage/automated-refetching#abstracting-common-providesinvalidates-usage
 */
export function providesTagsList<T extends TagType>(
  tagOrType: TagTypeOrFn<T>,
  xs: ProvidedID<true>
): RTQTag<T>[] {
  const type =
    typeof tagOrType === 'function' ? tagOrType.toString() : tagOrType
  const tags = [createTag(type, TAG_LIST)] as RTQTag<T>[]
  return xs === undefined
    ? tags
    : tags.concat(xs.map(resolveId).map(id => createTag(type, id)))
}

providesTagsList.type =
  <T extends TagType>(type: TagTypeOrFn<T>): TypedTagListProvider<T> =>
  xs =>
    providesTagsList(type, xs)
