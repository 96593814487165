import { clearAuthTokens, getAccessToken } from 'axios-jwt'

import { decodeUserData } from './lib'
import { IBaseUser } from './types'

export function logout() {
  clearAuthTokens()
}

export function getUser(): IBaseUser {
  const token = getAccessToken()
  if (token === undefined) {
    throw new Error('401 Unauthorized')
  }
  return decodeUserData(token)
}
