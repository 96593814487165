import clsx from 'clsx'

/*import { useFormContext } from 'src/components'

import { isParticipatingAuction } from './lib'*/

import styles from './BidOutcome.module.scss'

export function BidOutcome(props: {
  isFinished: boolean
  isWinning: boolean
  className?: string
}) {
  const { isFinished, isWinning, className } = props
  return (
    <div className={clsx(styles.root, className)}>
      <Choose>
        <When condition={isWinning}>
          <span className={styles.status_success}>
            {isFinished ? 'You have won' : "You're leading"}
          </span>
        </When>
        <Otherwise>
          <span className={styles.status_passive}>
            {isFinished ? 'Auction is finished' : 'Make a bid to take a lead'}
          </span>
        </Otherwise>
      </Choose>
    </div>
  )

  /* TODO: show detailed outcome when user's participation will be tracked on BE.
   *   On FE it will require implementing some persistent storage (otherwise it wont make quite sense),
   *   which is out of current scope. */
  /*const form = useFormContext()
  const isParticipating = isParticipatingAuction(form.formState)

  if (isParticipating) {
    return (
      <div className={clsx(styles.root, className)}>
        <Choose>
          <When condition={isFinished}>
            <Choose>
              <When condition={isWinning}>
                <span className={styles.status_success}>You have won</span>
              </When>
              <Otherwise>
                <span className={styles.status_fail}>You have lost</span>
              </Otherwise>
            </Choose>
          </When>

          <Otherwise>
            <Choose>
              <When condition={isWinning}>
                <span className={styles.status_success}>You're leading</span>
              </When>
              <Otherwise>
                <span className={styles.status_fail}>You're outbid</span>
              </Otherwise>
            </Choose>
          </Otherwise>
        </Choose>
      </div>
    )
  }

  return (
    <div className={clsx(styles.root, className)}>
      <Choose>
        <When condition={isFinished}>
          <span className={styles.status_fail}>Auction is finished</span>
        </When>

        <Otherwise>
          <span className={styles.status_passive}>
            You're not participating
          </span>
        </Otherwise>
      </Choose>
    </div>
  )*/
}
