import { createApi as createRTQApi } from '@reduxjs/toolkit/query/react'

import { flow, map } from 'lodash/fp'

import { axiosBaseQuery, rtqTags } from 'src/api'
import { IAgreement, IAgreementRaw } from 'src/types'
import { sortNewest } from 'src/utils'

import {
  IAcceptAgreementPayload,
  IDeclineAgreementPayload,
  IResolveAgreementResponse,
} from '../types'

const tags = rtqTags(['Agreement', 'Log', 'Portfolio'])

export const AvatarRTQApi = createRTQApi({
  reducerPath: 'avatar_api',

  baseQuery: axiosBaseQuery({
    baseURL: 'avatar',
  }),

  tagTypes: tags.toArray(),

  endpoints: builder => ({
    getAgreements: builder.query<IAgreement[], void>({
      query: () => 'agreements',
      providesTags: tags.Agreement.providesList,
      transformResponse: flow(map(parseAgreement), sortNewest),
    }),

    getAgreementDetails: builder.query<
      IAgreement,
      Pick<IAgreement, 'id' | 'type'>
    >({
      query: ({ id, type }) => `agreements/${type}/${id}`,
      providesTags: tags.Agreement.providesOne,
      transformResponse: parseAgreement,
    }),

    acceptAgreement: builder.mutation<
      IResolveAgreementResponse,
      IAcceptAgreementPayload
    >({
      query: data => ({
        url: `agreements/accept`,
        method: 'POST',
        data,
      }),
      invalidatesTags: (result, error, { agreementId }) => [
        tags.Agreement(agreementId),
      ],
    }),

    declineAgreement: builder.mutation<
      IResolveAgreementResponse,
      IDeclineAgreementPayload
    >({
      query: data => ({
        url: `agreements/decline`,
        method: 'POST',
        data,
      }),
      invalidatesTags: (result, error, { agreementId }) => [
        tags.Agreement(agreementId),
      ],
    }),
  }),
})

function parseAgreement(x: IAgreementRaw): IAgreement {
  return {
    id: x.agreementID,
    type: x.agreementType,
    status: x.status,
    date: x.agreementDate,

    investment: +x.investment,
    fee: x.fee,

    receiver: x.avatar,
    sender: x.adviser,

    attachment: x.filePath,
  }
}
