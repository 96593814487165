// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WalletAssets_root__2jahE {\n  display: grid;\n  grid-template-columns: repeat(auto-fit, 1.25em);\n  grid-gap: 0.35rem;\n  gap: 0.35rem;\n  overflow: hidden;\n}\n\n.WalletAssets_crypto_badge__quNJ6 {\n  border-radius: 50%;\n  padding: 4px;\n}\n\n.WalletAssets_crypto_logo__22bNe {\n  --size: 2em;\n}\n\n.WalletAssets_crypto_tooltip__lsqDQ {\n  text-align: center;\n}", "",{"version":3,"sources":["webpack://src/apps/cabinet/features/Wallet/pages/Wallets/WalletsList/WalletAssets.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,+CAAA;EACA,iBAAA;EAAA,YAAA;EACA,gBAAA;AACF;;AAEA;EACE,kBAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,kBAAA;AACF","sourcesContent":[".root {\n  display: grid;\n  grid-template-columns: repeat(auto-fit, 1.25em);\n  gap: 0.35rem;\n  overflow: hidden;\n}\n\n.crypto_badge {\n  border-radius: 50%;\n  padding: 4px;\n}\n\n.crypto_logo {\n  --size: 2em;\n}\n\n.crypto_tooltip {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "WalletAssets_root__2jahE",
	"crypto_badge": "WalletAssets_crypto_badge__quNJ6",
	"crypto_logo": "WalletAssets_crypto_logo__22bNe",
	"crypto_tooltip": "WalletAssets_crypto_tooltip__lsqDQ"
};
export default ___CSS_LOADER_EXPORT___;
