import React, { createContext, useContext } from 'react'

import { useChanged } from './hooks'

export interface IAppSettings {
  routes: {
    home: string
    login?: string
  }
}

/**
 * Settings context is intended to use in shared code.
 * It serves to break dependency loop.
 * DO NOT use it in particular app code, because it just makes no sense.
 */
export const AppSettingsContext = createContext<IAppSettings>({
  routes: {
    home: '/',
    login: '/login',
  },
})

export const AppSettings: React.FC<IAppSettings> = props => {
  const { children, ...settings } = props
  const changed = useChanged(settings)
  return (
    <AppSettingsContext.Provider value={changed}>
      {children}
    </AppSettingsContext.Provider>
  )
}

export const useAppSettings = () => useContext(AppSettingsContext)
