// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Connector_icon_help_container__1iUxn {\n  position: absolute;\n  justify-self: flex-end;\n}\n\n.Connector_wc_help_text__1vNFL {\n  max-width: 500px;\n  font-size: 0.875rem;\n}\n\n.Connector_wc_icon_header__3X2zR {\n  width: 52px;\n  height: 32px;\n}\n\n.Connector_wc_icon_btn__aGyOO {\n  width: 30px;\n  height: 18px;\n}\n\n.Connector_wc_icon_modal__31hnE {\n  width: 50px;\n  height: 50px;\n  border: 1px solid var(--th-border-color);\n  border-radius: 10px;\n  padding: 15px 9px;\n}", "",{"version":3,"sources":["webpack://src/apps/cabinet/features/Wallet/pages/WalletDetails/WalletConnect/Connector/Connector.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,sBAAA;AACF;;AAEA;EACE,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,wCAAA;EACA,mBAAA;EACA,iBAAA;AACF","sourcesContent":[".icon_help_container {\n  position: absolute;\n  justify-self: flex-end;\n}\n\n.wc_help_text {\n  max-width: 500px;\n  font-size: 0.875rem;\n}\n\n.wc_icon_header {\n  width: 52px;\n  height: 32px;\n}\n\n.wc_icon_btn {\n  width: 30px;\n  height: 18px;\n}\n\n.wc_icon_modal {\n  width: 50px;\n  height: 50px;\n  border: 1px solid var(--th-border-color);\n  border-radius: 10px;\n  padding: 15px 9px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon_help_container": "Connector_icon_help_container__1iUxn",
	"wc_help_text": "Connector_wc_help_text__1vNFL",
	"wc_icon_header": "Connector_wc_icon_header__3X2zR",
	"wc_icon_btn": "Connector_wc_icon_btn__aGyOO",
	"wc_icon_modal": "Connector_wc_icon_modal__31hnE"
};
export default ___CSS_LOADER_EXPORT___;
