import { Fieldset, Form, Grid } from 'src/components'
import { BlockchainAsset } from 'src/types'

import { FieldSelectAsset } from '../../../../components'

interface ISessionRequestFormFields {
  asset?: BlockchainAsset
}

interface ISessionRequestFormProps {
  assets: BlockchainAsset[]
  fallbackAsset: BlockchainAsset | undefined
  recommendedAssets: BlockchainAsset[]
  onReset?(): void
  onSubmit?(data: ISessionRequestFormFields): void
}

export function SessionRequestForm(props: ISessionRequestFormProps) {
  const { assets, fallbackAsset, recommendedAssets, onReset, onSubmit } = props
  return (
    <Form<ISessionRequestFormFields>
      reset
      btnReset={{ children: 'Cancel', accent: true }}
      btnSubmit={{ children: 'Connect' }}
      onReset={onReset}
      onSubmit={onSubmit}
      allowSubmit={assets.length > 0 || fallbackAsset !== undefined}
    >
      <Choose>
        <When condition={assets.length === 0}>
          <div style={{ marginBottom: 12 }}>
            {renderNoSupportedAssetsMsg({
              recommended: recommendedAssets,
              fallback: fallbackAsset,
            })}
          </div>
        </When>

        <Otherwise>
          <Fieldset>
            <FieldSelectAsset<ISessionRequestFormFields, 'asset'>
              name="asset"
              options={assets}
              rules={{ required: true }}
              label={
                <div style={{ fontSize: '1rem' }}>
                  {assets.length > 1
                    ? 'Which of your assets you wish to connect?'
                    : 'You have only one asset supported by this chain'}
                </div>
              }
              disabled={assets.length < 2}
              defaultValue={assets[0]}
            />
          </Fieldset>
        </Otherwise>
      </Choose>
      <hr style={{ marginBottom: '1rem' }} />
    </Form>
  )
}

function renderNoSupportedAssetsMsg(props: {
  recommended: BlockchainAsset[]
  fallback: BlockchainAsset | undefined
}) {
  const { recommended, fallback } = props
  return (
    <Grid gap={8}>
      <div>You don't have any assets supported by this chain.</div>
      <Choose>
        <When condition={recommended.length > 0}>
          {/* TODO: would be very cool for user if they could add recommended assets in one click right here */}
          <div>
            You may add following assets to this wallet:{' '}
            {recommended.join(', ')}
          </div>
        </When>

        <Otherwise>
          <div
            style={{
              borderRadius: '8px',
              border: '1px solid #B54708',
              padding: '8px',
              backgroundColor: '#FFFAEB',
              color: '#B54708',
            }}
          >
            Seems like we don't support this chain for now.
            <If condition={fallback !== undefined}>
              <br />
              {/* Logic see here: {@link https://dcspoc.atlassian.net/browse/PLT-403?focusedCommentId=10560} */}
              If you wish to proceed, we will try to connect your{' '}
              <b>{fallback}</b> wallet to it.
            </If>
          </div>
        </Otherwise>
      </Choose>
    </Grid>
  )
}
