import { Badge, RTQSuspender, Tooltip } from 'src/components'
import { getAssetFullName } from 'src/utils'

import { WalletRTQApi } from '../../../api'
import { CryptoLogo } from '../../../components'
import { IWalletListItem } from '../../../types'

import styles from './WalletAssets.module.scss'

export function WalletAssets(props: { wallet: IWalletListItem }) {
  const { wallet } = props

  const assetsState = WalletRTQApi.endpoints.getWalletAssets.useQuery(
    { wallet: wallet.address, provider: wallet.type },
    {
      selectFromResult: state => ({
        ...state,
        data: state.data?.assets,
      }),
    }
  )

  return (
    <RTQSuspender state={assetsState}>
      {assets => (
        <div className={styles.root}>
          {assets.map(x => {
            const { balance, asset_symbol: asset } = x
            const hasBalance = +balance > 0
            return (
              <Tooltip
                key={asset}
                placement="bottom"
                overlay={
                  <div className={styles.crypto_tooltip}>
                    <b>
                      {getAssetFullName(asset)} ({asset})
                    </b>
                    <div>Balance: {balance}</div>
                  </div>
                }
                destroyTooltipOnHide
              >
                <Badge
                  size="sm"
                  border
                  variant={hasBalance ? 'success' : undefined}
                  className={styles.crypto_badge}
                >
                  <CryptoLogo asset={asset} className={styles.crypto_logo} />
                </Badge>
              </Tooltip>
            )
          })}
        </div>
      )}
    </RTQSuspender>
  )
}
