import React, { createContext, useMemo, useRef, useState } from 'react'
import { BsFillArrowUpCircleFill } from 'react-icons/bs'

import clsx from 'clsx'

import { useSwitch } from 'src/hooks'

import { IAccordionContext, IAccordionProps } from './types'

import styles from './styles.module.scss'

export const AccordionContext = createContext<IAccordionContext>({
  isOpen: false,
  iconSize: 0,
  iconGap: 0,
  bodyMaxHeight: 0,
})

export function Accordion(props: IAccordionProps) {
  const {
    tag: Tag = 'div',
    children,
    header,
    lazy = true,
    isOpen: isOpenControlled,
    defaultOpen = false,
    onToggle,
    bodyMaxHeight,
    className,
    style,
  } = props

  const [isOpen, toggle] = useSwitch(defaultOpen, isOpenControlled, onToggle)
  const [isToggleTransitionFinished, setIsToggleTransitionFinished] =
    useState(defaultOpen)

  const refWasOpen = useRef(isOpen)
  if (isOpen) {
    refWasOpen.current = true
  }

  const context = useMemo(
    (): IAccordionContext => ({
      bodyMaxHeight: bodyMaxHeight ?? styles.default_body_max_height,
      iconSize: styles.icon_size,
      iconGap: styles.icon_gap,
      isOpen,
    }),
    [isOpen, bodyMaxHeight]
  )

  return (
    <Tag
      className={clsx(styles.accordion, className, {
        [styles.open]: isOpen,
        [styles.opened]: isOpen && isToggleTransitionFinished,
      })}
      style={
        {
          ...style,
          '--accordion-body-max-height':
            typeof bodyMaxHeight === 'number'
              ? `${bodyMaxHeight}px`
              : bodyMaxHeight,
        } as typeof style
      }
    >
      <AccordionContext.Provider value={context}>
        <div
          className={styles.header}
          onClick={() => {
            setIsToggleTransitionFinished(false)
            toggle()
          }}
        >
          {header}
          <BsFillArrowUpCircleFill className={styles.icon} size={24} />
        </div>

        <div
          className={styles.body}
          onTransitionEnd={() => {
            if (isOpen) {
              setIsToggleTransitionFinished(true)
            }
          }}
        >
          {!lazy || refWasOpen.current ? children : null}
        </div>
      </AccordionContext.Provider>
    </Tag>
  )
}

Accordion.Context = AccordionContext
