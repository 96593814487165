// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AssetsList_table__4t7fE {\n  background-color: var(--th-clr-bg-body);\n}\n.AssetsList_table__4t7fE tbody tr {\n  background-color: var(--th-clr-white);\n}\n.AssetsList_table__4t7fE .AssetsList_static_row__2F07N {\n  background-color: var(--th-clr-info-50);\n}\n\n.AssetsList_cell_name__30Hc9 {\n  max-width: 200px;\n}\n\n.AssetsList_cell_txt_accent__2w5GX {\n  font-size: 0.875rem;\n  font-weight: 500;\n  line-height: 1.43;\n}", "",{"version":3,"sources":["webpack://src/apps/cabinet/features/Wallet/pages/AddressBook/AssetsList/AssetsList.module.scss"],"names":[],"mappings":"AAAA;EACE,uCAAA;AACF;AACE;EACE,qCAAA;AACJ;AAEE;EACE,uCAAA;AAAJ;;AAIA;EACE,gBAAA;AADF;;AAIA;EACE,mBAAA;EACA,gBAAA;EACA,iBAAA;AADF","sourcesContent":[".table {\n  background-color: var(--th-clr-bg-body);\n\n  tbody tr {\n    background-color: var(--th-clr-white);\n  }\n\n  .static_row {\n    background-color: var(--th-clr-info-50);\n  }\n}\n\n.cell_name {\n  max-width: 200px;\n}\n\n.cell_txt_accent {\n  font-size: 0.875rem;\n  font-weight: 500;\n  line-height: 1.43;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "AssetsList_table__4t7fE",
	"static_row": "AssetsList_static_row__2F07N",
	"cell_name": "AssetsList_cell_name__30Hc9",
	"cell_txt_accent": "AssetsList_cell_txt_accent__2w5GX"
};
export default ___CSS_LOADER_EXPORT___;
