import React from 'react'
import { MdOutlineCurrencyExchange as IconTransaction } from 'react-icons/md'

import theme from 'src/styles/theme.module.scss'

import { AgreementType, INotification, NotificationType } from '../../../types'
import { Icon } from '../../base'
import { AgreementTypeIcon } from '../AgreementIcon'

export function CellOverview(props: { item: INotification }) {
  const { item } = props
  switch (item.notificationType) {
    case NotificationType.Wallet:
      return (
        <>
          <Icon type="wallet" size="1.25em" />
          Wallet update
        </>
      )

    case NotificationType.NodeAcquisition:
      return (
        <>
          <AgreementTypeIcon type={AgreementType.NodeAcquisition} />
          <div>
            <div>Contract update</div>
            <div style={{ color: theme.th_clr_txt_secondary }}>
              Node Acquisition
            </div>
          </div>
        </>
      )

    case NotificationType.SafeheronAcquisition:
    case NotificationType.DfnsAcquisition: {
      const isDFNS = item.notificationType === NotificationType.DfnsAcquisition
      return (
        <>
          <AgreementTypeIcon
            type={
              isDFNS
                ? AgreementType.DfnsAcquisition
                : AgreementType.SafeheronAcquisition
            }
          />
          <div>
            <div>Contract update</div>
            <div style={{ color: theme.th_clr_txt_secondary }}>
              {isDFNS ? 'DFNS Acquisition' : 'Safeheron Acquisition'}
            </div>
          </div>
        </>
      )
    }

    case NotificationType.Txn:
      return (
        <>
          <Icon type={IconTransaction} size="1.25em" />
          Transaction completed
        </>
      )

    default:
      return null
  }
}
