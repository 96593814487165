import { FaFileSignature, FaHandHoldingUsd } from 'react-icons/fa'
import { HiClipboardCheck } from 'react-icons/hi'
import { RiFileLockLine } from 'react-icons/ri'
import { TbFileX } from 'react-icons/tb'

import { AgreementStatus } from 'src/types'
import { getAgreementStatusLabel } from 'src/utils'

import { Flex } from '../../layouts'

export interface IAgreementStatusIconProps {
  status: AgreementStatus
  showTitle?: boolean
  size?: number | string
  color?: string
}

export function AgreementStatusIcon(props: IAgreementStatusIconProps) {
  const { status, size = '1.25em', color, showTitle } = props
  const Component = getIconComponent(status)
  return (
    <Flex align="center" inline gap="0.5em">
      <Component size={size} color={color} style={{ flexShrink: 0 }} />
      {showTitle && getAgreementStatusLabel(status)}
    </Flex>
  )
}

function getIconComponent(status: AgreementStatus) {
  switch (status) {
    case AgreementStatus.Proposed:
      return FaHandHoldingUsd
    case AgreementStatus.Verified:
      return HiClipboardCheck
    case AgreementStatus.Signed:
      return FaFileSignature
    case AgreementStatus.Notarised:
      return RiFileLockLine
    case AgreementStatus.Declined:
      return TbFileX
    default:
      return () => null
  }
}
