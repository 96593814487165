import { ReactElement } from 'react'
import { useWatch } from 'react-hook-form'

import {
  Badge,
  ErrorMessage,
  FieldRadio,
  Flex,
  FormProvider,
  SuspendableProvider,
  useForm,
} from 'src/components'
import { useOnChange } from 'src/hooks'
import { BlockchainAsset } from 'src/types'

import { WalletRTQApi } from '../../../../api'
import { CryptoLogo } from '../../../../components'
import { IWallet, IWalletListItem } from '../../../../types'
import { useWalletAssetsApp } from '../reducer'

// ---

export interface IFiltersState {
  asset: BlockchainAsset
}

interface IFiltersForm {
  asset?: BlockchainAsset
}

interface IFiltersProps {
  wallet: IWallet
  onChange?(filters: IFiltersState): void
}

// ---

export function Filters(props: IFiltersProps) {
  const { wallet, onChange } = props
  const {
    state: { txns: initialState },
  } = useWalletAssetsApp()

  const form = useForm<IFiltersForm>({
    defaultValues: {
      asset: initialState.asset,
    },
  })

  useOnChange(useWatch({ control: form.control }), form => {
    const { asset } = form as IFiltersForm
    if (asset !== undefined) {
      onChange?.({ asset })
    }
  })

  return (
    <FormProvider {...form}>
      <AssetsProvider wallet={wallet}>
        {assets => (
          <FieldRadio<IFiltersForm, 'asset'>
            label="Select an asset to show transactions for:"
            name="asset"
            data={assets}
            renderOption={({ option, checked, $label, $input }) => (
              <label style={{ cursor: 'pointer' }}>
                <Badge
                  border
                  variant={checked ? 'success' : undefined}
                  style={{ gap: '0.5em' }}
                >
                  <CryptoLogo asset={option as BlockchainAsset} />
                  <b>{$label}</b>
                  <span style={{ display: 'none' }}>{$input}</span>
                </Badge>
              </label>
            )}
            layout={$els => (
              <Flex wrap style={{ rowGap: '0.5rem' }}>
                {$els}
              </Flex>
            )}
          />
        )}
      </AssetsProvider>
    </FormProvider>
  )
}

function AssetsProvider(props: {
  wallet: IWalletListItem
  children: (xs: BlockchainAsset[]) => ReactElement
}) {
  const { wallet, children } = props
  const state = WalletRTQApi.endpoints.getWalletAssets.useQuery(
    {
      provider: wallet.type,
      wallet: wallet.address,
    },
    {
      selectFromResult: result => ({
        ...result,
        data: result.data?.assets.map(x => x.asset_symbol).sort(),
      }),
    }
  )

  if (state.error !== undefined) {
    return <ErrorMessage>{state.error}</ErrorMessage>
  }

  return (
    <SuspendableProvider suspended={state.isFetching}>
      {children(state.data ?? [])}
    </SuspendableProvider>
  )
}
