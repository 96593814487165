import { useEffect, useReducer, useRef } from 'react'

import { useAsync, useAsyncUnwrap } from 'src/hooks'

import { WCManager } from './Manager'
import { IConnectorOptions, IWCFacade } from './types'

type IHookOptions = Pick<IConnectorOptions, 'storageId'>

export function useWalletConnect(options: IHookOptions) {
  const [, forceUpdate] = useReducer(x => x + 1, 0)
  const refInstance = useRef<IWCFacade>()

  const asyncData = useAsyncUnwrap(
    useAsync(async (uri: IConnectorOptions['uri']): Promise<IWCFacade> => {
      let instance = refInstance.current
      if (instance === undefined) {
        try {
          instance = await WCManager.init({ ...options, uri })
          instance.onUpdate(forceUpdate)
          refInstance.current = instance
        } catch (e) {
          instance?.teardown()
          throw e
        }
      } else if (uri !== undefined) {
        await instance.setURI(uri)
      }
      return instance
    })
  )

  const [{ value: wc }, createClient] = asyncData

  useEffect(() => {
    if (wc === undefined) {
      /*
       * Initially, create without explicit uri,
       * allowing lib to try to search for a saved session
       */
      createClient(undefined)
    }
    return () => {
      wc?.teardown()
    }
  }, [wc, createClient])

  return asyncData
}
