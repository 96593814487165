import React, { ReactNode } from 'react'

import clsx from 'clsx'

import { Button, Icon, Tooltip } from 'src/components'

import styles from './styles.module.scss'

interface IBtnLogoutProps extends IStyled {
  onClick?(): void
  text?: ReactNode
  title?: ReactNode
  iconSize?: string | number
}

export function BtnLogout(props: IBtnLogoutProps) {
  const {
    onClick,
    className,
    style,
    text = 'Log out',
    title,
    iconSize = 20,
  } = props

  const $btn = (
    <Button
      variant="tertiary"
      className={clsx(styles.root, className)}
      onClick={onClick}
      style={style}
    >
      <Icon type="logout" size={iconSize} /> {text}
    </Button>
  )

  if (title === undefined) {
    return $btn
  }

  return (
    <Tooltip overlay="Log out" placement="top" autoWrap={false}>
      {$btn}
    </Tooltip>
  )
}
