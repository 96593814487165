// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/react-scripts/node_modules/css-loader/dist/cjs.js??ref--5-oneOf-7-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../node_modules/resolve-url-loader/index.js??ref--5-oneOf-7-3!../../../../node_modules/react-scripts/node_modules/sass-loader/dist/cjs.js??ref--5-oneOf-7-4!../../layouts/Box/Box.module.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IconButton_btn_icon__3AqiK {\n  --box-pad-def: 2px;\n  border-radius: min(25%, var(--th-border-radius));\n  flex-shrink: 0;\n}\n.IconButton_btn_icon__3AqiK .IconButton_spinner__2Qs3A {\n  --spinner-color: currentcolor;\n}", "",{"version":3,"sources":["webpack://src/components/base/Button/IconButton.module.scss"],"names":[],"mappings":"AAAA;EAEE,kBAAA;EACA,gDAAA;EAEA,cAAA;AAAF;AAEE;EACE,6BAAA;AAAJ","sourcesContent":[".btn_icon {\n  composes: box from \"../../layouts/Box/Box.module.scss\";\n  --box-pad-def: 2px;\n  border-radius: min(25%, var(--th-border-radius));\n\n  flex-shrink: 0;\n\n  .spinner {\n    --spinner-color: currentcolor;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn_icon": "IconButton_btn_icon__3AqiK " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["box"] + "",
	"spinner": "IconButton_spinner__2Qs3A"
};
export default ___CSS_LOADER_EXPORT___;
