// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProductTier_root__3Pa6T {\n  display: grid;\n  grid-gap: 2rem;\n  gap: 2rem;\n  grid-template-rows: auto 1fr auto;\n  padding: 1.5rem;\n  border-radius: 20px;\n  border: 1px solid var(--th-border-color);\n  width: 395px;\n  height: 320px;\n}\n\n.ProductTier_desc__KwJvC {\n  font-size: 0.875rem;\n  color: var(--th-clr-txt-hint);\n}\n.ProductTier_desc__KwJvC:empty {\n  display: none;\n}", "",{"version":3,"sources":["webpack://src/apps/cabinet/features/Bidding/pages/Bidding/ProductTier.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,cAAA;EAAA,SAAA;EACA,iCAAA;EAEA,eAAA;EACA,mBAAA;EACA,wCAAA;EAEA,YAAA;EACA,aAAA;AADF;;AAIA;EACE,mBAAA;EACA,6BAAA;AADF;AAGE;EACE,aAAA;AADJ","sourcesContent":[".root {\n  display: grid;\n  gap: 2rem;\n  grid-template-rows: auto 1fr auto;\n\n  padding: 1.5rem;\n  border-radius: 20px;\n  border: 1px solid var(--th-border-color);\n\n  width: 395px;\n  height: 320px;\n}\n\n.desc {\n  font-size: 0.875rem;\n  color: var(--th-clr-txt-hint);\n\n  &:empty {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "ProductTier_root__3Pa6T",
	"desc": "ProductTier_desc__KwJvC"
};
export default ___CSS_LOADER_EXPORT___;
