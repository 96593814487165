import {
  ControlSettings,
  FieldInput,
  Fieldset,
  Form,
  IFormProps,
  Icon,
  useModalConfirm,
} from 'src/components'

import { WalletRTQApi } from '../../../api'
import { FieldSelectAsset } from '../../../components'
import { ICreateFavoriteAssetPayload } from '../../../types'

import { useSupportedAssets } from './useSupportedAssets'

interface IFavAssetFormFields extends ICreateFavoriteAssetPayload {}

export function NewAssetForm(
  props: Pick<
    IFormProps<IFavAssetFormFields>,
    'onSubmit' | 'id' | 'showDefaultControls'
  >
) {
  const { supportedAssets, isFetching: isFetchingSupportedAssets } =
    useSupportedAssets()

  const { onSubmit, ...rest } = props

  const [createAsset] = WalletRTQApi.endpoints.addFavoriteAsset.useMutation()

  return (
    <Form<IFavAssetFormFields>
      {...rest}
      onSubmit={async (fields, ...rest) => {
        await createAsset(fields).unwrap()
        onSubmit?.(fields, ...rest)
      }}
    >
      <ControlSettings layout="150px 1fr" gap="2rem">
        <Fieldset>
          <FieldInput<IFavAssetFormFields, 'name'>
            name="name"
            label="Recipient Description"
            placeholder="e.g. Ledger, Satoshi Nakamoto, etc."
            rules={{ required: true }}
          />

          <FieldInput<IFavAssetFormFields, 'address'>
            name="address"
            label="Recipient Address"
            placeholder="Paste address here..."
            rules={{ required: true }}
          />

          <FieldSelectAsset<IFavAssetFormFields, 'asset_symbol'>
            name="asset_symbol"
            label="Asset Network"
            options={supportedAssets}
            loading={isFetchingSupportedAssets}
            rules={{ required: true }}
          />
        </Fieldset>
      </ControlSettings>
    </Form>
  )
}

export function useNewAssetFormModal() {
  const FORM_ID = 'new-asset-form'
  /* TODO: should display form submitting state in modal somehow */
  return useModalConfirm(
    {
      title: 'Add Recipient Address',
      subtitle: "Fill out the fields below to save a new recipient's address.",
      style: { content: { width: 700 } },
      icon: <Icon type="book" box size="md" />,
      btnConfirm: { children: 'Add Address' },
      form: FORM_ID,
    },
    ({ confirm }) => (
      <NewAssetForm
        id={FORM_ID}
        onSubmit={confirm}
        showDefaultControls={false}
      />
    )
  )
}
