// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_btn_new_agreement__S6r31 {\n  width: 200px;\n  align-self: start;\n}\n\n.styles_photo_container__2bDxu {\n  --size: 40px;\n  width: var(--size);\n  height: var(--size);\n}\n.styles_photo_container__2bDxu img {\n  width: 100%;\n  height: 100%;\n}\n\n.styles_row__zkrwd:target {\n  background-color: var(--th-clr-warn-50);\n  --table-row-border-color: var(--th-clr-warn-500);\n}", "",{"version":3,"sources":["webpack://src/apps/cabinet/features/Advisor/pages/AvatarDetails/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,iBAAA;AACF;;AAEA;EACE,YAAA;EACA,kBAAA;EACA,mBAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;AACJ;;AAIE;EACE,uCAAA;EACA,gDAAA;AADJ","sourcesContent":[".btn_new_agreement {\n  width: 200px;\n  align-self: start;\n}\n\n.photo_container {\n  --size: 40px;\n  width: var(--size);\n  height: var(--size);\n\n  img {\n    width: 100%;\n    height: 100%;\n  }\n}\n\n.row {\n  &:target {\n    background-color: var(--th-clr-warn-50);\n    --table-row-border-color: var(--th-clr-warn-500);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn_new_agreement": "styles_btn_new_agreement__S6r31",
	"photo_container": "styles_photo_container__2bDxu",
	"row": "styles_row__zkrwd"
};
export default ___CSS_LOADER_EXPORT___;
