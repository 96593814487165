import { createApi as createRTQApi } from '@reduxjs/toolkit/query/react'

import { flow, map } from 'lodash/fp'

import { RTQTag, axiosBaseQuery, rtqTags } from 'src/api'
import {
  AgreementType,
  IAgreement,
  IAgreementNodeAcquisition,
  IAgreementRaw,
  IAgreementWalletAcquisition,
  IBaseAgreement,
} from 'src/types'
import { sortNewest, toFormData } from 'src/utils'

import { IAgreementPayload, IInvestorAvatar, IInvestorAvatarRaw } from './types'

// ---

const tags = rtqTags(['Avatar', 'Agreement'])

export const AdvisorApi = createRTQApi({
  reducerPath: 'advisor_api',
  baseQuery: axiosBaseQuery({
    baseURL: 'adviser',
  }),
  tagTypes: tags.toArray(),
  endpoints: builder => ({
    getAvatars: builder.query<IInvestorAvatar[], void>({
      query: () => 'avatars',
      providesTags: tags.Avatar.providesList,
      transformResponse(xs: IInvestorAvatarRaw[]) {
        return xs.map(x => ({
          id: x.id.toString(),
          name: x.name,
          photo: x.photoURL,
        }))
      },
    }),

    getAvatarAgreements: builder.query<IAgreement[], IInvestorAvatar['id']>({
      query: id => `agreements?id=${id}`,
      providesTags: tags.Agreement.providesList,
      transformResponse: flow([map(parseAgreement), sortNewest]),
    }),
    getAgreementDetails: builder.query<
      IAgreement,
      Pick<IAgreement, 'id' | 'type'>
    >({
      query: ({ id, type }) => `agreements/${type}/${id}`,
      providesTags: tags.Agreement.providesOne,
      transformResponse: parseAgreement,
    }),

    createAgreement: builder.mutation<IAgreement, IAgreementPayload>({
      query: data => ({
        url: 'agreements',
        method: 'POST',
        data: composeAgreementPayload(data),
      }),
      invalidatesTags: (result, error, data) => {
        const xs = [tags.Agreement.list() as RTQTag]
        // If new acquisition contract was created,
        // it means this advisor now has a new investor to work with.
        // So have to refresh the list.
        if (
          error === undefined &&
          data.agreementType === AgreementType.NodeAcquisition
        ) {
          xs.push(tags.Avatar.list())
        }
        return xs
      },
      transformResponse: parseAgreement,
    }),
  }),
})

function parseAgreement(x: IAgreementRaw): IAgreement {
  const base: Omit<IBaseAgreement, 'investment' | 'fee'> = {
    id: x.agreementID,
    date: x.agreementDate,
    type: x.agreementType,
    status: x.status,
    sender: x.adviser,
    receiver: x.avatar,
    attachment: x.filePath,
    decline_reason: x.declineReason,
    decline_by: x.declinedBy,
  }
  switch (x.agreementType) {
    case AgreementType.SafeheronAcquisition:
    case AgreementType.DfnsAcquisition: {
      const result: IAgreementWalletAcquisition = {
        ...base,
        type: x.agreementType,
        investment: +x.investment,
        fee: x.fee,
      }
      return result
    }
    case AgreementType.NodeAcquisition: {
      const result: IAgreementNodeAcquisition = {
        ...base,
        type: x.agreementType,
        fee: x.fee,
      }
      return result
    }

    // TODO: not implemented in PoC app
    case AgreementType.ManagedPortfolio: {
      return base as IAgreement
    }
  }
}

const composeAgreementPayload = flow((x: IAgreementPayload) => {
  const base = {
    agreementType: x.agreementType,
    avatar: x.avatar,
  }
  switch (x.agreementType) {
    case AgreementType.SafeheronAcquisition: {
      return {
        ...base,
        investment: x.investment,
        fee: `${x.fee}%`, // blockchain requires this format
      }
    }
    case AgreementType.NodeAcquisition: {
      return {
        ...base,
        fee: x.fee,
        pdfFile: x.pdfFile,
      }
    }
  }
}, toFormData)
