import { DateTime } from './base'

export enum AgreementStatus {
  Proposed = 'PROPOSED',
  Signed = 'SIGNED',
  Verified = 'VERIFIED',
  Notarised = 'NOTARIZED',
  Declined = 'DECLINED',
}

export enum AgreementType {
  NodeAcquisition = 'NODE_ACQUISITION',
  SafeheronAcquisition = 'SAFEHERON_ACQUISITION',
  DfnsAcquisition = 'DFNS_ACQUISITION',
  // TODO:
  ManagedPortfolio = 'MANAGED_PORTFOLIO',
}

export interface IAgreementRaw {
  filePath: string
  agreementType: AgreementType
  agreementID: string
  agreementDate: string
  adviser: string
  avatar: string
  platform: string
  investment: string
  fee: string
  status: AgreementStatus
  declinedBy: string
  declineReason: string
}

export interface IBaseAgreement {
  id: string
  type: AgreementType
  status: AgreementStatus
  date: DateTime

  investment: number // TODO: probably `currency` will be a separate field in future
  fee: string // `xx%`

  sender: string // advisor username
  receiver: string // avatar username

  attachment: string // for upload – File (PDF)

  decline_reason?: string
  decline_by?: string

  // photo: string // sender's avatar, probably
  // description: string
}

export interface IAgreementWalletAcquisition extends IBaseAgreement {
  type: AgreementType.SafeheronAcquisition | AgreementType.DfnsAcquisition
}

export interface IAgreementNodeAcquisition
  extends Omit<IBaseAgreement, 'investment'> {
  type: AgreementType.NodeAcquisition
}

// TODO: not implemented in PoC
export interface IAgreementPortfolio extends IBaseAgreement {
  type: AgreementType.ManagedPortfolio
}

export type IAgreement =
  | IAgreementWalletAcquisition
  | IAgreementNodeAcquisition
  | IAgreementPortfolio
