import React, { ReactNode } from 'react'
import { GiGearHammer } from 'react-icons/gi'

import { Flex } from '../../layouts'

import styles from './styles.module.scss'

export interface IDevSectionProps {
  children?: ReactNode
  iconSize?: number
}

export function InDev(props: IDevSectionProps) {
  const { children, iconSize = 50 } = props

  const $msg = (
    <>
      <GiGearHammer size={iconSize} />
      <svg viewBox="0 0 220 30">
        <text x={0} y={15} dominantBaseline="central" fill="currentColor">
          Section under development
        </text>
      </svg>
    </>
  )

  if (children === undefined) {
    return <Flex align="center">{$msg}</Flex>
  }

  return (
    <div className={styles.root}>
      {children}
      <Flex align="center" justify="center" className={styles.overlay}>
        {$msg}
      </Flex>
    </div>
  )
}
