import { useAsync } from '@react-hook/async'
import { ReactNode } from 'react'

import { Button, Loader } from 'src/components'

import { ProviderFeatures } from './ProviderFeatures'

import styles from './WalletConfirmCard.module.scss'

export interface IWalletConfirmCardProps {
  walletName: string
  providerName: string
  logo: string
  bg: string
  description: string
  features: string[]
  onSubmit(): void
}

export const WalletConfirmCard = (props: IWalletConfirmCardProps) => {
  const {
    walletName,
    providerName,
    description,
    logo,
    bg,
    features,
    onSubmit,
  } = props

  return (
    <div className={styles.root}>
      <div className={styles.card} style={{ backgroundImage: `url(${bg})` }}>
        <header className={styles.wallet_name}>{walletName}</header>
        <img className={styles.logo} src={logo} alt="Logo card" />
      </div>
      <div className={styles.main}>
        <main>
          <h2 className={styles.provider_name}>{providerName}</h2>
          <p className={styles.description}>{description}</p>
          <h3 className={styles.features_header}>What you’ll get</h3>
          <ProviderFeatures features={features} />
        </main>
        <BtnSubmit onClick={onSubmit}>Install {providerName}</BtnSubmit>
      </div>
    </div>
  )
}

function BtnSubmit(props: { onClick(): void; children: ReactNode }) {
  const { onClick, children } = props
  const [state, handler] = useAsync(onClick as () => Promise<void>)
  const isLoading = state.status === 'loading'
  return (
    <Button
      startIcon={isLoading && <Loader size="1em" color="currentColor" />}
      onClick={handler}
      disabled={isLoading}
    >
      {children}
    </Button>
  )
}
