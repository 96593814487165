import { useSelector } from 'src/apps/cabinet'
import { Button, Page } from 'src/components'
import { selectUser } from 'src/features/User'

import startLogoImg from './img/start-logo.png'

import styles from './styles.module.scss'

interface IStartStepProps {
  onSubmit(): void
}

export function StartStep({ onSubmit }: IStartStepProps) {
  const user = useSelector(selectUser)

  return (
    <Page
      hero={<img src={startLogoImg} alt="Start" style={{ minHeight: 342 }} />}
      title={`Let’s set up a wallet, ${user.username}`}
      description={
        <div className={styles.description}>
          Get started with your first wallet! Follow the step-by-step process to
          complete the setup and start managing your funds with confidence.
        </div>
      }
      alignHeader="center"
      alignContent="center"
    >
      <Button onClick={onSubmit}>Get started</Button>
    </Page>
  )
}
