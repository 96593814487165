import { Card, Link, Page } from 'src/components'

import { AgreementTypesList } from '../../components'
import { buildAgreementFormLink } from '../../lib'

export function AgreementTemplatesPage() {
  return (
    <Page title="Agreement templates" alignContent="center">
      <Card style={{ width: 'max(50%, 400px)' }}>
        <AgreementTypesList
          renderItem={(type, props) => (
            <Link
              {...props}
              variant="none"
              to={buildAgreementFormLink({ type })}
            />
          )}
          defaultItemClickable
        />
      </Card>
    </Page>
  )
}
