import { ReactElement, createElement } from 'react'

import {
  IUseConfirm,
  IUseConfirmEmptyOptions,
  IUseConfirmOptions,
  useConfirm,
} from 'src/hooks'

import { ModalConfirm } from './ModalConfirm'
import { IModalConfirmProps } from './types'

// ---

type ModalProps = Omit<
  IModalConfirmProps,
  keyof IUseConfirmOptions | 'children'
>

type ConfirmModalMethods<T extends IUseConfirmOptions> = RenameKeys<
  IUseConfirm<T>,
  // To be compatible with methods of `useModal`
  { cancel: 'close' }
>

type ContentRenderer<T extends IUseConfirmOptions = IUseConfirmEmptyOptions> =
  | IModalConfirmProps['children']
  | ((
      props: Pick<ConfirmModalMethods<T>, 'close' | 'confirm'>
    ) => IModalConfirmProps['children'])

type IUseModalConfirm<T extends IUseConfirmOptions = IUseConfirmEmptyOptions> =
  ConfirmModalMethods<T> & {
    $element: ReactElement
  }

// ---

export function useModalConfirm(
  params: ModalProps,
  children?: ContentRenderer
): IUseModalConfirm

export function useModalConfirm<T extends IUseConfirmOptions>(
  params: ModalProps & T,
  children?: ContentRenderer<T>
): IUseModalConfirm<T>

// ---

export function useModalConfirm<T extends IUseConfirmOptions>(
  params: ModalProps & T,
  children?: ContentRenderer<T>
) {
  const { cancel: close, ...dialog } = useConfirm(params)
  const { form } = params
  return {
    ...dialog,
    close,
    $element: createElement(
      ModalConfirm,
      {
        ...params,
        onCancel: close,
        onConfirm: form ? undefined : dialog.confirm,
        isOpen: dialog.isOpen,
      },
      typeof children === 'function'
        ? children({ close, confirm: dialog.confirm })
        : children
    ),
  }
}
