import { forwardRef } from 'react'

import clsx from 'clsx'

import stylesTheme from 'src/styles/theme.module.scss'
import { mergeStyles } from 'src/utils'

import { useBoxSize } from '../../layouts/Box'

import { useIconSettingsContext } from './SettingsContext'
import { getIconByType } from './icons'
import { resolveColor } from './lib'
import { IIconProps } from './types'

import styles from './Icon.module.scss'

export const Icon = forwardRef<HTMLDivElement, IIconProps>(function Icon(
  props,
  ref
) {
  const { type, disabled, color, size, box, className, style, ...rest } = {
    ...useIconSettingsContext(),
    ...props,
  }

  const boxParams = useBoxSize(size)
  const Component = typeof type === 'string' ? getIconByType(type) : type

  if (!Component) return null

  return (
    <div
      className={clsx(className, styles.icon, boxParams.sizeClassName, {
        [styles.box]: box,
      })}
      ref={ref}
      style={mergeStyles(style, {
        '--icon-size': boxParams.size,
        color: disabled
          ? stylesTheme.th_clr_disabled
          : resolveColor(color, type),
      })}
    >
      <Component {...rest} />
    </div>
  )
})
