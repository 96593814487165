import { createSlice } from '@reduxjs/toolkit'

import { IAdvisorState } from './types'

const initialState: IAdvisorState = {
  loading: false,
  error: undefined,
}

export const AdvisorSlice = createSlice({
  name: 'advisor',
  initialState,

  reducers: {},
})
