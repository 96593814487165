import { AiFillCheckCircle } from 'react-icons/ai'
import { BiRefresh } from 'react-icons/bi'
import { BsCoin, BsFillInfoCircleFill } from 'react-icons/bs'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import { FiExternalLink, FiEye } from 'react-icons/fi'
import { IoIosCopy } from 'react-icons/io'
import {
  MdAddCard,
  MdAddLocationAlt,
  MdCancel,
  MdClear,
  MdOutlineModeEditOutline,
} from 'react-icons/md'
import { TbTrash } from 'react-icons/tb'

import { IIconType } from '../types'

import { ReactComponent as IconArrowNarrowDown } from './arrow-narrow-down.svg'
import { ReactComponent as IconArrowNarrowLeft } from './arrow-narrow-left.svg'
import { ReactComponent as IconArrowNarrowRight } from './arrow-narrow-right.svg'
import { ReactComponent as IconArrowNarrowUp } from './arrow-narrow-up.svg'
import { ReactComponent as IconBook } from './book.svg'
import { ReactComponent as IconCheck } from './check.svg'
import { ReactComponent as IconDownload } from './download.svg'
import { ReactComponent as IconEmptyList } from './empty-list.svg'
import { ReactComponent as IconLogout } from './logout.svg'
import { ReactComponent as IconNotification } from './notification.svg'
import { ReactComponent as IconUpload } from './upload.svg'
import { ReactComponent as IconWallet } from './wallet.svg'
import { ReactComponent as IconWalletConnect } from './walletconnect.svg'

export function getIconByType(type: IIconType) {
  switch (type) {
    case 'check':
      return IconCheck
    case 'success':
    case 'confirm':
      return AiFillCheckCircle
    case 'error':
      return MdCancel
    case 'external':
      return FiExternalLink
    case 'refresh':
      return BiRefresh
    case 'close':
    case 'cancel':
      return MdClear
    case 'delete':
      return TbTrash
    case 'info':
      return BsFillInfoCircleFill
    case 'edit':
      return MdOutlineModeEditOutline
    case 'eye':
      return FiEye
    case 'logout':
      return IconLogout
    case 'copy':
      return IoIosCopy
    case 'upload':
      return IconUpload
    case 'download':
      return IconDownload
    case 'wallet':
      return IconWallet
    case 'walletconnect':
      return IconWalletConnect
    case 'book':
      return IconBook
    case 'add-card':
      return MdAddCard
    case 'location':
      return MdAddLocationAlt
    case 'coin':
      return BsCoin
    case 'chevron-left':
      return FaChevronLeft
    case 'chevron-right':
      return FaChevronRight
    case 'notification':
      return IconNotification
    case 'empty-list':
      return IconEmptyList

    case 'arrow-narrow-down':
      return IconArrowNarrowDown
    case 'arrow-narrow-up':
      return IconArrowNarrowUp
    case 'arrow-narrow-left':
      return IconArrowNarrowLeft
    case 'arrow-narrow-right':
      return IconArrowNarrowRight
  }
}
