import React, { useState } from 'react'

import clsx from 'clsx'

import { UserPhoto } from 'src/components'

import styles from './UserMenu.module.scss'

export function UserInfo(props: {
  img?: string
  username: string
  desc: string
}) {
  const { img, username, desc } = props

  return (
    <div className={styles.user}>
      <Photo
        src={img}
        fallback={username
          .split(' ')
          .map(x => x[0])
          .join('')}
      />

      <div className={styles.info}>
        <div className={styles.username}>{username}</div>
        <div className={styles.desc}>{desc}</div>
      </div>
    </div>
  )
}

function Photo(props: {
  src: string | undefined
  fallback: string
  isVerified?: boolean
}) {
  const { src, fallback, isVerified } = props
  const [hasError, setHasError] = useState(false)

  const className = clsx(styles.avatar, { [styles.verified]: isVerified })
  return (
    <Choose>
      <When condition={src === undefined || hasError}>
        <div className={clsx(className, styles.avatar_placeholder)}>
          {fallback}
        </div>
      </When>

      <Otherwise>
        <UserPhoto
          className={className}
          src={src as string}
          alt="avatar-icon"
          size={34}
          onError={() => {
            setHasError(true)
          }}
        />
      </Otherwise>
    </Choose>
  )
}
