import {
  Children,
  ReactElement,
  ReactNode,
  cloneElement,
  createContext,
  useContext,
} from 'react'

import clsx from 'clsx'

import styles from './styles.module.scss'

const SuspendableContext = createContext(false)

export function SuspendableProvider(props: {
  suspended: boolean
  children: ReactNode
}) {
  const { suspended, children } = props
  return (
    <SuspendableContext.Provider value={suspended}>
      {children}
    </SuspendableContext.Provider>
  )
}

export interface ISuspendableProps {
  suspended?: boolean
  children: ReactElement<{ className?: string }>
}

function resolveSuspendedClassName(isSuspended: boolean): string {
  return clsx(styles.suspendable, {
    [styles.suspended]: isSuspended,
  })
}

export function Suspendable(props: ISuspendableProps) {
  const { children, suspended: controlledSuspended } = props
  const child = Children.only(children)
  const defaultSuspended = useContext(SuspendableContext)
  const suspended = controlledSuspended ?? defaultSuspended
  return cloneElement(child, {
    className: clsx(
      child.props.className,
      resolveSuspendedClassName(suspended)
    ),
  })
}

Suspendable.resolveSuspendedClassName = resolveSuspendedClassName
