import { partition, sortBy } from 'lodash'

import { TAG_LIST, rtqPromisifyBaseQuery, rtqResolveQueryFn } from 'src/api'
import { BlockchainAddress, BlockchainAsset } from 'src/types'

import {
  IWallet,
  IWalletAssetPayload,
  IWalletAssetsListResponse,
  IWalletDefiAsset,
  IWalletDescriptor,
  WalletID,
  WalletProvider,
} from '../../types'

import { composeAssetId, tags } from './base'
import Base from './wallet'

const composeWalletAssetsListId = (wallet: WalletID) => `${TAG_LIST}/${wallet}`

const ApiWithSupportedAssets = Base.injectEndpoints({
  endpoints: builder => ({
    getWalletSupportedAssets: builder.query<BlockchainAsset[], WalletProvider>({
      query: provider => `wallet/${provider}/list/active/assets`,
    }),
  }),
})

export default ApiWithSupportedAssets.injectEndpoints({
  endpoints: builder => ({
    getWalletAssets: builder.query<
      IWalletAssetsListResponse,
      IWalletDescriptor
    >({
      queryFn: ({ provider, wallet }, rtq, extra, baseQuery) => {
        return rtqResolveQueryFn(async () => {
          const { getWalletSupportedAssets } = ApiWithSupportedAssets.endpoints

          const [assets_data, supported_assets] = await Promise.all([
            rtqPromisifyBaseQuery<IWalletAssetsListResponse>(
              baseQuery(`wallet/${provider}/${wallet}/assets`)
            ),

            rtq.dispatch(getWalletSupportedAssets.initiate(provider)).unwrap(),
          ])

          const [assetsWithBalance, assetsWithoutBalance] = partition(
            assets_data.assets,
            x => +x.balance > 0
          )

          return {
            ...assets_data,
            assets: [
              // Your biggest actives at start, the rest at end in alphabet order
              ...sortBy(assetsWithBalance, x => +x.balance).reverse(),
              ...sortBy(assetsWithoutBalance, x => x.asset_symbol),
            ],
            supported_assets,
          }
        })
      },
      providesTags: (result, err, { wallet }) => [
        ...tags.Asset.providesList(
          result?.assets.map(x =>
            composeAssetId({ wallet, asset: x.asset_symbol })
          )
        ),
        tags.Asset(composeWalletAssetsListId(wallet)),
      ],
    }),

    getWalletDefiAssets: builder.query<
      IWalletDefiAsset[],
      Pick<IWalletDescriptor, 'wallet'>
    >({
      query: ({ wallet }) => `defi/${wallet}/assets`,
    }),

    createWalletAsset: builder.mutation<IWallet, IWalletAssetPayload>({
      query: ({ provider, ...payload }) => ({
        url: `wallet/${provider}/asset`,
        method: 'POST',
        data: payload,
      }),
      invalidatesTags: (result, err, { request_uuid: wallet }) => [
        tags.Asset(composeWalletAssetsListId(wallet)),
      ],
    }),

    deleteWalletAsset: builder.mutation<void, IWalletAssetPayload>({
      query: ({ provider, ...payload }) => ({
        url: `wallet/${provider}/asset`,
        method: 'DELETE',
        data: payload,
      }),
      invalidatesTags: (result, err, payload) => {
        const { request_uuid: wallet } = payload
        const assetId = composeAssetId({
          wallet,
          asset: payload.asset_symbol,
        })
        return [
          tags.Asset(composeWalletAssetsListId(wallet)),
          tags.Asset(assetId),
          tags.AssetAddress(assetId),
        ]
      },
    }),

    getAssetAddress: builder.query<
      BlockchainAddress,
      IWalletDescriptor & {
        asset: BlockchainAsset
      }
    >({
      query: ({ provider, wallet, asset }) =>
        `wallet/${provider}/${wallet}/${asset}/address`,
      transformResponse(response: [{ asset_address: string }]) {
        return response[0].asset_address
      },
      providesTags: (result, err, params) => [
        tags.AssetAddress(composeAssetId(params)),
      ],
    }),
  }),
})
