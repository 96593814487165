import { mapValues } from 'lodash'

import { rtqTag } from './rtqTag'
import { TagType, TypedTagFunc } from './types'

type TagsSet<T extends Dict = Dict> = {
  [K in keyof T]: T[K] extends TagType ? TypedTagFunc<T[K]> : never
} & {
  toArray(): Values<T>[]
}

export function rtqTags<T extends TagType>(types: T[]): TagsSet<{ [K in T]: K }>

export function rtqTags<T extends Dict>(types: T): TagsSet<T>

/**
 * <pre>
 *   tags = rtqTags([ 'Foo', 'Bar' ])
 *   tags.Foo('some') // => { type: 'Foo', id: 'some' }
 *   tags.Foo.list()  // => { type: 'Foo', id: 'LIST' }
 *   tags.Bar.toString()  // => 'Bar'
 *   tags.toArray() // => ['Foo', 'Bar']
 *
 *   tags = rtqTags({ Foo: 'Bar' } as const)
 *   tags.Foo('some') // => { type: 'Bar', id: 'some' }
 *   tags.toArray() // => ['Bar']
 * </pre>
 */
export function rtqTags(arg: TagType[] | Dict<TagType>) {
  const dict = Array.isArray(arg)
    ? arg.reduce((m, x) => ({ ...m, [x]: x }), {})
    : arg

  if ('toArray' in dict) {
    throw new Error("Can't create tags dict: name 'toArray' is reserved")
  }

  const result = mapValues(dict, rtqTag.type)
  ;(result as TagsSet).toArray = () => Object.values(dict)
  return result
}
