import { FieldInput, Fieldset } from 'src/components'
import { AgreementType } from 'src/types'
import { validators } from 'src/validation'

import { IInvestorAvatar, INodeRentAgreementPayload } from '../../../types'

import { BaseAgreementForm } from './BaseAgreementForm'
import { FieldUploadContract } from './FieldUploadContract'
import { useOnSubmit } from './lib'

export function AgreementFormNodeRent(props: { avatars: IInvestorAvatar[] }) {
  const { avatars } = props
  const onSubmit = useOnSubmit(avatars)

  return (
    <BaseAgreementForm
      onSubmit={onSubmit}
      defaultValues={{
        agreementType: AgreementType.NodeAcquisition,
      }}
    >
      <Fieldset>
        <FieldInput<INodeRentAgreementPayload, 'avatar'>
          name="avatar"
          placeholder="Person that invited you to chat"
          rules={{
            ...validators.rulesRequiredString,
            validate(value) {
              if (avatars.some(x => x.name === value)) {
                return 'This person has already acquired a node from you'
              }
            },
          }}
        />

        <FieldInput<INodeRentAgreementPayload, 'fee', 'number'>
          type="number"
          name="fee"
          placeholder="Cost per month"
          rules={{
            required: true,
            min: 0,
          }}
        />

        <FieldUploadContract name="pdfFile" />
      </Fieldset>
    </BaseAgreementForm>
  )
}
