import { useSelector } from 'react-redux'
import { ExtractRouteParams } from 'react-router'
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom'

import { useAppSettings } from 'src/AppSettingsContext'

import { createAuthRedirectLocation } from '../../HistoryReferrer'
import { IUseRoleAccessOptions, useRoleAccess } from '../hooks'
import { selectIsGuest } from '../selectors'

interface RoleRouteOptions {
  roles?: IUseRoleAccessOptions['roles']
  rolesAccess?: IUseRoleAccessOptions['access']
  rolesMatch?: IUseRoleAccessOptions['match']
}

// ---

/* Generic params copied from Route interface in react-router */
interface AuthRouteProps<
  Path extends string = string,
  Params extends { [K: string]: string | undefined } = ExtractRouteParams<
    Path,
    string
  >
> extends RouteProps<Path, Params>,
    RoleRouteOptions {}

export const AuthRoute = <
  Path extends string = string,
  Params extends { [K: string]: string | undefined } = ExtractRouteParams<
    Path,
    string
  >
>(
  props: AuthRouteProps<Path, Params>
) => {
  const { roles, rolesAccess, rolesMatch, ...rest } = props

  const isGuest = useSelector(selectIsGuest)

  if (isGuest) {
    return <AuthRedirect />
  }

  if (roles === undefined) {
    return <Route {...(rest as RouteProps)} />
  }

  return <RoleRoute {...(props as RoleRouteProps)} />
}

// ---

interface RoleRouteProps extends AuthRouteProps {
  roles: NonNullable<AuthRouteProps['roles']>
}

const RoleRoute = (props: RoleRouteProps) => {
  const { roles, rolesAccess: access, rolesMatch: match, ...rest } = props
  const isAccessAllowed = useRoleAccess({ roles, access, match })
  const isGuest = useSelector(selectIsGuest)
  const { routes } = useAppSettings()

  if (isAccessAllowed) {
    return <Route {...rest} />
  }

  if (isGuest) {
    return <AuthRedirect />
  }

  // If we're authorized, but still have no access to page –
  // there is nothing more we can do. Just redirect to home page.
  return <Redirect to={routes.home} />
}

// ---

function AuthRedirect() {
  const loc = useLocation()
  const { routes } = useAppSettings()
  return (
    <Redirect
      to={
        !routes.login
          ? routes.home
          : createAuthRedirectLocation(routes.login, loc)
      }
    />
  )
}
