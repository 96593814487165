import { HTMLAttributes, ReactElement } from 'react'

import clsx from 'clsx'

import { Grid, ILinkProps, Link } from 'src/components'

import srcZapperLogo from './logo-zapper.png'

import styles from './PeerLink.module.scss'

function normalizePeerIconUrl(x: string, appName: string): string {
  // @link https://dcspoc.atlassian.net/browse/PLT-637?focusedCommentId=10791
  // Zapper does so many trash-y things with its icon, so it's way simpler to just serve our own copy of it.
  if (appName === 'Zapper') {
    return srcZapperLogo
  }

  // somehow, services may return weird link like "https://domain.org/data:image/..."
  const idx = x.indexOf('data:image/')
  if (idx > 0) {
    return x.slice(idx)
  }

  return x
}

export function PeerIcon(
  props: HTMLAttributes<unknown> & {
    src: string
    alt?: string
    appName: string
  }
) {
  const { src, alt, appName, ...rest } = props

  /* Some DApps (namely Zapper) instead of a link to their logo
   * provide an SVG SOURCE of that logo. For you to fck with it as you please. */
  const isSVGSource = src.indexOf('<svg') !== -1

  if (isSVGSource) {
    return (
      <div
        {...rest}
        className={clsx(rest.className, styles.fake_icon)}
        dangerouslySetInnerHTML={{ __html: src }}
      />
    )
  }

  return <img {...rest} src={normalizePeerIconUrl(src, appName)} alt={alt} />
}

export function PeerLink(
  props: Omit<ILinkProps, 'children' | 'to'> & {
    to: string
    appName: string
    appIcon?: ReactElement
  }
) {
  const { to, appName, appIcon, ...rest } = props

  /* Some DApps (namely Zapper) may not provide their url in meta description. */
  const isEmptyLink = to === ''

  if (isEmptyLink) {
    return (
      <Grid columns align="center" gap="0.5em" {...rest}>
        {appIcon} {appName}
      </Grid>
    )
  }

  return (
    <Link external {...rest} to={to} variant="secondary">
      {appIcon} {to.replace(/^https?:\/\//, '')}
    </Link>
  )
}
