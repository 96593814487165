import { memo, useContext } from 'react'

import { IconButton, useModalConfirm } from 'src/components'
import { useOnChange } from 'src/hooks'
import { BlockchainAsset } from 'src/types'

import { WalletRTQApi } from '../../../../api'
import { WalletContext } from '../../WalletContext'

import { SuspendedAssetsContext } from './Contexts'

export const ControlDeleteAsset = memo(function ControlDeleteAsset(props: {
  asset: BlockchainAsset
}) {
  const { asset } = props
  const updateSuspended = useContext(SuspendedAssetsContext)

  const [deletionDialog, { isLoading }] = useDeleteAssetDialog(asset)

  useOnChange(isLoading, isLoading => {
    updateSuspended(asset, isLoading)
  })

  return (
    <>
      <IconButton
        icon="delete"
        title={{
          overlay: 'Delete asset from wallet',
          destroyTooltipOnHide: true,
        }}
        onClick={deletionDialog.open}
        accent
      />
      {deletionDialog.$element}
    </>
  )
})

function useDeleteAssetDialog(asset: BlockchainAsset) {
  const wallet = useContext(WalletContext)

  const [deleteAsset, deletionState] =
    WalletRTQApi.endpoints.deleteWalletAsset.useMutation()

  const deletionDialog = useModalConfirm(
    {
      async onConfirm() {
        return deleteAsset({
          asset_symbol: asset,
          request_uuid: wallet.address,
          provider: wallet.provider,
        })
      },

      btnConfirm: { accent: true, children: 'Delete' },
      title: 'Delete asset',
    },
    `Delete asset '${asset}' from this wallet?`
  )

  return [deletionDialog, deletionState] as const
}
