import { IAgreement } from 'src/types'

import { WalletProvider } from '../Wallet/types'

export interface IStoreProduct {
  id: string
  name: string
  description: string
  merchant: WalletProvider
}

/* At DB level, tier names are arbitrary (for now, at least),
 * but design assumes we always have specifically bronze/silver/gold tiers.
 * Let's assume we'll have DB populated in this way. */
export enum ProductTierLevels {
  Bronze = 'bronze',
  Silver = 'silver',
  Gold = 'gold',
}

export interface IProductTier {
  id: string
  product_id: IStoreProduct['id']
  tier_name: string
  buy_now_price: number
}

export interface ITierDescriptor {
  product: IStoreProduct['id']
  tier: IProductTier['id']
}

export interface ILotState {
  id: string
  price: number
  winning_bid: boolean
  time_left: number // msec
}

export interface IAuctionWinnerResponse {
  agreement_id: IAgreement['id']
}

export interface IWSState {
  websocket_address: string
  lots_state?: ILotState[]
  agreementId?: IAgreement['id']
}

interface IWSMsgBase {
  type: string
  data: unknown
}

export interface IWSMsgLotsState extends IWSMsgBase {
  type: 'lots_state'
  data: ILotState[]
}

export interface IWSMsgVictory extends IWSMsgBase {
  type: 'won_lot'
  data: { agreement_id: IAgreement['id']; lot_id: ILotState['id'] }
}

export type IWSMsg = IWSMsgLotsState | IWSMsgVictory
