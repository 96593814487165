import { ReactHTML } from 'react'

import clsx from 'clsx'

import { resolveCSSSize } from 'src/utils'

import styles from './styles.module.scss'

export interface IUserPhotoProps {
  src: string
  alt?: string
  size?: number | string
  tag?: keyof ReactHTML
  className?: string
  onError?: () => void
}

export function UserPhoto(props: IUserPhotoProps) {
  const {
    tag: Tag = 'div',
    className = '',
    src,
    alt,
    size = 20,
    onError,
  } = props
  return (
    <Tag
      className={clsx(styles.user_photo, className)}
      style={{ ['--size' as string]: resolveCSSSize(size) }}
    >
      <img src={src} alt={alt} onError={onError} />
    </Tag>
  )
}
