import { matchPath } from 'react-router-dom'

import { NavItem, Navigation } from 'src/apps/cabinet/components'
import { routes as allRoutes } from 'src/apps/cabinet/routes'

const routes = allRoutes.avatar

export function AvatarNavigation() {
  return (
    <Navigation>
      <NavItem
        link={routes.dashboard}
        exact={false}
        isActive={(match, location) => {
          const matches = (params: Parameters<typeof matchPath>[1]) =>
            matchPath(location.pathname, params) !== null

          return (
            matches({
              exact: true,
              path: routes.dashboard,
            }) ||
            matches({
              exact: false,
              path: routes.wallet.wallet_details,
            })
          )
        }}
      >
        Wealth
      </NavItem>

      <NavItem link={routes.agreements}>Agreements</NavItem>
      <NavItem link={routes.wallet.contacts}>Address Book</NavItem>

      {/* Disabled until fully implemented (05.03.24) */}
      {/*<NavItem link={routes.store} exact={false}>
        Marketplace
      </NavItem>*/}
    </Navigation>
  )
}
