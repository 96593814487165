// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BidOutcome_root__RtwfH:empty {\n  display: none;\n}\n\n.BidOutcome_status_success__1KVrP {\n  color: var(--th-clr-success-700);\n}\n\n.BidOutcome_status_fail__E5uCo {\n  color: var(--th-clr-warn-700);\n}\n\n.BidOutcome_status_passive__1sl2g {\n  color: var(--th-clr-txt-hint);\n  font-style: italic;\n}", "",{"version":3,"sources":["webpack://src/apps/cabinet/features/Bidding/pages/Bidding/BiddingForm/BidOutcome.module.scss"],"names":[],"mappings":"AACE;EACE,aAAA;AAAJ;;AAIA;EACE,gCAAA;AADF;;AAIA;EACE,6BAAA;AADF;;AAIA;EACE,6BAAA;EACA,kBAAA;AADF","sourcesContent":[".root {\n  &:empty {\n    display: none;\n  }\n}\n\n.status_success {\n  color: var(--th-clr-success-700);\n}\n\n.status_fail {\n  color: var(--th-clr-warn-700);\n}\n\n.status_passive {\n  color: var(--th-clr-txt-hint);\n  font-style: italic;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "BidOutcome_root__RtwfH",
	"status_success": "BidOutcome_status_success__1KVrP",
	"status_fail": "BidOutcome_status_fail__E5uCo",
	"status_passive": "BidOutcome_status_passive__1sl2g"
};
export default ___CSS_LOADER_EXPORT___;
