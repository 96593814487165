import React from 'react'

import { IFlexProps } from './types'

export function Flex(props: IFlexProps) {
  const {
    children,
    tag: Tag = 'div',
    column = false,
    inline = false,
    gap = '1rem',
    justify,
    align,
    reverse,
    wrap = false,
    className,
    style,
    ...rest
  } = props

  return (
    <Tag
      {...rest}
      style={{
        gap,
        display: inline ? 'inline-flex' : 'flex',
        flexDirection: resolveFlexDirectionStyle({ column, reverse }),
        justifyContent: justify,
        alignItems: align,
        // eslint-disable-next-line no-nested-ternary
        flexWrap: wrap === true ? 'wrap' : wrap === false ? 'nowrap' : wrap,
        ...style,
      }}
      className={className}
    >
      {children}
    </Tag>
  )
}

// ---

export function resolveFlexDirectionStyle(
  params: Pick<IFlexProps, 'column' | 'reverse'>
) {
  const { column, reverse } = params
  if (column) {
    return reverse ? 'column-reverse' : 'column'
  }

  return reverse ? 'row-reverse' : 'row'
}
