// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Form_controls__c4vwV {\n  width: 100%;\n}\n\n.Form_footer__35BXn {\n  display: flex;\n  grid-gap: 8px;\n  gap: 8px;\n  justify-content: flex-end;\n  flex-direction: column;\n}\n.Form_footer__35BXn:not(:empty) {\n  margin-top: 0.5rem;\n}", "",{"version":3,"sources":["webpack://src/components/base/form/Form/Form.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,aAAA;EACA,aAAA;EAAA,QAAA;EACA,yBAAA;EACA,sBAAA;AACF;AACE;EACE,kBAAA;AACJ","sourcesContent":[".controls {\n  width: 100%;\n}\n\n.footer {\n  display: flex;\n  gap: 8px;\n  justify-content: flex-end;\n  flex-direction: column;\n\n  &:not(:empty) {\n    margin-top: 0.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"controls": "Form_controls__c4vwV",
	"footer": "Form_footer__35BXn"
};
export default ___CSS_LOADER_EXPORT___;
