// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AssetsList_cell_txt_primary__1GXeP {\n  font-size: 1rem;\n  font-weight: 500;\n  line-height: 1.5;\n}\n\n.AssetsList_cell_txt_secondary__HZc-l {\n  font-size: 0.875rem;\n  font-weight: 400;\n  line-height: 1.43;\n}\n\n.AssetsList_cell_balance__FhWxN {\n  white-space: nowrap;\n}\n\n.AssetsList_cell_balance_crypto__KDxb5 {\n}\n\n.AssetsList_cell_balance_usd__2FX6a {\n}", "",{"version":3,"sources":["webpack://src/apps/cabinet/features/Wallet/pages/WalletDetails/WalletAssets/AssetsList/AssetsList.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,gBAAA;AACF;;AAEA;EACE,mBAAA;EACA,gBAAA;EACA,iBAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;AAEA;;AAEA;AAEA","sourcesContent":[".cell_txt_primary {\n  font-size: 1rem;\n  font-weight: 500;\n  line-height: 1.5;\n}\n\n.cell_txt_secondary {\n  font-size: 0.875rem;\n  font-weight: 400;\n  line-height: 1.43;\n}\n\n.cell_balance {\n  white-space: nowrap;\n}\n\n.cell_balance_crypto {\n  composes: cell_txt_primary;\n}\n\n.cell_balance_usd {\n  composes: cell_txt_secondary;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cell_txt_primary": "AssetsList_cell_txt_primary__1GXeP",
	"cell_txt_secondary": "AssetsList_cell_txt_secondary__HZc-l",
	"cell_balance": "AssetsList_cell_balance__FhWxN",
	"cell_balance_crypto": "AssetsList_cell_balance_crypto__KDxb5 AssetsList_cell_txt_primary__1GXeP",
	"cell_balance_usd": "AssetsList_cell_balance_usd__2FX6a AssetsList_cell_txt_secondary__HZc-l"
};
export default ___CSS_LOADER_EXPORT___;
