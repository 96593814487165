import { useContext } from 'react'
import { toast } from 'react-toastify'

import { Modal } from 'src/components'
import { BlockchainAsset, BlockchainMethod } from 'src/types'
import { isSupportedBlockchainMethod } from 'src/utils'

import {
  ICallRequestSwitchChainParams,
  IWCCallRequestPersonalSign,
  IWCCallRequestSwitchChain,
  IWCCallRequestTxn,
  IWCFacade,
} from '../../../../WalletConnect'
import { WalletRTQApi } from '../../../../api'
import { IWCCallResponseSign, IWCCallResponseTxn } from '../../../../types'
import { WalletContext } from '../../WalletContext'

import { CallRequest } from './CallRequest'

export function CallRequestContainer(props: {
  wc: IWCFacade
  title: JSX.Element
}) {
  const { wc, title } = props
  const { callRequest: req, assetSymbol } = wc.state
  const onApprove = useApproveRequestHandler(wc)

  if (req === null || assetSymbol === null) {
    return null
  }

  const onReject = () =>
    wc.rejectRequest({
      id: req.id,
      topic: req.topic,
      msg: 'Cancelled by user',
    })

  return (
    <Modal
      isOpen
      defaultRequired
      showCloseBtn
      title={title}
      onRequestClose={onReject}
      style={{
        content: { width: 400 },
      }}
      /* By design, this modal should have perfectly centered header, so we take close-btn off the flow.
       * And design guarantees that no content will overlap with btn. */
      floatCloseBtn
    >
      <CallRequest
        req={req}
        asset={assetSymbol}
        onReject={onReject}
        onApprove={onApprove}
      />
    </Modal>
  )
}

function useApproveRequestHandler(wc: IWCFacade) {
  const onTxnRequest = useTxnRequestHandler(wc)
  const onSwitchChainRequest = useSwitchChainRequestHandler(wc)
  const onPersonalSignRequest = usePersonalSignRequestHandler(wc)

  return async () => {
    const { assetSymbol: asset, callRequest: request } = wc.state

    if (request === null) {
      throw new Error('No call request found in client state')
    }

    if (asset === null) {
      throw new Error('Connected asset symbol is not defined')
    }

    if (!isSupportedBlockchainMethod(request.method)) {
      throw new Error(`Unsupported method: ${request.method}`)
    }

    switch (request.method) {
      case BlockchainMethod.eth_sendTransaction: {
        await onTxnRequest({ asset, request })
        toast.success(`Transaction is submitted`, {
          autoClose: 5000,
        })
        break
      }

      case BlockchainMethod.eth_switchChain: {
        await onSwitchChainRequest({ request })
        break
      }

      case BlockchainMethod.personalSign: {
        await onPersonalSignRequest({ asset, request })
        break
      }
    }
  }
}

function useTxnRequestHandler(wc: IWCFacade) {
  const wallet = useContext(WalletContext)
  const [submitCallRequest] =
    WalletRTQApi.endpoints.invokeWalletConnect.useMutation()

  return async (params: {
    asset: BlockchainAsset
    request: IWCCallRequestTxn
  }) => {
    const { asset, request: callRequest } = params

    const request = submitCallRequest({
      asset,
      request_id: wallet.address,
      method: callRequest.method as BlockchainMethod.eth_sendTransaction,
      data: callRequest.params,
    })

    const response = (await request.unwrap()) as IWCCallResponseTxn

    await wc.approveRequest({
      id: callRequest.id,
      topic: callRequest.topic,
      result: response.transaction_hash,
    })
  }
}

function useSwitchChainRequestHandler(wc: IWCFacade) {
  return async (params: {
    request: Pick<IWCCallRequestSwitchChain, 'id' | 'params'>
  }) => {
    const { request } = params
    // By spec: chain in this type of request is in hex format:
    // @see https://eips.ethereum.org/EIPS/eip-3326
    const { chainId: chainIdHex } =
      request.params as ICallRequestSwitchChainParams
    const chainId = parseInt(chainIdHex, 16)

    await wc.updateSession({
      requestId: request.id,
      chainId: chainId.toString(),
    })
  }
}

function usePersonalSignRequestHandler(wc: IWCFacade) {
  const [submitCallRequest] =
    WalletRTQApi.endpoints.invokeWalletConnect.useMutation()

  const { address: wallet } = useContext(WalletContext)

  return async (params: {
    asset: BlockchainAsset
    request: IWCCallRequestPersonalSign
  }) => {
    const { asset, request: callRequest } = params
    const [message, address] = callRequest.params

    const request = submitCallRequest({
      request_id: wallet,
      asset,
      method: BlockchainMethod.personalSign,
      data: {
        sign_data: message,
        from_address: address,
      },
    })

    const response = (await request.unwrap()) as IWCCallResponseSign

    await wc.approveRequest({
      id: callRequest.id,
      topic: callRequest.topic,
      result: response.transaction_hash,
    })
  }
}
