import React from 'react'

import {
  IAgreementNotification,
  INotification,
  NotificationType,
  UserRole,
} from 'src/types'
import { getUserRoleLabel } from 'src/utils'

import { Grid } from '../../layouts'
import { AgreementStatusBadge } from '../AgreementBadge'

import { KeyValue } from './lib'

export function CellDetails(props: { item: INotification }) {
  const { item } = props
  switch (item.notificationType) {
    case NotificationType.NodeAcquisition:
    case NotificationType.SafeheronAcquisition:
    case NotificationType.DfnsAcquisition:
      return <DetailsAcquisition item={item} />

    case NotificationType.Wallet: {
      const { wallet_name, status } = item.payload
      return (
        <div>
          <KeyValue left="Wallet:" right={wallet_name} />
          <KeyValue
            left="Status:"
            right={
              <span style={{ textTransform: 'capitalize' }}>
                {status.replaceAll('_', ' ')}
              </span>
            }
          />
        </div>
      )
    }

    case NotificationType.Txn: {
      const { status, amount, asset } = item.payload
      return (
        <div>
          <KeyValue
            left="Amount:"
            right={
              <>
                {amount} {asset}
              </>
            }
          />
          <KeyValue left="Status:" right={status} />
        </div>
      )
    }

    default:
      return null
  }
}

function DetailsAcquisition(props: { item: IAgreementNotification }) {
  const { item } = props
  return (
    <Grid gap="0.25rem">
      <KeyValue
        left={`${getUserRoleLabel(UserRole.Advisor)}:`}
        right={item.payload.issuer}
      />

      <AgreementStatusBadge prev={item.payload.statusPrev} showTitle size="sm">
        {item.payload.status}
      </AgreementStatusBadge>
    </Grid>
  )
}
