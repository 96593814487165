import { FaHandHoldingUsd, FaUserCog, FaUserTie } from 'react-icons/fa'
import { FiUsers } from 'react-icons/fi'

import { UserRole } from 'src/types'
import { getUserRoleLabel } from 'src/utils'

import { Flex } from '../layouts'

export interface IUserRoleIconProps {
  role: UserRole
  showTitle?: boolean
  size?: number | string
  color?: string
}

export function UserRoleIcon(props: IUserRoleIconProps) {
  const { role, size, color, showTitle } = props
  const Component = getIconComponent(role)
  return (
    <Flex align="center">
      <Component size={size} color={color} />{' '}
      {showTitle && getUserRoleLabel(role)}
    </Flex>
  )
}

function getIconComponent(role: UserRole) {
  switch (role) {
    case UserRole.Investor:
      return FaHandHoldingUsd
    case UserRole.Avatar:
      return FiUsers
    case UserRole.Advisor:
      return FaUserTie
    case UserRole.Operator:
      return FaUserCog
    default:
      return () => null
  }
}
