import { ErrorBoundary } from 'react-app-error-boundary'

import { Card, ListPlaceholder, Suspendable } from 'src/components'

import { IWalletListItem } from '../../../types'

import { Wallet } from './Wallet'

import styles from './WalletsList.module.scss'

interface IListProps {
  items: IWalletListItem[]
}

export function WalletsList(props: IListProps) {
  const { items } = props
  return (
    <Suspendable>
      <Choose>
        <When condition={items.length === 0}>
          <Card>
            <ListPlaceholder text="No wallets installed" />
          </Card>
        </When>

        <Otherwise>
          <ul className={styles.list}>
            {items.map(x => (
              <li key={x.address}>
                <ErrorBoundary>
                  <Wallet wallet={x} />
                </ErrorBoundary>
              </li>
            ))}
          </ul>
        </Otherwise>
      </Choose>
    </Suspendable>
  )
}
