import { FieldInput, Fieldset, Form } from 'src/components'

interface IWalletNameFormFields {
  name: string
}

export function WalletNameForm(props: {
  defaultName: string
  onCancel?(): void
  onSubmit?(data: IWalletNameFormFields): void
}) {
  const { defaultName, onCancel, onSubmit } = props

  return (
    <Form<IWalletNameFormFields>
      reset
      defaultValues={{ name: defaultName }}
      onSubmit={onSubmit}
      onReset={onCancel}
      btnSubmit={{ children: 'Confirm' }}
      btnReset={{ children: 'Cancel' }}
      onlySubmitChanges
    >
      <Fieldset>
        <FieldInput
          name="name"
          autoFocus
          autoSelect
          rules={{ required: true }}
          placeholder="Wallet Name"
        />
      </Fieldset>
    </Form>
  )
}
