import { useMemo } from 'react'
import { useWatch } from 'react-hook-form'

import { FieldInput, Fieldset, Form, IFormProps } from 'src/components'
import { validators } from 'src/validation'

import { FieldSelectAsset } from '../../components'
import { ITransactionFormFields, IWalletAsset } from '../../types'

// ---

interface ITransactionFormProps extends IFormProps<ITransactionFormFields> {
  availableAssets: IWalletAsset[]
}

// ---

export function TransactionForm(props: ITransactionFormProps) {
  const { availableAssets, onSubmit, ...config } = props

  if (availableAssets.length === 0) {
    return <div>No assets to transfer from provided</div>
  }

  return (
    <Form<ITransactionFormFields>
      {...config}
      onSubmit={onSubmit}
      btnSubmit="Submit transaction"
    >
      <Fieldset>
        <FieldAsset assets={availableAssets} />

        <FieldInput<ITransactionFormFields, 'to'>
          name="to"
          label="Receiver address"
          placeholder="Address..."
          rules={{ required: true }}
        />
        <FieldAmount assets={availableAssets} />
      </Fieldset>
    </Form>
  )
}

function FieldAsset(props: { assets: IWalletAsset[] }) {
  const { assets } = props
  const assetSymbols = useMemo(() => assets.map(x => x.asset_symbol), [assets])
  return (
    <FieldSelectAsset<ITransactionFormFields, 'asset'>
      name="asset"
      label="Which asset to transfer?"
      options={assetSymbols}
      rules={{ required: true }}
      defaultValue={assetSymbols[0]}
    />
  )
}

function FieldAmount(props: { assets: IWalletAsset[] }) {
  const { assets } = props
  const asset = useWatch<ITransactionFormFields, 'asset'>({ name: 'asset' })
  const balance = assets.find(x => x.asset_symbol === asset)?.balance as string // match is guaranteed, as select options are the same
  return (
    <FieldInput<ITransactionFormFields, 'value'>
      name="value"
      label={
        <span>
          Amount
          <If condition={balance !== undefined}>
            <i> (you have: {balance})</i>
          </If>
        </span>
      }
      rules={{
        required: true,
        ...validators.rulesPositiveNumberString,
        max: {
          value: balance,
          message: "You don't have enough balance",
        },
        validate: numStr => +numStr > 0 || 'Value must be greater than 0',
      }}
    />
  )
}
