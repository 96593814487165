import { useCallback, useLayoutEffect, useRef, useState } from 'react'

const useStateSafeInternal = (...args: Parameters<typeof useState>) => {
  const [state, setStateBase] = useState(...args)

  const refIsMounted = useRef(false)
  useLayoutEffect(() => {
    refIsMounted.current = true
    return () => {
      refIsMounted.current = false
    }
  })

  const setState = useCallback<typeof setStateBase>((...args) => {
    if (refIsMounted.current) {
      setStateBase(...args)
    }
  }, [])

  return [state, setState]
}

export const useStateSafe = useStateSafeInternal as typeof useState
