// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_full__1rYo6 {\n  height: 30px;\n}\n\n.styles_compact__1tHEh {\n  --size: 44px;\n  width: var(--size);\n  height: var(--size);\n}\n.styles_compact__1tHEh.styles_dfns__1Fod1 {\n  display: inline-flex;\n  justify-content: center;\n  align-items: center;\n  padding: 6px;\n  border-radius: 50%;\n  border: 1px solid var(--th-border-color);\n}", "",{"version":3,"sources":["webpack://src/apps/cabinet/features/Wallet/components/WalletProviderBadge/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,YAAA;EACA,kBAAA;EACA,mBAAA;AACF;AACE;EACE,oBAAA;EACA,uBAAA;EACA,mBAAA;EAEA,YAAA;EAEA,kBAAA;EACA,wCAAA;AADJ","sourcesContent":[".full {\n  height: 30px;\n}\n\n.compact {\n  --size: 44px;\n  width: var(--size);\n  height: var(--size);\n\n  &.dfns {\n    display: inline-flex;\n    justify-content: center;\n    align-items: center;\n\n    padding: 6px;\n\n    border-radius: 50%;\n    border: 1px solid var(--th-border-color);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"full": "styles_full__1rYo6",
	"compact": "styles_compact__1tHEh",
	"dfns": "styles_dfns__1Fod1"
};
export default ___CSS_LOADER_EXPORT___;
