import React from 'react'
import { setupReactAppOverlayErrorHandler } from 'react-app-error-boundary'
import ReactDOM from 'react-dom'
import Modal from 'react-modal'

import { enableMapSet } from 'immer'

import App from 'src/App'

import './styles/index.scss'

import * as serviceWorker from './serviceWorker'

const ROOT_ID = 'root'

enableMapSet()
Modal.setAppElement(`#${ROOT_ID}`)

setupReactAppOverlayErrorHandler()

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById(ROOT_ID)
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
