import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import {
  IAuthTokens,
  clearAuthTokens,
  authTokenInterceptor as createAuthTokenInterceptor,
} from 'axios-jwt'
import qs from 'qs'

import { Log } from 'src/log'
import { IJWTPair } from 'src/types'

import { AUTH_PORTAL_ERROR_QUERY_PARAM, ERROR_REFRESH_TOKEN } from '../const'
import { normalizeSlashes, normalizeUrl } from '../utils'

export const API_BASE_URL = '/api/v1'

const defaultAPI = createAxiosInstance()

export default defaultAPI

// ---

const JWT_AUTH_HEADER = 'Authorization'
const authTokenInterceptor = createAuthTokenInterceptor({
  requestRefresh,
  header: JWT_AUTH_HEADER,
})

export function createAxiosInstance(cfg: AxiosRequestConfig = {}) {
  const inst = axios.create({
    /**
     * Don't use default axios' serializer because it's behaviour is non-standard.
     * @see https://github.com/axios/axios/issues/1111
     */
    paramsSerializer: params =>
      qs.stringify(params, {
        arrayFormat: 'repeat',
      }),

    ...cfg,
  })

  inst.interceptors.request.use(request => {
    const { baseURL = '' } = request
    request.baseURL =
      baseURL.startsWith('/') || baseURL.startsWith('http')
        ? baseURL
        : `${API_BASE_URL}/${baseURL}`

    request.url = ensureTrailingSlash(request.url ?? '/')

    request.baseURL = normalizeSlashes(request.baseURL)
    request.url = normalizeSlashes(request.url)

    return request
  })

  inst.interceptors.response.use(undefined, (e: AxiosError) => {
    Log.error('[axios]', e)
    return Promise.reject(parseAxiosErrorMessage(e))
  })

  inst.interceptors.request.use(request => {
    const skipDefaultJWTAuth = request.headers?.[JWT_AUTH_HEADER] !== undefined
    if (skipDefaultJWTAuth) {
      return request
    }
    return authTokenInterceptor(request)
  })

  return inst
}

function parseAxiosErrorMessage(error: AxiosError): AxiosError {
  const { response } = error

  const responseMessage = resolveResponseErrorMessage(response?.data)
  if (responseMessage !== undefined) {
    error.message = responseMessage
  }

  return error
}

export function resolveResponseErrorMessage(data: string | Dict) {
  /* @see https://dcspoc.atlassian.net/browse/PLT-675
   * Handle Nginx errors, not processed by server */
  if (typeof data === 'string') {
    const str = data.toLowerCase()
    if (str.includes('<html>') && str.includes('nginx')) {
      console.error('Unexpected Nginx error:\n', data)
      return 'Something went wrong'
    }

    return data
  }

  return data.message as string | undefined
}

function ensureTrailingSlash(str: string): string {
  if (str === '') return '/'
  return (
    str
      // actually add the trailing slash
      .replace(/(^[^?]+)(\?=.+)?/, '$1/$2')
      // dedupe multiple trailing slashes, if source str already had one
      .replace(/\/+($|\?)/, '/$1')
  )
}

/**
 * @see https://www.npmjs.com/package/axios-jwt
 */
async function requestRefresh(token: string): Promise<IAuthTokens | string> {
  const AUTH_PORTAL = process.env.REACT_APP_AUTH_PORTAL_URL

  if (!AUTH_PORTAL) {
    throw new Error(
      "Auth Portal URL is not set. Can't access refresh-token endpoint."
    )
  }

  try {
    const {
      data: { access_token, refresh_token },
    } = await defaultAPI.post<IJWTPair>(
      `fractal-auth/refresh-token/`,
      undefined,
      {
        // This API lives in auth-gateway service.
        // Being called from the Cabinet app, ot should explicitly specify target host
        // (which will also cause CORS issues, so endpoint must have proper Access-Control-Allow-Origin specified)
        baseURL: `${AUTH_PORTAL}/${API_BASE_URL}`,
        headers: {
          [JWT_AUTH_HEADER]: `Bearer ${token}`,
        },
      }
    )

    return {
      accessToken: access_token,
      refreshToken: refresh_token,
    }
  } catch (e) {
    Log.error(
      `Failed to refresh access token. Redirecting to auth portal: ${AUTH_PORTAL}`
    )

    // Since we don't know the exact reason of failure, it's unknown whether retrying does make sense.
    // To don't fall into infinite loop, better clear tokens to start auth from the scratch.
    clearAuthTokens()

    const url = new URL(normalizeUrl(AUTH_PORTAL))
    url.searchParams.append(AUTH_PORTAL_ERROR_QUERY_PARAM, ERROR_REFRESH_TOKEN)
    window.location.assign(url)
    throw e
  }
}
