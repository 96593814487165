import { useRef, useState } from 'react'
import { ErrorBoundary } from 'react-app-error-boundary'

import { Card, Grid, Tab, TabList, TabPanel, Tabs } from 'src/components'
import { useOnChange } from 'src/hooks'

import { IWallet } from '../../../types'

import { AssetsList, BtnAddAsset } from './AssetsList'
import { BtnRefreshAssets } from './BtnRefreshAssets'
import { DefiAssets } from './DefiAssets'
import { TransactionsList } from './Transactions'
import { AssetContextProvider } from './reducer'

import styles from './WalletAssets.module.scss'

export type ContentType = 'assets' | 'defi' | 'txns'

export function WalletAssets(props: { wallet: IWallet }) {
  const { wallet } = props
  const [tabIndex, setTabIndex] = useState(0)
  return (
    <AssetContextProvider>
      <Card
        title={
          <Header wallet={wallet} contentType={getTabNameByIndex(tabIndex)} />
        }
        spacing="sm"
        tag="section"
        className={styles.card}
      >
        <Tabs
          onSelect={setTabIndex}
          selectedIndex={tabIndex}
          className={styles.tabs}
        >
          <TabList>
            <Tab>Assets</Tab>
            <Tab>De.Fi</Tab>
            <Tab>Transactions</Tab>
          </TabList>

          <TabPanel>
            <ErrorBoundary>
              <AssetsList provider={wallet.type} wallet={wallet.address} />
            </ErrorBoundary>
          </TabPanel>

          <TabPanel>
            <ErrorBoundary>
              <DefiAssets wallet={wallet.address} />
            </ErrorBoundary>
          </TabPanel>

          <TabPanel>
            <ErrorBoundary>
              <TransactionsList wallet={wallet} />
            </ErrorBoundary>
          </TabPanel>
        </Tabs>
      </Card>
    </AssetContextProvider>
  )
}

function Header(props: { wallet: IWallet; contentType: ContentType }) {
  const { wallet, contentType } = props
  const refTitle = useRef<HTMLDivElement>(null)

  useOnChange(contentType, () => {
    setTimeout(() => {
      refTitle.current?.scrollIntoView({
        behavior: 'smooth',
      })
    }, 0)
  })

  return (
    <Grid columns="1fr auto" align="center">
      <div style={{ fontSize: '1.875rem' }} ref={refTitle}>
        Assets
      </div>
      <Grid columns align="center">
        <If condition={contentType === 'assets'}>
          <BtnAddAsset wallet={wallet} />
        </If>
        <BtnRefreshAssets wallet={wallet} contentType={contentType} />
      </Grid>
    </Grid>
  )
}

function getTabNameByIndex(idx: number): ContentType {
  switch (idx) {
    case 0:
      return 'assets'
    case 1:
      return 'defi'
    case 2:
      return 'txns'
    default:
      throw new Error(`Unknown tab index: ${idx}`)
  }
}
