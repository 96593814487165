import { AsyncState, UserRole } from 'src/types'

export { UserRole }

export type UserAppState = { user: IBaseUserState }

export interface IBaseUser {
  id: number
  role: UserRole
  username: string
  photo: string | null
}

// ---

export interface IBaseUserState extends AsyncState {
  data: IBaseUser
}

// What should be encoded in JWT token
export interface IJWTData {
  sub: string // id
  role: UserRole
  username: string
  photo: string | null
}
