// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_root__13sHH {\n  position: relative;\n  -webkit-user-select: none;\n          user-select: none;\n}\n.styles_root__13sHH .styles_overlay__FPOFh {\n  visibility: hidden;\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.8);\n  overflow: hidden;\n  padding: 16px;\n}\n.styles_root__13sHH:hover .styles_overlay__FPOFh {\n  visibility: visible;\n}", "",{"version":3,"sources":["webpack://src/components/base/InDevelopment/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,yBAAA;UAAA,iBAAA;AACF;AACE;EACE,kBAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,oCAAA;EACA,gBAAA;EACA,aAAA;AACJ;AAGI;EACE,mBAAA;AADN","sourcesContent":[".root {\n  position: relative;\n  user-select: none;\n\n  .overlay {\n    visibility: hidden;\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgb(0 0 0 / 80%);\n    overflow: hidden;\n    padding: 16px;\n  }\n\n  &:hover {\n    .overlay {\n      visibility: visible;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "styles_root__13sHH",
	"overlay": "styles_overlay__FPOFh"
};
export default ___CSS_LOADER_EXPORT___;
