import { ReactNode, createContext, useContext } from 'react'

import { useChanged } from 'src/hooks/changes'

import { IIconSettingsContext } from './types'

const IconSettingsContext = createContext<IIconSettingsContext>({})

export function useIconSettingsContext() {
  return useContext(IconSettingsContext)
}

export function IconSettings(
  props: IIconSettingsContext & { children: ReactNode }
) {
  const { children, ...ctx } = props
  const memoized = useChanged(ctx)
  return (
    <IconSettingsContext.Provider value={memoized}>
      {children}
    </IconSettingsContext.Provider>
  )
}
