// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CallRequest_card__3O5yM {\n  background-color: var(--th-clr-bg-secondary);\n}\n\n.CallRequest_header__r8x26 {\n  text-align: center;\n  font-size: 1.125rem;\n  font-weight: 500;\n}\n\n.CallRequest_confirmation_text__10i2M {\n  text-align: center;\n  font-weight: 500;\n}", "",{"version":3,"sources":["webpack://src/apps/cabinet/features/Wallet/pages/WalletDetails/WalletConnect/CallRequest/CallRequest.module.scss"],"names":[],"mappings":"AAAA;EACE,4CAAA;AACF;;AAEA;EACE,kBAAA;EACA,mBAAA;EACA,gBAAA;AACF;;AAEA;EACE,kBAAA;EACA,gBAAA;AACF","sourcesContent":[".card {\n  background-color: var(--th-clr-bg-secondary);\n}\n\n.header {\n  text-align: center;\n  font-size: 1.125rem;\n  font-weight: 500;\n}\n\n.confirmation_text {\n  text-align: center;\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "CallRequest_card__3O5yM",
	"header": "CallRequest_header__r8x26",
	"confirmation_text": "CallRequest_confirmation_text__10i2M"
};
export default ___CSS_LOADER_EXPORT___;
