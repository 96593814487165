import { ReactNode, useContext, useEffect, useRef } from 'react'
import { ErrorBoundary } from 'react-app-error-boundary'

import clsx from 'clsx'

import { GoBackButton, IGoBackButtonProps } from 'src/features/HistoryReferrer'

import { PageSettingsContext } from './Context'
import { IPageProps } from './types'

import styles from './Page.module.scss'

const defined = (x: ReactNode) => x !== undefined && x !== null

export function Page(props: IPageProps) {
  const {
    className,
    hero,
    title,
    description,
    goBack,
    stickyHeader = false,
    children,
    alignHeader,
    alignContent,
    pushdown = false,
    gap = 12,
  } = { ...useContext(PageSettingsContext), ...props }

  const { refHeader, refScrollWatcher } = useScrollWatcher(stickyHeader)

  const hasHeader = defined(hero) || defined(title) || defined(description)
  return (
    <div
      className={clsx(styles.page, className, {
        [styles.pushdown]: pushdown,
      })}
    >
      <If condition={hasHeader}>
        <div
          ref={refScrollWatcher}
          className={styles.page_sticky_scroll_watcher}
        />
        <div
          ref={refHeader}
          className={clsx(styles.page_header, {
            [styles.page_header_sticky]: stickyHeader,
            [styles.page_header_center]: alignHeader === 'center',
            [styles.page_header_start]: alignHeader === 'start',
            [styles.page_header_stretch]: alignHeader === 'stretch',
          })}
        >
          <div className={styles.hero}>{hero}</div>
          {renderGoBackBtn(goBack)}
          <h1 className={clsx(styles.page_title)}>{title}</h1>
          <h2 className={clsx(styles.page_description)}>{description}</h2>
        </div>
      </If>

      <div
        className={clsx(styles.page_content, {
          [styles.page_content_center]: alignContent === 'center',
          [styles.page_content_start]: alignContent === 'start',
          [styles.page_content_stretch]: alignContent === 'stretch',
        })}
        style={{ gap }}
      >
        <ErrorBoundary>{children}</ErrorBoundary>
      </div>
    </div>
  )
}

function renderGoBackBtn(cfg: IPageProps['goBack']) {
  if (cfg === undefined) return null

  if (cfg === true) return <PageGoBackButton />

  if (typeof cfg === 'string') return <PageGoBackButton expect={cfg} />

  return <PageGoBackButton expect={cfg.link}>{cfg.label}</PageGoBackButton>
}

function PageGoBackButton(
  props: Omit<IGoBackButtonProps, 'variant' | 'className'>
) {
  return (
    <GoBackButton
      variant="tertiary"
      size="unset"
      className={styles.go_back}
      {...props}
    />
  )
}

function useScrollWatcher(enabled: boolean) {
  const refHeader = useRef<HTMLDivElement>(null)
  const refScrollWatcher = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (enabled) {
      const obs = new IntersectionObserver(entries => {
        refHeader.current?.classList.toggle(
          styles.page_header_sticking,
          !entries[0].isIntersecting
        )
      })

      const watcher = refScrollWatcher.current
      if (watcher !== null) {
        obs.observe(watcher)
      }

      return () => {
        obs.disconnect()
      }
    }
  }, [enabled])

  return { refHeader, refScrollWatcher }
}
