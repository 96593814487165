import RCTooltip from 'rc-tooltip'

import { ITooltipProps } from './types'

import 'rc-tooltip/assets/bootstrap.css'
import './styles.module.scss'

export function Tooltip(props: ITooltipProps) {
  const { placement = 'top', autoWrap = true, children, ...rest } = props

  const Wrapper = autoWrap === true ? 'div' : autoWrap

  return (
    <RCTooltip {...rest} {...{ placement }}>
      {Wrapper === false ? (
        children
      ) : (
        <Wrapper
          data-tooltip-ref-anchor="true"
          style={{ display: 'inline-flex' }}
        >
          {children}
        </Wrapper>
      )}
    </RCTooltip>
  )
}
