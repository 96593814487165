// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_root__2JCuu {\n  font-weight: 600;\n  line-height: 20px;\n  flex-shrink: 0;\n}", "",{"version":3,"sources":["webpack://src/components/domain/BtnLogout/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,iBAAA;EACA,cAAA;AACF","sourcesContent":[".root {\n  font-weight: 600;\n  line-height: 20px;\n  flex-shrink: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "styles_root__2JCuu"
};
export default ___CSS_LOADER_EXPORT___;
