// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NotificationsList_root__3MiGN tbody tr {\n  background-color: var(--th-clr-white);\n  /*&.row_unread {\n    --table-row-border-color: var(--th-clr-error);\n  }*/\n}\n\n.NotificationsList_cell_datetime__uz99k {\n  font-size: 0.8rem;\n  color: var(--th-clr-txt-secondary);\n}\n\n.NotificationsList_row_unread__3pDmK {\n  /* TODO: styles were temporary (?) removed because of design changes  */\n}", "",{"version":3,"sources":["webpack://src/components/domain/NotificationsList/NotificationsList.module.scss"],"names":[],"mappings":"AACE;EACE,qCAAA;EAGA;;IAAA;AAAJ;;AAMA;EACE,iBAAA;EACA,kCAAA;AAHF;;AAMA;EACE,uEAAA;AAHF","sourcesContent":[".root {\n  tbody tr {\n    background-color: var(--th-clr-white);\n\n    // pre-release 26.07.23: disabled by results of UI review; not used for now\n    /*&.row_unread {\n      --table-row-border-color: var(--th-clr-error);\n    }*/\n  }\n}\n\n.cell_datetime {\n  font-size: 0.8rem;\n  color: var(--th-clr-txt-secondary);\n}\n\n.row_unread {\n  /* TODO: styles were temporary (?) removed because of design changes  */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "NotificationsList_root__3MiGN",
	"cell_datetime": "NotificationsList_cell_datetime__uz99k",
	"row_unread": "NotificationsList_row_unread__3pDmK"
};
export default ___CSS_LOADER_EXPORT___;
