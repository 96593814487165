import { Badge, BadgeVariant } from 'src/components'

interface IBadgeTotalProps {
  value?: number
  variant?: BadgeVariant
}

export function BadgeTotal(props: IBadgeTotalProps) {
  const { value, variant = 'success' } = props
  if (value === undefined) return null

  return (
    <sup>
      <Badge style={{ display: 'inline-flex' }} variant={variant} size="sm">
        {value} total
      </Badge>
    </sup>
  )
}
