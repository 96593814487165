import React, { ReactNode } from 'react'

import clsx from 'clsx'

import { mergeStyles } from '../../../../utils'
import { Suspendable } from '../../Suspendable'

import { ControlContext } from './ControlContext'
import { useContentStyle } from './lib'
import { IControlComponentProps } from './types'

import styles from './Control.module.scss'

const DEFAULT_ERROR_TEXT = 'This field is invalid'
const DEFAULT_LAYOUT: IControlComponentProps['layout'] = 'row'

function Control(props: IControlComponentProps) {
  const { ignoreContext = false, className: ownClassName, ...rest } = props
  const { className: contextClassName, ...context } =
    React.useContext(ControlContext)

  const {
    tag: Tag = 'label',
    label,
    labelPosition = 'before',
    hint,
    hintPosition = 'footer',
    alignLabel = 'center',
    alignInput,
    layout = 'col',
    children,
    style,
    styleContent,
    classNameContent,
    classNameError,
    classNameHint,
    inline = false,
    gap,
    error,
    showError = true,
    loading = false,
    collapseEmptyFooter = false,
  } = ignoreContext ? rest : { ...context, ...rest }

  const hasError = showError && error !== undefined
  const hasHint = isNodeDefined(hint)
  const hasHintInFooter = hasHint && hintPosition === 'footer'
  const hasHintInLabel = hasHint && hintPosition === 'label'
  const hasLabel = isNodeDefined(label)

  const $hint = hasHint ? (
    <div className={clsx(styles.hint, classNameHint)}>{hint}</div>
  ) : null

  const $label = !(hasLabel || hasHintInLabel) ? null : (
    <div className={styles.label}>
      {label}
      {hasHintInLabel && $hint}
    </div>
  )

  return (
    <Tag
      className={clsx(styles.control, contextClassName, ownClassName, {
        [styles.control_inline]: inline,
      })}
      style={mergeStyles(style, {
        '--control-align-label': alignLabel,
        '--control-align-input': alignInput,
      })}
    >
      {/* TODO:
            this will cover label too, which is probably not desired.
            For universal solution will have to either wrap children in another block,
            or make Control to just provide SuspendableContext, and wrap whatever is needed at Form side */}
      <Suspendable suspended={loading}>
        <div
          className={clsx(styles.content, classNameContent)}
          style={useContentStyle({
            style: styleContent,
            gap,

            // If label is not specified, don't apply any additional styles to align it with input.
            // Just render input as it is.
            layout: layout ?? (hasLabel ? DEFAULT_LAYOUT : undefined),
          })}
        >
          <If condition={labelPosition === 'before'}>{$label}</If>

          {/* Control expects a single input child.
              Although it can be bypassed with Fragment, but that's completely up you then. */}
          {React.Children.only(children)}

          <If condition={labelPosition === 'after'}>{$label}</If>
        </div>
      </Suspendable>

      <div
        className={clsx(styles.footer, {
          [styles.footer_empty]:
            collapseEmptyFooter && !(hasError || hasHintInFooter),
        })}
      >
        <Choose>
          <When condition={hasError}>
            <div className={clsx(styles.error, classNameError)}>
              {error || DEFAULT_ERROR_TEXT}
            </div>
          </When>

          <When condition={hasHintInFooter}>{$hint}</When>

          {/* maintain same block height accordingly to font styles, even when it's visually empty */}
          <Otherwise>&nbsp;</Otherwise>
        </Choose>
      </div>
    </Tag>
  )
}

function isNodeDefined(
  x: ReactNode
): x is Exclude<ReactNode, undefined | null> {
  return x !== '' && x !== undefined && x !== null
}

export default React.memo(Control)
