type ParserOptions<T> = {
  serializer: (value: T) => string
  deserializer: (value: string) => T
}

type StorageOptions<T> = {
  storage?: Storage
  parser?: ParserOptions<T>
}

const DEFAULT_PARSER = {
  serializer: JSON.stringify,
  deserializer: JSON.parse,
}

interface IStorageValue<T, Required extends boolean = boolean> {
  get(): Required extends true ? T : T | undefined
  set(x: T): this
  remove(): this
}

export function storageValue<T = string>(
  key: string,
  opts?: StorageOptions<T>
): IStorageValue<T, false>

export function storageValue<T>(
  key: string,
  defaultValue: T,
  opts?: StorageOptions<T>
): IStorageValue<T, true>

export function storageValue<T>(key: string, ...args: any[]): IStorageValue<T> {
  const [defaultValue, opts] = parseOptions<T>(...args)

  const {
    storage = localStorage,
    parser: { serializer, deserializer } = DEFAULT_PARSER,
  } = opts

  return {
    get() {
      const item = storage.getItem(key)
      return item === null ? defaultValue : deserializer(item)
    },

    set(value: T) {
      storage.setItem(key, serializer(value))
      return this
    },

    remove() {
      storage.removeItem(key)
      return this
    },
  }
}

function parseOptions<T>(...args: any[]): [T | undefined, StorageOptions<T>] {
  switch (args.length) {
    case 0:
      return [undefined, {}]
    case 1:
      return [args[0], {}]
    default:
      return [args[0], args[1]]
  }
}
