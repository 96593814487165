import { Slice } from '@reduxjs/toolkit'
import { useMemo } from 'react'

import { DispatchResult, useThunkReducer } from './useThunkReducer'

//#region Types
type ActionCreators = Slice['actions']

type BoundActionCreator<A extends ActionCreators[string]> = (
  ...args: Parameters<A>
) => DispatchResult<ReturnType<A>>

type BoundSliceActions<T extends ActionCreators> = {
  [K in keyof T]: BoundActionCreator<T[K]>
}

type SliceState<S extends Slice> = ReturnType<S['getInitialState']>
type SliceActions<S extends Slice> = _Values<_SliceActions<S['actions']>>

type _Values<T> = T[keyof T]
type _SliceActions<S extends ActionCreators> = {
  [K in keyof S]: ReturnType<S[K]>
}

type SliceStateInitializer<S> = Partial<S> | ((base: S) => Partial<S>)
//#endregion

function resolveInitialState<S>(
  slice: Slice<S>,
  init: SliceStateInitializer<S>
): S {
  const base = slice.getInitialState()
  const override = typeof init === 'function' ? init(base) : init
  return { ...base, ...override }
}

function useSlice<S extends Slice>(
  slice: S,
  init?: SliceStateInitializer<SliceState<S>>
) {
  const [state, dispatch] = useThunkReducer<SliceState<S>, SliceActions<S>>(
    slice.reducer,
    undefined,
    !init ? slice.getInitialState : () => resolveInitialState(slice, init)
  )

  type Actions = S['actions']
  const boundActions = useMemo(
    () =>
      Object.entries(slice.actions).reduce((acc, [name, actionCreator]) => {
        const k = name as keyof Actions
        acc[k] = (...args) => dispatch(actionCreator(...args))
        return acc
      }, {} as BoundSliceActions<Actions>),
    [dispatch, slice.actions]
  )

  return [state, boundActions, dispatch] as const
}

export { useSlice }
