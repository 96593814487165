import stylesTheme from 'src/styles/theme.module.scss'

import { IIconProps } from './types'

export function getDefaultColor(type: IIconProps['type']) {
  switch (type) {
    case 'check':
      return stylesTheme.th_clr_primary_600
    case 'confirm':
    case 'success':
      return stylesTheme.th_clr_success
    case 'cancel':
    case 'error':
      return stylesTheme.th_clr_error
    case 'info':
      return stylesTheme.th_clr_info
    default:
      return undefined
  }
}

export function resolveColor(
  color: IIconProps['color'],
  type: IIconProps['type']
) {
  switch (color) {
    case true:
      return getDefaultColor(type)
    case false:
      return undefined
    default:
      return color
  }
}
