import { useRef, useState } from 'react'

import { format } from 'date-fns'

import { useOnChange, useOnMount } from 'src/hooks'

import { ILotState } from '../../../types'

export function LotTimer(props: {
  lot: Pick<ILotState, 'time_left' | 'price'>
  onExpired?(): void
  className?: string
}) {
  const { lot, onExpired, className } = props
  const time = useLotTimer({ ...lot, onExpired })
  return <div className={className}>{format(time, 'mm:ss')} remaining</div>
}

function useLotTimer(props: {
  time_left: number
  price: number
  onExpired?(): void
}) {
  const { time_left, price, onExpired } = props

  /* BE returns negative time values too, for some reason */
  const [time, setTime] = useState(Math.max(time_left, 0))
  const refTimer = useRef<NodeJS.Timer>()

  const refTime = useRef(time)
  refTime.current = time

  const SEC = 1000
  const MIN = 60 * SEC

  useOnMount(() => {
    const interval = SEC

    if (time <= 0) {
      onExpired?.()
      return
    }

    const cleanup = () => {
      const timer = refTimer.current
      if (timer) {
        clearTimeout(timer)
      }
    }

    refTimer.current = setInterval(() => {
      setTime(x => Math.max(0, x - interval))

      if (refTime.current === 0) {
        cleanup()
        onExpired?.()
      }
    }, interval)

    return cleanup
  })

  /* Scum protection: if new bid made in under a minute before end, reset timer to 1 minute */
  useOnChange(price, () => {
    if (time < MIN) {
      setTime(MIN)
    }
  })

  return time
}
