import { Link } from 'src/components'

import bellImg from './bell.svg'

import styles from './UserNotificationButton.module.scss'

export function UserNotificationButton(props: { link: string; id?: string }) {
  const { link, id } = props
  return (
    <Link className={styles.button} variant="btn-tertiary" to={link} id={id}>
      <img className={styles.icon} src={bellImg} alt="Bell" />
    </Link>
  )
}
