import { WalletProvider } from '../../../types'

export interface IWalletWizardState {
  step: WizardStep
  provider: WalletProvider | undefined
  name: string
  skipIntro: boolean
  isAgreementSigned: boolean
}

export enum WizardStep {
  Intro = 'intro',
  SelectProvider = 'select-provider',
  WalletName = 'wallet-name',
  Overview = 'overview',
  SignAgreement = 'sign-agreement',
  CreateWallet = 'create-wallet',
}

export const STEPS_ORDER: ReadonlyArray<WizardStep> = [
  WizardStep.Intro,
  WizardStep.SelectProvider,
  WizardStep.WalletName,
  WizardStep.Overview,
  WizardStep.SignAgreement,
  WizardStep.CreateWallet,
]

//#region utils

function resolveStepsOrder(state: Pick<IWalletWizardState, 'skipIntro'>) {
  return state.skipIntro ? STEPS_ORDER.slice(1) : STEPS_ORDER
}

function getStepIdxData(
  step: WizardStep,
  state: Parameters<typeof resolveStepsOrder>[0]
) {
  const order = resolveStepsOrder(state)
  const idx = order.indexOf(step)
  return [order, idx] as const
}

export const isAtFirstStep = (state: IWalletWizardState) =>
  resolveStepsOrder(state)[0] === state.step

export const isValidStepName = (step: unknown): step is WizardStep =>
  STEPS_ORDER.includes(step as WizardStep)

//#endregion

//#region validation

/**
 * Checks if the given step is completed
 * (meaning, the next step is accessible)
 */
const STEP_VALIDATORS: Partial<
  Record<WizardStep, (state: IWalletWizardState) => boolean>
> = {
  [WizardStep.SelectProvider]: state => state.provider !== undefined,
  [WizardStep.WalletName]: state => state.name !== '',
  [WizardStep.SignAgreement]: state => state.isAgreementSigned,
}

export function validateStepState(step: WizardStep, state: IWalletWizardState) {
  return STEP_VALIDATORS[step]?.(state) ?? true
}

export function checkStepAccess(step: WizardStep, state: IWalletWizardState) {
  const [order, idx] = getStepIdxData(step, state)
  const pastSteps = order.slice(0, idx)
  return pastSteps.every(step => validateStepState(step, state))
}

/**
 * Check if the given step is accessible,
 * and if not - replace it with the closest accessible one
 */
export function normalizeStep(
  step: WizardStep,
  state: IWalletWizardState
): WizardStep {
  const [order, idx] = getStepIdxData(step, state)

  /* This is possible if step is 'Intro' when `state.skipInto == true` */
  if (idx === -1) {
    return order[0]
  }

  const pastSteps = order.slice(0, idx)
  for (const pastStep of pastSteps) {
    if (!validateStepState(pastStep, state)) {
      return pastStep
    }
  }

  return step
}

export function normalizeWizardState(
  state: IWalletWizardState
): IWalletWizardState {
  return {
    ...state,
    step: normalizeStep(state.step, state),
  }
}

//#endregion

//#region navigation

export function getNextStep(
  direction: 1 | -1,
  baseStep: WizardStep,
  skipIntro = false
): WizardStep {
  const [order, idx] = getStepIdxData(baseStep, { skipIntro })
  const nextIdx = clamp(0, order.length - 1, idx + direction)
  const nextStep = order[nextIdx]
  return nextStep
}

const clamp = (min: number, max: number, value: number): number =>
  Math.max(min, Math.min(max, value))

//#endregion
