import { useMemo } from 'react'
import { Column } from 'react-table'

import { BlockchainAddress, Flex, RTQSuspender, Table } from 'src/components'
import { getChainName } from 'src/utils'

import { WalletRTQApi } from '../../../../api'
import { IWalletDefiAsset, WalletID } from '../../../../types'
import styles from '../AssetsList/AssetsList.module.scss'

export function DefiAssets(props: { wallet: WalletID }) {
  const { wallet } = props
  const state = WalletRTQApi.endpoints.getWalletDefiAssets.useQuery({ wallet })
  const columns = useColumns()
  return (
    <RTQSuspender state={state}>
      {assets => (
        <Table
          data={assets}
          columns={columns}
          initialState={{ pageSize: 999 }}
          showPagination={false}
        />
      )}
    </RTQSuspender>
  )
}

function useColumns() {
  return useMemo(
    (): Column<IWalletDefiAsset>[] => [
      {
        Header: 'Asset',
        accessor: 'name',
        Cell: ({ row }) => (
          <Flex align="center" gap="0.5em">
            <img
              src={row.original.icon}
              alt={row.original.display_name}
              style={{ height: '2em' }}
            />
            <b>{row.original.display_name}</b>
          </Flex>
        ),
      },
      {
        accessor: 'chain_id',
        Header: 'Chain',
        Cell: ({ value }) => getChainName(value),
      },
      {
        accessor: 'balance_usd',
        Header: 'Balance',
        Cell: ({
          value: balance_usd,
          row: {
            original: { balance, asset_symbol },
          },
        }) => (
          <div className={styles.cell_balance}>
            <div className={styles.cell_balance_crypto}>
              {balance} {asset_symbol}
            </div>
            <div className={styles.cell_balance_usd}>
              {renderUSD(balance_usd)}
            </div>
          </div>
        ),
      },
      {
        accessor: 'price',
        Header: 'Price',
        Cell: ({ value }) => renderUSD(value),
      },
      {
        accessor: 'address',
        Header: 'Address',
        Cell: ({ value }) => (
          <BlockchainAddress copy>{value}</BlockchainAddress>
        ),
      },
    ],
    []
  )
}

function renderUSD(x: number) {
  return `~$${x.toFixed(2)}`
}
