import { isGuestUser } from './lib'
import { UserAppState } from './types'

type SelectedUser<State extends UserAppState> = State['user']

export const selectUserState = <State extends UserAppState>(
  state: State
): SelectedUser<State> => state.user

export const selectUser = <State extends UserAppState>(
  state: State
): SelectedUser<State>['data'] => selectUserState(state).data

export const selectIsGuest = (state: UserAppState) =>
  isGuestUser(selectUser(state))
export const selectIsAuthorized = (state: UserAppState) => !selectIsGuest(state)
