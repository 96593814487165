import React, { Ref, isValidElement } from 'react'

import clsx from 'clsx'
import { isPrimitive } from 'utility-types'

import { resolveErrorMessage } from 'src/utils'

import { Icon } from '../Icon'

import styles from './styles.module.scss'

export interface IErrorMessageProps extends IStyled {
  children: unknown
  inline?: boolean
  block?: boolean
}

export const ErrorMessage = React.memo(
  React.forwardRef<HTMLElement, IErrorMessageProps>(function ErrorMessage(
    props,
    ref
  ) {
    const {
      children: error,
      inline = false,
      block = false,
      className,
      style,
    } = props

    const Tag = inline ? 'span' : 'div'
    const hasError =
      error !== undefined &&
      error !== null &&
      error !== '' &&
      !(Array.isArray(error) && error.length === 0)

    if (!hasError) return null

    const hasReactElements =
      Array.isArray(error) &&
      error.some(x => isValidElement(x) || isPrimitive(x))
    const msg = resolveErrorMessage(hasReactElements ? <>{error}</> : error)

    return (
      <Tag
        className={clsx(className, styles.root, {
          [styles.block]: block,
        })}
        style={style}
        ref={ref as Ref<HTMLDivElement>}
      >
        {block && <Icon type="error" className={styles.icon} />}
        {msg}
      </Tag>
    )
  })
)

// Shorthand for easy compatibility with patterns like `<Component as={Error} />`
export function ErrorBlock(props: Omit<IErrorMessageProps, 'block'>) {
  return <ErrorMessage {...props} block />
}
