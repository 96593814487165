import { ReactElement } from 'react'

import { routes } from 'src/apps/cabinet'
import { Page, RTQSuspender } from 'src/components'
import theme from 'src/styles/theme.module.scss'

import { BiddingRTQApi } from '../../api'
import { IProductTier, IStoreProduct } from '../../types'
import { ProductTiersList } from '../Bidding/ProductTiersList'
import { ProductContext } from '../Bidding/ProuctContext'

export function StoreProductPage(props: { productId: string }) {
  const { productId } = props

  return (
    <ProductProvider productId={productId}>
      {product => (
        <Page
          title={product.name}
          description={product.description}
          goBack={{ label: 'Back to App Store', link: routes.avatar.store }}
        >
          <ProductTiersProvider productId={productId}>
            {tiers => (
              <ProductContext.Provider value={product}>
                <ProductTiersList items={tiers} />
              </ProductContext.Provider>
            )}
          </ProductTiersProvider>

          <div style={{ color: theme.th_clr_txt_hint }}>
            You can buy at a fixed price, or pay less by making a bid.
          </div>
        </Page>
      )}
    </ProductProvider>
  )
}

function ProductProvider(props: {
  productId: string
  children: (product: IStoreProduct) => ReactElement
}) {
  const { productId, children } = props
  const state = BiddingRTQApi.endpoints.getProducts.useQuery(undefined, {
    selectFromResult: state => ({
      ...state,
      data: state.data?.find(x => x.id === productId),
    }),
  })

  return <RTQSuspender state={state}>{children}</RTQSuspender>
}

function ProductTiersProvider(props: {
  productId: string
  children: (product: IProductTier[]) => ReactElement
}) {
  const { productId, children } = props
  const state = BiddingRTQApi.endpoints.getProductTiers.useQuery({
    product: productId,
  })
  return <RTQSuspender state={state}>{children}</RTQSuspender>
}
