// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Connected_wc_icon_header__CpHpy {\n  width: 52px;\n  height: 32px;\n}\n\n.Connected_wallet_layout_compact__2wc1Z {\n  border: none;\n  padding: 0;\n  width: 100%;\n}\n\n.Connected_app_icon__sDTYQ {\n  border-radius: 50%;\n  --size: 2.25em;\n  width: var(--size);\n  height: var(--size);\n}\n\n.Connected_app_info__Fpxqe {\n  /* none */\n}\n\n.Connected_app_link__1_2Gz {\n  font-size: inherit;\n  word-break: break-word;\n  padding: 2px;\n}\n\n.Connected_icon_asset__KGIwZ {\n  --size: 1.25em;\n}\n\n.Connected_chain_name__y1ajx {\n  --badge-clr-icon: var(--th-clr-success-500);\n}\n\n.Connected_btn_disconnect__NbGeu {\n  width: 100%;\n}", "",{"version":3,"sources":["webpack://src/apps/cabinet/features/Wallet/pages/WalletDetails/WalletConnect/Connected/Connected.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF;;AAEA;EACE,YAAA;EACA,UAAA;EACA,WAAA;AACF;;AAEA;EACE,kBAAA;EACA,cAAA;EACA,kBAAA;EACA,mBAAA;AACF;;AAEA;EACE,SAAA;AACF;;AAEA;EACE,kBAAA;EACA,sBAAA;EACA,YAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,2CAAA;AACF;;AAEA;EACE,WAAA;AACF","sourcesContent":[".wc_icon_header {\n  width: 52px;\n  height: 32px;\n}\n\n.wallet_layout_compact {\n  border: none;\n  padding: 0;\n  width: 100%;\n}\n\n.app_icon {\n  border-radius: 50%;\n  --size: 2.25em;\n  width: var(--size);\n  height: var(--size);\n}\n\n.app_info {\n  /* none */\n}\n\n.app_link {\n  font-size: inherit;\n  word-break: break-word;\n  padding: 2px;\n}\n\n.icon_asset {\n  --size: 1.25em;\n}\n\n.chain_name {\n  --badge-clr-icon: var(--th-clr-success-500);\n}\n\n.btn_disconnect {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wc_icon_header": "Connected_wc_icon_header__CpHpy",
	"wallet_layout_compact": "Connected_wallet_layout_compact__2wc1Z",
	"app_icon": "Connected_app_icon__sDTYQ",
	"app_info": "Connected_app_info__Fpxqe",
	"app_link": "Connected_app_link__1_2Gz",
	"icon_asset": "Connected_icon_asset__KGIwZ",
	"chain_name": "Connected_chain_name__y1ajx",
	"btn_disconnect": "Connected_btn_disconnect__NbGeu"
};
export default ___CSS_LOADER_EXPORT___;
