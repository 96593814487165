import { ImgHTMLAttributes, ReactElement, useState } from 'react'

export function FallbackImg<Props extends ImgHTMLAttributes<HTMLImageElement>>(
  props: Props & {
    fallback?: ReactElement | ((props: Props) => ReactElement)
  }
) {
  const { fallback, onError, src, alt, ...rest } = props

  const [isError, setIsError] = useState(false)
  if (src === undefined || isError) {
    const $fallback =
      typeof fallback === 'function' ? fallback(props) : fallback
    return $fallback ?? null
  }

  return (
    <img
      {...rest}
      src={src}
      alt={alt}
      onError={(...args) => {
        setIsError(true)
        onError?.(...args)
      }}
    />
  )
}
