import { ReactNode } from 'react'

import { ErrorMessage, Icon, RTQSuspender, Tooltip } from 'src/components'

import { WalletRTQApi } from '../../../api'
import { formatWalletBalance } from '../../../components'
import { IWalletListItem } from '../../../types'

import styles from './Wallet.module.scss'

export function WalletBalance(props: { wallet: IWalletListItem }) {
  const { wallet } = props

  const state = WalletRTQApi.endpoints.getWalletAssets.useQuery(
    {
      provider: wallet.type,
      wallet: wallet.address,
    },
    {
      selectFromResult: state => ({
        ...state,
        data: state.data?.balance_usd ?? '0',
      }),
    }
  )

  return (
    <RTQSuspender state={state} renderError={renderBalanceError}>
      {balance => <BalanceView>{balance}</BalanceView>}
    </RTQSuspender>
  )
}

function BalanceView(props: { children: string }) {
  const { children: value } = props
  const formatted = formatWalletBalance(value)
  const currency = '$' // by design, we have balance stored specifically in USD
  return (
    <>
      ~{currency}
      {formatted}
    </>
  )
}

function renderBalanceError(err: ReactNode) {
  return (
    <Tooltip overlay={<ErrorMessage>{err}</ErrorMessage>} placement="right">
      <ErrorMessage className={styles.balance_error}>
        <BalanceView>0</BalanceView> <Icon type="error" />
      </ErrorMessage>
    </Tooltip>
  )
}
