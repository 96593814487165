import { ReactNode } from 'react'
import {
  FieldValues,
  UseFormReturn,
  UseFormStateReturn,
  useFormContext as useFormContextBase,
  useFormState,
  useWatch,
} from 'react-hook-form'

import { IUseFormReturn } from './types'

export function FormContext<Values extends FieldValues>(props: {
  children: (data: {
    ctx: UseFormReturn<Values>
    state: UseFormStateReturn<Values>
    values: Values
  }) => ReactNode
}) {
  const { children } = props
  const ctx = useFormContextBase<Values>()
  const state = useFormState<Values>()
  const values = useWatch<Values>({})
  return (
    <>
      {children({
        ctx,
        state,
        values: values as Values,
      })}
    </>
  )
}

// Version returning our customized form structure
export const useFormContext = useFormContextBase as <
  TFieldValues extends FieldValues = FieldValues
>() => IUseFormReturn<TFieldValues>
