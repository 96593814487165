import { MouseEventHandler, ReactElement, useCallback } from 'react'
import BaseModal from 'react-modal'

import clsx from 'clsx'

import { useSwitch } from 'src/hooks'

import { Icon } from '../Icon'

import { IModalProps } from './types'

import styles from './Modal.module.scss'

export function Modal(props: IModalProps) {
  const {
    children,
    isOpen,
    onRequestClose,
    defaultRequired = false,
    showCloseBtn = !defaultRequired,
    shouldCloseOnEsc = !defaultRequired,
    shouldCloseOnOverlayClick = !defaultRequired,
    portalClassName,
    floatCloseBtn,
    icon,
    footer,
    title,
    subtitle,
    modalBodyClassName,
    modalBodyStyle,
    idSlotIcon,
    idSlotTitle,
    idSlotSubtitle,
    idSlotFooter,
  } = props
  const isControlled = isOpen !== undefined
  const [isOpenControlled, toggle] = useSwitch(isOpen ?? false)

  const handleRequestClose = useCallback<
    NonNullable<typeof onRequestClose>
  >(() => {
    onRequestClose?.()
    if (!isControlled) {
      toggle.off()
    }
  }, [onRequestClose, isControlled, toggle])

  const hasHeader =
    icon !== undefined ||
    title !== undefined ||
    subtitle !== undefined ||
    showCloseBtn

  return (
    <BaseModal
      {...props}
      portalClassName={clsx(portalClassName, styles.portal)}
      isOpen={isOpen ?? isOpenControlled}
      onRequestClose={handleRequestClose}
      overlayElement={renderEventInterceptor}
      shouldCloseOnEsc={shouldCloseOnEsc}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    >
      <div className={styles.content}>
        <If condition={hasHeader}>
          <div className={styles.header_container}>
            <div className={styles.header}>
              <If condition={icon !== undefined || idSlotIcon !== undefined}>
                <div className={styles.header_icon} id={idSlotIcon}>
                  {icon}
                </div>
              </If>

              <div className={styles.header_content}>
                <If
                  condition={title !== undefined || idSlotTitle !== undefined}
                >
                  <h4 className={styles.title} id={idSlotTitle}>
                    {title}
                  </h4>
                </If>
                <If
                  condition={
                    subtitle !== undefined || idSlotSubtitle !== undefined
                  }
                >
                  <h5 className={styles.subtitle} id={idSlotSubtitle}>
                    {subtitle}
                  </h5>
                </If>
              </div>

              <If condition={showCloseBtn}>
                <Icon
                  type="close"
                  className={clsx(styles.btn_close, {
                    [styles.btn_close_float]: floatCloseBtn,
                  })}
                  size={24}
                  onClick={handleRequestClose}
                />
              </If>
            </div>
          </div>
        </If>

        <div
          className={clsx(styles.body, modalBodyClassName)}
          style={modalBodyStyle}
        >
          {children}
        </div>

        <If condition={footer !== undefined || idSlotFooter !== undefined}>
          <div className={styles.footer} id={idSlotFooter}>
            {footer}
          </div>
        </If>
      </div>
    </BaseModal>
  )
}

function renderEventInterceptor(
  props: { onClick?: MouseEventHandler },
  children: ReactElement
) {
  return (
    <div
      {...props}
      onClick={e => {
        e.stopPropagation()
        props.onClick?.(e)
      }}
    >
      {children}
    </div>
  )
}
