import React, { HTMLInputTypeAttribute, useRef } from 'react'

import clsx from 'clsx'

import { useOnMount } from 'src/hooks'
import { combineRefs } from 'src/utils'

import { IInputProps } from './types'

import styles from './styles.module.scss'

const noop = () => {}

function BaseInput<Type extends HTMLInputTypeAttribute = 'text'>(
  props: IInputProps<Type>
) {
  const {
    className,
    type = 'text',
    value = '',
    invalid = false,
    onChange = noop,
    autoFocus,
    autoSelect,
    refInput,
    // This covers console warnings, which Chrome generates for password fields without `autocomplete` attr
    autoComplete = type === 'password' ? 'password' : undefined,
    ...rest
  } = props

  const isCheckbox = type === 'checkbox' || type === 'radio'
  const ref = useRef<HTMLInputElement>(null)

  useOnMount(() => {
    if (autoFocus) {
      ref.current?.focus()
    }
    if (autoSelect) {
      ref.current?.select()
    }
  })

  return (
    <input
      {...rest}
      className={clsx(className, styles.base, {
        [styles.invalid]: invalid,
        [styles.check]: isCheckbox,
        [styles.text]: !isCheckbox,
      })}
      ref={combineRefs(refInput, ref)}
      type={type}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      value={value}
      onChange={e => {
        onChange(e.target.value, e)
      }}
    />
  )
}

export default React.memo(BaseInput) as typeof BaseInput
