import { createApi as createRTQApi } from '@reduxjs/toolkit/query/react'

import {
  API_BASE_URL,
  axiosBaseQuery,
  rtqPromisifyBaseQuery,
  rtqTags,
} from 'src/api'
import {
  BlockchainAsset,
  IPaginationMeta,
  IPaginationMetaRaw,
  IPaginationQuery,
  IPaginationResponse,
  IPaginationResponseRaw,
} from 'src/types'

import {
  IDefiProduct,
  ITransactionAPIParams,
  ITransactionHistoryEntry,
  ITransactionPayload,
  ITransactionVerifyResponse,
  IWCCallPayloadSign,
  IWCCallPayloadTxn,
  IWCCallResponseSign,
  IWCCallResponseTxn,
  TransactionVerifyStatus,
  TxnWarningSeverity,
  WalletID,
} from '../../types'

// ---

const { REACT_APP_BWI_JWT: BWI_JWT_DEV } = process.env

// ---

export const composeAssetId = (x: {
  wallet: WalletID
  asset: BlockchainAsset
}) => `${x.wallet}/${x.asset}`

// ---

export const tags = rtqTags(['Wallet', 'Asset', 'AssetAddress'])

// ---

export default createRTQApi({
  reducerPath: 'wallet_api',

  baseQuery: axiosBaseQuery({
    /** It must start with `/bwi`, not with `/api`.
     * BWI requests are proxied to a different host than all `/api` requests.
     * See 'src/setupProxy.js' */
    baseURL: `/bwi/${API_BASE_URL}`,

    headers: {
      ...(BWI_JWT_DEV === undefined
        ? undefined
        : {
            Authorization: `Bearer ${BWI_JWT_DEV}`,
          }),
    },
  }),

  tagTypes: tags.toArray(),

  endpoints: builder => ({
    /** @link https://dcspoc.atlassian.net/browse/PLT-482?focusedCommentId=10667 */
    sendTransaction: builder.mutation<
      IWCCallResponseTxn,
      ITransactionAPIParams
    >({
      query: ({ provider, ...rest }) => {
        const payload: ITransactionPayload = { ...rest }
        return {
          url: `payment/${provider}`,
          method: 'POST',
          data: payload,
        }
      },
      invalidatesTags: (result, err, { from: wallet, ...params }) => [
        tags.Asset(composeAssetId({ ...params, wallet })),
      ],
    }),
    verifyTransaction: builder.mutation<
      ITransactionVerifyResponse,
      ITransactionAPIParams & { source: 'polity' | 'wc' }
    >({
      queryFn: async ({ source, provider, ...payload }, rtq, _, baseQuery) => {
        let response: ITransactionVerifyResponse
        try {
          response = await rtqPromisifyBaseQuery<ITransactionVerifyResponse>(
            baseQuery({
              /* Functionality is the same, payload is the same,
               * but endpoints are different for some BE reasons */
              url:
                source === 'polity'
                  ? `payment/${provider}/scan`
                  : `assets/${payload.asset}/invoke/scan`,
              method: 'POST',
              data: payload,
            })
          )
        } catch {
          /* @see https://dcspoc.atlassian.net/browse/PLT-482?focusedCommentId=10733 */
          response = {
            action: TransactionVerifyStatus.Warn,
            warnings: [
              {
                kind: '',
                severity: TxnWarningSeverity.Warning,
                message:
                  // TODO: get proper "official" text from customer
                  "We can't verify safety of this transaction. Proceed at your own risk.",
              },
            ],
          }
        }
        return { data: response }
      },
    }),

    getTransactionsHistory: builder.query<
      IPaginationResponse<ITransactionHistoryEntry>,
      {
        wallet: WalletID
        asset: BlockchainAsset
      } & IPaginationQuery
    >({
      query: ({ wallet, asset, pageIndex, pageSize }) => ({
        url: `wallet/${wallet}/${asset}/transactions`,
        params: {
          limit: pageSize,
          offset:
            pageIndex === undefined || pageSize === undefined
              ? undefined
              : pageIndex * pageSize,
        },
      }),
      transformResponse(
        resp: IPaginationResponseRaw<ITransactionHistoryEntry>
      ) {
        const { data, pagination } = resp
        return {
          data,
          pagination: parsePagination(pagination, data.length),
        }
      },
    }),

    getProducts: builder.query<IDefiProduct[], void>({
      query: () => 'defi/products',
      transformResponse(data: { defi_products: IDefiProduct[] }) {
        return data.defi_products
      },
    }),

    invokeWalletConnect: builder.mutation<
      IWCCallResponseTxn | IWCCallResponseSign,
      IWCCallPayloadTxn | IWCCallPayloadSign
    >({
      query: data => {
        const { asset, ...rest } = data
        return {
          url: `assets/${asset}/invoke`,
          method: 'POST',
          data: rest,
        }
      },
    }),
  }),
})

// ---

function parsePagination(
  data: IPaginationMetaRaw,
  itemsInCurrentResponse: number
): IPaginationMeta {
  const { total: totalItems, prev: lnkPrev, next: lnkNext } = data
  const lnk = lnkNext || lnkPrev
  if (lnk === '') {
    return {
      pageIndex: 0,
      pageSize: itemsInCurrentResponse,
      pageCount: itemsInCurrentResponse === 0 ? 0 : 1,
      totalItems,
    }
  }

  const { searchParams } = new URL(lnk)

  /* If param is missing in URL – `.get` returns `null`
   * If param is present but has no value – `.get` returns an empty string.
   * Thus, default value should be resolved via `||` operator, not `??` */
  const limit = +(searchParams.get('limit') || itemsInCurrentResponse)
  const offset = +(searchParams.get('offset') || 0)

  return {
    pageIndex: limit === 0 ? 0 : Math.max(0, Math.ceil(offset / limit) - 1),
    pageSize: limit,
    pageCount: limit === 0 ? 0 : Math.ceil(totalItems / limit),
    totalItems,
  }
}
