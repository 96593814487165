import { useContext } from 'react'
import { toast } from 'react-toastify'

import { Flex, Icon, IconButton, Suspendable, useModal } from 'src/components'

import { WalletRTQApi } from '../../../api'
import { WalletContext } from '../WalletContext'

import { WalletNameForm } from './WalletNameForm'

export function WalletName(props: { name: string }) {
  const { name } = props
  const wallet = useContext(WalletContext)

  const [renameWallet, { isLoading }] =
    WalletRTQApi.endpoints.renameWallet.useMutation()

  const formModal = useNameForm({
    name: wallet.name,
    async onSubmit({ name }) {
      return await renameWallet({ name, wallet: wallet.address }).unwrap()
    },
  })

  return (
    <Suspendable suspended={isLoading}>
      <Flex align="center">
        {name}
        <IconButton
          icon="edit"
          title="Rename wallet"
          variant="secondary"
          size="sm"
          onClick={formModal.open}
        />
        {formModal.$element}
      </Flex>
    </Suspendable>
  )
}

function useNameForm(params: {
  name: string
  onSubmit(data: { name: string }): Promise<void>
}) {
  const { name, onSubmit } = params
  return useModal(
    {
      title: 'Rename wallet',
      subtitle: 'Enter new name for this wallet',
      icon: <Icon type="wallet" box size="md" />,
      style: { content: { minWidth: 400 } },
    },
    ({ close }) => (
      <WalletNameForm
        defaultName={name}
        onCancel={close}
        onSubmit={async ({ name }) => {
          try {
            await onSubmit({ name })
            close()
          } catch (e) {
            const msg = (e as Error).message
            toast.error(msg)
          }
        }}
      />
    )
  )
}
