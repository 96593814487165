import { Button, Flex, Link } from 'src/components'

import { ProviderFeatures } from './ProviderFeatures'

import styles from './ProviderCard.module.scss'

export interface IProviderCardProps {
  title: string
  logo: string
  logoCompact: string
  detailsLink: string
  description: string
  features: string[]
  onSelect(): void
}

export const ProviderCard = (props: IProviderCardProps) => {
  const {
    title,
    logo,
    logoCompact,
    detailsLink,
    description,
    features,
    onSelect,
  } = props

  return (
    <div className={styles.card}>
      <img className={styles.card_img} src={logo} alt={`${title} logotype`} />
      <div className={styles.card_content}>
        <div className={styles.card_header}>
          <img src={logoCompact} alt="" />
          <h3>{title}</h3>
        </div>
        <Flex column gap={8}>
          <p>{description}</p>
          <Link external endIcon={null} to={detailsLink} variant="primary">
            Learn more about {title}
          </Link>
          <h4>Wallet Features</h4>
          <ProviderFeatures features={features} />
        </Flex>
      </div>
      <div className={styles.card_footer}>
        <Button variant="secondary" onClick={onSelect}>
          Select
        </Button>
      </div>
    </div>
  )
}
