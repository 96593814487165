import { ReactNode } from 'react'

import { CabinetLayout } from '../../../components'
import { routes } from '../../../routes'

import { AvatarNavigation } from './Navigation'

export function AvatarLayout(props: { children: ReactNode }) {
  const { children } = props
  return (
    <CabinetLayout
      hrefHome={routes.avatar.dashboard}
      hrefInbox={routes.avatar.inbox}
      nav={<AvatarNavigation />}
    >
      {children}
    </CabinetLayout>
  )
}
