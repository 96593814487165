import { AgreementTypeIcon, Loader, Page } from 'src/components'
import { useQueryParams } from 'src/hooks'
import { AgreementType } from 'src/types'

import { AdvisorApi } from '../../api'
import { IAgreementFormQueryParams } from '../../types'

import {
  AgreementFormNodeRent,
  AgreementFormSafeheronAcquisition,
  AgreementTypeSelector,
} from './components'

export function AgreementFormPage() {
  const { type, user } = useAgreementQueryParams()

  const { data: avatars = [], isLoading: isLoadingAvatars } =
    AdvisorApi.endpoints.getAvatars.useQuery()

  return (
    <Page
      title="Create New Agreement"
      description={
        type !== undefined ? (
          <AgreementTypeIcon size={24} type={type} showTitle />
        ) : (
          'Choose agreement type'
        )
      }
      alignContent="center"
    >
      <Choose>
        <When condition={isLoadingAvatars}>
          <Loader />
        </When>

        <When condition={type === AgreementType.SafeheronAcquisition}>
          <AgreementFormSafeheronAcquisition
            avatars={avatars}
            defaultAvatarId={user}
          />
        </When>

        <When condition={type === AgreementType.NodeAcquisition}>
          <AgreementFormNodeRent avatars={avatars} />
        </When>

        <Otherwise>
          <AgreementTypeSelector user={user} />
        </Otherwise>
      </Choose>
    </Page>
  )
}

function useAgreementQueryParams() {
  const { type, user } = useQueryParams<IAgreementFormQueryParams>()
  const hasType =
    type !== undefined && Object.values(AgreementType).includes(type)
  return {
    type: hasType ? type : undefined,
    user,
  }
}
