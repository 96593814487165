import jwtDecode from 'jwt-decode'

import { IBaseUser, IJWTData } from './types'

export const GUEST_USER_ID = -1

export const ERROR_NO_CREDENTIALS_FOUND = '@@/ERROR_NO_CREDENTIALS_FOUND'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function getUserHomePage(user: IBaseUser) {
  return '/'
}

export function decodeUserData(access_token: string): IBaseUser {
  const { sub: id, ...rest } = jwtDecode<IJWTData>(access_token)
  return { ...rest, id: +id }
}

export function isGuestUser(user: IBaseUser) {
  return user.id === GUEST_USER_ID
}
