import * as H from 'history'
import qs from 'qs'

export function parseQueryString(string: string) {
  return qs.parse(string, { ignoreQueryPrefix: true })
}

export function updateSearchString<T extends Dict>(
  search: string,
  data: Partial<T>
): string {
  const current = parseQueryString(search)
  return qs.stringify({ ...current, ...data })
}

export function updateLocationQuery<T extends Dict>(
  loc: H.Location,
  data: Partial<T>
) {
  return { ...loc, search: updateSearchString<T>(loc.search, data) }
}
