import { ReactNode } from 'react'

import { Link } from 'src/components'
import { ReactComponent as Logo } from 'src/logo-polity.svg'

import { UserMenu } from '../UserMenu'
import { UserNotificationButton } from '../UserNotificationButton'

import styles from './Header.module.scss'

export interface IHeaderProps {
  children?: ReactNode
  hrefHome?: string
  /* TODO: if at later stages notification center will become a dropdown menu,
      instead of a separate page, remove this prop */
  hrefInbox?: string
}

export function Header(props: IHeaderProps) {
  const { children, hrefHome = '/', hrefInbox } = props
  return (
    <div className={styles.root}>
      <Link to={hrefHome}>
        <Logo className={styles.logo} />
      </Link>
      <div className={styles.content}>{children}</div>
      <div className={styles.user}>
        <If condition={hrefInbox !== undefined}>
          <UserNotificationButton link={hrefInbox as string} />
        </If>
        <UserMenu />
      </div>
    </div>
  )
}
