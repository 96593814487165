import React from 'react'

import { Button, Loader, Page, RTQSuspender } from 'src/components'

import { WalletRTQApi } from '../../../api'
import { WalletProvider } from '../../../types'

import styles from './SignAgreementStep.module.scss'

interface ISignAgreementStepProps {
  loading?: boolean
  provider: WalletProvider
  onSubmit(): void
}

export function SignAgreementStep({
  loading = false,
  provider,
  onSubmit,
}: ISignAgreementStepProps) {
  const agreementState =
    WalletRTQApi.endpoints.getWalletAgreement.useQuery(provider)

  return (
    <Page
      title={`Virtual Currency Wallet Agreement – ${getProviderLabel(
        provider
      )}`}
      description="Agree to the terms to complete the wallet setup"
      alignHeader="center"
      alignContent="center"
    >
      <RTQSuspender state={agreementState}>
        {agreement => (
          <div className={styles.root}>
            <div
              className={styles.doc}
              dangerouslySetInnerHTML={{ __html: agreement }}
            />
            <div className={styles.footer}>
              <Button
                onClick={onSubmit}
                disabled={loading}
                startIcon={loading && <Loader color="currentColor" />}
              >
                Agree & Continue
              </Button>
            </div>
          </div>
        )}
      </RTQSuspender>
    </Page>
  )
}

function getProviderLabel(provider: WalletProvider) {
  switch (provider) {
    case 'dfns':
      return 'DFNS'
    case 'safeheron':
      return 'Safeheron'
  }
}
