import { ReactNode, createContext } from 'react'

import { IPageProps } from './types'

type IPageSettingsContext = Partial<IPageProps>

export const PageSettingsContext = createContext<IPageSettingsContext>({})

/**
 * Intended to be used in basic layout components,
 * in case they need some common behavior for all pages inside.
 */
export function PageSettings(
  props: IPageSettingsContext & {
    children: ReactNode
  }
) {
  const { children, ...rest } = props
  return (
    <PageSettingsContext.Provider value={rest}>
      {children}
    </PageSettingsContext.Provider>
  )
}
