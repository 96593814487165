import { ReactNode } from 'react'
import { TbAlertTriangle, TbInfoCircle } from 'react-icons/tb'

import clsx from 'clsx'

import styles from './Alert.module.scss'

export type AlertVariant = 'success' | 'info' | 'warning' | 'error'

export interface IAlertProps extends IStyled {
  children?: ReactNode
  variant?: AlertVariant
  title?: string
  description?: string
}

export function Alert(props: IAlertProps) {
  const { className, children, variant, title, description, style } = props

  const IconComponent =
    variant === 'warning' || variant === 'error'
      ? TbAlertTriangle
      : TbInfoCircle

  return (
    <div
      style={style}
      className={clsx(styles.root, className, {
        [styles.variant_success]: variant === 'success',
        [styles.variant_info]: variant === 'info',
        [styles.variant_warning]: variant === 'warning',
        [styles.variant_error]: variant === 'error',
      })}
    >
      <IconComponent className={styles.icon} />

      <div className={styles.main}>
        <div className={clsx(styles.title, styles.not_empty)}>{title}</div>
        <div className={clsx(styles.description, styles.not_empty)}>
          {description}
        </div>
        <div className={styles.not_empty}>{children}</div>
      </div>
    </div>
  )
}
