import React, { ReactNode, useEffect, useMemo } from 'react'

type SourceObject = Parameters<typeof URL.createObjectURL>[0]

export function useObjectURL(obj: SourceObject): string {
  const src = useMemo(() => URL.createObjectURL(obj), [obj])

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(src)
    }
  }, [src])

  return src
}

export function ObjectURLProvider(props: {
  obj: SourceObject
  children: (src: string) => ReactNode
}) {
  const { obj, children } = props
  const src = useObjectURL(obj)
  return React.createElement(React.Fragment, undefined, children(src))
}
