import { Badge } from '../../base/Badge'
import { Button } from '../../base/Button'

import { IPaginationProps } from './types'

import styles from './pagination.module.scss'

const DEFAULT_PAGE_SIZES = [10, 20, 30]

export default function Pagination<Row extends object>(
  props: IPaginationProps<Row>
) {
  const {
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    pageIndex,
    pageSize,
    pageSizesList = DEFAULT_PAGE_SIZES,
    showPageSizeSelector = false,

    renderPageCounter = ({ current, total }) => (
      <Badge border className={styles.badge}>
        Page
        <b>{current}</b>
        of
        <b>{total}</b>
      </Badge>
    ),
  } = props

  return (
    <div className={styles.pagination}>
      <div className={styles.btns_holder}>
        <Button
          className={styles.btn_navigation}
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          {'<<'}
        </Button>

        <Button
          className={styles.btn_navigation}
          onClick={previousPage}
          disabled={!canPreviousPage}
        >
          {'<'}
        </Button>

        <Button
          className={styles.btn_navigation}
          onClick={nextPage}
          disabled={!canNextPage}
        >
          {'>'}
        </Button>

        <Button
          className={styles.btn_navigation}
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          {'>>'}
        </Button>
      </div>

      <span className={styles.page_counter}>
        {renderPageCounter({
          current: pageIndex + 1,
          total: pageOptions.length,
        })}
      </span>

      <If condition={showPageSizeSelector}>
        <span className={styles.page_size}>
          <Badge border className={styles.badge}>
            <select
              className={styles.page_size_selector}
              value={pageSize}
              onChange={e => {
                setPageSize(+e.target.value)
              }}
            >
              {pageSizesList.map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
            per page
          </Badge>
        </span>
      </If>
    </div>
  )
}
