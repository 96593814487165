import { goBack as goBackHistory } from 'connected-react-router'
import { toast } from 'react-toastify'

import { useDispatch, useUser } from 'src/apps/cabinet/store'
import { useOnChange, useSlice, useSyncQueryParams } from 'src/hooks'

import { WalletRTQApi } from '../../../api'

import { slice } from './slice'
import {
  IWalletWizardState,
  WizardStep,
  isAtFirstStep,
  isValidStepName,
  normalizeWizardState,
} from './wizard'

// ---

type WalletFlowQueryParams = Pick<
  IWalletWizardState,
  'step' | 'name' | 'provider'
>

function useWalletFlowQueryParams() {
  return useSyncQueryParams<WalletFlowQueryParams>({
    parse: {
      step: x => (isValidStepName(x) ? x : WizardStep.Intro),
      provider: x => (x === 'dfns' || x === 'safeheron' ? x : undefined),
    },
  })
}

// ---

interface IWalletFlowOptions {
  skipIntro?: boolean
}

function useWalletFlowSlice(opts: IWalletFlowOptions = {}) {
  const { skipIntro = false } = opts

  const [queryParams, setQueryParams] = useWalletFlowQueryParams()

  const tuple = useSlice(slice, state =>
    normalizeWizardState({ ...state, ...queryParams, skipIntro })
  )

  // ---
  // Sync state changes to query params

  const [state] = tuple
  useOnChange(state, state => {
    const { name, provider, step } = state
    const params: WalletFlowQueryParams = { name, provider, step }
    setQueryParams(params, { replace: true })
  })

  // ---

  return tuple
}

export function useWalletFlow(opts: IWalletFlowOptions = {}) {
  const dispatch = useDispatch()
  const user = useUser()

  const [state, actions] = useWalletFlowSlice(opts)

  const [submitSignAgreement, { isLoading: isSigningAgreement }] =
    WalletRTQApi.endpoints.signWalletAgreement.useMutation()

  const [submitCreateWallet, { isLoading: isCreatingWallet }] =
    WalletRTQApi.endpoints.createWallet.useMutation()

  return [
    {
      ...state,
      isSigningAgreement,
      isCreatingWallet,
    },
    {
      ...actions,

      // Override "goBack" action: when at first step, redirect user back to referrer page
      goBack: () =>
        isAtFirstStep(state) ? dispatch(goBackHistory()) : actions.goBack(),

      signAgreement: async () => {
        const { provider } = state
        if (provider !== undefined) {
          await showPromiseError(
            submitSignAgreement({ avatarId: user.id, provider }).unwrap(),
            'sign-agreement'
          )
          actions.setAgreementSigned()
        } else {
          console.error('[signAgreement]: No provider selected')
        }
      },

      createWallet: async () => {
        const { name, provider } = state
        if (provider !== undefined && name !== undefined) {
          await showPromiseError(
            submitCreateWallet({ name, provider }).unwrap(),
            'create-wallet'
          )
        } else {
          console.error(
            '[createWallet]: Both provider and name must be selected. Got following: %o',
            { provider, name }
          )
        }
      },
    },
  ] as const
}

// ---

function showPromiseError<T>(promise: Promise<T>, errorId: string): Promise<T> {
  return toast.promise(promise, {
    error: {
      autoClose: false,
      toastId: errorId,
      render: ({ data }) => data.message ?? data,
    },
  })
}
