import { createSlice } from '@reduxjs/toolkit'

import { WalletProvider } from '../../../types'

import {
  IWalletWizardState,
  STEPS_ORDER,
  checkStepAccess,
  getNextStep,
} from './wizard'

export const slice = createSlice({
  name: 'create-wallet-flow',

  initialState: (): IWalletWizardState => ({
    step: STEPS_ORDER[0],
    provider: undefined,
    name: '',
    skipIntro: false,
    isAgreementSigned: false,
  }),

  reducers: create => {
    const navigate = (direction: 1 | -1, state: IWalletWizardState) => {
      const nextStep = getNextStep(direction, state.step, state.skipIntro)
      const accessAllowed = direction === -1 || checkStepAccess(nextStep, state)

      if (accessAllowed) {
        state.step = nextStep
      }
    }

    const goBack = navigate.bind(null, -1)
    const goNext = navigate.bind(null, 1)

    return {
      goBack: create.reducer(goBack),
      goNext: create.reducer(goNext),

      setProvider: create.reducer<WalletProvider>((state, action) => {
        state.provider = action.payload
        goNext(state)
      }),

      setName: create.reducer<string>((state, action) => {
        state.name = action.payload
        goNext(state)
      }),

      confirmOverview: create.reducer(goNext),

      setAgreementSigned: create.reducer(state => {
        state.isAgreementSigned = true
        goNext(state)
      }),
    }
  },
})
