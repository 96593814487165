import React, { useMemo, useRef } from 'react'
import ReactSelect, { components as ReactSelectComponents } from 'react-select'
import ReactSelectAsync from 'react-select/async'
import ReactSelectCreatableAsync from 'react-select/async-creatable'
import ReactSelectCreatable from 'react-select/creatable'

import clsx from 'clsx'
import { flow } from 'lodash'

import { assignRef } from 'src/utils'

import { CustomScroll } from '../../CustomScroll'

import { IDefaultSelectOption, ISelectProps, SelectValue } from './types'

import styles from './Select.module.scss'

const isPrimitive = (x: unknown): x is string | number =>
  typeof x === 'string' || typeof x === 'number'

const normalizeOptionValue = (x: unknown) => x + ''

const defaultGetValue = flow((x: unknown): SelectValue => {
  if (isPrimitive(x)) return x
  if (typeof x === 'object' && x !== null) {
    const dict = x as Dict
    if (isPrimitive(dict.id)) return dict.id
    if (isPrimitive(dict.value)) return dict.value
  }
  return EMPTY_VALUE
}, normalizeOptionValue)

const EMPTY_VALUE = ''

const SelectComponents = {
  basic: {
    sync: ReactSelect,
    async: ReactSelectAsync,
  },
  creatable: {
    sync: ReactSelectCreatable,
    async: ReactSelectCreatableAsync,
  },
}

export function Select<
  T,
  Async extends boolean = false,
  Creatable extends boolean = false
>(props: ISelectProps<T, Async, Creatable>) {
  const {
    options = [],
    value = EMPTY_VALUE,
    invalid = false,
    clearable = false,
    disabled = false,
    loading = false,
    creatable = false,
    onChange,
    refInput,
    className,
    getOptionValue = defaultGetValue,
    getOptionLabel,
    async,
    components,
    ...rest
  } = props

  const needNormalization = options.some(isPrimitive)
  const normalizedOptions = useMemo(
    () =>
      needNormalization
        ? options.map(x => ({ value: x, label: x } as unknown as T))
        : options,
    [options, needNormalization]
  )

  /**
   * Keep in mind, that behavior of async select completely differs from sync one,
   * and interface becomes ambiguous.
   * In particular:
   * - `options` prop isn't used at all.
   *  - loaded options are stored in internal state, thus resolving option by value becomes impossible.
   *  This ref is a workaround for this problem.
   */
  const refSelectedOption = useRef<T | null>(null)

  // eslint-disable-next-line no-nested-ternary
  const valueItem = async
    ? refSelectedOption.current ??
      /* If we provide a default value - nothing has been selected yet
       * TODO: this whole ternary is a one big pile of situative patches =( */
      (value as T)
    : isPrimitive(value)
    ? normalizedOptions.find(
        x =>
          normalizeOptionValue(getOptionValue(x)) ===
          normalizeOptionValue(value)
      )
    : value

  const componentsSet = creatable
    ? SelectComponents.creatable
    : SelectComponents.basic
  const Component = async ? componentsSet.async : componentsSet.sync

  return (
    <Component
      {...rest}
      options={normalizedOptions}
      value={valueItem}
      isClearable={clearable}
      isDisabled={disabled}
      isLoading={loading}
      components={{ MenuList, ...components }}
      getOptionValue={x => normalizeOptionValue(getOptionValue(x))}
      getOptionLabel={
        needNormalization && getOptionLabel !== undefined
          ? x => getOptionLabel((x as unknown as IDefaultSelectOption<T>).value)
          : getOptionLabel
      }
      ref={instance => {
        const input = instance?.controlRef?.parentElement?.querySelector(
          'input[type=hidden]'
        ) as HTMLInputElement | null
        assignRef(refInput, input)
      }}
      className={clsx(className, styles.select, {
        [styles.invalid]: invalid,
        [styles.disabled]: disabled,
      })}
      onChange={item => {
        refSelectedOption.current = item

        const itemValue =
          item === undefined || item === null ? undefined : getOptionValue(item)
        onChange?.(itemValue, item as T)
      }}
      // 'fixed' will make sure that opening menu won't cause appearing scroll on parent element
      menuPosition="fixed"
      // when menuPosition="fixed", menu won't follow input when page scrolls
      // so just deny to scroll page when menu opened
      menuShouldBlockScroll
      // @see https://react-select.com/styles#overriding-the-theme
      theme={base => ({
        ...base,
        colors: {
          ...base.colors,
          primary: styles.select_clr_primary,
          primary25: styles.select_clr_highlight,
          neutral0: styles.select_clr_option_selected,
        },
      })}
    />
  )
}

/*
 * Add fancy scroll bar for menu list.
 * Keep in mind that being placed inside another custom scroll,
 * nested one will stop working. This is how react-simplebar works.
 * But that's quite rare case (for now, at least), and for forms should be ok.
 */
const MenuList: typeof ReactSelectComponents.MenuList = props => {
  const { maxHeight, children, className } = props
  return (
    <CustomScroll autoHeightMax={maxHeight} className={className}>
      {children}
    </CustomScroll>
  )
}
