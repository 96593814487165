import styles from './ProviderFeatures.module.scss'

interface IProviderFeaturesProps {
  features: string[]
}

export const ProviderFeatures = ({ features }: IProviderFeaturesProps) => {
  return (
    <ul>
      {features.map((item, i) => (
        <li className={styles.feature} key={i}>
          {item}
        </li>
      ))}
    </ul>
  )
}
