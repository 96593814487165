import { useCallback } from 'react'

import { useSwitch } from './useSwitch'

// ---

type ActionCallback<F> = F extends (...args: infer PT) => infer R
  ? (...args: PT) => Promise<Awaited<R>>
  : () => void

export interface IUseConfirmOptions {
  onOpen?: Func
  onCancel?: Func
  onConfirm?: Func
  onClosed?: (confirmed: boolean) => void
}

export interface IUseConfirmEmptyOptions extends IUseConfirmOptions {
  onOpen: undefined
  onCancel: undefined
  onConfirm: undefined
}

export interface IUseConfirm<
  T extends IUseConfirmOptions = IUseConfirmEmptyOptions
> {
  isOpen: boolean
  open: ActionCallback<T['onOpen']>
  cancel: ActionCallback<T['onCancel']>
  confirm: ActionCallback<T['onConfirm']>
}

// ---

export function useConfirm(): IUseConfirm

export function useConfirm<T extends IUseConfirmOptions>(
  opts: T
): IUseConfirm<T>

// ---

export function useConfirm<T extends IUseConfirmOptions>(options: T = {} as T) {
  const [checked, toggle] = useSwitch(false)
  const { onOpen, onCancel, onConfirm, onClosed } = options

  return {
    isOpen: checked,

    open: useCallback(
      async (...args: unknown[]) => {
        const res = await onOpen?.(...args)
        toggle.on()
        return res
      },
      [toggle, onOpen]
    ),

    cancel: useCallback(
      async (...args: unknown[]) => {
        const res = await onCancel?.(...args)
        toggle.off()
        await onClosed?.(false)
        return res
      },
      [toggle, onCancel, onClosed]
    ),

    confirm: useCallback(
      async (...args: unknown[]) => {
        const res = await onConfirm?.(...args)
        toggle.off()
        await onClosed?.(true)
        return res
      },
      [toggle, onConfirm, onClosed]
    ),
  }
}
