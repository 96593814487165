import { generatePath } from 'react-router-dom'

import { routes } from 'src/apps/cabinet'
import { Link, UserPhoto } from 'src/components'

import { IAvatarsListItemProps } from './types'

import styles from './Item.module.scss'

export function AvatarsListItem(props: IAvatarsListItemProps) {
  const {
    data: { id, name, photo },
  } = props

  return (
    <Link
      className={styles.item}
      variant="none"
      to={generatePath(routes.advisor.avatar_details, { id })}
    >
      <UserPhoto src={photo} alt="avatar-icon" size={60} />
      <h3 className={styles.name}>{name}</h3>
    </Link>
  )
}
