import dfnsLogo from '../../../components/WalletProviderBadge/dfns.svg'
import safeheronLogo from '../../../components/WalletProviderBadge/safeheron.png'
import { WalletProvider } from '../../../types'

import dfnsBg from './img/dfns-bg.png'
import dfnsLogoBg from './img/dfns-logo-bg.png'
import dfnsLogoCompact from './img/dfns-logo-compact.svg'
import safeheronBg from './img/safeheron-bg.png'
import safeheronLogoBg from './img/safeheron-logo-bg.png'
import safeheronLogoCompact from './img/safeheron-logo-compact.svg'

interface IProviderAssets {
  logoBg: string
  logo: string
  logoCompact: string
  bg: string
}

export interface IProviderData extends IProviderAssets {
  title: string
  description: string
  features: string[]
  link: string
}

/**
 * Shared data, used across multiple steps
 */
export const PROVIDERS_DATA: Record<WalletProvider, IProviderData> = {
  dfns: {
    title: 'DFNS',
    description:
      'Network institutional-grade multi-party computation (MPC) wallet. Built on DFNS user infrastructure.',
    features: [
      'MPC-based cryptography eliminates the potential risk of a single point of failure on private keys.',
      'Zero-trust security architecture defends against third-party malicious acts.',
      'Users have complete control of recovery phrases of all key shards.',
    ],
    link: 'https://www.dfns.co/',

    logoBg: dfnsLogoBg,
    logo: dfnsLogo,
    logoCompact: dfnsLogoCompact,
    bg: dfnsBg,
  },

  safeheron: {
    title: 'Safeheron',
    description:
      'An institutional-grade MPC self-custody platform for digital assets.',
    features: [
      'Utilizes MPC for distributed key management, increasing security by dividing key control.',
      'Securely connect to dApps, flexibly access EVM and non-EVM chains.',
      'Maintain full control and ownership, ensuring no risk of asset misappropriation.',
    ],
    link: 'https://www.safeheron.com/',

    logoBg: safeheronLogoBg,
    logo: safeheronLogo,
    logoCompact: safeheronLogoCompact,
    bg: safeheronBg,
  },
}
