// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../img/check-feature.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProviderFeatures_feature__1_dJr {\n  margin-bottom: 12px;\n  padding-left: 28px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat left center;\n  background-size: 20px;\n}", "",{"version":3,"sources":["webpack://src/apps/cabinet/features/Wallet/pages/InstallWallet/steps/components/ProviderFeatures.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,kBAAA;EACA,yEAAA;EACA,qBAAA;AACF","sourcesContent":[".feature {\n  margin-bottom: 12px;\n  padding-left: 28px;\n  background: url(\"../img/check-feature.svg\") no-repeat left center;\n  background-size: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"feature": "ProviderFeatures_feature__1_dJr"
};
export default ___CSS_LOADER_EXPORT___;
