import { Form, IFormProps } from 'src/components'

import { IAgreementPayload } from '../../../types'

export interface IBaseAgreementFormProps
  extends IFormProps<IAgreementPayload> {}

export function BaseAgreementForm(props: IBaseAgreementFormProps) {
  const { children, ...rest } = props

  return (
    <Form<IAgreementPayload> style={{ width: 300 }} {...rest}>
      {children}
    </Form>
  )
}
