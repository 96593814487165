import { Flex, Loader } from 'src/components'
import theme from 'src/styles/theme.module.scss'

import srcIconCheck from '../img/check-wallet-ready.svg'

import styles from './CreateWalletLoader.module.scss'

export const CreateWalletLoader = ({ loading }: { loading: boolean }) => {
  return (
    <Flex justify="center" align="center" className={styles.wrapper}>
      <Choose>
        <When condition={loading}>
          <Loader size={48} width={6} color={theme.th_clr_primary_600} />
        </When>

        <Otherwise>
          <img
            className={styles.success_icon}
            src={srcIconCheck}
            alt="load success"
          />
        </Otherwise>
      </Choose>
    </Flex>
  )
}
