// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Fieldset_fieldset__2Fwpy {\n  margin: 0;\n  padding: 0;\n  border: none;\n}", "",{"version":3,"sources":["webpack://src/components/base/form/Fieldset/Fieldset.module.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,UAAA;EACA,YAAA;AACF","sourcesContent":[".fieldset {\n  margin: 0;\n  padding: 0;\n  border: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldset": "Fieldset_fieldset__2Fwpy"
};
export default ___CSS_LOADER_EXPORT___;
