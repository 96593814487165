// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WalletAssets_card__1sPMx {\n  overflow: visible;\n}\n\n.WalletAssets_tabs__3adp3 {\n  /* none */\n}", "",{"version":3,"sources":["webpack://src/apps/cabinet/features/Wallet/pages/WalletDetails/WalletAssets/WalletAssets.module.scss"],"names":[],"mappings":"AAAA;EAGE,iBAAA;AADF;;AAIA;EACE,SAAA;AADF","sourcesContent":[".card {\n  // 'auto' works wrong in Safari.\n  // It behaves as `scroll`, while in other browsers as `visible`.\n  overflow: visible;\n}\n\n.tabs {\n  /* none */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "WalletAssets_card__1sPMx",
	"tabs": "WalletAssets_tabs__3adp3"
};
export default ___CSS_LOADER_EXPORT___;
