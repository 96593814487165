import { AgreementStatus } from './Agreement'
import { DateTime, UUID } from './base'

export enum NotificationType {
  Txn = 'TRANSACTION_NOTIFICATION',
  Wallet = 'WALLET_NOTIFICATION',
  SafeheronAcquisition = 'SAFEHERON_ACQUISITION',
  DfnsAcquisition = 'DFNS_ACQUISITION',
  NodeAcquisition = 'NODE_ACQUISITION',
}

export interface IBaseNotification<Payload extends object = object> {
  id: number
  uuid: UUID
  sentAt: DateTime
  createdAt: DateTime
  isRead: boolean
  notificationType: NotificationType
  payload: Payload
  meta: Dict // TODO: any specific fields?
}

export interface ITransactionNotification
  extends IBaseNotification<{
    asset: string
    amount: string
    merchant: string // what is this for?
    sourceWalletAddress: string
    destinationWalletAddress: string
    sourceAccount: string
    destinationAccount: string
    transactionId: string
    declinedBy?: string
    declineReason?: string
    status: string // TODO: enum?
  }> {
  notificationType: NotificationType.Txn
}

export interface INewWalletNotification
  extends IBaseNotification<{
    request_uuid: UUID // wallet id
    wallet_name: string
    status: string
  }> {
  notificationType: NotificationType.Wallet
}

interface IAgreementNotificationPayload {
  agreementId: string
  issuer: string // username
  recipients: string[] // username
  status: AgreementStatus
  statusPrev?: AgreementStatus
  declinedBy?: string
  declineReason: string
}

export interface IInvestmentNotification
  extends IBaseNotification<IAgreementNotificationPayload> {
  notificationType:
    | NotificationType.SafeheronAcquisition
    | NotificationType.DfnsAcquisition
}

export interface INodeAcquisitionNotification
  extends IBaseNotification<IAgreementNotificationPayload> {
  notificationType: NotificationType.NodeAcquisition
}

export type IAgreementNotification =
  | IInvestmentNotification
  | INodeAcquisitionNotification

export type INotification =
  | IAgreementNotification
  | INewWalletNotification
  | ITransactionNotification
