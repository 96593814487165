import { routes } from 'src/apps/cabinet/routes'
import { Link, Page } from 'src/components'
import { useOnMountLayout } from 'src/hooks'

import { CreateWalletLoader } from './components/CreateWalletLoader'

import styles from './styles.module.scss'

interface ICreateWalletStepProps {
  loading?: boolean
  onSubmit: () => void
}

export const CreateWalletStep = (props: ICreateWalletStepProps) => {
  const { loading = false, onSubmit } = props

  // By design: this page indicates already running process of creating wallet.
  // So we run it at layout stage, immediately showing a loading indicator.
  useOnMountLayout(() => {
    onSubmit()
  })

  return (
    <Page
      hero={<CreateWalletLoader loading={loading} />}
      title={loading ? 'Wallet setup in progress' : 'Wallet setup complete'}
      className={styles.finish_step_page}
      stickyHeader={false}
      description={
        <div className={styles.description}>
          {loading
            ? 'We are in the process of configuring your wallet. Please allow some time for the installation to complete'
            : 'Your wallet setup has been successfully completed. You may now access your dashboard.'}
        </div>
      }
      alignHeader="center"
      alignContent="center"
    >
      <Link
        to={routes.avatar.dashboard}
        variant="btn-primary"
        disabled={loading}
      >
        Enter Dashboard
      </Link>
    </Page>
  )
}
