import { FieldInput, Fieldset, Form, Page } from 'src/components'

import styles from './styles.module.scss'

interface IFormNameFields {
  name: string
}

interface IWalletNameStepProps {
  defaultName?: string
  onSubmit(data: IFormNameFields): void
}

export const WalletNameStep = ({
  onSubmit,
  defaultName,
}: IWalletNameStepProps) => (
  <Page
    title="Name your wallet"
    description={
      <div className={styles.description}>
        Assign a unique name to your wallet that reflects its intended use.
        <br />
        This will help you easily identify its purpose in the future.
      </div>
    }
    alignHeader="center"
    alignContent="center"
  >
    <Form<IFormNameFields>
      className={styles.form}
      btnSubmit="Continue"
      onSubmit={onSubmit}
    >
      <Fieldset>
        <FieldInput<IFormNameFields, 'name'>
          name="name"
          placeholder="Wallet name..."
          rules={{ required: true }}
          defaultValue={defaultName}
        />
      </Fieldset>
    </Form>
  </Page>
)
