// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModalConfirm_footer__19nwQ {\n  height: 40px;\n}", "",{"version":3,"sources":["webpack://src/components/base/Modal/ModalConfirm.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF","sourcesContent":[".footer {\n  height: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "ModalConfirm_footer__19nwQ"
};
export default ___CSS_LOADER_EXPORT___;
